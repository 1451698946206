import { handleActions, createAction } from "redux-actions";
import { all, call, take, put, select, takeEvery } from "redux-saga/effects";
import {
	getAllNotificationsRequest,
	getLastNotificationsRequest,
	sendNotificationsViewedRequest,
} from "../../api/notifications/notifications";
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader";
import {
	dangerMessage,
} from "../../components/UIKit/alert/alertReducer";

const namespace = "notifications";

const initialState = {
	all: [],
	last: [],
};

const GET_NOTIFICATIONS = `${namespace}/GET_NOTIFICATIONS`;
const SET_NOTIFICATIONS = `${namespace}/SET_NOTIFICATIONS`;
const GET_LAST_NOTIFICATIONS = `${namespace}/GET_LAST_NOTIFICATIONS`;
const SET_LAST_NOTIFICATIONS = `${namespace}/SET_LAST_NOTIFICATIONS`;

const SET_NOTIFICATIONS_VIEWED = `${namespace}/SET_NOTIFICATIONS_VIEWED`;
const SEND_NOTIFICATIONS_VIEWED = `${namespace}/SEND_NOTIFICATIONS_VIEWED`;

export const getNotifications = createAction(GET_NOTIFICATIONS);
export const setNotifications = createAction(SET_NOTIFICATIONS);
export const getLastNotifications = createAction(GET_LAST_NOTIFICATIONS);
export const setLastNotifications = createAction(SET_LAST_NOTIFICATIONS);

export const setNotificationsViewed = createAction(SET_NOTIFICATIONS_VIEWED);
export const sendNotificationsViewed = createAction(SEND_NOTIFICATIONS_VIEWED);

export default handleActions(
	{
		[SET_NOTIFICATIONS]: (state, { payload }) => ({ ...state, all: payload }),
		[SET_LAST_NOTIFICATIONS]: (state, { payload }) => ({
			...state,
			last: payload,
		}),
		[SET_NOTIFICATIONS_VIEWED]: (state, { payload }) => ({
			...state,
			last: { notifications: state["last"].notifications.map((i) => ({...i, viewed: true}))},
		}),
	},
	initialState
);

export const notificationsSelector = (state) => state[namespace].all;
export const lastNotificationsSelector = (state) => state[namespace].last;

function* getAllNotificationsSaga() {
	while (true) {
		const { payload } = yield take(GET_NOTIFICATIONS);
		//yield put(addGlobalLoadItem(GET_NOTIFICATIONS));
		try {
			const { data } = yield call(getAllNotificationsRequest, payload);

			const prevNotificationsData = yield select(notificationsSelector);

			if (payload.page !== 1) {
				data.notifications.items =
					prevNotificationsData.notifications.items.concat(
						data.notifications.items
					);
			}

			if(payload?.cb){
				payload.cb()
			}

			yield put(setNotifications(data));
		} catch (err) {
			console.log(err); 
		} finally {
			//yield put(removeGlobalLoadItem(GET_NOTIFICATIONS));
		}
	}
}

function* getLastNotificationsSaga() {
	while (true) {
		yield take(GET_LAST_NOTIFICATIONS);
		yield put(addGlobalLoadItem(GET_LAST_NOTIFICATIONS));
		try {
			const { data } = yield call(getLastNotificationsRequest);

			yield put(setLastNotifications(data));
		} catch (err) {
			console.log(err); 
		} finally {
			yield put(removeGlobalLoadItem(GET_LAST_NOTIFICATIONS));
		}
	}
}

function* sendNotificationsViewedSaga({ payload }) {
	try {
		yield call(sendNotificationsViewedRequest, payload);

		yield put(setNotificationsViewed());
	} catch (err) {
		console.log(err); 
	}
}

export function* notificationsSagas() {
	yield all([
		getAllNotificationsSaga(),
		getLastNotificationsSaga(),
		takeEvery(SEND_NOTIFICATIONS_VIEWED, sendNotificationsViewedSaga),
	]);
}
