import { createAction, handleActions } from "redux-actions"
import { all, call, delay, put, take, takeLatest } from "redux-saga/effects"
import {
	getCompanyStructureRequest,
	getDocumentRequest,
	getDocumentsRequest,
	getTranslationDocumentRequest,
	getTranslationsListRequest,
	searchRequest,
} from "../../api/knowledge-base/knowledge-base"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"

const gap = ["H1", "H2", "H3", "H4", "H5", "H6"]

const namespace = "knowledgeBase"

const GET_COMPANY_STRUCTURE = `${namespace}/GET_COMPANY_STRUCTURE`
const SET_COMPANY_STRUCTURE = `${namespace}/SET_COMPANY_STRUCTURE`

const GET_DOCUMENTS = `${namespace}/GET_DOCUMENTS`
const SET_DOCUMENTS = `${namespace}/SET_DOCUMENTS`

const GET_DOCUMENT = `${namespace}/GET_DOCUMENT`
const SET_DOCUMENT = `${namespace}/SET_DOCUMENT`
const RESET_DOCUMENT = `${namespace}/RESET_DOCUMENT`

const SET_ERROR = `${namespace}/SET_ERROR`

const SEARCH_DOCUMENTS = `${namespace}/SEARCH_DOCUMENTS`
const SET_SEARCHED_DOCUMENTS = `${namespace}/SET_SEARCHED_DOCUMENTS`
const SET_DOCUMENT_TEXT = `${namespace}/SET_DOCUMENT_TEXT`
const SET_DOCUMENT_ARTICLES = `${namespace}/SET_DOCUMENT_ARTICLES`

const GET_TRANSLATIONS_LIST = `${namespace}/GET_TRANSLATIONS_LIST`
const SET_TRANSLATIONS_LIST = `${namespace}/SET_TRANSLATIONS_LIST`
const GET_TRANSLATIONS_DOCUMENT = `${namespace}/GET_TRANSLATIONS_DOCUMENT`

const initialState = {
	companyStructure: null,
	documents: null,
	document: null,
	department: null,
	error: null,
	searchedDocuments: null,
	documentText: "",
	documentArticles: null,
	translationList: null,
}

export const getCompanyStructure = createAction(GET_COMPANY_STRUCTURE)
export const setCompanyStructure = createAction(SET_COMPANY_STRUCTURE)

export const getDocuments = createAction(GET_DOCUMENTS)
export const setDocuments = createAction(SET_DOCUMENTS)

export const getDocument = createAction(GET_DOCUMENT)
export const setDocument = createAction(SET_DOCUMENT)

export const setError = createAction(SET_ERROR)

export const searchDocuments = createAction(SEARCH_DOCUMENTS)
export const setSearchedDocuments = createAction(SET_SEARCHED_DOCUMENTS)
export const resetDocument = createAction(RESET_DOCUMENT)

export const getTranslationsList = createAction(GET_TRANSLATIONS_LIST)
export const setTranslationsList = createAction(SET_TRANSLATIONS_LIST)
export const getTranslationDocument = createAction(GET_TRANSLATIONS_DOCUMENT)

export default handleActions(
	{
		[SET_COMPANY_STRUCTURE]: (state, { payload }) => ({
			...state,
			companyStructure: payload,
		}),
		[SET_DOCUMENTS]: (state, { payload }) => ({
			...state,
			documents: payload,
		}),
		[SET_DOCUMENT]: (state, { payload }) => ({
			...state,
			document: payload,
		}),
		[SET_DOCUMENT_ARTICLES]: (state, { payload }) => ({
			...state,
			documentArticles: payload,
		}),
		[SET_DOCUMENT_TEXT]: (state, { payload }) => ({
			...state,
			documentText: payload,
		}),
		[SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
		[SET_SEARCHED_DOCUMENTS]: (state, { payload }) => ({
			...state,
			searchedDocuments: payload,
		}),
		[RESET_DOCUMENT]: (state) => ({
			...state,
			document: null,
			documentArticles: null,
			documentText: null,
		}),
		[SET_TRANSLATIONS_LIST]: (state, { payload }) => ({
			...state,
			translationList: payload,
		}),
	},
	initialState
)

export const companyStructureSelector = (state) =>
	state[namespace].companyStructure
export const documentsSelector = (state) => state[namespace].documents
export const documentSelector = (state) => state[namespace].document
export const errorSelector = (state) => state[namespace]?.error
export const searchedDocumentsSelector = (state) =>
	state[namespace].searchedDocuments
export const documentArticlesSelector = (state) =>
	state[namespace]?.documentArticles
export const documentTextSelector = (state) => state[namespace]?.documentText
export const translationListSelector = (state) =>
	state[namespace].translationList

function* getCompanyStructureSaga() {
	while (true) {
		const { payload } = yield take(GET_COMPANY_STRUCTURE)
		yield put(addGlobalLoadItem(GET_COMPANY_STRUCTURE))
		try {
			const { data } = yield call(getCompanyStructureRequest, payload)
			if (data?.company_structure_tree) {
				yield put(setCompanyStructure(data))
			}
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem(GET_COMPANY_STRUCTURE))
		}
	}
}

function* getDocumentsSaga() {
	while (true) {
		const { payload } = yield take(GET_DOCUMENTS)
		try {
			const { data } = yield call(getDocumentsRequest, payload)
			if (data?.kb_articles_list) {
				yield put(setDocuments(data?.kb_articles_list))
			}
		} catch (err) {
			console.log(err)
		} finally {
		}
	}
}

function* prepareDocumentSaga(data) {
	while (true) {
		const {
			payload: { kb_document },
		} = yield take(SET_DOCUMENT)
		const { text } = kb_document
		const parser = new DOMParser()
		const parseDoc = parser.parseFromString(text, "text/html")
		const res = parseDoc.querySelectorAll("h1,h2,h3,h4,h5,h6")
		let articles = []
		let content = text

		if (res?.length) {
			for (let i = 0; i < res?.length; i++) {
				const offset = gap.findIndex((g) => g === res[i].nodeName)
				const artixleOptions = {
					href: `#anchor-${i}`,
					title: res[i]?.textContent,
				}
				if (offset) {
					artixleOptions["gap"] = offset * 12
				}
				articles.push(artixleOptions)
				res[i].id = `anchor-${i}`
			}

			content = parseDoc.body.innerHTML
		}
		yield put({
			type: SET_DOCUMENT_ARTICLES,
			payload: articles,
		})
		yield put({
			type: SET_DOCUMENT_TEXT,
			payload: content,
		})
	}
}

function* getDocumentSaga() {
	while (true) {
		const { payload } = yield take(GET_DOCUMENT)
		try {
			const { data } = yield call(getDocumentRequest, payload)
			console.log("data", data)
			yield put(setDocument(data))

			yield put(setError(null))
		} catch (err) {
			console.log(err)

			if (err.response.status === 404) {
				yield put(setError(404))
			}
		}
	}
}

function* searchDocumentsSaga({ payload }) {
	yield delay(1000)
	if (!payload?.search?.length) {
		yield put(setSearchedDocuments(null))
	}
	if (payload?.search?.length >= 3) {
		yield call(searchSaga, payload)
	}
}

function* searchSaga(payload) {
	const { cb, ...other } = payload
	try {
		const { data } = yield call(searchRequest, other)
		yield put(setSearchedDocuments(data?.documents))
		if (typeof cb === "function") {
			cb()
		}
	} catch (err) {
		console.log(err)
	}
}

function* getTranslationsListSaga() {
	while (true) {
		const { payload } = yield take(GET_TRANSLATIONS_LIST)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(getTranslationsListRequest, payload)
			yield put(setTranslationsList(data?.translates))
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

function* getTranslationDocumentSaga(){
	while(true){
		const {payload} = yield take(GET_TRANSLATIONS_DOCUMENT)
		yield put(addGlobalLoadItem())
		try{
			const {data} = yield call(getTranslationDocumentRequest, payload)
			yield put(setDocument(data))
		}catch(err){
			console.log(err)
		}finally{
			yield put(removeGlobalLoadItem())
		}
	}
}

export function* knowledgeBaseSagas() {
	yield all([
		getCompanyStructureSaga(),
		getDocumentsSaga(),
		getDocumentSaga(),
		takeLatest(SEARCH_DOCUMENTS, searchDocumentsSaga),
		prepareDocumentSaga(),
		getTranslationsListSaga(),
		getTranslationDocumentSaga()
	])
}
