import { useEffect, useMemo, useRef } from "react"

const Paginator = ({ meta, cb = () => {} }) => {
	const ref = useRef(null)
	const page = meta?.current_page
	const lastPage = meta?.last_page || 1
	const observer = useMemo(
		() =>
			new IntersectionObserver((entries) => {
				const first = entries[0]
				if (first.isIntersecting && page < lastPage) {
					cb(page, lastPage)
				}
			}),
		[page, lastPage, cb]
	)

	useEffect(() => {
		const currentObserver = observer
		const current = ref?.current
		if (current) {
			currentObserver.observe(current)
		}
		return () => {
			if (current) {
				currentObserver.unobserve(current)
			}
		}
	}, [ref, observer])
	return <div ref={ref} />
}

export default Paginator
