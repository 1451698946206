import styles from "./status.module.sass"
import Typography from "../UIKit/base/Typography"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next";
import { TickSquareIcon, MinusSquareIcon, VideoSquareIcon, CloseSquareIcon } from '../icons'

const statusesMap = {
	in_progress: "in_progress",
	completed: "completed",
	pending: "pending",
	returned: "returned",
	not_started: "not_started",
	approved: "approved",
	product_bought: "in_my_courses",
	failed: "failed"
}

export default function Status({
	children,
	fontVariant = "medium",
	className,
	isMobile,
}) {
	const { t } = useTranslation()

	if (!statusesMap[children]) {
		return null
	}
	return (
		<>
			{!isMobile && (
				<Typography
					className={classNames(styles.wrapper, className, styles[children])}
					component="span"
					fontVariant={fontVariant}
				>
					{t(statusesMap[children])}
				</Typography>
			)}
			{isMobile && (
				<>
					{children === "completed" && <TickSquareIcon />}
					{children === "in_progress" && <VideoSquareIcon />}
					{children === "not_started" && <MinusSquareIcon />}
				</>
			)}
		</>
	)
}

Status.propTypes = {
	children: PropTypes.node,
	fontVariant: PropTypes.string,
	className: PropTypes.string,
}
