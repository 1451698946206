import classNames from "classnames"
import PropTypes from "prop-types"
import Typography from "../base/Typography"
import styles from "./CircleProgress.module.sass"

export default function CircleProgress({ value, count, className, ...props }) {
	const strokeDasharray = 135
	const strokeDashoffset = !count
		? strokeDasharray
		: strokeDasharray - (strokeDasharray / count) * value
	return (
		<div className={classNames(styles.container, className)} {...props}>
			<svg fill="none">
				<circle />
				<circle
					strokeDasharray={strokeDasharray}
					strokeDashoffset={strokeDashoffset}
				/>
			</svg>
			{!!(value && count) && (
				<Typography
					component="span"
					fontVariant="medium"
				>{`${value}/${count}`}</Typography>
			)}
		</div>
	)
}

CircleProgress.propTypes = {
	value: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	className: PropTypes.string,
}
