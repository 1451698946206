import PreloaderBorder from "../../PreloaderTemplates/PreloaderBorder";
import PreloaderBreadcrumbs from "../../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderButton from "../../PreloaderTemplates/PreloaderButton";
import PreloaderCard from "../../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../../PreloaderTemplates/PreloaderSquare";
import PreloaderMobileTitle from "../PreloaderMobileTitle";
import styles from "./index.module.sass";

const PreloaderLesson = () => {
  return (
    <>
      <PreloaderMobileTitle /> <PreloaderBreadcrumbs />
      <div className={styles.wrapper}>
        <div className={styles.lesson}>
          <div className={styles.doc__header}>
            <div>
              <PreloaderRect width={400} height={48} />
              <div className={styles.lesson__lines}>
                <PreloaderLine width={120} />
                <PreloaderLine width={120} />
              </div>
            </div>
            <div className={styles.doc__buttons}>
              <PreloaderButton /> <PreloaderButton />
            </div>
          </div>

          <div className={styles.lesson__body}>
            <div className={styles.lesson__left}>
              <PreloaderCard padding={"24px"}>
                <PreloaderRect
                  backgroundLight={true}
                  width={"100%"}
                  height={170}
                />
              </PreloaderCard>
              <PreloaderCard padding={"24px"}>
                <PreloaderRect
                  backgroundLight={true}
                  width={"100%"}
                  height={170}
                />
              </PreloaderCard>
            </div>

            <div className={styles.lesson__right}>
              <PreloaderCard padding={24}>
                <PreloaderLessonBlock />
				<PreloaderBorder />
				<PreloaderLessonBlock />
              </PreloaderCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreloaderLesson;

const PreloaderLessonBlock = () => {
	return (
	  <div className={styles.lesson__block}>
		<div className={styles.lesson__title}>
			<PreloaderSquare size={48} />
			<div className={styles.lesson__data}> 
			<PreloaderRect width={"100%"} height={24} />
			<PreloaderLine width={80} />
			</div>
			<PreloaderCircle size={24} />
		</div>
		<PreloaderBorder />
		 <PreloaderLessonLine />
		 <PreloaderLessonLine />
		 <PreloaderLessonLine />
		 <PreloaderLessonLine />
	  </div>
	);
  };
  

const PreloaderLessonLine = () => {
  return (
    <div className={styles.reviews__rating}>
      <div className={styles.reviews__line}>
        <PreloaderCircle size={24} />
        <PreloaderRect height={24} width={"calc(100% - 96px)"} />

        <PreloaderCircle size={24} />
        <PreloaderCircle size={24} />
      </div>
    </div>
  );
};
