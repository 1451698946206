import { useTranslation } from "react-i18next";
import { NoSkillsImage } from "./NoSkillsImage";
import style from "./Skills.module.sass";

const NoSkills = () => {
	const { t } = useTranslation();

	return (
		<div className={style.noItems}>
			<NoSkillsImage />
			<h3>{t("no_skills")}</h3>
			<p>{t("you_have_no_skills")}</p>
		</div>
	);
};

export default NoSkills;
