export function AbortIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.98683 21C5.99174 20.997 4.78145 20.3112 4.02482 19.2293C3.3679 18.3894 3 17.2867 3 16L3 8C3 5.99706 3.68646 4.7831 4.77071 4.0248C5.61059 3.3679 6.71332 3 8 3L16.0044 3C18.0047 3.00101 19.2174 3.68725 19.9752 4.77069C20.6321 5.61058 21 6.71332 21 8L21 16C21 16.0044 21 16.0088 21 16.0132C20.997 18.0083 20.3112 19.2185 19.2293 19.9752C18.3894 20.6321 17.2867 21 16 21H8C7.99561 21 7.99122 21 7.98683 21Z"
				fill="#6B4DFF"
			/>
		</svg>
	)
}
