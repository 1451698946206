import React, { cloneElement, useCallback, useRef } from "react"
import { api } from "../../api/http"

export function useDownloadFile() {
	const ref = useRef()
	ref.current = document.createElement("a")
	document.body.appendChild(ref.current)

	return useCallback(async (url, filename) => {
		try {
			const { data } = await api.get(url, { responseType: "blob" })
			const fileUrl = window.URL.createObjectURL(data)
			ref.current.href = fileUrl
			ref.current.download = filename
			ref.current.click()

			window.URL.revokeObjectURL(fileUrl)
			document.body.removeChild(ref.current)
		} catch (err) {
			console.log(err)
		}
	}, [])
}
export async function downloadFile(url, filename) {
	const a = document.createElement("a")
	document.body.appendChild(a)
	try {
		const { data } = await api.get(url, { responseType: "blob" })
		const fileUrl = window.URL.createObjectURL(data)
		a.href = fileUrl
		a.download = filename
		a.click()

		window.URL.revokeObjectURL(fileUrl)
		document.body.removeChild(a)
	} catch (err) {
		console.log(err)
	}
}

export default function DownloadLink({ children, href, className, ...props }) {
	const downloader = useDownloadFile()

	async function handleClick() {
		await downloader(href, props.filename)
	}

	return (
		<div className={className} onClick={handleClick}>
			{cloneElement(children, { ...props })}
		</div>
	)
}
