import { Field, Formik, Form } from "formik";
import * as Yup from "yup";

import styles from "./Onboarding.module.sass";
import { PasswordControl } from "../forms/input-control";
import Button from "../forms/button/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPasswordRequest } from "../../api/onboarding";
import { setToken } from "../../store/reducers/auth";
import { onBoardingPassword } from "../../store/reducers/auth";

export default function PasswordForm({
  setStep,
  token,
  email,
  invite,
  redirect,
  setRedirectUrl,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleSubmit(values) {
    if (invite) {
      dispatch(
        onBoardingPassword({
          ...values,
          token,
          setStep,
          redirect,
          setRedirectUrl,
          navigate,
        })
      );
    }

    if (!invite) {
      try {
        const { data } = await setPasswordRequest({
          ...values,
          email,
          token,
          redirect,
        });

        if (
          data?.authorization?.access_token &&
          data?.authorization?.refresh_token
        ) {
          localStorage.setItem("token", data?.authorization?.access_token);
          localStorage.setItem(
            "refresh_token",
            data?.authorization?.refresh_token
          );

          const redirect = data?.redirect
            ? new URL(data?.redirect).pathname
            : null;

          if (redirect) {
            setRedirectUrl(redirect);
          }
         
          dispatch(setToken(data?.authorization?.access_token));
          navigate(`/set-password`, { replace: true });
          setStep((s) => s + 1);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required(t("password_required"))
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
            t("password_helper")
          ),
      })}
      onSubmit={handleSubmit}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Field
            component={PasswordControl}
            className={styles.field}
            placeholder={t("enter_password")}
            name="password"
            label={t("please_create_password")}
            message
          />
          <div className={styles.form__button}>
            <Button type="submit" disabled={!(isValid && dirty)}>
              next
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
