import { useMemo, useEffect } from "react"
import { useLocation } from "react-router-dom"

export const scrollIntoViewWithOffset = (selector, offset) => {
	window.scrollTo({
		behavior: "smooth",
		top:
			selector.getBoundingClientRect().top -
			document.body.getBoundingClientRect().top -
			offset,
	})
}

const ScrollToHashElement = (ref) => {
	let location = useLocation()

	let hashElement = useMemo(() => {
		let hash = location.hash
		const removeHashCharacter = (str) => {
			const result = str.slice(1)
			return result
		}

		if (hash) {
			let element = document.getElementById(removeHashCharacter(hash))
			return element
		} else {
			return null
		}
	}, [location, ref])

	useEffect(() => {
		if (hashElement) {
			if (window.clientWidth > 768) {
				hashElement.scrollIntoView({
					behavior: "smooth",
					offset: 100,
					// block: "end",
					inline: "nearest",
				})
			} else {
				scrollIntoViewWithOffset(hashElement, 81)
			}
		}
	}, [hashElement])

	return null
}

export default ScrollToHashElement
