import React, { useRef, useState } from "react";
import { format, isBefore, isPast } from "date-fns";
import ClassNames from "classnames";
import * as calendar from "./utils";
import { calendarLangs } from "./utils";
import styles from "./calendar.module.sass";
import Typography from "../base/Typography";
import {
  ArrowIcon,
  QuizIcon,
  ArrowRightIcon,
  LearningPathIcon,
  BookIcon,
} from "../../icons";
import { LOCALE } from "../../localeFormat";
import { useTranslation, withTranslation } from "react-i18next";
import useClickOutside from "../../hooks/useClickOutside";
import i18next from "i18next";
import { Link } from "react-router-dom"; 

const weeksDays = calendar.getWeekDays();

const MODE = {
  DATE: "DATE",
  MONTH: "MONTH",
  YEAR: "YEAR",
};
class Calendar extends React.Component {
  state = {
    date: new Date(),
    currentDate:
      this.props?.value && this.props.withValue
        ? new Date(this.props?.value)
        : new Date(),
    range: this.props.rangeValue || [],
    mode: MODE.DATE,
  };
  ref = React.createRef();
  componentDidMount() {
    if (this.props.afterDate) {
      this.setState((state) => {
        const date = new Date(state.currentDate);
        date.setMonth(date.getMonth() + this.props.afterDate);
        return { ...state, currentDate: date };
      });
    }
  }

  handleChangeDate = (e) => {
    e.preventDefault();
    const { currentDate, mode } = this.state;
    const variant = parseInt(e.currentTarget.getAttribute("data-variant"));
    const date = new Date(currentDate);
    const year = date.getFullYear();
    const day = date.getDate();
    const { onChange, name } = this.props;
    date.setDate(1);
    if (mode === MODE.MONTH) {
      date.setFullYear(year + variant);
    }
    if (mode === MODE.YEAR) {
      date.setFullYear(year + 10 * variant);
    }
    if (mode === MODE.DATE) {
      date.setMonth(date.getMonth() + variant);
    }

    const validate = calendar.dateValidation(day, date);
    if (mode === MODE.MONTH) {
      onChange({
        name,
        value: calendar.formatDate(validate),
        mode: this.state.mode,
      });
    }

    this.setState({ currentDate: validate });
  };

  handleClick = (e) => {
    e.preventDefault();
    const { currentTarget } = e;
    const value = currentTarget.dataset.value;
    const { onChange, name } = this.props;
    onChange({
      name,
      value: calendar.formatDate(value),
      mode: this.state.mode,
    });
  };

  handleMode = (e) => {
    e.preventDefault();
    const { monthChange, yearChange } = this.props;
    const { currentTarget } = e;
    const { mode } = currentTarget.dataset;
    if (mode === MODE.MONTH && !monthChange) {
      return;
    }
    if (mode === MODE.YEAR && !yearChange) {
      return;
    }
    this.setState({ mode });
  };

  handleMonth = ({ currentTarget }) => {
    const { currentDate } = this.state;
    const { month } = currentTarget.dataset;
    const date = new Date(currentDate);
    const day = date.getDate();
    date.setDate(1);
    date.setMonth(parseInt(month));
    const validate = calendar.dateValidation(day, date);
    const { onChange, name } = this.props;
    onChange({
      name,
      value: calendar.formatDate(validate),
      mode: this.state.mode,
    });
    this.setState({ currentDate: validate, mode: MODE.DATE });
  };

  handleYear = ({ currentTarget }) => {
    const { currentDate } = this.state;
    const { year } = currentTarget.dataset;
    const date = new Date(currentDate);
    const day = date.getDate();
    date.setDate(1);
    date.setFullYear(parseInt(year));
    const validate = calendar.dateValidation(day, date);
    const { onChange, name } = this.props;
    onChange({
      name,
      value: calendar.formatDate(validate),
      mode: this.state.mode,
    });
    this.setState({ currentDate: validate, mode: MODE.DATE });
  };

  render() {
    const { currentDate, date, mode } = this.state;
    const { children, chooseDate } = this.props;

    const items = calendar.getMonthData(
      currentDate.getFullYear(),
      currentDate.getMonth()
    );
    const {
      className,
      value,
      beforeAllowed,
      monthChange = false,
      yearChange = false,
      t,
    } = this.props;
    const monthList = calendar.monthList();
    const yearsList = calendar.yearsList(currentDate);
    return (
      <Typography className={ClassNames(styles.container, className)}>
        <Leafer
          onChange={this.handleChangeDate}
          currentDate={this.state.currentDate}
          monthChange={monthChange}
          yearChange={yearChange}
          mode={mode}
          changeMode={this.handleMode}
        />
        {mode === MODE.DATE && (
          <div className={ClassNames(styles.row, styles.title)}>
            {weeksDays.map((e) => (
              <div
                className={ClassNames(styles.col, styles.weekdays)}
                key={e.valueOf()}
              >
                {e &&
                  format(e, "ccc", {
                    awareOfUnicodeTokens: true,
                    locale: LOCALE[localStorage.getItem("i18nextLng")],
                  })}
              </div>
            ))}
          </div>
        )}
        {mode === MODE.DATE &&
          items.map((item, i) => (
            <div className={ClassNames(styles.row, styles.row__values)} key={i}>
              {item.map((e, j) => {
                return ( 
                    <Day
                      e={e}
                      j={j}
                      chooseDate={this.props.chooseDate}
                      calendar={calendar}
                      value={value}
                      date={date}
                      currentDate={currentDate}
                      handleClick={this.handleClick}
                      calendarData={this.props.calendarData}
                      nextDay={item[j + 1]}
                      key={j}
                    />  
                );
              })}
            </div>
          ))}
        {mode === MODE.MONTH && (
          <div className={styles.change__wrapper}>
            <div className={styles.change__container}>
              {monthList.map((l, i) => {
                return (
                  <Typography
                    onClick={this.handleMonth}
                    className={styles.change__button}
                    data-month={i}
                    key={l}
                  >
                    {format(l, "LLLL", {
                      awareOfUnicodeTokens: true,
                      locale: LOCALE[localStorage.getItem("i18nextLng")],
                    })}
                  </Typography>
                );
              })}
            </div>
            <Typography
              component="button"
              fontVariant="semiBold"
              type="button"
              onClick={() => {
                this.setState((s) => ({ ...s, mode: MODE.DATE }));
              }}
              className={styles.mode__cancel}
            >
              {t("cancel")}
            </Typography>
          </div>
        )}
        {mode === MODE.YEAR && (
          <div className={styles.change__wrapper}>
            <div className={styles.change__container}>
              {yearsList.map((l, i) => {
                return (
                  <Typography
                    onClick={this.handleYear}
                    className={styles.change__button_year}
                    data-year={l}
                    key={l}
                  >
                    {l}
                  </Typography>
                );
              })}
            </div>
            <Typography
              component="button"
              type="button"
              onClick={() => {
                this.setState((s) => ({ ...s, mode: MODE.DATE }));
              }}
              className={styles.mode__cancel}
            >
              {t("cancel")}
            </Typography>
          </div>
        )}
        {children}
      </Typography>
    );
  }
}

export default withTranslation()(Calendar);

function Leafer({ onChange, currentDate, changeMode, mode }) {
  const { t } = useTranslation();
  return (
    <div className={ClassNames(styles.leafer)}>
      <button
        className={ClassNames(styles.leafer__button)}
        data-variant={-1}
        onClick={onChange}
        type="button"
      >
        <ArrowIcon className={ClassNames(styles.arrow, styles.arrow_left)} />
      </button>
      {mode === MODE.DATE && (
        <div className={styles.leafer__mode}>
          <Typography
            onClick={changeMode}
            fontVariant="semiBold"
            className={styles.leafer__title}
            data-mode={MODE.MONTH}
          >
            {format(currentDate, "LLLL", {
              awareOfUnicodeTokens: true,
              locale: LOCALE[localStorage.getItem("i18nextLng")],
            })}
          </Typography>
          <Typography
            onClick={changeMode}
            fontVariant="semiBold"
            className={styles.leafer__title}
            data-mode={MODE.YEAR}
          >
            {format(currentDate, "yyyy", {
              awareOfUnicodeTokens: true,
              locale: LOCALE[localStorage.getItem("i18nextLng")],
            })}
          </Typography>
        </div>
      )}
      {mode === MODE.MONTH && (
        <Typography fontVariant="semiBold" className={styles.leafer__title}>
          {format(currentDate, "yyyy", {
            awareOfUnicodeTokens: true,
            locale: LOCALE[localStorage.getItem("i18nextLng")],
          })}
        </Typography>
      )}
      {mode === MODE.YEAR && (
        <Typography fontVariant="semiBold" className={styles.leafer__title}>
          {t("select_year")}
        </Typography>
      )}
      <button
        className={styles.leafer__button}
        data-variant={1}
        onClick={onChange}
        type="button"
      >
        <ArrowIcon className={ClassNames(styles.arrow, styles.arrow_right)} />
      </button>
    </div>
  );
}

const Day = ({
  e,
  chooseDate,
  calendar,
  handleClick,
  calendarData,
  currentDate,
  date,
  value,
  j,
  nextDay,
}) => {
  const [showModal, setShowModal] = useState(false);
  const events = calendarData?.[formatDate(e)];

  const nextDayEvents = calendarData?.[formatDate(nextDay)];

  const ref = useRef(null);

  useClickOutside(ref, () => setShowModal(false));

  return (
    <div
      className={ClassNames(styles.day, {
        [styles.value_nextDayEvents]: !!nextDayEvents,
        [styles.value_events]: e && calendarData?.[formatDate(e)],
      })}
    >
      <div
        data-value={e}
        data-choose={
          chooseDate &&
          (isBefore(new Date(e), new Date(chooseDate)) ||
            calendar.isEqual(e, chooseDate))
        }
        onClick={(e) => {
          handleClick(e);
          setShowModal((s) => !s);
        }}
        className={ClassNames(styles.col, styles.value, {
          [styles.value_hidden]:
            e && e.getMonth().valueOf() !== currentDate.getMonth().valueOf(),

          [styles.value_now]: e && calendar.isEqual(e, date),
          [styles.value_selected]: e && calendar.isEqual(e, value),
          [styles.value_events]: e && calendarData?.[formatDate(e)],
        })}
        key={e.valueOf() + j}
      >
        {e && format(e, "d", { locale: LOCALE[localStorage.getItem("i18nextLng")] })}
      </div>
      {!!events?.length && showModal && (
        <div ref={ref} className={styles.modal}>
          <div className={styles.events}>
            <div className={styles.events__date}>
              <div className={styles.events__weekday}>
                <Typography>
                  {format(e, "cccc", {
                    locale: LOCALE[localStorage.getItem("i18nextLng")],
                  })}
                </Typography>
              </div>
              <div className={styles.events__day}>
                {" "}
                <Typography fontVariant="bold">{e.getDate()}</Typography>
              </div>
              <div className={styles.events__month}>
                <Typography>
                  {e.toLocaleString(i18next.language, { month: "long" })}
                </Typography>
              </div>
            </div>
            <div className={styles.events__list}>
              {!!events?.length &&
                events.map((event, index) => (
                  <Event key={index} event={event} />
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Event = ({ event }) => {
  const getLink = () => {
    if (event.model === "App\\Models\\Product" && event.type === "course")
      return `/products/${event.id}${
        event?.path_id ? `?filter[path_id]=${event?.path_id}` : ""
      }`;
    if (event.model === "App\\Models\\Product" && event.type === "path")
      return `/products/${event.id}`;
    if (event.model === "App\\Models\\Survey") return event.url;
    if (event.model === "App\\Models\\KbQuestionnaire")
      return `/surveys/${event.id}/questions/${event.question_id}`;
  };

  const getIcon = () => {
    if (event.model === "App\\Models\\Product" && event.type === "course")
      return <BookIcon />;
    if (event.model === "App\\Models\\Product" && event.type === "path")
      return <LearningPathIcon />;
    if (
      event.model === "App\\Models\\KbQuestionnaire" ||
      event.model === "App\\Models\\Survey"
    )
      return <QuizIcon />;
  };

  return (
    <Typography className={styles.event} component={Link} to={getLink()}>
      <div className={styles.event__title}>
        <div className={styles.event__icon}>{getIcon()}</div>
        <Typography className={styles.event__title}>{event?.title}</Typography>
      </div>
      <div>
        <ArrowRightIcon />
      </div>
    </Typography>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const newDate = `${year}-${month}-${day}`;

  return newDate;
};
