import {
	CheckBoxButtonGreyIcon,
	RadioBoxButtonGreyIcon,
	ScaleTypeIcon,
	TextIcon,
} from "../icons"

export const types = {
	text: {
		name: "text_answer",
		icon: TextIcon,
	},
	radio: {
		name: "select_one",
		icon: RadioBoxButtonGreyIcon,
	},
	text_performance: {
		name: "text_answer",
		icon: TextIcon,
	},
	radio_performance: {
		name: "select_one",
		icon: RadioBoxButtonGreyIcon,
	},
	checkbox: {
		name: "select_one_or_more",
		icon: CheckBoxButtonGreyIcon,
	},
	scale: {
		name: "scale",
		icon: ScaleTypeIcon,
	},
}
