import Api from "./api"
import { store } from "../.."
import { logout } from "../../store/reducers/auth"
import {
	dangerMessage,
	errorNetworkMessage,
} from "../../components/UIKit/alert/alertReducer"

function userLogout() {
	if (store) {
		const { dispatch } = store
		dispatch(logout())
	}
}

function error(data, errorData) {
	if (store) {
		const { dispatch } = store
		dispatch(dangerMessage(data, errorData))
	}
}

function errorNetwork(data) {
	if (store) {
		const { dispatch } = store
		dispatch(errorNetworkMessage(data))
	}
}
export const api = new Api({
	logout: userLogout,
	errorMessage: error,
	errorNetwork: errorNetwork,
})
