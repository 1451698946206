import { HeartIcon, HeartIconFill } from "../icons";

import styles from "./Reviews.module.sass";

const ReviewRating = ({ score, isMobile }) => {
  const maxScore = 5;

  return (
    <>
      {score && (
        <div className={styles.reviewRating}>
          {Array(score)
            .fill()
            .map((i, index) => (
              <HeartIconFill key={index} />
            ))}

          {Array(maxScore - score)
            .fill()
            .map((i, index) => (
              <HeartIcon key={index} />
            ))}
        </div>
      )}
    </>
  );
};

export default ReviewRating;

