import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { titleSelector } from "../../store/reducers/domainTheme"
import { Helmet } from "react-helmet-async"
import { blobToBase64 } from "../utils"

const BASE_URL = process.env.REACT_APP_END_POINT_BD

const Head = () => {
	const title = useSelector(titleSelector)

	const [favicon, setFavicon] = useState(null)
 

	useEffect(() => {
		async function request() {
			const req = new XMLHttpRequest()
			req.open("GET", `${BASE_URL}/v1/image/favicon-origin.ico`, true)
			req.responseType = "blob"
			req.onload = async function (event) {
				if (event?.currentTarget?.status === 404) {
					setFavicon(null)
					return
				}

				const blob = req.response
				const base64favicon = await blobToBase64(blob)
				setFavicon(base64favicon)
			}

			req.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
			req.send()
		}
		request()
	}, [])

	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={title} />
			{favicon && <link rel="icon" href={favicon} />}
		</Helmet>
	)
}

export default Head
