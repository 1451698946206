import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderList from "../PreloaderTemplates/PreloaderList";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import styles from "./Preloaders.module.sass";

const PreloaderKnowbase = () => {
  return (
    <> 
    <PreloaderMobileTitle />
    <div className={styles.knowbase}>
      <PreloaderRect width={360} height={48} />
      <PreloaderCard padding={24}>
        <PreloaderRect width={"100%"} height={24} />
      </PreloaderCard>

      <PreloaderList />
    </div>
    </>
  );
};

export default PreloaderKnowbase;
