import { Link, useLocation, useNavigate } from "react-router-dom";
import Typography from "../UIKit/base/Typography";
import { ArrowLeftIcon } from "../icons";
import styles from "./Mobile.module.sass";
import classNames from "classnames";
import { NotificationsButton } from "../Notifications/NotificationsButton";
import { NotificationIcon } from "../icons";
import { UserNavigation } from "../layouts/Header/Header";
import { useState } from "react"; 
import NotificationsPopup from "../Notifications/NotificationsPopup";
import Modal from "../UIKit/modal";
const MobileTitle = ({ title, showBackArrow = true }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const path = location?.pathname;

  const [modal, setModal] = useState(false)

  const handleBack = () => {
    if (!showBackArrow) {
      return;
    }

    if (window?.history?.length <= 1) {
      return navigate(`/project`);
    }
    return navigate(-1);
  };

  const handleCloseModal = () => {
    setModal(false)
  }
  return (
    <>
      <div className={styles.mobileHeader__title}>
        <div className={styles.mobileHeader__arrow} onClick={handleBack}>
          {showBackArrow && <ArrowLeftIcon />}
        </div>

        <div className={styles.mobileHeader__text}>
          <Typography component="h3" fontVariant="regular">
            {title}
          </Typography>
        </div>

        <div className={styles.mobileHeader__buttons}>
          <div className={styles.mobileHeader__notificationButtonMobile}>
            <div
            onClick={() => setModal(true)}
              to="/notifications-mobile"
              className={classNames(
                styles.headerMobile__item,
                styles.mobileHeader__notifications,
                {
                  [styles.headerMobile__active]:
                    path === "/notifications-mobile",
                }
              )}
            >
              <NotificationIcon />
            </div>
          </div>

          <div className={styles.mobileHeader__notificationButtonTablet}>
            <NotificationsButton />
          </div>

          <UserNavigation />
        </div>
      </div>
      <Modal
                isOpen={modal}
                className={styles.modal}
                onClose={handleCloseModal}
              >
                {(close) => (
                  <>
                    <div
                      className={styles.popUpWrap}
                    >
                       <NotificationsPopup closePopup={handleCloseModal} />
                    </div>
                  </>
                )}
              </Modal> 
    </>
  );
};

export default MobileTitle;
 
 