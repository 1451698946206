import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder";
import PreloaderBreadcrumbs from "../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderButton from "../PreloaderTemplates/PreloaderButton";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import styles from "./Preloaders.module.sass";

const PreloaderProduct = ({ type }) => {
  return (
    <>
      <PreloaderMobileTitle />
      <PreloaderBreadcrumbs />

      <div className={styles.product}>
        <div className={styles.product__header}>
          <PreloaderRect width={400} height={48} />
          <div className={styles.product__lines}>
            <PreloaderLine width={120} />
            <PreloaderLine width={120} />
          </div>
          <div className={styles.product__buttons}>
            <PreloaderButton />
            <PreloaderButton />
          </div>
        </div>
        <div className={styles.product__body}>
          <div className={styles.product__left}>
            {type === "path" && (
              <>
                <PreloaderPathCourse />

                <PreloaderPathCourse />
                <PreloaderPathCourse />
              </>
            )}

            {(type === "course" || !type) && (
              <>
                <PreloaderCard padding={24}>
                  <PreloaderModule />

                  <PreloaderBorder />
                  {/*---*/}

                  <PreloaderModule />
                </PreloaderCard>
              </>
            )}
          </div>

          <div className={styles.product__right}>
            <PreloaderCard padding={16}>
              <PreloaderRect width={"100%"} height={186} />
              <PreloaderRect width={"100%"} height={48} />
              <PreloaderBorder />
              <PreloaderRect width={"100%"} height={32} />
              <div className={styles.product__right_lines}>
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={160} />
              </div>
              <PreloaderBorder />
              <PreloaderRect width={"100%"} height={32} />
              <div className={styles.product__right_lines}>
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={160} />
              </div>
            </PreloaderCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreloaderProduct;

const PreloaderPathCourse = () => {
  return (
    <PreloaderCard>
      {" "}
      <div className={styles.pathCourse}>
        <div className={styles.pathCourse__left}>
          <PreloaderRect width={"100%"} height={136} />
        </div>
        <div className={styles.pathCourse__right}>
          <div className={styles.pathCourse__blocks}>
            <PreloaderRect width={"100%"} height={32} />
            <PreloaderRect width={320} height={32} />
          </div>
          <PreloaderLine width={160} />
          <PreloaderBorder />
          <div className={styles.pathCourse__lines}>
            {" "}
            <PreloaderLine width={120} /> <PreloaderLine width={120} />
          </div>
        </div>
      </div>
    </PreloaderCard>
  );
};

const PreloaderModule = () => {
  return (
    <>
      <div className={styles.product__block}>
        <div>
          <PreloaderSquare size={48} />

          <div className={styles.product__title}>
            <PreloaderRect width={240} height={24} />
            <PreloaderLine width={80} />
          </div>
        </div>

        <div>
          <PreloaderCircle size={24} /> <PreloaderCircle size={24} />
        </div>
      </div>
      <PreloaderBorder />
        <PreloaderModuleLine />
        <PreloaderModuleLine />
        <PreloaderModuleLine />
        <PreloaderModuleLine />
    </>
  );
};
const PreloaderModuleLine = () => {
  return (
    <>
     <div className={styles.product__block}>
        <div>
          <PreloaderCircle size={24} />
          <PreloaderLine width={160} />
        </div>

        <div>
          {" "}
          {window.innerWidth < 768 ? <PreloaderCircle size={24} /> : <PreloaderLine width={160} />}
        </div>
      </div>
    </>)}