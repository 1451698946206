import style from './textArea.module.scss'

export function createTextArea(data, onChange) {
    function handleChange(e) {
        if (onChange) {
            return onChange(e)
        }

        data.function(e.target.value)

    }

    return (
        <div className={`${style.input} ${addMargin(data)}`}>
            {createLabel(data)}
            <div className={`${style.input_wrap} ${regulationsStyleButton(data)}`}>
                <textarea
                    name={data.name}
                    className={style.input_elem}
                    style={data?.style?.hasOwnProperty('height') ? {height: data.style.height} : ''}
                    value={data.value}
                    onChange={handleChange}
                    placeholder={data.placeholder}
                    disabled={data.disabled}
                />
            </div>
            {createHelper(data)}
        </div>
    )
}

function addMargin(data) {
    if (data?.style?.hasOwnProperty('margin')) {
        return `
            ${data.style.margin.hasOwnProperty('left') && data.style.margin.left === 'left' ? style.marginLeft : ''} 
            ${data.style.margin.hasOwnProperty('right') && data.style.margin.right === 'right' ? style.marginRight : ''} 
            ${data.style.margin.hasOwnProperty('top') && data.style.margin.top === 'top' ? style.marginTop : ''} 
            ${data.style.margin.hasOwnProperty('bottom') && data.style.margin.bottom === 'bottom' ? style.marginBottom : ''}
        `
    }
    return ''
}

function createLabel(data) {
    if (data.label) {
        return <div className={style.label}>{data.label}</div>
    }
}

function createHelper(data) {
    if (data.helper) {
        return <div className={style.helper}>{data.helper}</div>
    }
}

function regulationsStyleButton(data) {
    let tmp = ''
    if (data?.style?.mainType === '' || data?.style?.mainType === 'base') tmp = style.base
    if (data?.style?.mainType === 'error') tmp = style.error
    if (data?.style?.mainType === 'disabled') tmp = style.disabled
    if (data?.style?.mainType === 'disabledFilled') tmp = style.disabledFilled
    if (data?.style?.mainType === 'readOnly') tmp = style.readOnly

    return tmp
}
