import classNames from "classnames";
import React from "react";
import styles from "./Switcher.module.scss";

function Switcher({label, className, id,...props}) {
    const htmlFor = id ? id : `checkbox-${Math.random()}`;
    return (
        <>
            <input
                className={styles.switcher}
                type="checkbox"
                id={htmlFor}
                {...props}
            />
            <label
                className={classNames(styles.switcher__label, className)}
                htmlFor={htmlFor}
            >
                {label}
            </label>
        </>
    );
}

export default Switcher;
