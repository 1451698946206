import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import styles from "./Preloaders.module.sass";

const PreloaderSupport = () => {
  return (
    <> 
    <PreloaderMobileTitle />
    <div className={styles.faq}>
      <div className={styles.doc__header}>
        {" "}
        <div className={styles.doc__buttons}>
          <PreloaderRect width={360} height={48} />
        </div>
      </div>
      <div className={styles.faq__body}>
        <div className={styles.faq__left}>
          <PreloaderCard padding={"24px"}>
            <div className={styles.faq__item}>
              <div className={styles.faq__item_left}>
                <PreloaderRect width={"100%"} height={32} />
                <PreloaderCircle size={24} />
              </div>   <div className={styles.faq__item_lines}>
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={160} />
                  </div> 
                  <PreloaderBorder />  
            </div>
            <PreloaderFaqItem /> <PreloaderFaqItem /> <PreloaderFaqItem /> <PreloaderFaqItem />
          </PreloaderCard>
        </div>

        <div className={styles.faq__right}>
          <PreloaderCard padding={24}>
            <div className={styles.reviews__line}>
              <PreloaderCircle size={24} />
              <PreloaderLine width={220} />
            </div>
            <PreloaderBorder />
            <div>
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={160} />
            </div>
            <div>
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={160} />
            </div>
          </PreloaderCard>
        </div>
      </div>
    </div>
    </>
  );
};

export default PreloaderSupport;

const PreloaderFaqItem = () => {
  return (
    <div className={styles.faq__item}>
      <div className={styles.faq__item_left}>
        <PreloaderRect width={"100%"} height={32} />
        <PreloaderCircle size={24} />
      </div>
      <PreloaderBorder />
    </div>
  );
};
