import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { courseSelector } from "../../store/reducers/course";
import {
  getReviews,
  getStatistic,
  reviewsSelector,
  statisticSelector,
} from "../../store/reducers/reviews";
import MobileHeader from "../Mobile/MobileHeader";
import Typography from "../UIKit/base/Typography";
import { ArrowLongIcon } from "../icons";
import NoItems from "./NoItems";
import styles from "./Reviews.module.sass";
import ReviewsList from "./ReviewsList";
import ReviewsStatistic from "./ReviewsStatistic";
import { useEffect, useMemo } from "react";
import {
  resetBreadcrumbs,
  setBreadcrumbs,
} from "../../store/reducers/breadcrumbs";
import PreloaderReviews from "../Preloader/Preloaders/PreloaderReviews"
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader";

const Reviews = ({ isMobile }) => {
  const { productId } = useParams();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const reviewsData = useSelector(reviewsSelector);
  const reviews = reviewsData?.reviews?.items;

  const statisticData = useSelector(statisticSelector);

  const title = statisticData?.product?.title;
  const bought = statisticData?.product?.bought;

  const areMoreReviews = reviewsData?.reviews?.has_more_pages;

  const location = useLocation();

  const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])

  const crumbs = useMemo(() => {
    const arr = bought
      ? [{ path: "/products", title: t("my_courses") }]
      : [{ path: "/catalog", title: t("catalog") }];

    if (location.state && location.state?.pathId) {
      arr.push({
        path: bought
          ? `/products/${location.state?.pathId}`
          : `/catalog/${location.state?.pathId}`,
        title: location.state?.pathName,
      });
    }

    if (productId) {
      arr.push({
        path: bought
          ? `/products/${productId}${
              location.state?.pathId
                ? `?filter[path_id]=${location.state?.pathId}`
                : ""
            }`
          : `/catalog/${productId}${
              location.state?.pathId
                ? `?filter[path_id]=${location.state?.pathId}`
                : ""
            }`,
        title: title,
      });
    }

    if (location.pathname.split("/").pop() === "reviews") {
      arr.push({
        path: `/products/${productId}/reviews`,
        title: t("reviews"),
      });
    }
    return arr;
  }, [productId, bought, title]);

  useEffect(() => {
    !loading && dispatch(setBreadcrumbs(crumbs));
    return () => {
      dispatch(resetBreadcrumbs());
    };
  }, [crumbs, dispatch, loading]);

  useEffect(() => {
    if (productId) {
      dispatch(getReviews({ product_id: productId, page: 1 }));
      dispatch(getStatistic({ productId: productId }));
    }
  }, [productId, dispatch]);

  const getMoreReviews = () => {
    if (areMoreReviews) {
      const pageNumber = reviewsData.reviews.next_page_url.split("page=")[1];
      dispatch(getReviews({ product_id: +productId, page: pageNumber }));
    }
  };

  if(loading) {
    return <PreloaderReviews />
  }

  return (
    <>
      <MobileHeader title={t("reviews")} breadcrumbsTitle={title} />

      <div className={styles.reviews}>
        <Typography
          component="h1"
          fontVariant="bold"
          className={styles.reviews__title}
        >
          {t("reviews_for")} "{title}"{" "}
        </Typography>

        {!!reviews?.length && (
          <div className={styles.reviews__inner}>
            <div className={styles.reviews__left}>
              <ReviewsList isMobile={isMobile} />
              <div className={styles.reviews__more}>
                {areMoreReviews && (
                  <Typography
                    fontVariant="semiBold"
                    className={styles.reviews__moreButton}
                    component="button"
                    onClick={getMoreReviews}
                  >
                    <ArrowLongIcon />
                    {t("more")}
                  </Typography>
                )}
              </div>
            </div>
            <ReviewsStatistic />
          </div>
        )}

        {!reviews?.length && <NoItems />}
      </div>
    </>
  );
};

export default Reviews;
