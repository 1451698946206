import styles from "./preloader.module.sass";

const PreloaderRing = () => {
  return (
    <div className={styles.ring__wrapper}>
      <div className={styles.ring}></div>
    </div>
  );
};

export default PreloaderRing;
