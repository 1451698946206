import React from "react"
import PropTypes from "prop-types"
import InputControl from "./InputControl"
import ClassNames from "classnames"
import styles from "./input-control.module.sass"

import { PasswordEye, PasswordEyeClose } from "../../icons"

const PasswordControl = ({
	className = "",
	autocomplete = false,
	...props
}) => {
	const [visible, setVisible] = React.useState(false)
	const classes = ClassNames(styles.password, className)
	const { field, form, ...attrs } = props
	const onHandleClick = () => {
		setVisible(!visible)
	}
	return (
		<InputControl
			className={classes}
			type={visible ? "text" : "password"}
			autoComplete={autocomplete}
			{...attrs}
			field={field}
			form={form}
		>
			{field.value && visible && (
				<PasswordEyeClose
					className={styles.password__image}
					onClick={onHandleClick}
				/>
			)}
			{field.value && !visible && (
				<PasswordEye
					className={styles.password__image}
					onClick={onHandleClick}
				/>
			)}
		</InputControl>
	)
}

PasswordControl.propTypes = {
	className: PropTypes.string,
}

export default PasswordControl
