import { handleActions, createAction } from "redux-actions";
import { all, call, take, put, select, takeEvery } from "redux-saga/effects";
import {
    sendCommentRequest,
    getRepliesRequest,
    getCommentsRequest,
} from "../../api/comments/comments";
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader";
import {
    successMessage,
    dangerMessage,
} from "../../components/UIKit/alert/alertReducer";

const namespace = "comments";

const initialState = {
    comments: null,
    replies: {},
};

const GET_COMMENTS = `${namespace}/GET_COMMENTS`;
const SET_COMMENTS = `${namespace}/SET_COMMENTS`;
const GET_REPLIES = `${namespace}/GET_REPLIES`;
const SET_REPLIES = `${namespace}/SET_REPLIES`;
const SEND_COMMENT = `${namespace}/SEND_COMMENT`;

export const getComments = createAction(GET_COMMENTS);
export const setComments = createAction(SET_COMMENTS);
export const getReplies = createAction(GET_REPLIES);
export const setReplies = createAction(SET_REPLIES);
export const sendComment = createAction(SEND_COMMENT);

export default handleActions(
    {
        [SET_COMMENTS]: (state, { payload }) => ({
            ...state,
            comments: payload,
        }),
        [SET_REPLIES]: (state, { payload }) => ({ ...state, replies: payload }),
    },
    initialState
);

export const commentsSelector = (state) => state[namespace].comments;
export const repliesSelector = (state) => state[namespace].replies;

function* getCommentsSaga() {
    while (true) {
        const { payload } = yield take(GET_COMMENTS);
       // yield put(addGlobalLoadItem(GET_COMMENTS));
        try {
            const { data } = yield call(getCommentsRequest, payload);

            const prevCommentsData = yield select(commentsSelector);

            if (payload.page !== 1) {
                data.comments.items = prevCommentsData.comments.items.concat(
                    data.comments.items
                );
            }

            yield put(setComments(data));
        } catch (err) {
            console.log(err); 
        } finally {
           // yield put(removeGlobalLoadItem(GET_COMMENTS));
        }
    }
}

function* getRepliesSaga() {
    while (true) {
        const { payload } = yield take(GET_REPLIES);

        yield put(addGlobalLoadItem(GET_REPLIES));
        try {
            const { data } = yield call(getRepliesRequest, payload);

            const replies = yield select(repliesSelector);

            replies[payload.parentId] = data;

            const newReplies = { ...replies };

            yield put(setReplies(newReplies));
        } catch (err) {
            console.log(err); 
        } finally {
            yield put(removeGlobalLoadItem(GET_REPLIES));
        }
    }
}

function* sendCommentSaga({ payload }) {
    try {
        const { data } = yield call(sendCommentRequest, payload.comment);

        if (!data.comment.parent_id) {
            const commentsData = yield select(commentsSelector);
            commentsData.comments.items.unshift(data?.comment);
            const newCommentsData = { ...commentsData };
            yield put(setComments(newCommentsData));
        } else {
            const commentsData = yield select(commentsSelector);

            const index = commentsData.comments.items.findIndex(
                (i) => i.id === data.comment.parent_id
            );

            commentsData.comments.items[index].replies.unshift(data?.comment);
            commentsData.comments.items[index].replies_count += 1;

            const newCommentsData = { ...commentsData };
            yield put(setComments(newCommentsData));
        }

        yield put(successMessage(data?.message));

        payload.clearInput();
    } catch (err) {
        console.log(err); 
    }
}

export function* commentsSagas() {
    yield all([
        getCommentsSaga(),
        takeEvery(GET_REPLIES, getRepliesSaga),
        takeEvery(SEND_COMMENT, sendCommentSaga),
    ]);
}
