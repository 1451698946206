import { api } from "../http"

export async function getSurveysRequest({ queryString }) {
	let url = `/v1/surveys`
	if (queryString) {
		url = `${url}?${queryString}`
	}
	return await api.get(url)
}

export async function getSurveyRequest({ surveyId, questionId }) {
	const url = `/v1/knowledge-base/questionnaires/${surveyId}/questions/${questionId}`
	return await api.get(url)
}

export async function sendAnswerRequest({ surveyId, questionId, values }) {
	const url = `/v1/knowledge-base/questionnaires/${surveyId}/questions/${questionId}`
	return await api.put(url, values)
}
export async function sendFinishRequest({ surveyId }) {
	const url = `/v1/knowledge-base/questionnaires/${surveyId}/finish`
	return await api.patch(url)
}

export async function getManualSurveyRequest({ surveyId, questionId }) {
	const url = `/v1/surveys/${surveyId}/questions/${questionId}`
	return await api.get(url)
}

export async function getPerformanceSurveyRequest({
	surveyId,
	questionId,
	personId,
}) {
	const url = `/v1/surveys/${surveyId}/questions/${questionId}/${personId}`
	return await api.get(url)
}

export async function sendPerformanceAnswerRequest({
	surveyId,
	questionId,
	values,
	personId,
}) {
	const url = `/v1/surveys/${surveyId}/questions/${questionId}/${personId}`
	return await api.put(url, values)
}

export async function sendFinishPerformanceRequest({ surveyId, personId }) {
	const url = `/v1/surveys/${surveyId}/finish/${personId}`
	return await api.patch(url)
}

export async function sendManualAnswerRequest({
	surveyId,
	questionId,
	values,
}) {
	const url = `/v1/surveys/${surveyId}/questions/${questionId}`
	return await api.put(url, values)
}

export async function sendFinishManualRequest({ surveyId }) {
	const url = `/v1/surveys/${surveyId}/finish`
	return await api.patch(url)
}
