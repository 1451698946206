import * as React from "react"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "$surfacePrimary",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 320,
		borderRadius: 12,
		padding: 12,
		boxShadow: "0px 4px 16px rgba(107, 77, 255, 0.12)",
	},
	[`& .${tooltipClasses.tooltipArrow}`]: {
		backgroundColor: "$surfacePrimary",
		boxShadow: "0px 4px 16px rgba(107, 77, 255, 0.12)",
	},
	[`& .${tooltipClasses.arrow}`]: {
		fontSize: `2rem`,
		bacgroundColor: "$surfacePrimary",
		"&:before": {
			// border: `3px solid #FBFAFF `,
			background: "$surfacePrimary",
			boxShadow: "0px 4px 16px rgba(107, 77, 255, 0.12)",
		},
	},
}))

const determineIsTruncated = (element) => {
	if (!element.current) return false
	return element.current.scrollWidth > element.current.clientWidth
}

export default function CustomizedTooltips({
	children,
	boundaryRef,
	...props
}) {
	const ref = React.useRef(null)
	const [open, setOpen] = React.useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpen = () => {
		const isTruncated = determineIsTruncated(ref)
		if (isTruncated) {
			setOpen(true)
		}
	}

	return (
		<HtmlTooltip
			open={open}
			disableTouchListener
			disableInteractive
			onClose={handleClose}
			onOpen={handleOpen}
			onMouseDown={(e) => e.preventDefault()}
			{...props}
		>
			{React.cloneElement(children, { ref })}
		</HtmlTooltip>
	)
}
