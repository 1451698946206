import axios from "axios"
import { isPast } from "date-fns"
import jwt_decode from "jwt-decode"
import { store } from "../.."
import { dangerMessage } from "../../components/UIKit/alert/alertReducer"
const BASE_URL = process.env.REACT_APP_END_POINT_BD
const URLS = {}

export default class Api {
	constructor(options = {}) {
		this.errorMessage = options.errorMessage
		this.instance = axios.create({
			// withCredentials: true, TODO: CORS
			baseURL: BASE_URL,
		})
		this.refresh = null
		this.instance.interceptors.request.use((config) => {
			if (this.token) {
				config.headers.Authorization = `Bearer ${this.token}`
			} else {
				delete config.headers["Authorization"]
			}
			config.signal = URLS[config.url]
			return config
		})

		this.instance.interceptors.response.use(
			(config) => {
				return config
			},
			(err) => {
				if (axios.isCancel(err)) {
					return Promise.reject("User cancel the network request.")
				}
				if (err?.response?.status === 401) {
					window.location.assign("/404")
				}

				if (
					(err?.response?.status === 422 || err?.response?.status === 403) &&
					err?.response?.data?.message
				) {
					if (err?.response?.data?.validation_errors) {
						this.errorMessage(
							err?.response?.data?.message,
							Object.values(err?.response?.data?.validation_errors)?.flat()
						)
					} else {
						this.errorMessage(err?.response?.data?.message)
					}
				}
				if (err?.response?.status === 404) {
					window.location.assign("/404")
				}

				if (err?.response?.status >= 400) {
					throw err
				}

				return err
			}
		)
	}

	refreshValidation = () => {
		const access = localStorage.getItem("anonymous_token")
		try {
			const decode = jwt_decode(access)

			const exp = decode.exp * 1000 - 10000 //Date - 10sek
			this.token = access
			if (isPast(new Date(exp), new Date()) && !this.refresh) {
				this.refresh = this.refreshQuery(access)
			}
		} catch (err) {
			localStorage.removeItem("anonymous_token")
			this.token = null
		}
	}
	refreshQuery = async () => {
		const refresh_token = localStorage.getItem("anonymous_refresh_token")
		await new Promise((res, rej) => {
			axios
				.post(`${BASE_URL}/v1/anonymous/refresh`, {
					refresh_token,
				})
				.then(({ data: { authorization } }) => {
					if (authorization?.access_token && authorization?.refresh_token) {
						localStorage.setItem("anonymous_token", authorization?.access_token)
						localStorage.setItem(
							"refresh_anonymous_token",
							authorization?.refresh_token
						)
					}
					this.token = authorization.access_token
					res((this.refresh = null))
				})
				.catch((err) => {
					window.location.assign("/404")
					rej((this.refresh = null))
				})
		})
	}

	request = async (url, method, data, options) => {
		this.refreshValidation()
		// this.token = localStorage.getItem("anonymous_token")TODO:
		if (!URLS[url]) {
			URLS[url] = options?.signal
		}
		try {
			if (this.refresh) {
				await this.refresh
			}
			const response = await this.instance[method](url, data, options)
			delete URLS[url]
			if (response.code === "ERR_NETWORK") {
				throw response
			}
			return response
		} catch (err) {
			delete URLS[url]
			throw err
		}
	}

	get = async (url, data = null, options = null) =>
		await this.request(url, "get", data, options)

	post = async (url, data = null, options = null) =>
		await this.request(url, "post", data, options)

	put = async (url, data = null, options = null) =>
		await this.request(url, "put", data, options)

	patch = async (url, data = null, options = null) =>
		await this.request(url, "patch", data, options)

	delete = async (url, data = null, options = null) =>
		await this.request(url, "delete", data, options)

	options = async (url, data = null, options = null) =>
		await this.request(url, "options", data, options)
}

function error(data, errorData) {
	if (store) {
		const { dispatch } = store
		dispatch(dangerMessage(data, errorData))
	}
}
const api = new Api({ errorMessage: error })

export async function anonymousAuthRequest(token) {
	const url = `/v1/anonymous/login`
	return await api.post(url, { token })
}

export async function anonymousGetQuestionRequest({ surveyId, questionId }) {
	const url = `/v1/anonymous/surveys/${surveyId}/questions/${questionId}`
	return await api.get(url)
}
export async function anonymousGetPerformanceQuestionRequest({ surveyId, questionId,personId }) {
	const url = `/v1/anonymous/surveys/${surveyId}/questions/${questionId}/${personId}`
	return await api.get(url)
}

export async function sendAnonymousManualAnswerRequest({
	surveyId,
	questionId,
	values,
}) {
	const url = `/v1/anonymous/surveys/${surveyId}/questions/${questionId}`
	return await api.put(url, values)
}

export async function sendAnonymousPerformanceAnswerRequest({
	surveyId,
	questionId,
	values,
	personId,
}) {
	const url = `/v1/anonymous/surveys/${surveyId}/questions/${questionId}/${personId}`
	return await api.put(url, values)
}

export async function sendAnonymousFinishPerformanceRequest({
	surveyId,
	personId,
}) {
	const url = `/v1/anonymous/surveys/${surveyId}/finish/${personId}`
	return await api.patch(url)
}

export async function sendAnonymousFinishSurveyRequest({ surveyId }) {
	const url = `/v1/anonymous/surveys/${surveyId}/finish`
	return await api.patch(url)
}
