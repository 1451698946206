import { useTranslation } from "react-i18next"
import Typography from "../base/Typography"
import styles from "./DatePicker.module.sass"
import { CalendarIcon } from "../../icons"
import { format } from "date-fns"
import Calendar from "../calendar/Calendar"
import { useRef, useState } from "react"
import useClickOutside from "../../hooks/useClickOutside"
import { CloseIcon } from "../../icons"
import classNames from "classnames"

export default function DatePicker({
	label,
	value,
	onChange,
	name,
	placeholder,
	startDate,
	hasRemoveIcon,
	beforeAllowed,
	monthChange = false,
	yearChange = false,
	...props
}) {
	const { t } = useTranslation()
	const [toggleCalendar, setToggleCalendar] = useState(false)
	const ref = useRef(null)

	useClickOutside(ref, () => {
		setToggleCalendar(false)
	})
	function hadleDateSelect(e) {
		if (e?.mode === "DATE") {
			setToggleCalendar(false)
		}
		onChange(e)
	}
	function removeDate(e) {
		if (!props?.readOnly) {
			onChange({ name, value: null })
		}
	}

	function handleClick(e) {
		if (!props?.readOnly) {
			setToggleCalendar((s) => !s)
		}
	}

	return (
		<div className={styles.wrapper} ref={ref}>
			<Typography className={styles.label} fontVariant="medium">
				{label}
			</Typography>
			<div className={styles.input__wrapper}>
				<Typography
					fontVariant="medium"
					type="text"
					component="input"
					placeholder={placeholder}
					value={value ? format(new Date(value), "dd.MM.yyyy") : ""}
					className={classNames(styles.input, {
						[styles.input__disabled]: props?.readOnly,
					})}
					onClick={handleClick}
					readOnly={true}
					onChange={() => {}}
				/>
				{!hasRemoveIcon && !props?.readOnly && (
					<CalendarIcon onClick={handleClick} />
				)}
				{hasRemoveIcon && !value && !props?.readOnly && (
					<CalendarIcon onClick={handleClick} />
				)}
				{hasRemoveIcon && value && !props?.readOnly && (
					<CloseIcon className={styles.removeIcon} onClick={removeDate} />
				)}
			</div>
			{toggleCalendar && (
				<div className={styles.calendar}>
					<Calendar
						value={value}
						onChange={hadleDateSelect}
						name={name}
						chooseDate={startDate}
						beforeAllowed={beforeAllowed}
						monthChange={monthChange}
						yearChange={yearChange}
						withValue
					/>
				</div>
			)}
		</div>
	)
}
