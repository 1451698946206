import { Outlet } from "react-router-dom";
import HeaderAuth from "./Header/HeaderAuth";
import styles from "./Layout.module.sass";
import { errorSelector } from "../../store/reducers/domainTheme";
import { useSelector } from "react-redux";
import Page404 from "../404";
import { createContext, useState } from "react";
import i18next from "i18next";
import Typography from "../UIKit/base/Typography";

export const AuthLayoutctx = createContext()

export default function AuthLayout() {
	const error = useSelector(errorSelector);
	const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || i18next.resolvedLanguage)

	return (
		<AuthLayoutctx.Provider value={{currentLang,setCurrentLang}}>
			{error?.response?.status !== 404 && (
				<Typography className={styles.wrapper}>
					<HeaderAuth />
					<Outlet />
				</Typography>
			)}
			{error?.response?.status === 404 && <Page404 />}
		</AuthLayoutctx.Provider>
	);
}