import { useTranslation } from "react-i18next";
import { NoItemsImage } from "./NoItemsImage";
import style from "./index.module.sass";

const NoItems = () => {
	const { t } = useTranslation();

	return (
		<div className={style.noItems}>
			<NoItemsImage />
			<h3>{t("no_knowledge_base")}</h3>
			<p>{t("no_knowledge_base_desc")}</p>
		</div>
	);
};

export default NoItems;
