import classNames from "classnames"
import parse from "html-react-parser"
import Typography from "../../base/Typography"
import styles from "./EditorView.module.sass"
import "./index.sass"
import { Children, Fragment, forwardRef, useContext, useEffect } from "react"

const EditorView = forwardRef(({ content, className, ...props }, ref) => {
	if (!content) {
		return null
	}
	return (
		<Content className={className} {...props} ref={ref}>
			{parse(content)}
		</Content>
	)
})

export default EditorView

const Content = forwardRef(({ children, className, props }, ref) => {
	const childrenToArray = Children.toArray(children)
	useEffect(() => {
		if (ref?.current) {
			const images = ref.current?.querySelectorAll("img")

			if (images?.length) {
				for (let i = 0; i < images?.length; i++) {
					const span = document.createElement("span")
					span.classList.add("editor-image")
					const div = document.createElement("div")
					images[i].parentNode.insertBefore(span, images[i])
					div.appendChild(images[i])
					span.appendChild(div)
				}
			}
		}
	}, [ref])

	return (
		<Typography
			className={classNames(styles.wrapper, className)}
			fontVariant="regular"
			{...props}
			ref={ref}
		>
			{childrenToArray?.map((c, i) => {
				if (c?.type === "table") {
					return (
						<div className={styles.table__wrapper} key={c?.type + "-" + i}>
							{c}
						</div>
					)
				}
				return <Fragment key={c?.type + "-" + i}>{c}</Fragment>
			})}
		</Typography>
	)
})
