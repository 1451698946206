import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Typography from "../UIKit/base/Typography";
import CancelButton from "../UIKit/buttons/CancelButton/CancelButton";
import Modal from "../UIKit/modal";
import { ArrowLeftIcon } from "../icons";
import styles from "./Mobile.module.sass";

const MobileBreadcrumbs = ({ title }) => {
  const [modal, setModal] = useState(false);

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  }; 
  return (
    <>
      <div className={styles.menuLeft} onClick={handleOpenModal}>
        <span>...</span>
        <ArrowLeftIcon />
        <Typography component="span" fontVariant="medium">
          {title}
        </Typography>
      </div>

      <Modal isOpen={modal} className={styles.modal} onClose={handleCloseModal}>
        {(close) => (
          <div className={styles.modal__inner}>
            <div className={styles.modal__header}>
              <Typography component="span" fontVariant="medium"></Typography>
              <CancelButton
                className={styles.modal__cancel}
                onClick={handleCloseModal}
              />
            </div>
            <div className={styles.modal__breadcrumbs}>
              <Breadcrumbs />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default MobileBreadcrumbs;
