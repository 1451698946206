import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFaq, faqSelector } from "../../store/reducers/user";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Typography from "../UIKit/base/Typography";
import FAQItem from "./FAQItem";
import style from "./FAQ.module.sass";
import MobileTitle from "../Mobile/MobileTitle"; 
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader";
import PreloaderSupport from "../Preloader/Preloaders/PreloaderFaq";

const FAQ = () => {
  const dispatch = useDispatch();
  const faq = useSelector(faqSelector);

  const { t } = useTranslation();

  const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])


  useEffect(() => {
    dispatch(getFaq());
  }, [dispatch]);

  if(loading){
			 return <PreloaderSupport />
	  }

  return (
    <>
      <MobileTitle title={t("need_help")} />

      <div className={style.faq}>
        <Typography className={style.faq__title} fontVariant="bold">
          <h2>{t("frequently_asked_questions")}</h2>
        </Typography>
        <div className={style.faq__wrapper}>
          {!!faq?.faq?.length && (
            <div>
              <div className={classNames(style.card, style.faq__questions)}>
                <div className={style.faq__list}>
                  {faq?.faq.map((item) => (
                    <FAQItem item={item} />
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className={classNames(style.card, style.faq__contacts)}>
            <Typography fontVariant="bold">
              <h2>{t("didnt_find_answer")}</h2>{" "}
            </Typography>
            {!!faq?.contacts?.support_email && (
              <>
                <p>{t("our_email")} </p>
                <p className={style.faq__contact}>
                  {faq?.contacts.support_email}
                </p>
              </>
            )}

            {!!faq?.contacts?.support_phone && (
              <>
                <p>{t("our_phone")}</p>
                <p className={style.faq__contact}>
                  {faq?.contacts.support_phone}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
