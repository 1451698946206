export function DesignBezier(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.14 7.72C19.43 8.47 20.15 9 21 9C22.1 9 23 8.1 23 7C23 5.9 22.1 5 21 5C20.15 5 19.43 5.53 19.14 6.28C19.09 6.27 19.05 6.25 19 6.25H15V5.5C15 4.68 14.32 4 13.5 4H10.5C9.68 4 9 4.68 9 5.5V6.25H5C4.95 6.25 4.91 6.27 4.86 6.28C4.57 5.53 3.85 5 3 5C1.9 5 1 5.9 1 7C1 8.1 1.9 9 3 9C3.85 9 4.57 8.47 4.86 7.72C4.91 7.73 4.95 7.75 5 7.75H7.57C5.52 9.27 4.25 11.79 4.25 14.5C4.25 14.67 4.26 14.83 4.28 15H4C3.17 15 2.5 15.67 2.5 16.5V18.5C2.5 19.33 3.17 20 4 20H6C6.22 20 6.42 19.95 6.61 19.86C7.13 19.64 7.5 19.11 7.5 18.5V16.5C7.5 15.67 6.83 15 6 15H5.77C5.77 14.97 5.78 14.94 5.78 14.91C5.76 14.77 5.76 14.64 5.76 14.5C5.76 12.03 7.03 9.77 9.02 8.6C9.06 9.37 9.71 10 10.5 10H13.5C14.29 10 14.94 9.37 14.99 8.6C16.98 9.77 18.25 12.04 18.25 14.5C18.25 14.64 18.24 14.77 18.23 14.91C18.23 14.94 18.24 14.97 18.24 15H18C17.17 15 16.5 15.67 16.5 16.5V18.5C16.5 19.11 16.87 19.64 17.39 19.86C17.58 19.95 17.78 20 18 20H20C20.83 20 21.5 19.33 21.5 18.5V16.5C21.5 15.67 20.83 15 20 15H19.72C19.74 14.83 19.75 14.67 19.75 14.5C19.75 11.79 18.48 9.27 16.43 7.75H19C19.05 7.75 19.09 7.73 19.14 7.72Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignBrush(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM10.49 16.72C10.15 17.06 9.7 17.29 9.15 17.36L7.8 17.51C7.06 17.59 6.43 16.96 6.51 16.21L6.66 14.85C6.79 13.64 7.8 12.87 8.87 12.85C8.97 12.84 9.09 12.85 9.2 12.86C9.67 12.92 10.12 13.14 10.5 13.51C10.87 13.88 11.08 14.32 11.14 14.77C11.15 14.88 11.16 14.99 11.16 15.09C11.16 15.71 10.93 16.28 10.49 16.72ZM17.44 7.55C16.72 9.35 15.07 11.71 13.5 13.25C13.27 11.83 12.14 10.71 10.7 10.51C12.24 8.93 14.62 7.26 16.43 6.53C16.75 6.41 17.07 6.5 17.27 6.7C17.47 6.92 17.57 7.23 17.44 7.55Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignColorfilter(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0974C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.67 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3688C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z"
				fill="#5B5766"
			/>
			<path
				d="M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z"
				fill="#5B5766"
			/>
			<path
				d="M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.493 13.503 21.0851 13.7248 20.8263C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8156 15.4844 14.6342 15.6478 14.5438C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0428 19.1968 10.9231 19.4452 11.0978C20.9863 12.1819 22 13.9756 22 16Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignEraser(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.0302 22H13.9902C13.5702 22 13.2402 21.66 13.2402 21.25C13.2402 20.84 13.5802 20.5 13.9902 20.5H21.0302C21.4502 20.5 21.7802 20.84 21.7802 21.25C21.7802 21.66 21.4502 22 21.0302 22Z"
				fill="#5B5766"
			/>
			<path
				d="M13.64 16.6904C14.03 17.0804 14.03 17.7104 13.64 18.1104L10.66 21.0904C9.55 22.2004 7.77 22.2604 6.59 21.2704C6.52 21.2104 6.46 21.1504 6.4 21.0904L5.53 20.2204L3.74 18.4304L2.88 17.5704C2.81 17.5004 2.75 17.4304 2.69 17.3604C1.71 16.1804 1.78 14.4204 2.88 13.3204L5.86 10.3404C6.25 9.95035 6.88 9.95035 7.27 10.3404L13.64 16.6904Z"
				fill="#5B5766"
			/>
			<path
				d="M21.1199 10.6404L16.1199 15.6404C15.7299 16.0304 15.0999 16.0304 14.7099 15.6404L8.33986 9.29043C7.94986 8.90043 7.94986 8.27043 8.33986 7.87043L13.3399 2.88043C14.5099 1.71043 16.4299 1.71043 17.5999 2.88043L21.1199 6.39043C22.2899 7.56043 22.2899 9.47043 21.1199 10.6404Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignCopy(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
				fill="#5B5766"
			/>
			<path
				d="M17.0998 2H12.8998C9.81668 2 8.37074 3.09409 8.06951 5.73901C8.00649 6.29235 8.46476 6.75 9.02167 6.75H11.0998C15.2998 6.75 17.2498 8.7 17.2498 12.9V14.9781C17.2498 15.535 17.7074 15.9933 18.2608 15.9303C20.9057 15.629 21.9998 14.1831 21.9998 11.1V6.9C21.9998 3.4 20.5998 2 17.0998 2Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignDropper(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.99988 21.8699C4.25988 21.8699 3.52988 21.5899 2.96988 21.0299C2.42988 20.4899 2.12988 19.7699 2.12988 18.9999C2.12988 18.2299 2.42988 17.5099 2.96988 16.9699L10.9406 8.99915L10.4699 8.52848C10.1799 8.23848 10.1799 7.75848 10.4699 7.46848C10.7599 7.17848 11.2399 7.17848 11.5299 7.46848L12.0006 7.93915L15.9699 3.96988C17.0899 2.84988 18.9099 2.84988 20.0299 3.96988C20.5699 4.50988 20.8699 5.22988 20.8699 5.99988C20.8699 6.76988 20.5699 7.48988 20.0299 8.02988L16.0606 11.9992L16.5299 12.4685C16.8199 12.7585 16.8199 13.2385 16.5299 13.5285C16.3799 13.6785 16.1899 13.7485 15.9999 13.7485C15.8099 13.7485 15.6199 13.6785 15.4699 13.5285L15.0006 13.0592L7.02988 21.0299C6.46988 21.5899 5.73988 21.8699 4.99988 21.8699ZM4.02988 18.0299L12.0006 10.0592L13.9406 11.9992L5.96988 19.9699C5.43988 20.4999 4.55988 20.4999 4.02988 19.9699C3.76988 19.7099 3.62988 19.3699 3.62988 18.9999C3.62988 18.6299 3.76988 18.2899 4.02988 18.0299Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignLayer(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.3702 4.89012L13.5102 2.28012C12.6502 1.90012 11.3502 1.90012 10.4902 2.28012L4.63018 4.89012C3.15018 5.55012 2.93018 6.45012 2.93018 6.93012C2.93018 7.41012 3.15018 8.31012 4.63018 8.97012L10.4902 11.5801C10.9202 11.7701 11.4602 11.8701 12.0002 11.8701C12.5402 11.8701 13.0802 11.7701 13.5102 11.5801L19.3702 8.97012C20.8502 8.31012 21.0702 7.41012 21.0702 6.93012C21.0702 6.45012 20.8602 5.55012 19.3702 4.89012Z"
				fill="#5B5766"
			/>
			<path
				d="M11.9998 17.04C11.6198 17.04 11.2398 16.96 10.8898 16.81L4.14982 13.81C3.11982 13.35 2.31982 12.12 2.31982 10.99C2.31982 10.58 2.64982 10.25 3.05982 10.25C3.46982 10.25 3.79982 10.58 3.79982 10.99C3.79982 11.53 4.24982 12.23 4.74982 12.45L11.4898 15.45C11.8098 15.59 12.1798 15.59 12.4998 15.45L19.2398 12.45C19.7398 12.23 20.1898 11.54 20.1898 10.99C20.1898 10.58 20.5198 10.25 20.9298 10.25C21.3398 10.25 21.6698 10.58 21.6698 10.99C21.6698 12.11 20.8698 13.35 19.8398 13.81L13.0998 16.81C12.7598 16.96 12.3798 17.04 11.9998 17.04Z"
				fill="#5B5766"
			/>
			<path
				d="M11.9998 22C11.6198 22 11.2398 21.92 10.8898 21.77L4.14982 18.77C3.03982 18.28 2.31982 17.17 2.31982 15.95C2.31982 15.54 2.64982 15.21 3.05982 15.21C3.46982 15.21 3.79982 15.54 3.79982 15.95C3.79982 16.58 4.16982 17.15 4.74982 17.41L11.4898 20.41C11.8098 20.55 12.1798 20.55 12.4998 20.41L19.2398 17.41C19.8098 17.16 20.1898 16.58 20.1898 15.95C20.1898 15.54 20.5198 15.21 20.9298 15.21C21.3398 15.21 21.6698 15.54 21.6698 15.95C21.6698 17.17 20.9498 18.27 19.8398 18.77L13.0998 21.77C12.7598 21.92 12.3798 22 11.9998 22Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignBlur(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.47 7.40963L15.93 6.19963C16.23 6.04963 16.31 5.65963 16.08 5.41963C15.19 4.43963 14.29 3.61963 13.57 2.99963C13.24 2.71963 12.75 2.94963 12.75 3.37963V6.95963C12.75 7.32963 13.14 7.56963 13.47 7.40963Z"
				fill="#5B5766"
			/>
			<path
				d="M12.75 19.7404V21.3903C12.75 21.6904 13.02 21.9404 13.32 21.8903C16.05 21.4404 18.33 19.6103 19.41 17.1503C19.6 16.7303 19.14 16.3104 18.72 16.5104L13.03 19.3003C12.86 19.3803 12.75 19.5503 12.75 19.7404Z"
				fill="#5B5766"
			/>
			<path
				d="M11.25 3.35963C11.25 2.92963 10.76 2.69963 10.43 2.96963C8.06997 4.94963 3.87997 9.11963 3.89997 13.8996C3.89997 17.9196 6.83997 21.2496 10.68 21.8896C10.98 21.9396 11.25 21.6896 11.25 21.3896V3.35963Z"
				fill="#5B5766"
			/>
			<path
				d="M13.4402 12.4704L18.7302 10.2504C19.0002 10.1304 19.1302 9.82043 19.0002 9.56043C18.6302 8.83043 18.2002 8.12043 17.7302 7.46043C17.5902 7.26043 17.3302 7.20043 17.1102 7.30043L13.0202 9.29043C12.8502 9.37043 12.7402 9.55043 12.7402 9.74043V12.0104C12.7502 12.3604 13.1102 12.6104 13.4402 12.4704Z"
				fill="#5B5766"
			/>
			<path
				d="M19.83 14.3097C19.99 14.2297 20.09 14.0797 20.09 13.9097C20.09 13.2697 20.01 12.6397 19.87 12.0197C19.8 11.7197 19.47 11.5597 19.19 11.6797L13.05 14.2997C12.87 14.3797 12.75 14.5597 12.75 14.7597V16.9597C12.75 17.3297 13.14 17.5697 13.47 17.4097L19.37 14.5397L19.83 14.3097Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignMask(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.2402 9.51974L20.9502 14.2297C21.2302 14.5097 21.7202 14.3597 21.8002 13.9697C21.9302 13.3297 22.0002 12.6697 22.0002 11.9997C22.0002 9.70974 21.2202 7.59974 19.9302 5.91974C19.7502 5.67974 19.3902 5.66974 19.1802 5.87974L16.2402 8.81974C16.0502 9.00974 16.0502 9.32974 16.2402 9.51974Z"
				fill="#5B5766"
			/>
			<path
				d="M14.4801 10.58L12.7101 12.35C12.5101 12.55 12.5101 12.86 12.7101 13.06L18.5301 18.88C18.7301 19.08 19.0501 19.08 19.2401 18.88C19.8601 18.23 20.3901 17.5 20.8201 16.71C20.9201 16.52 20.8801 16.27 20.7301 16.12L15.1901 10.58C14.9901 10.39 14.6701 10.39 14.4801 10.58Z"
				fill="#5B5766"
			/>
			<path
				d="M10.9398 14.1203L9.17979 15.8803C8.97979 16.0803 8.97979 16.3903 9.17979 16.5903L14.0998 21.5203C14.2298 21.6503 14.4198 21.7003 14.5898 21.6503C15.5198 21.4003 16.3998 21.0203 17.1998 20.5203C17.4698 20.3503 17.5098 19.9703 17.2798 19.7403L11.6498 14.1103C11.4498 13.9303 11.1298 13.9303 10.9398 14.1203Z"
				fill="#5B5766"
			/>
			<path
				d="M18.0802 4.06967C16.1002 2.53967 13.5302 1.73967 10.7702 2.06967C6.26016 2.60967 2.61016 6.26967 2.07016 10.7697C1.74016 13.5297 2.54016 16.0897 4.07016 18.0797C4.25016 18.3197 4.61016 18.3297 4.82016 18.1197L18.1202 4.81967C18.3302 4.60967 18.3202 4.24967 18.0802 4.06967Z"
				fill="#5B5766"
			/>
			<path
				d="M7.40023 17.6695L5.88023 19.1895C5.67023 19.3995 5.68023 19.7595 5.92023 19.9395C7.43023 21.1095 9.28023 21.8495 11.3102 21.9895C11.7402 22.0195 11.9502 21.5095 11.6402 21.1995L8.11023 17.6695C7.92023 17.4695 7.60023 17.4695 7.40023 17.6695Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignCopysuccess(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.0998 2H12.8998C9.81668 2 8.37074 3.09409 8.06951 5.73901C8.00649 6.29235 8.46476 6.75 9.02167 6.75H11.0998C15.2998 6.75 17.2498 8.7 17.2498 12.9V14.9781C17.2498 15.535 17.7074 15.9933 18.2608 15.9303C20.9057 15.629 21.9998 14.1831 21.9998 11.1V6.9C21.9998 3.4 20.5998 2 17.0998 2Z"
				fill="#5B5766"
			/>
			<path
				d="M11.1 8H6.9C3.4 8 2 9.4 2 12.9V17.1C2 20.6 3.4 22 6.9 22H11.1C14.6 22 16 20.6 16 17.1V12.9C16 9.4 14.6 8 11.1 8ZM12.29 13.65L8.58 17.36C8.44 17.5 8.26 17.57 8.07 17.57C7.88 17.57 7.7 17.5 7.56 17.36L5.7 15.5C5.42 15.22 5.42 14.77 5.7 14.49C5.98 14.21 6.43 14.21 6.71 14.49L8.06 15.84L11.27 12.63C11.55 12.35 12 12.35 12.28 12.63C12.56 12.91 12.57 13.37 12.29 13.65Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
