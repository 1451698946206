import { all, call, take, put } from "redux-saga/effects"
import {
	anonymousGetPerformanceQuestionRequest,
	anonymousGetQuestionRequest,
	sendAnonymousFinishPerformanceRequest,
	sendAnonymousFinishSurveyRequest,
	sendAnonymousManualAnswerRequest,
	sendAnonymousPerformanceAnswerRequest,
} from "../../api/anonymous/anonymous"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"
import { successMessage } from "../../components/UIKit/alert/alertReducer"

const namespace = `anonymous`

const SET_AUTH = `${namespace}/SET_AUTH`
const GET_SURVEY = `${namespace}/GET_SURVEY`
const GET_PERFORMANCE_SURVEY = `${namespace}/GET_PERFORMANCE_SURVEY`
const SET_SURVEY = `${namespace}/SET_SURVEY`
const SET_INFO = `${namespace}/SET_INFO`
const SEND_MANUAL_ANSWER = `${namespace}/SEND_MANUAL_ANSWER`
const SEND_PERFORMANCE_ANSWER = `${namespace}/SEND_PERFORMANCE_ANSWER`
const SET_QUESTION = `${namespace}/SET_QUESTION`
const SEND_FINISH_PERFORMANCE = `${namespace}/SEND_FINISH_PERFORMANCE`
const SEND_FINISH = `${namespace}/SEND_FINISH`
const LOGOUT = `${namespace}/LOGOUT`
const RESET_STATE = `${namespace}/RESET_STATE`

export const getSurvey = (payload) => ({ type: GET_SURVEY, payload })
export const getPerformanceSurvey = (payload) => ({ type: GET_PERFORMANCE_SURVEY, payload })
export const setSurvey = (payload) => ({ type: SET_SURVEY, payload })
export const setAuth = (payload) => ({ type: SET_AUTH, payload })
export const setInfo = (payload) => ({ type: SET_INFO, payload })
export const sendAnonimousManualAnswer = (payload) => ({
	type: SEND_MANUAL_ANSWER,
	payload,
})
export const sendAnonimousPerformanceAnswer = (payload) => ({
	type: SEND_PERFORMANCE_ANSWER,
	payload,
})
export const sendAnonimousFinish = (payload) => ({
	type: SEND_FINISH,
	payload,
})
export const sendAnonimousFinishPerformance = (payload) => ({
	type: SEND_FINISH_PERFORMANCE,
	payload,
})
export const setQuestion = (payload) => ({ type: SET_QUESTION, payload })
export const anonymousLogoutAction = () => ({ type: LOGOUT })
export const resetAnonymousState = () => ({ type: RESET_STATE })

const initialState = {
	auth: false,
	info: null,
	survey: {
		question: null,
		questions: [],
		next_question_id: null,
		previous_question_id: null,
		questionnaire: null,
	},
}

const handleAction = {
	DEFAULT: (state) => state,
	[SET_AUTH]: (state, { payload }) => ({ ...state, auth: payload }),
	[SET_SURVEY]: (state, { payload }) => ({ ...state, survey: payload }),
	[SET_INFO]: (state, { payload }) => ({ ...state, info: payload }),
	[SET_QUESTION]: (state, { payload }) => ({
		...state,
		survey: { ...state["survey"], question: payload },
	}),
	// [RESET_STATE]: () => initialState,
}

function* anonymousLogout() {
	while (true) {
		yield take(LOGOUT)
		localStorage.removeItem("anonymous_token")
		localStorage.removeItem("anonymous_refresh_token")
		yield put(resetAnonymousState())
	}
}

export default function anonymous(state = initialState, { type, payload }) {
	const handler = handleAction[type] || handleAction.DEFAULT
	return handler(state, { type, payload })
}

export const anonymousAuthSelector = (state) => state[namespace].auth
export const anonymousSurveySelector = (state) => state[namespace]?.survey
export const anonymousInfoSelector = (state) => state[namespace].info

function* getQuestionSaga() {
	while (true) {
		const { payload } = yield take(GET_SURVEY)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(anonymousGetQuestionRequest, {
				...payload,
			})
			yield put(setSurvey(data))
		} catch (err) {
			console.log(err)
		}finally {
			yield put(removeGlobalLoadItem())
		}
	}
}
function* getPerformanceQuestionSaga() {
	while (true) {
		const { payload } = yield take(GET_PERFORMANCE_SURVEY)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(anonymousGetPerformanceQuestionRequest, {
				...payload,
			})
			yield put(setSurvey(data))
		} catch (err) {
			console.log(err)
		}finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

function* sendAnonimousManualAnswerSaga() {
	while (true) {
		const {
			payload: { cb, ...other },
		} = yield take(SEND_MANUAL_ANSWER)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(sendAnonymousManualAnswerRequest, {
				...other,
			})
			yield put(setQuestion(data?.question))
			yield put(successMessage(data?.message))
			if (typeof cb === "function") {
				cb()
			}
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

function* sendAnonimousPerformanceAnswerSaga() {
	while (true) {
		const {
			payload: { cb, ...other },
		} = yield take(SEND_PERFORMANCE_ANSWER)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(sendAnonymousPerformanceAnswerRequest, {
				...other,
			})
			yield put(setQuestion(data?.question))
			yield put(successMessage(data?.message))
			if (typeof cb === "function") {
				cb()
			}
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

function* sendAnonimousFinishPerformanceSaga() {
	while (true) {
		const {
			payload: { cb, ...other },
		} = yield take(SEND_FINISH_PERFORMANCE)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(sendAnonymousFinishPerformanceRequest, other)
			yield put(successMessage(data?.message))
			if (typeof cb === "function") {
				cb()
			}
		} catch (err) {
			console.log(err)
		}finally{
			yield put(removeGlobalLoadItem())
		}
	}
}

function* sendFinishManualSaga() {
	while (true) {
		const {
			payload: { cb, ...other },
		} = yield take(SEND_FINISH)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(sendAnonymousFinishSurveyRequest, other)

			yield put(successMessage(data?.message))
			if (typeof cb === "function") {
				cb()
			}
		} catch (err) {
			console.log(err)
		}finally{
			yield put(removeGlobalLoadItem())
		}
	}
}

export function* anonymousSagas() {
	yield all([
		getQuestionSaga(),
		sendAnonimousManualAnswerSaga(),
		sendAnonimousFinishPerformanceSaga(),
		sendAnonimousPerformanceAnswerSaga(),
		sendFinishManualSaga(),
		anonymousLogout(),
		getPerformanceQuestionSaga()
	])
}
