const namespace = "start"
const initialState = {
	singl: null,
	status: false,
	userData: null,
	newProjectStep: 1,
	newProjectData: {
		title: "",
		domain: "",
		custom_domain: "",
		support_email: "",
		support_phone: "",
		currency_id: "",
		language: "",
		project_theme_id: "",
		primary_color: "",
		text_color: "",
		bg_color: "",
		iconpack: "",
		font: "",
	},
	projects: null,
	projectsTrashed: null,
	products: null,
	productsFlag: false,
	curentProject: null,
	curentProjectUsers: [],
	curentProjectUserId: null,
	courseId: null,
	usersRoles: [],
	currencies: [],
	timeZones: [],
	timeZonesFlag: false,
	error: [],
	themes: [],
	profile: null,
}

export const startReducer = (state = initialState, action) => {
	switch (action.type) {
		case "LOGIN":
			return { ...state, userData: action.data }
		case "NEW_PROJECTS_STEP":
			return { ...state, newProjectStep: action.data }
		case "PROJECTS":
			return { ...state, projects: action.data }
		case "PROJECTS_TRASHED":
			return { ...state, projectsTrashed: action.data }

		case "CURENT_PROJECT":
			return { ...state, curentProject: action.data }
		case "CURENT_PROJECT_USERS":
			return { ...state, curentProjectUsers: action.data }
		case "CURENT_PROJECT_USER_ID":
			return { ...state, curentProjectUserId: action.data }
		case "USERS_ROLES":
			return { ...state, usersRoles: action.data }
		case "CURRENCIES":
			return { ...state, currencies: action.data }
		case "TIME_ZONES":
			return { ...state, timeZones: action.data }
		case "TIME_ZONES_FLAF":
			return { ...state, timeZonesFlag: action.data }
		case "ERROR":
			return { ...state, error: action.data }
		case "THEMES":
			return { ...state, themes: action.data }
		case "PROFILE":
			return { ...state, profile: action.data }
		case "СOURSE_ID":
			return { ...state, courseId: action.data }
		case "PRODUCTS":
			return { ...state, products: action.data }
		case "PRODUCTS_FLAG":
			return { ...state, productsFlag: action.data }

		default:
			return state
	}
}

export const startSelector = (state) => state[namespace]
