import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	Outlet,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom"
import {
	getManualSurvey,
	getPerformanceSurvey,
	getSurvey,
	getSurveys,
	initialState,
	setSurvey,
} from "../../../store/reducers/surveys"
import { globalLoadSelector, setShowLoader } from "../../../store/reducers/loader"
import PreloaderSurvey from "../../Preloader/Preloaders/PreloaderSurvey/PreloaderSurvey"

export default function SurveysLayout() {
	const dispatch = useDispatch()
	const { surveyId, questionId, personId } = useParams()
	const [query] = useSearchParams()
	const navigate = useNavigate()
	const { surveyType } = useParams()

	
	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])

	useEffect(() => {
		dispatch(getSurveys({ queryString: query.toString() }))
	}, [dispatch, surveyId, query])

	useEffect(() => {
		if (surveyId) {
			if (surveyType === "manual") {
				dispatch(
					getManualSurvey({ surveyId, questionId, navigate, surveyType })
				)
			}
			if (surveyType === "performance") {
				dispatch(
					getPerformanceSurvey({
						surveyId,
						questionId,
						personId,
						navigate,
						surveyType,
					})
				)
			}
			if (!surveyType) {
				dispatch(getSurvey({ surveyId, questionId, navigate }))
			}
		}
		return () => {
			dispatch(setSurvey(initialState.survey))
		}
	}, [dispatch, surveyId, questionId, navigate, surveyType, personId])

	if(loading) {
		return <PreloaderSurvey />
	}
	return <Outlet />
}
