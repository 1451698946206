import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder";
import PreloaderBreadcrumbs from "../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderButton from "../PreloaderTemplates/PreloaderButton";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import styles from "./Preloaders.module.sass";
import PreloaderMobileTitle from "./PreloaderMobileTitle";

const PreloaderNotifications = () => {
  return (
    <>
      <PreloaderMobileTitle />
      <div className={styles.notifs}>
        <PreloaderRect width={460} height={48} />
        <div className={styles.notifs__buttons}>
          <PreloaderButton /> <PreloaderButton /> <PreloaderButton />{" "}
          <PreloaderButton />
        </div>
        <div className={styles.notifs__caption}>
          <PreloaderRect width={90} height={24} />
        </div>
        <div className={styles.notifs__list}>
          <PreloaderNotification />
          <PreloaderNotification />
          <PreloaderNotification />
        </div>
      </div>
    </>
  );
};

export default PreloaderNotifications;

const PreloaderNotification = () => {
  return (
    <div>
      <PreloaderCard padding={"24px"}>
        <div className={styles.notif}>
          <div className={styles.notif__data}>
            <div className={styles.notif__left}>
              <PreloaderCircle size={24} />
              <PreloaderLine width={160} />
            </div>
            <div className={styles.notif__right}>
              <PreloaderCircle size={24} />
              <PreloaderLine width={80} />
            </div>
          </div>
          <PreloaderBorder />
          <div className={styles.notif__data}>
            <div className={styles.notif__left}>
              <PreloaderLine width={480} />
            </div>
            <div className={styles.notif__right}>
              <PreloaderLine width={160} />
            </div>
          </div>
        </div>
      </PreloaderCard>
    </div>
  );
};
