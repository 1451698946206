import CancelIcon from "./cancelIcon";
import styles from './index.module.sass'
import classNames from "classnames";

export default function CancelButton({className, ...props}) {
    return (
        <button className={classNames(styles.button, className)} {...props}>
            <CancelIcon/>
        </button>
    )
}
