import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import styles from "./Preloaders.module.sass";

const PreloaderProducts = () => {
  return (
    <>
    <PreloaderMobileTitle />
      <div className={styles.products__panel}>
        <PreloaderRect width={360} height={48} />
        <div className={styles.products__buttons}>
          <PreloaderRect width={96} height={48} />
          <PreloaderRect width={96} height={48} />
          <PreloaderRect width={96} height={48} />
        </div>
      </div>
      <div className={styles.products}>
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
      </div>
    </>
  );
};

export default PreloaderProducts;

export const ProductCard = () => {
  return (
    <div className={styles.productsCard}>
      <PreloaderCard width={"100%"} height={280}>
        <PreloaderRect width={"100%"} height={120} />
        <div className={styles.productsCard__inner}>
          <div className={styles.productsCard__left}>
            <PreloaderLine width={88} />
            <PreloaderRect width={"100%"} height={32} />
            <PreloaderLine width={160} />
            <PreloaderLine width={88} />
          </div>{" "}
        </div>
      </PreloaderCard>
    </div>
  );
};
