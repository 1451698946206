import i18next from 'i18next';
import { useSelector } from "react-redux";
import {
	notificationsSelector
} from "../../store/reducers/notifications";
import styles from "./Notifications.module.sass";
import NotificationsItem from "./NotificationsItem";
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';

const NotificationsList = () => {
	const {t} = useTranslation()

	const notificationsData = useSelector(notificationsSelector);
	const notifications = formatNotificationsArray(
		notificationsData?.notifications?.items
	);

	return (
		<div className={styles.notifications__list}>
			{!!notifications?.length &&
				notifications.map((item) => (
					<div key={item.date}>
						{item.date && <div className={styles.notification__date}>{formatDate(item.date, i18next.language, t)}</div>}
						<div className={styles.notifications__list_items}>
						{item.items.map((item) => (
							<NotificationsItem key={item.id} item={item} />
						))}
						</div>
					</div>
				))}
		</div>
	);
};

export default NotificationsList;

function formatDate(date, lang = "en", t) {

	 

	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);
	const formatDateString = (date) => date.toISOString().split('T')[0];


	const dateString = new Date(date)

	if(formatDateString(dateString) === formatDateString(today))
		{
			return t("today")
		}

		if(formatDateString(dateString) === formatDateString(today))
			{
				return t("yesterday")
			}
	return dateString.toLocaleString(lang, { day: "numeric", month: 'short', year: 'numeric' });
}

function formatNotificationsArray(notifications) {
	if (notifications?.length) {
		var groups = {};

		notifications.forEach(function (val) {
			var date = val.sent_at.split(" ")[0];
			if (date in groups) {
				groups[date].push(val);
			} else {
				groups[date] = new Array(val);
			}
		});

		const groupArrays = Object.keys(groups).map((date) => {
			return {
				date,
				items: groups[date],
			};
		});

		return groupArrays;
	}
}
