import { createPortal } from "react-dom"
import styles from "./AiWidget.module.sass"
import { useDispatch, useSelector } from "react-redux"
import { authSelector } from "../../store/reducers/auth"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import {
	AiAssistantIcon,
	DocumentIcon,
	LessonIcon,
	QuizIcon,
	TaskIcon,
} from "../icons"
import Typography from "../UIKit/base/Typography"
import { useTranslation } from "react-i18next"
import CancelButton from "../UIKit/buttons/CancelButton/CancelButton"
import AutoExpandInput from "../forms/AutoExpandInput/AutoExpandInput"
import { MessageIcon } from "./MessageIcon"

import { Link, useLocation } from "react-router-dom"
import Avatar from "../UIKit/avatarView/AvatarView"
import { LeftArrow, RightArrow } from "./Arrows"
import classNames from "classnames"
import {
	assistantDocumentsSelector,
	assistantHistorySelector,
	assistantLastMessageIdSelector,
	assistantLoadSelector,
	assistantMessagesSelector,
	getHistory,
	getLazyHistory,
	resetState,
	searchDocuments,
	setRequest,
} from "../../store/reducers/assistant"
import { LoadIcon } from "../KnowledgeBase/SearchAi/LoadIcon"
import { localeFormat } from "../localeFormat"
import { CancelRequest } from "../hooks/CancelRequest"
import { AbortIcon } from "./AbortIcon"
import Slider from "../UIKit/slider/Slider"

const controller = new CancelRequest()
const searchController = new CancelRequest()

/*
let root
 
if (typeof document === "object") {
	root = document.createElement("div")
	root.setAttribute("class", styles.root)
	document.body.appendChild(root) 
}
*/

const WidgetWrapper = ({ children }) => {
	const auth = useSelector(authSelector)
	const [toggle, setToggle] = useState(false)
	const { pathname } = useLocation()

	const rootElement = document.getElementById('root');
	//rootElement.setAttribute("class", styles.root)
	// useEffect(() => {
	// 	if (toggle) {
	// 		document.documentElement.style.overflowY = "hidden"
	// 	}

	// 	return () => {
	// 		document.documentElement.style.overflowY = "auto"
	// 	}
	// }, [toggle])

	if (!auth || pathname.includes("anonymous")) {
		return null
	}
	function onClose() {
		setToggle(false)
	}
	function handleOpen() {
		setToggle(true)
	}

	return createPortal(
		<div className={styles.root}>
			<button type="button" onClick={handleOpen} className={styles.button}>
				<AiAssistantIcon />
			</button>
			{toggle ? children(onClose) : null}
		</div>,
		rootElement
	)
}

const AiWidget = () => {
	const { t } = useTranslation()
	const [value, setValue] = useState("")
	const history = useSelector(assistantHistorySelector)
	const messages = useSelector(assistantMessagesSelector)
	const dispatch = useDispatch()
	const docs = useSelector(assistantDocumentsSelector)
	const load = useSelector(assistantLoadSelector)
	const ref = useRef(null)

	const handleChange = ({ target }) => {
		const { value } = target
		if (value?.length > 4000 && !messages) {
			return
		}
		setValue(value)
		dispatch(
			searchDocuments({
				search: value,
				options: { signal: searchController.signal },
			})
		)
	}

	const handleSearch = useCallback(() => {
		if (!value || value?.length > 4000) return
		searchController.abort()
		dispatch(
			setRequest({
				message: value,
				options: { signal: controller.signal },
				cb: () => {
					setValue("")
				},
			})
		)
	}, [dispatch, value])

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.keyCode == 13 && e.shiftKey) {
				e.preventDefault()
				handleSearch()
			}
		}

		window.addEventListener("keydown", handleKeyDown, false)
		return () => {
			window.removeEventListener("keydown", handleKeyDown, false)
		}
	}, [handleSearch])

	return (
		<WidgetWrapper>
			{(close) => (
				<div ref={ref} className={styles.wrapper}>
					<Typography component="header" className={styles.header}>
						<AiAssistantIcon />
						{t("AI_assistant")}
						<CancelButton className={styles.cancel} onClick={close} />
					</Typography>
					<Main history={history} messages={messages} />
					<footer className={styles.footer}>
						{docs?.length > 0 && (
							<ul className={styles.documents}>
								{docs?.map((s, i) => (
									<Typography
										key={`${s?.id}-${s?.status}-${i}`}
										fontVariant="medium"
										component="li"
										onClick={close}
									>
										<Link
											target="_blank"
											to={`/knowledge-base/documents/${s?.id}`}
										>
											<DocumentIcon />
											<p>{s?.name}</p>
										</Link>
									</Typography>
								))}
							</ul>
						)}
						<div className={styles.footer__action}>
							<AutoExpandInput
								initialHeight={40}
								className={styles.input}
								value={value}
								onChange={handleChange}
								placeholder={t("message_to_Ai")}
							/>
							{load ? (
								<button
									onClick={() => {
										controller.abort()
									}}
									className={styles.message__button}
									type="button"
								>
									<AbortIcon />
								</button>
							) : (
								<button
									onClick={handleSearch}
									className={classNames(styles.message__button, {
										[styles.active]: value?.length,
									})}
									type="button"
								>
									<MessageIcon />
								</button>
							)}
						</div>
					</footer>
				</div>
			)}
		</WidgetWrapper>
	)
}

export default AiWidget

const Message = ({
	owner,
	children,
	component = "p",
	documents,
	scroll,
	media,
}) => {
	return (
		<Typography
			className={classNames(styles.message__wrapper, {
				[styles.message__wrapper_owner]: owner,
			})}
		>
			<Avatar
				name={owner ? "M" : "a i"}
				textClassName={classNames(styles.avatar, {
					[styles.avatar_owner]: owner,
				})}
			/>
			<Typography
				component="div"
				className={classNames(styles.message, {
					[styles.message_owner]: owner,
				})}
			>
				<Typography className={styles.message__content} component={component}>
					{children}
				</Typography>
				<RelatedMedia media={media} />
				<RelatedDocuments documents={documents} />
				{owner ? <RightArrow /> : <LeftArrow />}
			</Typography>
			{scroll ? <ScrollRef /> : null}
		</Typography>
	)
}

const Main = ({ history, messages }) => {
	const dispatch = useDispatch()
	const load = useSelector(assistantLoadSelector)
	const lastId = useSelector(assistantLastMessageIdSelector)
	const { t } = useTranslation()
	const ref = useRef(null)
	const [lazyLoad, setLazyLoad] = useState(false)

	useEffect(() => {
		dispatch(getHistory({}))
		return () => {
			dispatch(resetState())
		}
	}, [dispatch])

	if (!history) {
		return null
	}

	const handleScroll = (e) => {
		const { target } = e

		const { meta } = history
		const perPage = meta?.per_page
		const page = meta?.current_page
		const lastPage = meta?.last_page
		if (target.scrollTop <= 20 && !load && !lazyLoad && page < lastPage) {
			dispatch(
				getLazyHistory({
					perPage: perPage,
					page: page + 1,
					setLoad: setLazyLoad,
				})
			)
		}
	}

	return (
		<Typography
			onScroll={handleScroll}
			ref={ref}
			component="main"
			className={styles.main}
		>
			{history?.data
				? Object.keys(history?.data)?.map((h, i) => {
						if (!history?.data[h]?.length) {
							return null
						}
						const scope = history?.data[h]
						return (
							<Fragment key={`${h}-date-${i}`}>
								{i === 0 && lazyLoad ? <LoadRef load={lazyLoad} /> : null}
								<Typography
									fontVariant="medium"
									className={styles.message__date}
								>
									{getDate(h)}
								</Typography>
								{scope?.length > 0 &&
									scope.map((s, j) => (
										<Fragment key={`${s}-${j}`}>
											{messages[s]?.request?.message ||
											messages[s]?.request?.related_documents?.length ? (
												<Message
													documents={messages[s]?.request?.related_documents}
													media={messages[s]?.request?.related_media}
													owner
												>
													{messages[s]?.request?.message}
												</Message>
											) : null}
											{messages[s]?.response?.message ? (
												<Message
													scroll={s == lastId}
													documents={messages[s]?.response?.related_documents}
													media={messages[s]?.response?.related_media}
												>
													{messages[s]?.response?.message}
												</Message>
											) : null}
										</Fragment>
									))}
								{load ? <ScrollRef /> : null}
							</Fragment>
						)
				  })
				: null}
			{load ? (
				<Message component="div">
					<div className={styles.load__container}>
						<LoadIcon className={styles.load_icon} />
						{t("ai_is_working")}
					</div>
				</Message>
			) : null}
		</Typography>
	)
}

function ScrollRef() {
	const ref = useRef(null)
	const load = useSelector(assistantLoadSelector)
	useEffect(() => {
		ref.current.scrollIntoView()
	}, [ref, load])
	return <div ref={ref} />
}

function LoadRef({ load }) {
	const loadRef = useRef(null)
	useEffect(() => {
		loadRef.current.scrollIntoView()
	}, [loadRef, load])
	return (
		<div
			className={classNames(
				styles.load__container,
				styles.load__container_lazy
			)}
			ref={loadRef}
		>
			<LoadIcon className={styles.load_icon} />
		</div>
	)
}

function getDate(date) {
	if (!date) return null
	const [y, m, d] = date.split("-")
	return localeFormat(new Date(y, m, d))
}

const DOCUMENT_TYPES = {
	document: {
		icon: DocumentIcon,
	},
	task: {
		icon: TaskIcon,
	},
	lesson: {
		icon: LessonIcon,
	},
	quiz: {
		icon: QuizIcon,
	},
}

function RelatedDocuments({ documents }) {
	const { t } = useTranslation()
	if (!documents?.length) {
		return null
	}
	return (
		<div className={styles.document__wrapper}>
			<Typography fontVariant="medium" className={styles.document__list_title}>
				{t("based_on_documents", { count: documents?.length })}
			</Typography>
			<ul>
				{documents?.map((d, i) => {
					const Icon =
						DOCUMENT_TYPES[d?.type]?.icon || DOCUMENT_TYPES.document?.icon
					let to = `/knowledge-base/documents/${d?.id}`
					if (d?.type !== "document") {
						to = `/products/${d?.additional?.product_id}/${d?.type}/${d?.id}`
					}
					return (
						<li key={`${d?.id}-link-${i}`} className={styles.document__link}>
							<Typography
								to={to}
								fontVariant="medium"
								component={Link}
								target="_blank"
							>
								{Icon ? <Icon /> : null}
								{d?.name}
							</Typography>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

function RelatedMedia({ media: mediaFiles = [] }) {
	const [show, setShow] = useState(null)
	if (!mediaFiles?.length) {
		return null
	}

	function handleClick({ currentTarget }) {
		const index = +currentTarget.dataset.index

		setShow(+index)
	}

	return (
		<div className={styles.media__wrapper}>
			{mediaFiles?.map((m, i) => {
				const urlArr = m?.mime.split("/")
				const type = urlArr[urlArr.length - 1]
				if (type !== "mp4") {
					return (
						<img
							data-index={i}
							onClick={handleClick}
							key={m?.id}
							src={m?.url}
							alt=""
						/>
					)
				}
				return (
					<video
						data-index={i}
						onClick={handleClick}
						key={m?.id}
						src={m?.url}
						type="video/mp4"
					/>
				)
			})}

			{show !== null ? (
				<Galery show={show} media={mediaFiles} setShow={() => setShow(null)} />
			) : null}
		</div>
	)
}

function Galery({ setShow, show, media }) {
	function handleClick() {
		setShow()
	}
	return (
		<div className={styles.overlay}>
			<div className={styles.container}>
				<button
					className={classNames(styles.button_slide, styles.close)}
					onClick={setShow}
				>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4.70078 15.2994C4.82578 15.4244 4.98411 15.4828 5.14245 15.4828C5.30078 15.4828 5.46745 15.4244 5.58411 15.2994L10.0008 10.8828L14.4174 15.2994C14.5424 15.4244 14.7008 15.4828 14.8591 15.4828C15.0174 15.4828 15.1758 15.4244 15.3008 15.2994C15.5424 15.0578 15.5424 14.6578 15.3008 14.4161L10.8841 9.99944L15.3008 5.58277C15.5424 5.3411 15.5424 4.94111 15.3008 4.69944C15.0591 4.45777 14.6591 4.45777 14.4174 4.69944L10.0008 9.11611L5.58411 4.69944C5.34245 4.45777 4.94245 4.45777 4.70078 4.69944C4.45911 4.94111 4.45911 5.3411 4.70078 5.58277L9.11745 9.99944L4.70078 14.4161C4.45911 14.6578 4.45911 15.0578 4.70078 15.2994Z"
							fill="black"
						/>
					</svg>
				</button>
				<Slider initialSlide={show}>
					{media.map((m, i) => {
						const Icon =
							DOCUMENT_TYPES[m?.type]?.icon || DOCUMENT_TYPES.document?.icon
						let to = `/knowledge-base/documents/${m?.document_id}`
						if (m?.type !== "document") {
							to = `/products/${m?.additional?.product_id}/${m?.type}/${m?.document_id}`
						}
						const urlArr = m?.mime.split("/")
						const type = urlArr[urlArr.length - 1]
						return (
							<div className={styles.slide} key={m.id}>
								{type !== "mp4" ? (
									<img src={m.url} alt="image" />
								) : (
									<video
										controls
										src={m?.url}
										type="video/mp4"
										controlslist="nodownload"
									/>
								)}
								<div className={styles.slide__description_wrapper}>
									{Icon && <Icon className={styles.icon} />}
									<Typography className={styles.slide__description}>
										{m?.description}
									</Typography>
									<Link
										className={styles.link}
										to={to}
										target="_blank"
										onClick={handleClick}
										type="button"
									>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M6.00065 15.1667H10.0007C13.6207 15.1667 15.1673 13.62 15.1673 10V8.66671C15.1673 8.39337 14.9407 8.16671 14.6673 8.16671C14.394 8.16671 14.1673 8.39337 14.1673 8.66671V10C14.1673 13.0734 13.074 14.1667 10.0007 14.1667H6.00065C2.92732 14.1667 1.83398 13.0734 1.83398 10V6.00004C1.83398 2.92671 2.92732 1.83337 6.00065 1.83337H7.33398C7.60732 1.83337 7.83398 1.60671 7.83398 1.33337C7.83398 1.06004 7.60732 0.833374 7.33398 0.833374H6.00065C2.38065 0.833374 0.833984 2.38004 0.833984 6.00004V10C0.833984 13.62 2.38065 15.1667 6.00065 15.1667Z" />
											<path d="M14.6674 5.03337C14.3941 5.03337 14.1674 4.80671 14.1674 4.53337V2.54003L9.02061 7.68686C8.92061 7.78686 8.79395 7.83353 8.66728 7.83353C8.54061 7.83353 8.41395 7.78686 8.31395 7.68686C8.12061 7.49353 8.12061 7.17353 8.31395 6.9802L13.4608 1.83337H11.4674C11.1941 1.83337 10.9674 1.60671 10.9674 1.33337C10.9674 1.06004 11.1941 0.833374 11.4674 0.833374H14.6674C14.9408 0.833374 15.1674 1.06004 15.1674 1.33337V4.53337C15.1674 4.80671 14.9408 5.03337 14.6674 5.03337Z" />
										</svg>
									</Link>
								</div>
							</div>
						)
					})}
				</Slider>
			</div>
		</div>
	)
}
