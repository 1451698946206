import Color from "color";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#6b4dff29",
  secondaryGray: "#A4A1B2",
};

export function NoItemsImage(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme.title) {
      const { primary_color } = theme;
      const bg_color = theme.negate
        ? Color(theme.bg_color).negate().hex()
        : theme.bg_color;
      const text_color = theme.negate
        ? Color(theme.text_color).negate().hex()
        : theme.text_color;

      const pureWhite = theme.negate ? "#000000" : "#ffffff";

      const secondaryGray = Color(pureWhite)
        .mix(Color(bg_color))
        .mix(Color(text_color), 0.4)
        .hex();
      const secondaryLightPurple = Color(pureWhite)
        .mix(Color(bg_color))
        .mix(Color(primary_color), 0.16)
        .hex();
      const secondaryBackground = Color(pureWhite)
        .mix(Color(bg_color))
        .mix(Color(primary_color), 0.04);

      setColors({
        primary_color,
        bg_color,
        secondaryBackground,
        secondaryLightPurple,
        secondaryGray,
      });
    }
  }, [theme]);

  return (
    <svg
      width="331"
      height="268"
      viewBox="0 0 331 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M179.375 198V219.875C179.375 236.762 165.637 250.5 148.75 250.5H61.25C44.3625 250.5 30.625 236.762 30.625 219.875V214.187C30.625 200.45 41.825 189.25 55.5625 189.25H170.625C175.437 189.25 179.375 193.187 179.375 198Z"
        fill={colors?.secondaryLightPurple}
      />
      <path
        d="M135.625 75.5H74.375C39.375 75.5 30.625 84.25 30.625 119.25V185.575C37.275 179.712 46.025 176.125 55.5625 176.125H170.625C175.437 176.125 179.375 172.187 179.375 167.375V119.25C179.375 84.25 170.625 75.5 135.625 75.5ZM113.75 152.062H70C66.4125 152.062 63.4375 149.087 63.4375 145.5C63.4375 141.912 66.4125 138.937 70 138.937H113.75C117.337 138.937 120.312 141.912 120.312 145.5C120.312 149.087 117.337 152.062 113.75 152.062ZM140 121.437H70C66.4125 121.437 63.4375 118.462 63.4375 114.875C63.4375 111.287 66.4125 108.312 70 108.312H140C143.587 108.312 146.562 111.287 146.562 114.875C146.562 118.462 143.587 121.437 140 121.437Z"
        fill={colors?.secondaryLightPurple}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.957 134.941C207.131 166.98 161.033 172.828 128.994 148.002C96.9553 123.177 91.1077 77.0785 115.933 45.0394C140.759 13.0003 186.857 7.15265 218.896 31.9784C250.935 56.8041 256.783 102.902 231.957 134.941Z"
        fill={colors?.secondaryBackground}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.82 163.807C239.036 159.325 230.928 159.801 224.902 163.953C193.533 185.57 150.56 185.538 118.838 160.958C79.5935 130.549 72.4308 74.0832 102.84 34.8384C133.249 -4.40648 189.715 -11.5692 228.959 18.8399C260.681 43.4198 271.442 85.024 258.342 120.797C255.826 127.669 257.389 135.638 263.174 140.121L325.194 188.177C331.734 193.245 332.928 202.656 327.86 209.197C322.792 215.738 313.381 216.932 306.84 211.863L244.82 163.807ZM231.93 134.865C207.096 166.915 160.983 172.765 128.933 147.931C96.8828 123.096 91.0333 76.9828 115.867 44.9328C140.701 12.8829 186.815 7.03328 218.865 31.8674C250.915 56.7015 256.764 102.815 231.93 134.865Z"
        fill={colors?.bg_color}
      />
      <mask
        id="mask0_399_117"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="101"
        y="17"
        width="148"
        height="148"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.126 135.604C208.263 167.692 162.095 173.548 130.008 148.685C97.9206 123.822 92.0642 77.6542 116.927 45.5667C141.791 13.4792 187.958 7.62277 220.046 32.486C252.133 57.3492 257.989 103.517 233.126 135.604Z"
          fill={colors?.bg_color}
        />
      </mask>
      <g mask="url(#mask0_399_117)">
        <path
          d="M140 63.0001H69.9999C30 63.0001 20 73.0001 20 113V188.8C27.6 182.1 37.6 178 48.4999 178H180C185.5 178 190 173.5 190 168V113C190 73.0001 180 63.0001 140 63.0001ZM115 150.5H64.9999C60.8999 150.5 57.4999 147.1 57.4999 143C57.4999 138.9 60.8999 135.5 64.9999 135.5H115C119.1 135.5 122.5 138.9 122.5 143C122.5 147.1 119.1 150.5 115 150.5ZM145 115.5H64.9999C60.8999 115.5 57.4999 112.1 57.4999 108C57.4999 103.9 60.8999 100.5 64.9999 100.5H145C149.1 100.5 152.5 103.9 152.5 108C152.5 112.1 149.1 115.5 145 115.5Z"
          stroke={colors?.secondaryLightPurple}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeDasharray="12 12"
        />
      </g>
    </svg>
  );
}
