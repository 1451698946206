import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import styles from "./index.module.sass"
import {
	searchDocuments,
	searchedDocumentsSelector,
	setSearchedDocuments,
} from "../../../store/reducers/knowledgeBase"
import Typography from "../../UIKit/base/Typography"
import { DocumentIcon, SearchWithAiIcon } from "../../icons"
import CancelButton from "../../UIKit/buttons/CancelButton/CancelButton"
import Modal from "../../UIKit/modal"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { searchAiRequest } from "../../../api/knowledge-base/knowledge-base"
import EditorView from "../../UIKit/editor/view/EditorView"
import { LoadIcon } from "./LoadIcon"
import { CancelRequest } from "../../hooks/CancelRequest"
import StartIcon from "./start"

const controller = new CancelRequest()
const searchController = new CancelRequest()

const SearchAi = ({ className }) => {
	const [modal, setModal] = useState(false)
	const { t } = useTranslation()
	const [search, setSearch] = useState("")
	const [papper, setPapper] = useState(false)
	const [load, setLoad] = useState(false)
	const [ai, setAi] = useState(null)
	const [badReq, setBadReq] = useState(false)
	const dispatch = useDispatch()
	const searchedDocuments = useSelector(searchedDocumentsSelector)
	const ref = useRef(null)

	function handleCloseModal() {
		setSearch("")
		setAi(null)
		setModal(false)
		setPapper(false)
		dispatch(setSearchedDocuments(null))
		setBadReq(false)
		searchController.abort()
		controller.abort()
	}

	const handleChange = ({ target: { value } }) => {
		setSearch(value)
		if (ai) {
			setAi(null)
		}
		dispatch(
			searchDocuments({
				search: value,
				options: { signal: searchController.signal },
			})
		)
	}
	const handleAbbort = () => {
		controller.abort()
	}
	const handleAi = async () => {
		if (load || !search) {
			return
		}
		setLoad(true)
		setAi(null)
		setBadReq(false)
		searchController.abort()
		dispatch(setSearchedDocuments(null))
		try {
			const { data } = await searchAiRequest(
				{
					queryString: `search=${search}&ai=true`,
				},
				{
					signal: controller.signal,
				}
			)
			dispatch(searchDocuments({ search: "" }))
			setAi(data)
		} catch (err) {
			if (err === "User cancel the network request.") {
				console.log("cancel")
				return
			}
			setBadReq(true)
			dispatch(searchDocuments({ search: "" }))
		} finally {
			setLoad(false)
		}
	}

	return (
		<div className={classNames(styles.search, className)}>
			<Typography
				fontVariant="semiBold"
				component="button"
				type="button"
				onClick={() => {
					setModal(true)
				}}
			>
				<SearchWithAiIcon />
				<span>{t("search_with_ai")}</span>
			</Typography>

			<Modal
				className={styles.modal}
				onClose={handleCloseModal}
				isOpen={modal}
				submit={handleAi}
			>
				{(close, submit) => (
					<>
						<div className={styles.modal__header}>
							<Typography fontVariant="medium" component="span">
								{t("search_with_ai")}
							</Typography>
							<CancelButton className={styles.cancel} onClick={close} />
						</div>
						<div className={styles.modal__content}>
							<div className={styles.input__container} ref={ref}>
								<div className={styles.input__wrapper}>
									<Typography
										fontVariant="medium"
										className={styles.input}
										component="input"
										type="text"
										placeholder={t("search_input_placeholder")}
										value={search}
										onChange={handleChange}
										onFocus={() => {
											setPapper(true)
										}}
									/>
								</div>
							</div>

							{load ? (
								<Typography
									fontVariant="semiBold"
									component="button"
									type="button"
									className={classNames(
										styles.button__search,
										styles.button__search_cancel
									)}
									onClick={handleAbbort}
								>
									{t("cancel")}
								</Typography>
							) : (
								<Typography
									fontVariant="semiBold"
									component="button"
									type="button"
									className={styles.button__search}
									onClick={submit}
								>
									{t("ask_ai")}
								</Typography>
							)}
						</div>
						{load ? (
							<Typography className={styles.load_icon__container}>
								<LoadIcon className={styles.load_icon} />
								{t("ai_is_working")}
							</Typography>
						) : (
							<>
								{papper && searchedDocuments?.length > 0 && !ai && (
									<div className={classNames(styles.dropdown)}>
										<ul className={styles.dropdown__container}>
											{searchedDocuments?.length > 0 &&
												searchedDocuments?.map((s, i) => (
													<Typography
														key={`${s?.id}-${s?.status}-${i}`}
														fontVariant="medium"
														component="li"
														onClick={close}
													>
														<Link to={`/knowledge-base/documents/${s?.id}`}>
															<DocumentIcon />
															<span>{s?.name}</span>
														</Link>
													</Typography>
												))}
										</ul>
									</div>
								)}
								<AiContainer ai={ai} closeModal={close} />
								{!ai && !searchedDocuments?.length && (
									<StartContainer
										noItem={
											(searchedDocuments && !searchedDocuments?.length) ||
											badReq
										}
									/>
								)}
							</>
						)}
					</>
				)}
			</Modal>
		</div>
	)
}

export default SearchAi

function StartContainer({ noItem }) {
	const { t } = useTranslation()
	return (
		<div className={styles.container}>
			<StartIcon />
			<div className={styles.text}>
				<Typography className={styles.sub} fontVariant="medium">
					{noItem
						? t("no_item_description")
						: t("start_typing_to_search_for_a_document")}
				</Typography>
			</div>
		</div>
	)
}

const usePrintText = (sourceText, interval = 20) => {
	let [timeoutId, setTimeoutId] = useState(NaN)
	let [textInfo, setTextInfo] = useState({
		isDone: false,
		currentText: ``,
		sourceText,
	})

	const isDone = () => textInfo.currentText.length === sourceText.length
	const getNextText = () =>
		textInfo.currentText.concat(sourceText.charAt(textInfo.currentText.length))

	useEffect(() => {
		if (!isDone()) {
			print()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textInfo])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => () => cancel(), [])

	const print = () => {
		let timeoutId = setTimeout(() => {
			setTextInfo({
				currentText: getNextText(),
				sourceText,
			})
		}, interval)

		setTimeoutId(timeoutId)
	}

	const cancel = () => {
		if (!Number.isNaN(timeoutId)) {
			clearTimeout(timeoutId)
		}
	}
	return [textInfo]
}

function AiContainer({ ai, closeModal }) {
	const { t } = useTranslation()

	if (!ai) {
		return null
	}
	const { documents, ai_text } = ai
	return (
		<div className={styles.container__print}>
			{ai_text && <EditorContainer text={ai_text} />}
			<div className={styles.documents__container}>
				<Typography fontVariant="medium" className={styles.based}>
					{t("based_on_documents", { count: documents?.length })}
				</Typography>
				{documents?.length > 0 && (
					<ul className={styles.documents}>
						{documents.map((d) => (
							<Typography
								fontVariant="medium"
								component="li"
								key={d?.id}
								onClick={closeModal}
							>
								<Link
									to={`/knowledge-base/documents/${d?.id}`}
									onClick={closeModal}
								>
									<DocumentIcon />
									<span>{d?.name}</span>
								</Link>
							</Typography>
						))}
					</ul>
				)}
			</div>
		</div>
	)
}

function EditorContainer({ text }) {
	const [textInfo] = usePrintText(text, 20)
	return (
		<EditorView content={textInfo?.currentText} className={styles.editor} />
	)
}
