import React, { useEffect, useRef, useState } from "react"
import Typography from "../../UIKit/base/Typography"
import styles from "../login.module.sass"
import { slides } from "./utils"
import { useTranslation } from "react-i18next"
import { Image1 } from "./SliderImages/Image1"

export default function AuthSlider() {
	const [slide, setSlide] = useState(0)
	const slideCount = slides.length
	const slideLastIndex = slides.length - 1
	const ref = useRef(null)
	let start = new Date().getTime()

	const { t } = useTranslation()

	const animate = (time) => {
		const delta = new Date().getTime() - start
		if (delta > 5000) {
			start = Date.now()
			setSlide((s) => {
				if (s === slideLastIndex) {
					return 0
				}
				return s + 1
			})
		}
		ref.current = requestAnimationFrame(animate)
	}
	useEffect(() => {
		ref.current = requestAnimationFrame(animate)
		return () => cancelAnimationFrame(ref.current)
	}, [])

	function mouseEnter() {
		cancelAnimationFrame(ref.current)
	}

	function mouseMove() {
		ref.current = requestAnimationFrame(animate)
	}

	function handleClick({ target }) {
		const value = target.dataset.slide
		setSlide(+value)
	}

	return (
		<div
			className={styles.slider}
			onMouseEnter={mouseEnter}
			onMouseLeave={mouseMove}
		>
			<div className={styles.slider__img}>
				{slides[slide].src()}
			</div>
			<Typography
				className={styles.slider__text}
				component="p"
				fontVariant="bold"
			>
				{t(slides[slide].text)}
			</Typography>
			<div className={styles.slider__dots}>
				{Array.from(new Array(slideCount), (_, i) => (
					<span
						key={i}
						data-active={i === slide}
						data-slide={i}
						onClick={handleClick}
					/>
				))}
			</div> 
		</div>
	)
}
