import { useCallback, useEffect } from "react"
import { anonymousAuthRequest } from "../../../api/anonymous/anonymous"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
	anonymousAuthSelector,
	anonymousLogoutAction,
	setAuth,
	setInfo,
} from "../../../store/reducers/anonymous"
import {
	addGlobalLoadItem,
	removeGlobalLoadItem,
} from "../../../store/reducers/loader"

export default function AnonymousAuth() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { token } = useParams()
	const auth = useSelector(anonymousAuthSelector)

	const login = useCallback(async () => {
		try {
			dispatch(addGlobalLoadItem())
			localStorage.removeItem("anonymous_token")
			localStorage.removeItem("anonymous_refresh_token")
			const { data } = await anonymousAuthRequest(token)
			const { authorization, ...other } = data
			localStorage.setItem("anonymous_token", authorization?.access_token)
			localStorage.setItem(
				"anonymous_refresh_token",
				authorization?.refresh_token
			)
			dispatch(setAuth(true))
			dispatch(setInfo(other?.survey))
			if (other?.survey?.type === "manual") {
				return navigate(
					`/anonymous/${token}/survey/${other?.survey?.id}/question/${other?.survey?.next_question_id}/manual`,
					{ replace: true }
				)
			}
			if (other?.survey?.type === "performance") {
				return navigate(
					`/anonymous/${token}/survey/${other?.survey?.id}/question/${other?.survey?.next_question_id}/performance/${other?.survey?.target_person?.id}`,
					{ replace: true }
				)
			}
			return navigate(
				`/anonymous/${token}/survey/${other?.survey?.id}/question/${other?.survey?.next_question_id}`,
				{ replace: true }
			)
		} catch (err) {
			console.log(err)
		} finally {
			dispatch(removeGlobalLoadItem())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		login()
		return () => {
			dispatch(anonymousLogoutAction())
		}
	}, [login, dispatch])
	if (!auth) {
		return null
	}
	return <Outlet />
}

// 216ae734024afed1f695db208dc740f2
