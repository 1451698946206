import PreloaderCard from "./PreloaderCard";
import PreloaderLine from "./PreloaderLine";
import styles from "./PreloaderTemplates.module.sass";

const PreloaderList = () => {
    return (
      <div className={styles.list}>
        <PreloaderCard width={"100%"}>
          <PreloaderLine width={"100%"} className={styles.line__head} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
        </PreloaderCard>
      </div>
    );
  };
  

export default PreloaderList