import PreloaderBreadcrumbs from "../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import styles from "./Preloaders.module.sass";

const PreloaderCertificates = () => {
  return (
    <>
      <PreloaderMobileTitle />
      <PreloaderBreadcrumbs />
      <div className={styles.certificates__wrapper}>
        <PreloaderRect width={360} height={48} />

        <div className={styles.certificates}>
          <Certificate />
          <Certificate />
          <Certificate />
          <Certificate />
          <Certificate />
          <Certificate />
        </div>
      </div>
    </>
  );
};

export default PreloaderCertificates;

export const Certificate = () => {
  return (
    <div className={styles.certificate}>
      <PreloaderCard width={"100%"} padding={"24px"}>
        <PreloaderRect width={"100%"} height={200} />
        <div className={styles.certificate__inner}>
          <div className={styles.certificate__left}>
            <div className={styles.certificate__lines}>
              <PreloaderLine width={"100%"} />
              <PreloaderLine width={160} />
            </div>
            <PreloaderCircle size={24} />
          </div>{" "}
        </div>
      </PreloaderCard>
    </div>
  );
};
