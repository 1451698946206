import Switcher from "./SwitcherControl"
import React from "react"
import styles from "./Switcher.module.scss"
import { useField } from "formik"
import Typography from "../../UIKit/base/Typography"

export default function SwitcherLabel({ label, name }) {
	const [, meta, helpers] = useField({ name })
	const { value } = meta
	const { setValue } = helpers

	const handleChange = () => {
		setValue(!value)
	}

	return (
		<div className={styles.label__wrapper}>
			<Switcher checked={!!value} onChange={handleChange} />
			<Typography
				component="span"
				fontVariant="medium"
				className={styles.label}
			>
				{label}
			</Typography>
		</div>
	)
}
