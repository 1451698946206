import classNames from "classnames"
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom"


export default function CustomLink({
	children,
	to,
	activeClassName,
	className,
	...props
}) {
	const location = useLocation()
	const path = location.pathname.split("/")[1]
	//let resolved = useResolvedPath(to)
	//let match = useMatch({ path: path, end: true })
	let match = path === to 

	if(path === "products" || path === "catalog") {
		match = false
	}
	

	return (
		<Link
			className={classNames(className, { [activeClassName]: match })}
			to={to}
			{...props}
		>
			{children}
		</Link>
	)
}
