import i18next from "i18next";
import { useSelector } from "react-redux";
import { statisticSelector } from "../../store/reducers/reviews";
import { formatEndings } from "../utils";

import styles from "./Reviews.module.sass";

import { HeartIcon, HeartIconFill } from "../icons";
import ReviewSend from "./ReviewSend";

const ReviewsStatistic = () => {
  const statisticData = useSelector(statisticSelector);
  const statistic = statisticData.statistic;

  const statisticPercent = (statistic?.score * 100) / 5;

  return (
    <>
      {!!statistic?.total && (
        <div className={styles.reviewsStatistic}>
          <div className={styles.reviewsStatistic__sendButton}>
            <ReviewSend />
          </div>
          <div className={styles.reviewsStatistic__header}>
            <h1 className={styles.reviewsStatistic__score}>
              {statistic.score}
            </h1>
            <div className={styles.reviewsStatistic__rating}>
              <div className={styles.reviewsStatistic__scoresOutlined}>
                {Array(5)
                  .fill()
                  .map((i) => (
                    <HeartIcon />
                  ))}
              </div>

              <div
                className={styles.reviewsStatistic__scoresFilled}
                style={{ width: `${statisticPercent}%` }}
              >
                <div className={styles.reviewsStatistic__scoresFilledInner}>
                  {Array(5)
                    .fill()
                    .map((i) => (
                      <HeartIconFill />
                    ))}
                </div>
              </div>
            </div>
            <span className={styles.reviewsStatistic__totalTop}>
              {formatEndings(statistic.total, "review", i18next.language)}
            </span>
          </div>

          <div className={styles.reviewsStatistic__items}>
            {Object.entries(statistic.items)
              .reverse()
              .map(([key, value]) => (
                <div className={styles.reviewsStatistic__item}>
                  <div className={styles.reviewsStatistic__score}>
                    <h3>{key}</h3>
                  </div>
                  <div className={styles.reviewsStatistic__progress}>
                    <div
                      className={styles.reviewsStatistic__progressPercent}
                      style={{ width: `${value.percents}%` }}
                    ></div>
                  </div>
                  <span className={styles.reviewsStatistic__percent}>
                    {value.percents}%
                  </span>
                  <span className={styles.reviewsStatistic__total}>
                    {value.total}
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewsStatistic;
