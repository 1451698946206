import axios from "axios"
import { api } from "../http"

export async function getLessonRequest(lessonId) {
	const url = `/v1/post/${lessonId}`
	return await api.get(url)
}

export async function sendLessonStatusRequest(lessonId, status) {
	const url = `/v1/post/${lessonId}/update-status/${status}`
	return await api.patch(url)
}

export async function sendHomeworkFormRequest(data, lessonId, options) {
	const url = `/v1/post/${lessonId}/answer`
	return await api.post(url, data, options)
}

export async function sendQuizStartRequest(lessonId) {
	const url = `/v1/post/${lessonId}/start`
	return api.patch(url)
}

export async function sendQuizAnswerRequest(lessonId, questionId, data) {
	const url = `/v1/post/${lessonId}/question/${questionId}/answer`
	return api.post(url, data)
}

export async function sendQuizFinishRequest(lessonId) {
	const url = `/v1/post/${lessonId}/finish`
	return api.patch(url)
}

export async function sendPostAttachmentsRequest({ postId, data }) {
	const url = `/v1/post/${postId}/answer/store-start`
	return await api.post(url, data)
}

export async function sendMediaRequest(url, file, config) {
	const headers = {
		"Content-Type": "multipart/form-data",
	}
	return await axios.post(url, file, { ...config, headers })
}

export async function sendHomeWorkFormFinishRequest({postId, data}){
    const url = `/v1/post/${postId}/answer/store-finish`
    return await api.post(url,data)
}
export async function tryAgainQuizRequest(lessonId){
    const url = `/v1/post/${lessonId}/try-again`  
    return api.patch(url)
} 
