import React, { useEffect, useMemo } from "react";
import styles from "../Lesson/lesson.module.scss";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  answersSelector,
  attachmentsSelector,
  getLesson,
  postSelector,
} from "../../store/reducers/lesson";
import Attachments from "../attachments/Attachments";
import Answer from "../Answer/Answer";
import Reviews from "./HomeWorkReviews";
import Typography from "../UIKit/base/Typography";
import useScrollTop from "../hooks/useScrollTop";
import EditorView from "../UIKit/editor/view/EditorView";
import ScoreProgress from "../ScoreProgress";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Homework = () => {
  const post = useSelector(postSelector);
  const attachments = useSelector(attachmentsSelector);
  const dispatch = useDispatch();
  const { postId } = useParams();
  const answers = useSelector(answersSelector);

  const { t } = useTranslation(); 

  const score = useMemo(() => {
    return answers?.[0];
  }, [answers]); 

  useScrollTop(post);

  /*
  useEffect(() => {
    dispatch(getLesson(postId));
  }, [postId, dispatch]);
*/

  return ( 
    <>
      <div className={styles.card_big}>
      {(post?.description || !!attachments.length) &&
        <div className={classNames(styles.card, styles.homework__desc)}>
          <div className={styles.container}>
            {/* <Typography fontVariant="medium" className={styles.description}>{post ? post.description : null}</Typography> */}
            {post?.description && (
              <EditorView
                content={post?.description}
                fontVariant="medium"
                className={styles.description}
              />
            )}
            {!!attachments.length && 
              <div className={styles.wrapFiles}>
                <Attachments attachments={attachments} />
              </div>
            }
          </div>
        </div>
        }
       { (post?.status === "completed" ||  post?.status === "failed")  && 
       <div className={classNames(styles.card, styles.homework__done)}>
          <Typography
            fontVariant="medium"
            className={styles.homework__done_title}
          >
            {t("homework_completed")}
          </Typography>
          <Typography
            fontVariant="medium"
            className={styles.homework__done_text}
          >
            {post?.status === "completed" ? t("homework_completed_desc") : t("homework_failed")}
          </Typography>

          <ScoreProgress
            score={score?.score}
            total={score?.passing_total}
            passing={score?.passing_score}
          />
        </div>
        }
      </div>
      <Answer />
      <Reviews />
    </>
  );
};

export default Homework;
