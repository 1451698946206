import React, { useEffect } from "react"
import { createPortal } from "react-dom"
import PropTypes from "prop-types"
import ClassNames from "classnames"
import styles from "./modal.module.sass"
import { useModal } from "."
import Typography from "../base/Typography"

let modalRoot
if (typeof document === "object") {
	modalRoot = document.createElement("div")
	modalRoot.setAttribute("class", styles.modalRoot)
	document.body.appendChild(modalRoot)
}

const ModalContent = ({
	children,
	closingAnimate = false,
	className,
	submit,
}) => {
	const { isOpen, close, disabled } = useModal()
	useEffect(() => {
		const handleKeyUp = (e) => {
			if (e.which === 27) {
				e.preventDefault()
				close()
			}
		}

		window.addEventListener("keyup", handleKeyUp, false)
		return () => {
			window.removeEventListener("keyup", handleKeyUp, false)
		}
	}, [close])

	function handleSumit() {
		if (arguments?.length) {
			return submit(...arguments, { close })
		}
		return submit({ close }) //TODO: close must be a submit argument
		// close()
	}

	if (!isOpen) return null
	return createPortal(
		<div className={styles.root}>
			<div
				className={ClassNames(styles.modal__overlay, {
					[styles.modal__overlay_closed]: closingAnimate,
				})}
				onClick={close}
			/>
			<div
				className={ClassNames(
					styles.modal__wrapper,
					{
						[styles.modal__wrapper_closed]: closingAnimate,
					},
					className
				)}
			>
				<Typography className={styles.modal}>
					{children(close, handleSumit, disabled)}
				</Typography>
			</div>
		</div>,
		modalRoot
	)
}

ModalContent.proptypes = {
	children: PropTypes.node.isRequired,
	closingAnimate: PropTypes.bool,
}

export default ModalContent
