import classNames from "classnames"
import { ErrorMessage } from "formik"
import Typography from "../../UIKit/base/Typography"
import Select from "./Select"
import styles from "./select.module.sass"

export default function SelectControl({
	form,
	field,
	permission = true,
	readOnly = false,
	create,
	...props
}) {
	const { message, ...rest } = props
	const erorStyle =
		form &&
		form.errors &&
		form.errors[field.name] &&
		form.touched &&
		form.touched[field.name]
	return (
		<Select
			{...rest}
			value={field.value}
			onChange={form.setFieldValue}
			name={field.name}
			className={classNames({ [styles.error]: erorStyle })}
			readOnly={!permission || props?.readOnly}
			create
		>
			{!!message && (
				<Typography component="p" className={styles.message}>
					{field.name && message && <ErrorMessage name={field.name} />}
				</Typography>
			)}
		</Select>
	)
}