import React, { useEffect } from "react";
import styles from "./ChartRadar.module.sass";
import Typography from "../UIKit/base/Typography";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ChartRadar, { Level } from "./ChartRadar";
import { useDispatch, useSelector } from "react-redux";
import { getSkills, skillsSelector } from "../../store/reducers/user";
import classNames from "classnames";

const ChartRadarCard = () => {
  const { t } = useTranslation(); 
  const skills = useSelector(skillsSelector)?.skills?.[0];

  if (!skills?.length) {
    return null;
  }

  return (
    <div className={styles.radar__wrapper}>
      <div className={styles.radar__header}>
        <Typography>{t("skills")}</Typography>
        <Typography
          className={styles.radar__link}
          component={Link}
          to={"/skills"}
          fontVariant="bold"
        >
          {t("more")}
        </Typography>
      </div>
      <ChartRadar skills={skills} />
      {window.innerWidth < 768 &&
      <>
      <div className={styles.skills__header}>
                  <div className={styles.skills__subject}>
                    <Typography> {t("skills")} </Typography>
                  </div>
                  <div className={styles.skills__level}>
                    <Typography> {t("level")} </Typography>
                  </div>
                </div>
      {
        skills?.length &&
        skills.map((i) => (
          <div
            key={i.subject}
            className={classNames(styles.skills__item, {
              [styles.skills__item_mobile]: window.innerWidth <= 768,
            })}
          >
            <div className={styles.skills__subject}>
              <Typography>
                {" "}
                 {i.title}{" "}
              </Typography>
            </div>
            <div className={styles.skills__level}>
              <Level level={i.mark} />
              <Typography> {i.level} </Typography> 
            </div>
          </div>
        ))}
        </>
}
    </div>
  );
};

export default ChartRadarCard;
 