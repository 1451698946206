export function PlayIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.93072 20.3521C6.56355 20.7165 7.28268 20.8986 8.04015 20.8986C9.07569 20.8986 10.2071 20.563 11.3673 19.9015L17.2833 16.488C19.2968 15.3278 20.3995 13.7362 20.3995 12.0103C20.3995 10.2844 19.2873 8.69271 17.2833 7.53253L11.3673 4.11909C9.35375 2.9589 7.4265 2.7959 5.93072 3.65885C4.43495 4.5218 3.61035 6.26687 3.61035 8.58724V15.4237C3.61035 17.7345 4.43495 19.4892 5.93072 20.3521Z"
				fill="#FBFAFF"
			/>
		</svg>
	);
}

export function PauseIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.65 19.11V4.89C10.65 3.54 10.08 3 8.64 3H5.01C3.57 3 3 3.54 3 4.89V19.11C3 20.46 3.57 21 5.01 21H8.64C10.08 21 10.65 20.46 10.65 19.11Z"
				fill="#FBFAFF"
			/>
			<path
				d="M21.0001 19.11V4.89C21.0001 3.54 20.4301 3 18.9901 3H15.3601C13.9301 3 13.3501 3.54 13.3501 4.89V19.11C13.3501 20.46 13.9201 21 15.3601 21H18.9901C20.4301 21 21.0001 20.46 21.0001 19.11Z"
				fill="#FBFAFF"
			/>
		</svg>
	);
}

export function SettingsIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.0002 22.7501C11.3302 22.7501 10.6501 22.5801 10.0501 22.2301L4.11014 18.8001C2.91014 18.1001 2.16016 16.8101 2.16016 15.4201V8.58011C2.16016 7.19011 2.91014 5.90011 4.11014 5.20011L10.0501 1.77012C11.2501 1.07012 12.7402 1.07012 13.9502 1.77012L19.8902 5.20011C21.0902 5.90011 21.8401 7.19011 21.8401 8.58011V15.4201C21.8401 16.8101 21.0902 18.1001 19.8902 18.8001L13.9502 22.2301C13.3502 22.5801 12.6702 22.7501 12.0002 22.7501ZM12.0002 2.7501C11.5902 2.7501 11.1701 2.8601 10.8001 3.0701L4.86014 6.5001C4.12014 6.9301 3.66016 7.72011 3.66016 8.58011V15.4201C3.66016 16.2701 4.12014 17.0701 4.86014 17.5001L10.8001 20.9301C11.5401 21.3601 12.4602 21.3601 13.2002 20.9301L19.1402 17.5001C19.8802 17.0701 20.3401 16.2801 20.3401 15.4201V8.58011C20.3401 7.73011 19.8802 6.9301 19.1402 6.5001L13.2002 3.0701C12.8302 2.8601 12.4102 2.7501 12.0002 2.7501Z"
				fill="#FBFAFF"
			/>
			<path
				d="M11.0902 15.6703C10.7302 15.6703 10.3902 15.5803 10.0802 15.4103C9.39023 15.0103 8.99023 14.2003 8.99023 13.1903V10.7903C8.99023 9.78027 9.39023 8.9703 10.0802 8.5703C10.7702 8.1703 11.6703 8.23028 12.5403 8.74028L14.6202 9.94029C15.4902 10.4403 16.0002 11.1903 16.0002 11.9903C16.0002 12.7903 15.5002 13.5303 14.6202 14.0403L12.5403 15.2403C12.0603 15.5303 11.5602 15.6703 11.0902 15.6703ZM11.1003 9.8203C11.0003 9.8203 10.9102 9.8403 10.8402 9.8803C10.6302 10.0003 10.5002 10.3403 10.5002 10.8003V13.2003C10.5002 13.6503 10.6302 13.9903 10.8402 14.1203C11.0502 14.2403 11.4102 14.1803 11.8002 13.9503L13.8802 12.7503C14.2702 12.5203 14.5002 12.2403 14.5002 12.0003C14.5002 11.7603 14.2702 11.4803 13.8802 11.2503L11.8002 10.0503C11.5402 9.90028 11.2903 9.8203 11.1003 9.8203Z"
				fill="#FBFAFF"
			/>
		</svg>
	);
}

export function MaximizeIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 9.75C1.59 9.75 1.25 9.41 1.25 9V6.5C1.25 3.61 3.61 1.25 6.5 1.25H9C9.41 1.25 9.75 1.59 9.75 2C9.75 2.41 9.41 2.75 9 2.75H6.5C4.43 2.75 2.75 4.43 2.75 6.5V9C2.75 9.41 2.41 9.75 2 9.75Z"
				fill="#FBFAFF"
			/>
			<path
				d="M22 9.75C21.59 9.75 21.25 9.41 21.25 9V6.5C21.25 4.43 19.57 2.75 17.5 2.75H15C14.59 2.75 14.25 2.41 14.25 2C14.25 1.59 14.59 1.25 15 1.25H17.5C20.39 1.25 22.75 3.61 22.75 6.5V9C22.75 9.41 22.41 9.75 22 9.75Z"
				fill="#FBFAFF"
			/>
			<path
				d="M17.5 22.75H16C15.59 22.75 15.25 22.41 15.25 22C15.25 21.59 15.59 21.25 16 21.25H17.5C19.57 21.25 21.25 19.57 21.25 17.5V16C21.25 15.59 21.59 15.25 22 15.25C22.41 15.25 22.75 15.59 22.75 16V17.5C22.75 20.39 20.39 22.75 17.5 22.75Z"
				fill="#FBFAFF"
			/>
			<path
				d="M9 22.75H6.5C3.61 22.75 1.25 20.39 1.25 17.5V15C1.25 14.59 1.59 14.25 2 14.25C2.41 14.25 2.75 14.59 2.75 15V17.5C2.75 19.57 4.43 21.25 6.5 21.25H9C9.41 21.25 9.75 21.59 9.75 22C9.75 22.41 9.41 22.75 9 22.75Z"
				fill="#FBFAFF"
			/>
		</svg>
	);
}

export function MinimizeIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 22.75C14.59 22.75 14.25 22.41 14.25 22V19.5C14.25 16.61 16.61 14.25 19.5 14.25H22C22.41 14.25 22.75 14.59 22.75 15C22.75 15.41 22.41 15.75 22 15.75H19.5C17.43 15.75 15.75 17.43 15.75 19.5V22C15.75 22.41 15.41 22.75 15 22.75Z"
				fill="#FBFAFF"
			/>
			<path
				d="M9 22.75C8.59 22.75 8.25 22.41 8.25 22V19.5C8.25 17.43 6.57 15.75 4.5 15.75H2C1.59 15.75 1.25 15.41 1.25 15C1.25 14.59 1.59 14.25 2 14.25H4.5C7.39 14.25 9.75 16.61 9.75 19.5V22C9.75 22.41 9.41 22.75 9 22.75Z"
				fill="#FBFAFF"
			/>
			<path
				d="M3.5 8.75H2C1.59 8.75 1.25 8.41 1.25 8C1.25 7.59 1.59 7.25 2 7.25H3.5C5.57 7.25 7.25 5.57 7.25 3.5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V3.5C8.75 6.39 6.39 8.75 3.5 8.75Z"
				fill="#FBFAFF"
			/>
			<path
				d="M22 9.75H19.5C16.61 9.75 14.25 7.39 14.25 4.5V2C14.25 1.59 14.59 1.25 15 1.25C15.41 1.25 15.75 1.59 15.75 2V4.5C15.75 6.57 17.43 8.25 19.5 8.25H22C22.41 8.25 22.75 8.59 22.75 9C22.75 9.41 22.41 9.75 22 9.75Z"
				fill="#FBFAFF"
			/>
		</svg>
	);
}

export function VolumeOffIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.55 20.5901C11.76 20.5901 10.89 20.3101 10.02 19.7601L7.1 17.9301C6.9 17.8101 6.67 17.7401 6.44 17.7401H5C2.58 17.7401 1.25 16.4101 1.25 13.9901V9.99011C1.25 7.57011 2.58 6.24011 5 6.24011H6.43C6.66 6.24011 6.89 6.17011 7.09 6.05011L10.01 4.22011C11.47 3.31011 12.89 3.14011 14.01 3.76011C15.13 4.38011 15.74 5.67011 15.74 7.40011V16.5701C15.74 18.2901 15.12 19.5901 14.01 20.2101C13.57 20.4701 13.07 20.5901 12.55 20.5901ZM5 7.75011C3.42 7.75011 2.75 8.42011 2.75 10.0001V14.0001C2.75 15.5801 3.42 16.2501 5 16.2501H6.43C6.95 16.2501 7.45 16.3901 7.89 16.6701L10.81 18.5001C11.78 19.1001 12.68 19.2601 13.29 18.9201C13.9 18.5801 14.25 17.7301 14.25 16.6001V7.41011C14.25 6.27011 13.9 5.42011 13.29 5.09011C12.68 4.75011 11.77 4.90011 10.81 5.51011L7.88 7.33011C7.45 7.61011 6.94 7.75011 6.43 7.75011H5Z"
				fill="#FBFAFF"
			/>
			<path
				d="M22 14.7099C21.81 14.7099 21.62 14.6399 21.47 14.4899L17.51 10.5299C17.22 10.2399 17.22 9.75994 17.51 9.46994C17.8 9.17994 18.28 9.17994 18.57 9.46994L22.53 13.4299C22.82 13.7199 22.82 14.1999 22.53 14.4899C22.38 14.6299 22.19 14.7099 22 14.7099Z"
				fill="#FBFAFF"
			/>
			<path
				d="M17.9999 14.75C17.8099 14.75 17.6199 14.68 17.4699 14.53C17.1799 14.24 17.1799 13.76 17.4699 13.47L21.4299 9.50998C21.7199 9.21998 22.1999 9.21998 22.4899 9.50998C22.7799 9.79998 22.7799 10.28 22.4899 10.57L18.5299 14.53C18.3799 14.68 18.1899 14.75 17.9999 14.75Z"
				fill="#FBFAFF"
			/>
		</svg>
	);
}

export function VolumeLowIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.552 20.5984C11.762 20.5984 10.892 20.3184 10.022 19.7684L7.10195 17.9384C6.90195 17.8184 6.67195 17.7484 6.44195 17.7484H5.00195C2.58195 17.7484 1.25195 16.4184 1.25195 13.9984V9.99841C1.25195 7.57841 2.58195 6.24841 5.00195 6.24841H6.43195C6.66195 6.24841 6.89195 6.17841 7.09195 6.05841L10.012 4.22841C11.472 3.31841 12.892 3.14841 14.012 3.76841C15.132 4.38841 15.742 5.67841 15.742 7.40841V16.5784C15.742 18.2984 15.122 19.5984 14.012 20.2184C13.572 20.4784 13.082 20.5984 12.552 20.5984ZM5.00195 7.75841C3.42195 7.75841 2.75195 8.42841 2.75195 10.0084V14.0084C2.75195 15.5884 3.42195 16.2584 5.00195 16.2584H6.43195C6.95195 16.2584 7.45195 16.3984 7.89195 16.6784L10.812 18.5084C11.782 19.1084 12.682 19.2684 13.292 18.9284C13.902 18.5884 14.252 17.7384 14.252 16.6084V7.41841C14.252 6.27841 13.902 5.42841 13.292 5.09841C12.682 4.75841 11.782 4.90841 10.812 5.51841L7.89195 7.33841C7.45195 7.61841 6.95195 7.75841 6.43195 7.75841H5.00195Z"
				fill="#FBFAFF"
			/>
			<path
				d="M18.0017 16.76C17.8417 16.76 17.6917 16.71 17.5517 16.61C17.2217 16.36 17.1517 15.89 17.4017 15.56C18.9717 13.47 18.9717 10.55 17.4017 8.46004C17.1517 8.13004 17.2217 7.66004 17.5517 7.41004C17.8817 7.16004 18.3517 7.23004 18.6017 7.56004C20.5717 10.18 20.5717 13.84 18.6017 16.46C18.4617 16.66 18.2317 16.76 18.0017 16.76Z"
				fill="#FBFAFF"
			/>
		</svg>
	);
}

export function VolumeHightIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.55 20.5901C11.76 20.5901 10.89 20.3101 10.02 19.7601L7.1 17.9301C6.9 17.8101 6.67 17.7401 6.44 17.7401H5C2.58 17.7401 1.25 16.4101 1.25 13.9901V9.99011C1.25 7.57011 2.58 6.24011 5 6.24011H6.43C6.66 6.24011 6.89 6.17011 7.09 6.05011L10.01 4.22011C11.47 3.31011 12.89 3.14011 14.01 3.76011C15.13 4.38011 15.74 5.67011 15.74 7.40011V16.5701C15.74 18.2901 15.12 19.5901 14.01 20.2101C13.57 20.4701 13.07 20.5901 12.55 20.5901ZM5 7.75011C3.42 7.75011 2.75 8.42011 2.75 10.0001V14.0001C2.75 15.5801 3.42 16.2501 5 16.2501H6.43C6.95 16.2501 7.45 16.3901 7.89 16.6701L10.81 18.5001C11.77 19.1001 12.68 19.2601 13.29 18.9201C13.9 18.5801 14.25 17.7301 14.25 16.6001V7.41011C14.25 6.27011 13.9 5.42011 13.29 5.09011C12.68 4.75011 11.77 4.90011 10.81 5.51011L7.88 7.33011C7.45 7.61011 6.94 7.75011 6.43 7.75011H5Z"
				fill="#FBFAFF"
			/>
			<path
				d="M17.9998 16.7498C17.8398 16.7498 17.6898 16.6998 17.5498 16.5998C17.2198 16.3498 17.1498 15.8798 17.3998 15.5498C18.9698 13.4598 18.9698 10.5398 17.3998 8.44978C17.1498 8.11978 17.2198 7.64978 17.5498 7.39978C17.8798 7.14978 18.3498 7.21978 18.5998 7.54978C20.5598 10.1698 20.5598 13.8298 18.5998 16.4498C18.4498 16.6498 18.2298 16.7498 17.9998 16.7498Z"
				fill="#FBFAFF"
			/>
			<path
				d="M19.8299 19.2498C19.6699 19.2498 19.5199 19.1998 19.3799 19.0998C19.0499 18.8498 18.9799 18.3798 19.2299 18.0498C21.8999 14.4898 21.8999 9.50978 19.2299 5.94978C18.9799 5.61978 19.0499 5.14978 19.3799 4.89978C19.7099 4.64978 20.1799 4.71978 20.4299 5.04978C23.4999 9.13978 23.4999 14.8598 20.4299 18.9498C20.2899 19.1498 20.0599 19.2498 19.8299 19.2498Z"
				fill="#FBFAFF"
			/>
		</svg>
	);
}

export function PlaybackIcon(props) {
	return (
		<svg
			{...props}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.15001 7.85003C2.08334 7.85003 2.00834 7.84172 1.94168 7.81672C1.61668 7.70005 1.45002 7.3417 1.55835 7.0167C2.11668 5.45003 3.08336 4.07506 4.37502 3.03339C4.64169 2.81672 5.03335 2.85838 5.25001 3.12504C5.46668 3.39171 5.42502 3.78338 5.15836 4.00838C4.05002 4.90004 3.2167 6.08336 2.7417 7.43336C2.65003 7.6917 2.40834 7.85003 2.15001 7.85003Z"
				fill="#232226"
			/>
			<path
				d="M2.14983 13.4C1.89149 13.4 1.64983 13.2417 1.55817 12.9833C1.2165 12.0083 1.0415 11.0083 1.0415 10C1.0415 9.65833 1.32484 9.375 1.6665 9.375C2.00817 9.375 2.2915 9.65833 2.2915 10C2.2915 10.8667 2.44151 11.7333 2.74151 12.5667C2.85818 12.8917 2.68316 13.25 2.35816 13.3666C2.29149 13.3916 2.21649 13.4 2.14983 13.4Z"
				fill="#232226"
			/>
			<path
				d="M10 18.9586C9.11668 18.9586 8.24167 18.8253 7.39167 18.567C7.05834 18.467 6.87501 18.117 6.97501 17.7836C7.07501 17.4503 7.42501 17.267 7.75834 17.367C8.48334 17.592 9.24168 17.7003 10 17.7003C14.25 17.7003 17.7083 14.2419 17.7083 9.99195C17.7083 9.55861 17.6667 9.10861 17.5833 8.63361C17.525 8.29194 17.75 7.96695 18.0917 7.90861C18.4333 7.85028 18.7583 8.07527 18.8167 8.41693C18.9167 8.96693 18.9667 9.48362 18.9667 10.0003C18.9584 14.942 14.9417 18.9586 10 18.9586Z"
				fill="#232226"
			/>
			<path
				d="M4.76685 17.0836C4.62518 17.0836 4.49185 17.0419 4.37518 16.9419C3.90018 16.5586 3.51684 16.1919 3.19184 15.8169C2.96684 15.5585 3.00019 15.1585 3.25852 14.9335C3.51685 14.7085 3.91685 14.7419 4.14185 15.0002C4.41685 15.3169 4.75018 15.6335 5.15852 15.9669C5.42518 16.1835 5.46684 16.5752 5.25017 16.8419C5.13351 17.0002 4.95018 17.0836 4.76685 17.0836Z"
				fill="#232226"
			/>
			<path
				d="M16.8663 5.90827C16.6663 5.90827 16.4747 5.81659 16.3497 5.63326C14.908 3.53326 12.533 2.28324 9.99969 2.28324C9.24135 2.28324 8.48302 2.3916 7.75802 2.6166C7.43302 2.7166 7.08302 2.53326 6.97468 2.19993C6.86635 1.8666 7.05802 1.5166 7.39135 1.4166C8.24135 1.15826 9.11635 1.0249 9.99969 1.0249C12.9497 1.0249 15.708 2.48327 17.383 4.91661C17.5747 5.19994 17.508 5.59159 17.2247 5.78325C17.1164 5.87492 16.9913 5.90827 16.8663 5.90827Z"
				fill="#232226"
			/>
			<path
				d="M9.2417 13.0586C8.9417 13.0586 8.65836 12.9836 8.40003 12.8419C7.82503 12.5086 7.4917 11.8336 7.4917 10.9919V8.99189C7.4917 8.15022 7.82503 7.47525 8.40003 7.14192C8.97503 6.80858 9.72505 6.85857 10.45 7.28357L12.1834 8.28358C12.9084 8.70024 13.3334 9.32523 13.3334 9.9919C13.3334 10.6586 12.9167 11.2752 12.1834 11.7002L10.45 12.7002C10.05 12.9419 9.63337 13.0586 9.2417 13.0586ZM9.25005 8.18358C9.16671 8.18358 9.0917 8.20025 9.03337 8.23358C8.85837 8.33358 8.75004 8.6169 8.75004 9.00023V11.0003C8.75004 11.3753 8.85837 11.6586 9.03337 11.7669C9.20837 11.8669 9.50836 11.8169 9.83336 11.6253L11.5667 10.6252C11.8917 10.4336 12.0834 10.2002 12.0834 10.0002C12.0834 9.80024 11.8917 9.56691 11.5667 9.37524L9.83336 8.37523C9.6167 8.25023 9.40838 8.18358 9.25005 8.18358Z"
				fill="#232226"
			/>
		</svg>
	);
}

export function PictureInPictureIcon(props) {
	return (
		<svg
			{...props}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.4998 18.9582H11.6665C11.3248 18.9582 11.0415 18.6748 11.0415 18.3332C11.0415 17.9915 11.3248 17.7082 11.6665 17.7082H12.4998C16.3415 17.7082 17.7082 16.3415 17.7082 12.4998V7.49984C17.7082 3.65817 16.3415 2.2915 12.4998 2.2915H7.49984C3.65817 2.2915 2.2915 3.65817 2.2915 7.49984V8.3165C2.2915 8.65817 2.00817 8.9415 1.6665 8.9415C1.32484 8.9415 1.0415 8.65817 1.0415 8.3165V7.49984C1.0415 2.97484 2.97484 1.0415 7.49984 1.0415H12.4998C17.0248 1.0415 18.9582 2.97484 18.9582 7.49984V12.4998C18.9582 17.0248 17.0248 18.9582 12.4998 18.9582Z"
				fill="#232226"
			/>
			<path
				d="M6.5415 18.9585H4.2915C2.07484 18.9585 1.0415 17.9252 1.0415 15.7085V13.4585C1.0415 11.2418 2.07484 10.2085 4.2915 10.2085H6.5415C8.75817 10.2085 9.7915 11.2418 9.7915 13.4585V15.7085C9.7915 17.9252 8.75817 18.9585 6.5415 18.9585ZM4.2915 11.4585C2.75817 11.4585 2.2915 11.9252 2.2915 13.4585V15.7085C2.2915 17.2418 2.75817 17.7085 4.2915 17.7085H6.5415C8.07484 17.7085 8.5415 17.2418 8.5415 15.7085V13.4585C8.5415 11.9252 8.07484 11.4585 6.5415 11.4585H4.2915Z"
				fill="#232226"
			/>
		</svg>
	);
}

export function ArrowLeftIcon(props) {
	return (
		<svg
			{...props}
			width="9"
			height="16"
			viewBox="0 0 9 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.4999 15.2248C7.34157 15.2248 7.18324 15.1664 7.05824 15.0414L1.6249 9.60811C0.741569 8.72477 0.741569 7.27477 1.6249 6.39144L7.05824 0.958105C7.2999 0.716439 7.6999 0.716439 7.94157 0.958105C8.18324 1.19977 8.18324 1.59977 7.94157 1.84144L2.50824 7.27477C2.10824 7.67477 2.10824 8.32477 2.50824 8.72477L7.94157 14.1581C8.18324 14.3998 8.18324 14.7998 7.94157 15.0414C7.81657 15.1581 7.65824 15.2248 7.4999 15.2248Z"
				fill="#5B5766"
			/>
		</svg>
	);
}

export function TickIcon(props) {
	return (
		<svg
			{...props}
			width="14"
			height="10"
			viewBox="0 0 14 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.98141 9.30625C4.81475 9.30625 4.65641 9.23958 4.53975 9.12292L0.514746 5.09792C0.273079 4.85625 0.273079 4.45625 0.514746 4.21458C0.756413 3.97292 1.15641 3.97292 1.39808 4.21458L4.98141 7.79792L12.5981 0.18125C12.8397 -0.0604167 13.2397 -0.0604167 13.4814 0.18125C13.7231 0.422917 13.7231 0.822916 13.4814 1.06458L5.42308 9.12292C5.30641 9.23958 5.14808 9.30625 4.98141 9.30625Z"
				fill="#5B5766"
			/>
		</svg>
	);
}
