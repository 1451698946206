import styles from "./product.module.scss"

export default function ViewCourseImg({ src }) {
	if (!src) {
		return (
			<svg
				className={styles.course__img_svg}
				width="36"
				height="36"
				viewBox="0 0 36 36"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M28.6001 35.9167H7.31677C4.78344 35.9167 2.46677 34.6334 1.13344 32.4667C-0.199897 30.3 -0.316564 27.6667 0.816769 25.3834L3.68344 19.6334C4.61677 17.7667 6.11677 16.6 7.8001 16.4167C9.48344 16.2334 11.2001 17.0667 12.5001 18.6834L12.8668 19.15C13.6001 20.05 14.4501 20.5334 15.2834 20.45C16.1168 20.3834 16.8834 19.7834 17.4501 18.7667L20.6001 13.0834C21.9001 10.7334 23.6334 9.51671 25.5168 9.60004C27.3834 9.70004 28.9834 11.1 30.0501 13.5667L35.2668 25.75C36.2334 28 36.0001 30.5667 34.6501 32.6167C33.3168 34.7 31.0501 35.9167 28.6001 35.9167ZM8.26677 18.9167C8.2001 18.9167 8.13344 18.9167 8.06677 18.9334C7.23344 19.0167 6.46677 19.6834 5.91677 20.7667L3.0501 26.5167C2.3001 28 2.38344 29.75 3.2501 31.1667C4.11677 32.5834 5.6501 33.4334 7.31677 33.4334H28.5834C30.2168 33.4334 31.6668 32.65 32.5668 31.2834C33.4668 29.9167 33.6168 28.2834 32.9668 26.7834L27.7501 14.6C27.1168 13.1 26.2334 12.1834 25.3834 12.15C24.6001 12.1 23.5834 12.9334 22.7834 14.35L19.6334 20.0334C18.6668 21.7667 17.1501 22.85 15.5001 23C13.8501 23.1334 12.1668 22.3334 10.9168 20.7667L10.5501 20.3C9.8501 19.3834 9.0501 18.9167 8.26677 18.9167Z" />
				<path d="M9.61677 12.5834C6.18344 12.5834 3.36677 9.78337 3.36677 6.33337C3.36677 2.88337 6.16677 0.083374 9.61677 0.083374C13.0668 0.083374 15.8668 2.88337 15.8668 6.33337C15.8668 9.78337 13.0668 12.5834 9.61677 12.5834ZM9.61677 2.58337C7.5501 2.58337 5.86677 4.26671 5.86677 6.33337C5.86677 8.40004 7.5501 10.0834 9.61677 10.0834C11.6834 10.0834 13.3668 8.40004 13.3668 6.33337C13.3668 4.26671 11.6834 2.58337 9.61677 2.58337Z" />
			</svg>
		)
	}
	return <img src={src} alt="img" />
}
