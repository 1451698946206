import {
    legacy_createStore as createStore,
    applyMiddleware,
    compose,
    combineReducers,
} from "redux"
import createSagaMiddleware from "redux-saga";
import {reducers} from './reducers'
import sagas from "./sagas";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware]


const configStore = () => {
    const store = createStore(
        combineReducers({
            ...reducers,
        }),
        {},
        composeEnhancers(applyMiddleware(...middlewares))
    )
    sagaMiddleware.run(sagas);
    return store
}

const store = configStore()

export default store
