import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sendCourseAccess } from "../../store/reducers/course";
import { productSelector } from "../../store/reducers/products";
import Typography from "../UIKit/base/Typography";
import Button from "../forms/button/Button";
import { KeyIcon } from "../icons";
import style from "./request.module.sass";

const RequestAccessButton = ({ setIsBought, isMobile }) => {
  const product = useSelector(productSelector);
  const type = product?.product?.type;
  const id = product?.product?.id;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(sendCourseAccess({ data: { type, id }, setIsBought }));
  };

  return (
    <>
      {!isMobile && (
        <Typography
          component={Button}
          className={`${style.requestAccess} ${style.button_primary}`}
          onClick={handleClick}
        >
          {type === "path" ? "enroll_path" : "enroll_course"}
        </Typography>
      )}

      {isMobile && (
        <div className={style.requestAccess__mobile} onClick={handleClick}>
          <Typography>{t('enroll_course')}</Typography>
        </div>
      )}
    </>
  );
};

export default RequestAccessButton;
