import styles from "./Comments.module.sass";
import CommentsListItem from "./CommentsListItem";

const CommentsList = ({ comments }) => {
  return (
    <div className={styles.comments__list}>
      {!!comments?.length &&
        comments.map((comment) => (
          <CommentsListItem key={comment.id} comment={comment} />
        ))}
    </div>
  );
};

export default CommentsList;
