import {api} from "../http";

export async function getCommentsRequest({postId, page}) {
    const url = `/v1/post/${postId}/comments?page=${page}`
    return await api.get(url)
}

export async function getRepliesRequest({postId, parentId, page}) {
    const url = `/v1/post/${postId}/comments/${parentId}?page=${page}`
    return await api.get(url)
}

export async function sendCommentRequest({postId, data}) {
    const url = `/v1/post/${postId}/comments`
    return await api.post(url, data)
}
 