import axios from "axios";
import { api } from "../http";

const BASE_URL = process.env.REACT_APP_END_POINT_BD

export async function domainThemeRequest() {
    const domainName = window.location.host.split('.')[0]
    const url = `${BASE_URL}/v1/theme/${domainName}`
    return await axios.get(url)
}

export async function getTimezonesRequest(){
    const url = `${BASE_URL}/v1/references/timezones`
    return await axios.get(url)
}
 
export async function getLogoRequest(){
    const url = `${BASE_URL}/v1/image/logo-origin.png`
    return await axios.get(url, { responseType: "blob" })
}

export async function getCountriesRequest(){
    const url = `${BASE_URL}/v1/references/countries`
    return await api.get(url)
}