import {api} from "../http";

export async function getProductsRequest({type, status}) {
    let url = `/v1/products?type=${type}`

    if(status) {
        url = `${url}&filter[status]=${status}`
    }
    return await api.get(url)
}

export async function getProductRequest({productId, queryString}) {
    let url = `/v1/products/${productId}`

    if(queryString) {
        url = `${url}?${queryString}`
    }
    return await api.get(url)
}
