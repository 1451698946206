import styles from "./modules.module.scss";
import Percent from "../Percent/Percent";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { modulesSelector } from "../../store/reducers/course"; 
import { productSelector } from "../../store/reducers/products";
import Lessons from "../Lessons/Lessons";
import Typography from "../UIKit/base/Typography";
import PropTypes from "prop-types"; 
import useIconPack from "./ModuleIconPack/useIconPack";
import {
  ModuleDefaultIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  LessonIcon,
} from "../icons";
import { formatEndings } from "../utils";
import i18next from "i18next";

export default function Modules({state}) {
  const modules = useSelector(modulesSelector);

  if (!modules) {
    return null;
  }
  return modules.map((e) => {
    return <Module key={`${e.id}-${e.status}`} item={e} state={state}/>;
  });
}
 
function Module({ item, state }) {
  //const course = useSelector(courseSelector)
  const course = useSelector(productSelector);
  const isBought = course?.product?.bought;
  const isAvailable = course?.product?.available;
  const startSoon = !!course?.product?.start_days_left

  const [open, setOpen] = useState(false);

  const ModuleIcon = useIconPack()[item.icon];

  const isMobile = window.innerWidth <= 768;

  const handleModuleStatus = () => {
    setOpen((s) => !s);
  };
  return (
    <div className={styles.arrStructure}>
      <div className={styles.arrStructure_wrap} onClick={handleModuleStatus}>
        <div className={styles.arrStructure_wrap_right}>
          <div className={styles.icon_elem}>
            {item.icon ? <ModuleIcon /> : <ModuleDefaultIcon />}
          </div>
          <div>
            <Typography fontVariant="semiBold" className={styles.moduleTitle}>
              {item.title}
            </Typography>
            <Typography fontVariant="" className={styles.moduleSubTitle}>
              <LessonIcon />
              {formatEndings(item.posts_count, "lesson", i18next.language)}
            </Typography>
          </div>
        </div>
        <div className={styles.arrStructure_wrap_rightMain}>
          {isBought && (isAvailable || startSoon) && (
            <>
              <Percent
                info={{
                  radius: isMobile ? 12 : 16,
                  passingScore: item.posts_completed,
                  passingTotal:
                    item.posts_count !== null ? item.posts_count : 1,
                }}
              />
              <div className={styles.progress_lessons_text}>
                {item.posts_completed}/{item.posts_count}
              </div>
            </>
          )}
          <div className={styles.arrStructure_wrap_svg_open}>
            {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
        </div>
      </div>
      {open && <Lessons lessons={item?.posts} state={state} />}
    </div>
  );
}