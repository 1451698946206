import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import { setColorPalette } from "../utils";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#E2E0FF",
  secondaryGray: "#A4A1B2",
};

export function NoItemsImage(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme.title) {
      const newColors = setColorPalette(theme);
      setColors(newColors);
    }
  }, [theme]);

  return (
    <svg
      width="331"
      height="268"
      viewBox="0 0 331 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.306 239.334L168.527 243.025C152.809 247.238 143.251 242.007 139.222 226.963L121.099 159.33V220.871C121.099 236.447 113.224 243.972 96.9506 243.972H82.6849C74.9346 243.972 69.0899 242.266 65.0952 238.797C61.1004 242.266 55.2557 243.972 47.5055 243.972H33.2397C16.9659 243.972 9.0918 236.447 9.0918 220.871V105.197C9.0918 89.6215 16.9659 82.0967 33.2397 82.0967H47.5055C55.2557 82.0967 61.1004 83.8024 65.0952 87.2711C69.0899 83.8024 74.9346 82.0967 82.6849 82.0967H96.9506C106.76 82.0967 113.519 84.83 117.339 90.4119C119.902 88.8856 122.996 87.6396 126.631 86.666L140.406 82.9746C156.128 78.7625 165.682 83.9931 169.715 99.0369L199.652 210.771C203.685 225.815 198.024 235.122 182.306 239.334ZM48.5437 128.405C48.5437 132.916 44.8864 136.574 40.3747 136.574C35.863 136.574 32.2058 132.916 32.2058 128.405C32.2058 123.894 35.863 120.236 40.3747 120.236C44.8864 120.236 48.5437 123.894 48.5437 128.405ZM147.622 137.44C151.98 136.273 154.569 131.794 153.398 127.437C152.232 123.079 147.754 120.493 143.396 121.66C139.039 122.828 136.454 127.307 137.62 131.665C138.787 136.023 143.264 138.608 147.622 137.44ZM89.8199 136.574C94.3316 136.574 97.9888 132.916 97.9888 128.405C97.9888 123.894 94.3316 120.236 89.8199 120.236C85.3082 120.236 81.6509 123.894 81.6509 128.405C81.6509 132.916 85.3082 136.574 89.8199 136.574ZM22.2168 200.014H58.5284V208.642H22.2168V200.014ZM107.974 200.014H71.6619V208.642H107.974V200.014ZM181.575 194.021L146.503 203.419L148.733 211.753L183.81 202.354L181.575 194.021Z"
        fill={colors?.secondaryLightPurple}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.957 134.941C207.131 166.98 161.033 172.828 128.994 148.002C96.9553 123.177 91.1077 77.0785 115.933 45.0394C140.759 13.0003 186.857 7.15265 218.896 31.9784C250.935 56.8041 256.783 102.902 231.957 134.941Z"
        fill={colors?.secondaryBackground}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.82 163.807C239.036 159.325 230.928 159.801 224.902 163.953C193.533 185.57 150.56 185.538 118.838 160.958C79.5935 130.549 72.4308 74.0832 102.84 34.8384C133.249 -4.40648 189.715 -11.5692 228.959 18.8399C260.681 43.4198 271.442 85.024 258.342 120.797C255.826 127.669 257.389 135.638 263.174 140.121L325.194 188.177C331.734 193.245 332.928 202.656 327.86 209.197C322.792 215.738 313.381 216.932 306.84 211.863L244.82 163.807ZM231.93 134.865C207.096 166.915 160.983 172.765 128.933 147.931C96.8828 123.096 91.0333 76.9828 115.867 44.9328C140.701 12.8829 186.815 7.03328 218.865 31.8674C250.915 56.7015 256.764 102.815 231.93 134.865Z"
        fill={colors?.bg_color}
      />
      <mask
        id="mask0_399_119"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="101"
        y="17"
        width="148"
        height="148"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.126 135.604C208.263 167.692 162.095 173.548 130.008 148.685C97.9206 123.822 92.0642 77.6542 116.927 45.5667C141.791 13.4792 187.958 7.62277 220.046 32.486C252.133 57.3492 257.989 103.517 233.126 135.604Z"
          fill={colors?.bg_color}
        />
      </mask>
      <g mask="url(#mask0_399_119)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.349 250.239L177.602 254.457C159.639 259.272 148.716 253.294 144.111 236.101L123.398 158.806V229.139C123.398 246.939 114.399 255.539 95.8007 255.539H79.497C70.6396 255.539 63.9599 253.59 59.3945 249.625C54.8291 253.59 48.1494 255.539 39.292 255.539H22.9883C4.38964 255.539 -4.60938 246.939 -4.60938 229.139V96.9393C-4.60938 79.139 4.38964 70.5392 22.9883 70.5392H39.292C48.1494 70.5392 54.8291 72.4886 59.3945 76.4528C63.9599 72.4886 70.6396 70.5392 79.497 70.5392H95.8007C107.012 70.5392 114.736 73.6629 119.101 80.0423C122.031 78.2979 125.566 76.874 129.722 75.7613L145.464 71.5426C163.433 66.7287 174.35 72.7065 178.96 89.8995L213.174 217.595C217.783 234.788 211.313 245.425 193.349 250.239ZM40.4785 123.463C40.4785 128.619 36.2988 132.799 31.1426 132.799C25.9863 132.799 21.8066 128.619 21.8066 123.463C21.8066 118.307 25.9863 114.127 31.1426 114.127C36.2988 114.127 40.4785 118.307 40.4785 123.463ZM153.711 133.789C158.691 132.455 161.65 127.336 160.312 122.356C158.979 117.376 153.862 114.42 148.882 115.755C143.901 117.089 140.947 122.208 142.28 127.188C143.613 132.169 148.73 135.124 153.711 133.789ZM87.6513 132.799C92.8076 132.799 96.9872 128.619 96.9872 123.463C96.9872 118.307 92.8076 114.127 87.6513 114.127C82.4951 114.127 78.3154 118.307 78.3154 123.463C78.3154 128.619 82.4951 132.799 87.6513 132.799ZM10.3906 205.302H51.8896V215.162H10.3906V205.302ZM108.398 205.302H66.8994V215.162H108.398V205.302ZM192.515 198.452L152.432 209.193L154.98 218.718L195.068 207.976L192.515 198.452Z"
          fill={colors?.secondaryLightPurple}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeDasharray="12 12"
        />
      </g>
    </svg>
  );
}
