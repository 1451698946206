import { api } from "../http"

export async function getDashboardRequest(){
    const url = '/v1/dashboard'
    return await api.get(url)
}

export async function getLearningRequest(){
    const url = '/v1/dashboard/learning'
    return await api.get(url)
}

export async function getCalendarRequest(){
    const url = '/v1/dashboard/calendar'
    return await api.get(url)
}