import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarView from "../UIKit/avatarView/AvatarView";
import { ArrowDownIcon, ArrowUpIcon } from "../icons";
import { formatEndings, timeSince } from "../utils";
import CommentAdd from "./CommentAdd";
import styles from "./Comments.module.sass";
import CommentsList from "./CommentsList";

const CommentsListItem = ({ comment }) => {
	const [showAddReply, setShowAddReply] = useState(false);
	const [showReplies, setShowReplies] = useState(false);
	const [parentComment, setParentComment] = useState("");

	const { t } = useTranslation();

	const {
		avatar,
		content,
		created_at,
		id,
		is_mine,
		person_name,
		replies_count,
		parent_id,
		replies
	} = comment;

	const isReplyToReply = !!parent_id;
 

	const handleFooterClick = () => {
	}

	const handleReplyClick = () => {

		if(!showAddReply) {
			setShowReplies(true)
		}
		setShowAddReply((prev) => !prev);

	};

	const handleRepliesClick = () => {
		setShowReplies((prev) => !prev);
	};

	return (
		<div className={styles.comment}>
			<div className={styles.comment__avatar}>
				<div className={`${styles.tabLine_header_name} ${styles.tabLine_name}`}>
					<div className={styles.tabLine_name_round}>
						<AvatarView name={person_name} src={avatar?.big} />
					</div>
				</div>
			</div>

			<div className={styles.comment__body}>
				<div className={styles.comment__header}>
					<div className={styles.comment__name}>
						{is_mine ? (
							<h3 className={styles.comment__me}>{t("me")}</h3>
						) : (
							<h3>{person_name}</h3>
						)}
					</div>

					<div className={styles.comment__date}>
						{timeSince(created_at, i18next.language)} {t("ago")}
					
					</div>
				</div>

				<div className={styles.comment__text}>
					{parentComment.parent_id && (
						<span className={styles.comment__parentPerson}>
							@{parentComment.person_name}
						</span>
					)}
					<p>{typeof(formatText(content)) === "string" ? content : <><span>{formatText(content)?.name}</span>{formatText(content)?.content}</>}</p>
				</div>

				<div className={styles.comment__footer} onClick={handleFooterClick}>
					<span className={styles.comment__reply} onClick={handleReplyClick}>
						{t("reply")}
					</span>

					{!!replies_count && (
						<span
							className={styles.comment__repliesCount}
							onClick={handleRepliesClick}
						>
							{formatEndings(replies_count, "reply", i18next.language)}{" "}
							{showReplies ? <ArrowUpIcon /> : <ArrowDownIcon />}
						</span>
					)}
				</div>
				{showAddReply && (
					<div className={styles.comment__addReply}>
						<CommentAdd
							parent_id={id}
							setShowAddReply={setShowAddReply}
							reply={true}
							isReplyToReply={isReplyToReply}
							replyId={parent_id}
							answerPersonName={person_name}
						/>
					</div>
				)}
				{!!replies_count && showReplies && (
					<div className={styles.comment__replies}>
						<CommentsList comments={replies} />
					</div>
				)}
			</div>
		</div>
	);
};

export default CommentsListItem;


const formatText = (text) => {

	if(text[0] === "@") {
		const index = text.indexOf(" ")
		const name = text.slice(0, index)
		const content = text.slice(index)

		const obj = {name, content}
		return obj

	}

	return text

}