import {useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {useSearchParams} from "react-router-dom"
import {setEmail}  from "../../store/reducers/user";
import { useNavigate } from "react-router-dom"

const EmailSet = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate()

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

	useEffect(()=> {
		dispatch(setEmail({data: {token}, cb: () => navigate("/profile", {replace: true})}))
	}, [token, dispatch, navigate])

	return null
}

export default EmailSet