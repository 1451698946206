import {Image1} from "./SliderImages/Image1.js"
import {Image2} from "./SliderImages/Image2.js"
import {Image3} from "./SliderImages/Image3.js"

export const slides = [
	{
		text: "follow_your_performance",
		src: Image1,
	},
	{
		text: "grow_with_progress",
		src: Image2,
	},
	{
		text: "learn_with_interface",
		src: Image3,
	},
]
