import {startReducer as start} from "./startReducer"
import loader from './loader'
import auth from "./auth"
import user from "./user"
import course from './course'
import products from "./products"
import domainTheme from "./domainTheme";
import lesson from "./lesson";
import alert from '../../components/UIKit/alert/alertReducer'
import breadcrumbs from "./breadcrumbs"
import notifications from './notifications'
import catalog from './catalog'
import certificates from './certificates'
import reviews from './reviews'
import comments from './comments'
import knowledgeBase from './knowledgeBase'
import surveys from "./surveys"
import department from "./department"
import dashboard from "./dashboard"
import assistant from "./assistant"
import anonymous from "./anonymous"



export const reducers = {
    anonymous,
    assistant,
    dashboard,
    department,
    surveys,
    loader,
    user,
    auth,
    start,
    domainTheme,
    products,
    course,
    lesson,
    alert,
    breadcrumbs,
    catalog,
    certificates,
    notifications,
    reviews,
    comments,
    knowledgeBase
}
