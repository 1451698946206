import { CheckBoxButtonGreyIcon,  RadioBoxButtonGreyIcon, TextIcon } from "../../icons";

export const types = {
    textarea: {
        name: 'text_answer',
        icon: TextIcon
    },
    radio:{
        name: 'select_one',
        icon: RadioBoxButtonGreyIcon
    },checkbox:{
        name: 'select_one_or_more',
        icon: CheckBoxButtonGreyIcon
    },
}