import React from "react"
import ReactDOM from "react-dom/client"
import "./index.sass"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import { Provider } from "react-redux"

import { BrowserRouter } from "react-router-dom"
import "./i18n"
import store from "./store"
import { HelmetProvider } from "react-helmet-async"
const { dispatch } = store

export { dispatch }
export { store }

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
		<HelmetProvider>
			<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
		</HelmetProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
