import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { courseSelector } from "../../../store/reducers/course";
import { lessonSelector } from "../../../store/reducers/lesson";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import MobileBreadcrumbs from "../../Mobile/MobileBreadcrumbs";
import MobileTitle from "../../Mobile/MobileTitle";
import Typography from "../../UIKit/base/Typography";
import CancelButton from "../../UIKit/buttons/CancelButton/CancelButton";
import Modal from "../../UIKit/modal";
import { SliderIcon } from "../../icons";
import { Modules } from "./LessonLayout";
import styles from "./MobileHeader.module.sass";

const MobileHeader = () => {
  const { t } = useTranslation();
  const { post, modules } = useSelector(lessonSelector);
  const course = useSelector(courseSelector);
  const [modal, setModal] = useState(false);

  const { postId } = useParams();

  useEffect(() => {
    setModal(false);
  }, [postId]);

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <div className={styles.mobileHeader}>
      <MobileTitle title={post?.title} />
      <div className={styles.mobileHeader__tabletBreadcrumbs}>
        <Breadcrumbs />
      </div>
      <div className={styles.mobileHeader__menu}>
        <MobileBreadcrumbs title={post?.product?.name} />
        <div className={styles.mobileHeader__menuRight}>
          <div
            className={styles.mobileHeader__buttonInfo}
            onClick={handleOpenModal}
          >
            {window.innerWidth <= 768 ? (
              <SliderIcon />
            ) : (
              <Typography fontVariant="medium">{t("lessons")}</Typography>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={modal} className={styles.modal} onClose={handleCloseModal}>
        {(close) => (
          <div className={styles.modal__inner}>
            <div className={styles.modal__header}>
              <Typography component="span" fontVariant="medium">
                {t("lessons")}
              </Typography>
              <CancelButton
                className={styles.mobileHeader__cancel}
                onClick={handleCloseModal}
              />
            </div>

            <Modules modules={modules} />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default MobileHeader;
