import { handleActions, createAction } from "redux-actions"
import {
	all,
	call,
	take,
	put,
	takeEvery,
	takeLeading,
} from "redux-saga/effects"
import {
	getCertificatesRequest,
	generateCertificateRequest,
	downloadCertificateRequest,
} from "../../api/certificates/certificates"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"

import { downloadFile } from "../../components/downloadLink"

const namespace = "certificates"

const initialState = []

const GET_CERTIFICATES = `${namespace}/GET_CERTIFICATES`
const SET_CERTIFICATES = `${namespace}/SET_CERTIFICATES`
const GENERATE_CERTIFICATE = `${namespace}/GENERATE_CERTIFICATE`
const DOWNLOAD_CERTIFICATE = `${namespace}/DOWNLOAD_CERTIFICATE`

export const getCertificates = createAction(GET_CERTIFICATES)
export const setCertificates = createAction(SET_CERTIFICATES)
export const generateCertificate = createAction(GENERATE_CERTIFICATE)
export const downloadCertificate = createAction(DOWNLOAD_CERTIFICATE)

export default handleActions(
	{
		[SET_CERTIFICATES]: (state, { payload }) => payload,
	},
	initialState
)

export const certificatesSelector = (state) => state[namespace].certificates

function* getCertificatesSaga() {
	while (true) {
		yield take(GET_CERTIFICATES)
		// yield put(addGlobalLoadItem(GET_CERTIFICATES))
		try {
			const { data } = yield call(getCertificatesRequest)
			yield put(setCertificates(data))
		} catch (err) {
			console.log(err) 
		} finally {
			// yield put(removeGlobalLoadItem(GET_CERTIFICATES))
		}
	}
}

function* generateCertificateSaga({ payload: { cb, ...other } }) {
	yield put(addGlobalLoadItem(GENERATE_CERTIFICATE))
	try {
		yield call(generateCertificateRequest, other)
		yield put(getCertificates())
		if (typeof cb === "function") {
			cb()
		}
	} catch (err) {
		console.log(err)
	} finally {
		yield put(removeGlobalLoadItem(GENERATE_CERTIFICATE))
	}
}

function* downloadCertificateSaga({ payload: { cb, ...other } }) {
	yield put(addGlobalLoadItem())
	try {
		const { data } = yield call(downloadCertificateRequest, other)

		const imgLink = JSON.parse(data.split("\r\n")[5])?.url

		yield call(downloadFile, imgLink, "certificate")
		if (typeof cb === "function") {
			cb()
		}
	} catch (err) {
		console.log(err)
	} finally {
		yield put(removeGlobalLoadItem())
	}
}

export function* certificatesSagas() {
	yield all([
		getCertificatesSaga(),
		takeLeading(GENERATE_CERTIFICATE, generateCertificateSaga),
		takeEvery(DOWNLOAD_CERTIFICATE, downloadCertificateSaga),
	])
}
