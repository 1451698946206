import { useState, useEffect } from "react";
import { HeartIcon, HeartIconFill } from "../icons";

import styles from "./Reviews.module.sass";

const ReviewRatingSet = ({ reviewRating, setReviewRating }) => {
  //rerender

  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(null);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setRating(reviewRating)
  }, [reviewRating])

  const handleOver = (value) => {
    setHoverRating(value);
    setIsHover(true);
  };

  const handleOut = () => {
    setHoverRating(0);
    setIsHover(false);
  };

  const handleClick = (value) => {
    setRating(value)
    setReviewRating(value)
  }

 

  return (
    <div className={styles.reviewSend__rate}>
      {Array(5)
        .fill()
        .map((i, index) => (
          <div
            className={styles.reviewSend__rateItem}
            onMouseOver={() => handleOver(index + 1)}
            onMouseOut={handleOut}
            onClick={() => handleClick(index + 1)}
          >
            {isHover && (
              <>
                {index + 1 <= hoverRating ? <HeartIconFill /> : <HeartIcon />}
              </>
            )}
            {!isHover && (
              <>{index + 1 <= rating ? <HeartIconFill /> : <HeartIcon />}</>
            )}
          </div>
        ))}
    </div>
  );
};

export default ReviewRatingSet;
