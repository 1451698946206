import { createAction, handleActions } from "redux-actions"
import { all, call, put, take } from "redux-saga/effects"
import { getDashboardRequest, getLearningRequest, getCalendarRequest } from "../../api/dashboard/dashboard"
import { getCourseRequest } from "../../api/course/course"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"

const namespace = `dashboard`

const GET_DASHBOARD = `${namespace}/GET_DASBOARD`
const SET_DASHBOARD = `${namespace}/SET_DASBOARD`
const MOVE_TO_POST = `${namespace}/MOVE_TO_POST`

const GET_LEARNING = `${namespace}/GET_LEARNING`
const SET_LEARNING = `${namespace}/SET_LEARNING`

const GET_СALENDAR = `${namespace}/GET_СALENDAR`
const SET_СALENDAR = `${namespace}/SET_СALENDAR`

const initialState = {
	dashboard: null,
	learning: null,
	calendar: null
}

export const getDashboard = createAction(GET_DASHBOARD)
export const setDashboard = createAction(SET_DASHBOARD)

export const moveToPost = createAction(MOVE_TO_POST)

export const getLearning = createAction(GET_LEARNING)
export const setLearning = createAction(SET_LEARNING)

export const getCalendar = createAction(GET_СALENDAR)
export const setCalendar = createAction(SET_СALENDAR)

export default handleActions(
	{
		[SET_DASHBOARD]: (state, { payload }) => ({...state, dashboard: payload}),
		[SET_LEARNING]: (state, { payload }) => ({...state, learning: payload}),
				[SET_СALENDAR]: (state, { payload }) => ({...state, calendar: payload}),
	},
	initialState
)

export const dasboardSelector = (state) => state[namespace].dashboard
export const learningSelector = (state) => state[namespace].learning
export const calendarSelector = (state) => state[namespace].calendar

function* getDashboardSaga() {
	while (true) {
		yield take(GET_DASHBOARD)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(getDashboardRequest)
			yield put(setDashboard(data))
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

function* getLearningSaga() {
	while (true) {
		yield take(GET_LEARNING)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(getLearningRequest)
			yield put(setLearning(data))
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

function* getCalendarSaga() {
	while (true) {
		yield take(GET_СALENDAR)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(getCalendarRequest)
			yield put(setCalendar(data))
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

function* moveToPostSaga() {
	while (true) {
		const {
			payload: { courseId, navigate },
		} = yield take(MOVE_TO_POST)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(getCourseRequest, courseId)

			if (data?.action_button?.additional_data?.id) {
				navigate(
					`/products/${courseId}/${data?.action_button?.additional_data?.type}/${data?.action_button?.additional_data?.id}`
				)
			} else {
				navigate(`/products/${courseId}`)
			}
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

export function* dashboardSagas() {
	return yield all([getDashboardSaga(), moveToPostSaga(), getLearningSaga(), getCalendarSaga()])
}
