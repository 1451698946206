import { useEffect } from "react"
import AvatarView from "../UIKit/avatarView/AvatarView"
import styles from "./Dasboard.module.sass"
import {
	dasboardSelector,
	learningSelector,
	getLearning,
	getDashboard,
	moveToPost,
	getCalendar,
	calendarSelector,
} from "../../store/reducers/dashboard"
import { useDispatch, useSelector } from "react-redux"
import Typography from "../UIKit/base/Typography"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
	CardImageIcon,
	TimerIcon,
	LessonIcon,
	ModuleIcon,
	LockIcon,
} from "../icons"
import { localeFormat } from "../localeFormat"
import classNames from "classnames"
import MobileTitle from "../Mobile/MobileTitle"
import { MenuMobile } from "../layouts/Header/Header"
import Calendar from "./Calendar/Calendar"
import { formatEndings } from "../utils"
import Status from "../Status/Status"
import i18next from "i18next"
import ChartRadarCard from "../ChartRadar/ChartRadarCard"
import Bots from "./Bots/Bots"
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader"
import PreloaderDashboard from "../Preloader/Preloaders/PreloaderDashboard"
import { getSkills } from "../../store/reducers/user" 

export default function Dasboard() {
	const dasboard = useSelector(dasboardSelector)
	const learning = useSelector(learningSelector)?.learning
	const calendar = useSelector(calendarSelector)?.calendar
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])

	useEffect(() => {
		dispatch(getDashboard())
		dispatch(getLearning())
		dispatch(getCalendar())
		dispatch(getSkills())
	}, [dispatch])

	if (loading) {
		return <PreloaderDashboard />
	}

	return (
		<> 
			<MobileTitle title={t("dashboard")} showBackArrow={false} />
			<MenuMobile />
			<div className={styles.wrapper}>
				<div className={styles.col}>
					<UserCard person={dasboard?.person} />
					<div className={styles.data}>
						<div className={styles.link__container}>
							<LinkCard
								text="compeated_courses"
								value={dasboard?.statistic?.completed}
								href="/products?type=course&status=completed"
							/>
							<LinkCard
								text="courses_in_progress"
								value={dasboard?.statistic?.in_progress}
								href="/products?type=course&status=in_progress"
							/>
							<LinkCard
								text="granted_certificates"
								value={dasboard?.statistic?.certificates}
								href="/certificates"
								className={styles.certificates}
							/>
							<LinkCard
								text="spent_time_in_hours"
								value={dasboard?.statistic?.spend_hours}
							/>
						</div>
						<Calendar calendar={calendar} />
						<Bots bots={dasboard?.bot_links} />
					</div>
				</div>
				<div className={styles.col}>
					<ScheduledSurveys surveys={dasboard?.surveys} />
					<Courses courses={learning} />
					<ChartRadarCard />
				</div>
			</div>
		</>
	)
}

function UserCard({ person }) {
	const { t } = useTranslation()
	if (!person) {
		return null
	}
	return (
		<div className={styles.user__wrapper}>
			<AvatarView
				fontVariant="semiBold"
				name={person?.name}
				src={person?.avatar?.big}
				imgClassName={styles.user__avatar}
				textClassName={styles.user__avatar}
			/>
			<div className={styles.user__info}>
				<Typography fontVariant="semiBold" component={`h3`}>
					{`${t("Hello")},\n${person?.name}`}
				</Typography>
				<Typography component={`p`}>{t("track_activity")}</Typography>
			</div>
		</div>
	)
}

function LinkCard({ href = null, text, value, className }) {
	const { t } = useTranslation()
	const Component = href ? Link : "div"
	return (
		<Component
			className={classNames(styles.link__card, { [className]: className })}
			to={href}
		>
			<div className={styles.link__name}>
				<Typography>{t(text)}</Typography>
				{href && (
					<svg
						className={styles.link__name_icon}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M8.9101 20.6695C8.7201 20.6695 8.5301 20.5995 8.3801 20.4495C8.0901 20.1595 8.0901 19.6795 8.3801 19.3895L14.9001 12.8695C15.3801 12.3895 15.3801 11.6095 14.9001 11.1295L8.3801 4.60953C8.0901 4.31953 8.0901 3.83953 8.3801 3.54953C8.6701 3.25953 9.1501 3.25953 9.4401 3.54953L15.9601 10.0695C16.4701 10.5795 16.7601 11.2695 16.7601 11.9995C16.7601 12.7295 16.4801 13.4195 15.9601 13.9295L9.4401 20.4495C9.2901 20.5895 9.1001 20.6695 8.9101 20.6695Z" />
					</svg>
				)}
			</div>
			<Typography
				fontVariant="semiBold"
				component="h3"
				className={styles.link__count}
			>
				{value}
			</Typography>
			<div className={styles.tabArrow}>
				{href && (
					<>
						<Typography component="span" className={styles.tabArrow__text}>
							{t("more")}
						</Typography>
						<svg
							className={styles.link__name_icon}
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M8.9101 20.6695C8.7201 20.6695 8.5301 20.5995 8.3801 20.4495C8.0901 20.1595 8.0901 19.6795 8.3801 19.3895L14.9001 12.8695C15.3801 12.3895 15.3801 11.6095 14.9001 11.1295L8.3801 4.60953C8.0901 4.31953 8.0901 3.83953 8.3801 3.54953C8.6701 3.25953 9.1501 3.25953 9.4401 3.54953L15.9601 10.0695C16.4701 10.5795 16.7601 11.2695 16.7601 11.9995C16.7601 12.7295 16.4801 13.4195 15.9601 13.9295L9.4401 20.4495C9.2901 20.5895 9.1001 20.6695 8.9101 20.6695Z" />
						</svg>
					</>
				)}
			</div>
		</Component>
	)
}

function ScheduledSurveys({ surveys }) {
	const { t } = useTranslation()
	if (!surveys?.length) {
		return null
	}

	return (
		<div className={styles.surveys}>
			<div className={styles.surveys__header}>
				<Typography>{t("scheduled_surveys")}</Typography>
				<Typography
					fontVariant="semiBold"
					component={Link}
					className={styles.more}
					to="/surveys"
				>
					{t("view_all")}
				</Typography>
			</div>
			{surveys?.map((s) => {
				let to = `/surveys/${s?.id}/questions/${s?.next_question_id}`
				if (s?.type === "manual") {
					to = `/surveys/${s?.id}/questions/${s?.next_question_id}/manual`
				}
				if (s?.type === "performance") {
					to = `/surveys/${s?.id}/questions/${s?.next_question_id}/performance/people/${s?.target_person?.id}`
				}
				return (
					<Link key={s?.id} to={to} className={styles.survey__container}>
						<div className={styles.survey__info_container}>
							<Typography className={styles.survey__name}>{s?.name}</Typography>
							<div className={styles.survey__info}>
								<Typography className={styles.survey__time}>
									<TimerIcon className={styles.survey__icon} />
									{localeFormat(s?.finish_date_at)}
								</Typography>
								{s?.questions_count ? (
									<Typography className={styles.survey__info_name}>
										{`${s?.questions_count} ${t("questions")}`}
									</Typography>
								) : null}
								{s?.target_person?.name ? (
									<Typography className={styles.survey__info_name}>
										{s?.target_person?.name}
									</Typography>
								) : null}
							</div>
						</div>

						<svg
							className={styles.link__name_icon}
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M8.9101 20.6695C8.7201 20.6695 8.5301 20.5995 8.3801 20.4495C8.0901 20.1595 8.0901 19.6795 8.3801 19.3895L14.9001 12.8695C15.3801 12.3895 15.3801 11.6095 14.9001 11.1295L8.3801 4.60953C8.0901 4.31953 8.0901 3.83953 8.3801 3.54953C8.6701 3.25953 9.1501 3.25953 9.4401 3.54953L15.9601 10.0695C16.4701 10.5795 16.7601 11.2695 16.7601 11.9995C16.7601 12.7295 16.4801 13.4195 15.9601 13.9295L9.4401 20.4495C9.2901 20.5895 9.1001 20.6695 8.9101 20.6695Z" />
						</svg>
					</Link>
				)
			})}
		</div>
	)
}

function Courses({ courses }) {
	const { t } = useTranslation()
	if (!courses?.length) {
		return null
	}

	const map = courses.slice(0, 3)
	return (
		<div className={styles.courses}>
			<div className={styles.courses__header}>
				<Typography>{t("keep_learning")}</Typography>
				<Typography
					fontVariant="semiBold"
					component={Link}
					className={styles.more}
					to="/products"
				>
					{t("view_all")}
				</Typography>
			</div>
			<div>
				{map?.map((c) => (
					<CourseCard key={c?.product_id} course={c} />
				))}
			</div>
		</div>
	)
}

export function CourseCard({ course, isInPath, pathId, pathName }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const closed = course?.consistent_blocked
	const isMobile = window.innerWidth < 769

	function handleClick(e) {
		e.preventDefault()
		const {
			currentTarget: { value },
		} = e
		dispatch(moveToPost({ courseId: value, navigate }))
	}

	const speakers = isInPath ? course?.speakers_full : course?.speakers
	const type = isInPath ? course?.type : course?.product_type
	const title = isInPath ? course?.name : course?.product_title
	const avatars = isInPath
		? speakers.map((item) => {
				return { avatar: item.avatar, name: item.name }
		  })
		: speakers.map((item) => {
				return { avatar: item.speaker_avatar, name: item.speaker_name }
		  })

	const itemsName = course?.product_type === "path" ? "courses" : "lessons"

	const link = isInPath
		? `/products/${course?.id}?filter[path_id]=${pathId}`
		: `products/${course?.product_id}`

	return (
		<Link
			key={course?.id}
			to={link}
			className={classNames(styles.default__card, {
				[styles.default__closed]: closed,
				[styles.inPath]: isInPath,
			})}
			state={{
				type: "path",
				pathId: pathId,
				pathName: pathName,
				isInPath: isInPath,
			}}
		>
			{closed ? (
				course?.cover ? (
					<Theme src={course?.cover?.big} name={course?.name} closed={true} />
				) : (
					<div className={styles.default__hero_closed}>
						<LockIcon />
					</div>
				)
			) : (
				<Theme
					src={isInPath ? course?.cover?.big : course?.product_cover}
					name={course?.name}
				/>
			)}
			{isInPath && !closed && (
				<Status className={styles.status}>{course?.status}</Status>
			)}
			<div
				className={classNames(styles.default__info, {
					[styles.default__info_closed]: closed,
				})}
			>
				{(!isInPath || (isInPath && isMobile)) && (
					<Typography className={styles.default__type}>{t(type)}</Typography>
				)}
				<Typography
					fontVariant={isInPath && isMobile ? "regular" : "regular"}
					className={styles.default__title}
				>
					{title}
				</Typography>
				<div className={styles.coach__block}>
					<div className={styles.coach__avatars}>
						{!!avatars?.length &&
							avatars.slice(0, 2).map((s, i) => (
								<div
									key={i}
									className={styles.coach__avatar_wrapper}
									style={{ zIndex: speakers?.length - i }}
								>
									{!isInPath && (
										<AvatarView
											fontVariant="semiBold"
											name={s?.name}
											src={s?.avatar}
											imgClassName={styles.speaker__avatar}
											textClassName={styles.speaker__avatar}
										/>
									)}
									{isInPath && (
										<AvatarView
											fontVariant="semiBold"
											name={s?.name}
											src={s?.avatar?.small}
											imgClassName={styles.speaker__avatar}
											textClassName={styles.speaker__avatar}
										/>
									)}
								</div>
							))}
					</div>
					<Typography component="span" className={styles.coach}>
						{!!speakers.length &&
							speakers
								.map((s) => (isInPath ? s.name : s.speaker_name))
								.join(", ")}
					</Typography>
				</div>
				{!isInPath && (
					<div className={styles.default__footer}>
						<Typography className={styles.default__lessons}>
							{`${course?.lessons?.in_progress + course?.lessons?.completed}/${
								course?.lessons?.all
							} ${t(itemsName)}`}
						</Typography>
						<div className={styles.default__progress_wrapper}>
							<div
								className={styles.default__progress}
								style={{
									width:
										Math.ceil(
											((course?.lessons?.in_progress +
												course?.lessons?.completed) *
												100) /
												course?.lessons?.all
										) + "%",
								}}
							/>
						</div>
						<button
							type="button"
							className={styles.keep_learning}
							onClick={handleClick}
							value={course?.product_id}
						>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M2.93072 17.3521C3.56355 17.7165 4.28268 17.8986 5.04015 17.8986C6.07569 17.8986 7.20711 17.563 8.3673 16.9015L14.2833 13.488C16.2968 12.3278 17.3995 10.7362 17.3995 9.01027C17.3995 7.28438 16.2873 5.69271 14.2833 4.53253L8.3673 1.11909C6.35375 -0.0411003 4.4265 -0.204102 2.93072 0.658847C1.43495 1.5218 0.610352 3.26687 0.610352 5.58724V12.4237C0.610352 14.7345 1.43495 16.4892 2.93072 17.3521Z" />
							</svg>
						</button>
					</div>
				)}

				{isInPath && (
					<div className={styles.footer__line}>
						<div>
							<ModuleIcon />
							<Typography>
								{" "}
								{formatEndings(
									course !== null ? course?.modules_count : 0,
									"module",
									i18next.language
								)}
							</Typography>
						</div>
						<div>
							<LessonIcon />
							<Typography>
								{formatEndings(
									course !== null ? course?.posts_count : 0,
									"lesson",
									i18next.language
								)}
							</Typography>
						</div>
						{!!course?.duration_days && (
							<div>
								<TimerIcon />
								<Typography>
									{formatEndings(
										course !== null ? course?.duration_days : 0,
										"day",
										i18next.language
									)}
								</Typography>
							</div>
						)}
					</div>
				)}
			</div>
		</Link>
	)
}

export function Theme({ src, nameClassName, imgClassName, closed }) {
	if (!src) {
		return (
			<div className={classNames(styles.default__hero_wrapper, nameClassName)}>
				<CardImageIcon />
			</div>
		)
	}
	return (
		<div
			className={classNames(styles.default__hero, imgClassName, {
				[styles.default__hero_closed]: closed,
			})}
		>
			<img src={src} alt="default course heero" />
			{closed && <LockIcon />}
		</div>
	)
}
