import { useField } from "formik"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./radio.module.sass"

export default function Radio({ name, className, label, value, children, valueIsArray,readOnly }) {
	const [form, , helpers] = useField(name)

	const handleChange = ({ target }) => {
		if(readOnly) return
		if(valueIsArray){
			return helpers.setValue([target.value])
		}
		helpers.setValue(target.value)
	}
	const VALUE = valueIsArray ? form.value.includes(value.toString()):form.value === value

	return (
		<RadioSelf
			name={name}
			className={className}
			label={label}
			value={value}
			onChange={handleChange}
			// eslint-disable-next-line eqeqeq
			checked={VALUE}
			readOnly={readOnly}
		>
			{children}
		</RadioSelf>
	)
}

Radio.propTypes = {
	name: PropTypes.string.isRequired,
	classNames: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string,PropTypes.bool, PropTypes.number]),
	children: PropTypes.node,
}

export function RadioSelf({
	name,
	value,
	onChange,
	checked,
	className,
	label,
	children,
	readOnly
}) {
	const htmlFor = `radio-${Math.random()}`
	return (
		<div className={classNames(styles.radio__wrapper, className)}>
			<input
				name={name}
				id={htmlFor}
				type="radio"
				value={value}
				onChange={onChange}
				checked={checked}
				readOnly={readOnly}
			/>
			<label htmlFor={htmlFor} className={styles.radio} />
			<span className={styles.radio__content}>{label}</span>
			{children}
		</div>
	)
}
