import { addMinutes, getDaysInMonth } from "date-fns"


const DAYS_IN_WEEK = 7

const EU = [6, 0, 1, 2, 3, 4, 5]

export function formatDate(date) {
	const d = new Date(date)
	let offset = d.getTimezoneOffset() * -1
	return addMinutes(d, offset)
}

export function formatDateZone(date) {
	const d = new Date(date)
	let offset = d.getTimezoneOffset()
	return addMinutes(d, offset)
}

export function getMonthData(year, month) {
	const result = []
	let day = 1

	const date = new Date(year, month, day, "00", "00", "01")
	const daysInMonth = getDaysInMonth(date)
	const monthStartsOn = EU[date.getDay()]

	for (let i = 0; i < (daysInMonth + monthStartsOn) / DAYS_IN_WEEK; i++) {
		result[i] = []

		for (let j = 0; j < DAYS_IN_WEEK; j++) {
			if (i === 0 && j < monthStartsOn) {
				result[i] = getDatesInCurrentWeek(new Date(year, month, day))
			} else if (day > daysInMonth) {
				result[i] = getDatesInCurrentWeek(new Date(year, month, daysInMonth))
			} else {
				result[i][j] = new Date(year, month, day++, "00", "00", "01")
			}
		}
	}

	return result
}

export function getWeekDays(dateProps = new Date()) {
	return Array.from(new Array(DAYS_IN_WEEK), (e, i) => {
		const date = new Date(dateProps)
		date.setDate(date.getDate() + (i - date.getDay() + 1))
		return date
	})
}

export function getDatesInCurrentWeek(dateProps = new Date(), first = false) {
	return Array.from(new Array(DAYS_IN_WEEK), (e, i) => {
		const date = new Date(dateProps)

		date.setDate(date.getDate() + (i - EU[date.getDay()]))

		return date
	})
}

export function isEqual(a, b) {
	if (!a || !b) return false

	a = new Date(a)
	b = new Date(b)

	return (
		a.getFullYear() === b.getFullYear() &&
		a.getMonth() === b.getMonth() &&
		a.getDate() === b.getDate()
	)
}
export function isWithinInterval(date, a, b) {
	if (!date || !a || !b) return false

	const DATE = new Date(date)
	const A = new Date(a)
	const B = new Date(b)

	return DATE.valueOf() <= B.valueOf() && DATE.valueOf() >= A.valueOf()
}
export function arrayInterval(date, arr) {
	if (!Array.isArray(arr) || !date) {
		return false
	}
	const object = {}
	object.isInvalid = false

	const prepareDate = `${date.getFullYear()}-${
		date.getMonth() + 1
	}-${date.getDate()}`

	arr.forEach((e) => {
		if (
			isWithinInterval(
				new Date(prepareDate),
				new Date(e.start_day),
				new Date(e.end_day)
			)
		) {
			return (object.isInvalid = true), (object.status = e.status)
		}
	})
	return object
}

export function monthList() {
	const y = new Date().getFullYear()
	return Array.from(new Array(12), (_, i) => {
		const str = `${i + 1}/1/${y}`
		return new Date(str)
	})
}

export function yearsList(date, count = 10) {
	const year = date.getFullYear()
	const currentIndex = year % count === 0 ? count - 1 : (year % count) - 1
	const result = []
	for (let i = 0; i < count; i++) {
		if (i < currentIndex) {
			result.push(year - currentIndex + i)
		}
		if (i > currentIndex) {
			result.push(year + i - currentIndex)
		}
		if (i === currentIndex) {
			result.push(year)
		}
	}
	return result
}

export function dateValidation(day, date) {
	const year = date?.getFullYear()
	const month = date?.getMonth()
	const count = getDaysInMonth(year, month + 1)
	if (day <= count) {
		return new Date(`${month + 1}/${day}/${year}`)
	}
	return new Date(`${month + 1}/${count}/${year}`)
}