import {api} from "../http";

export async function registrationRequest(data) {
    const url = '/v1/register'
    return await api.post(url, data)
}

export async function loginRequest(data) {
    const url = `/v1/login`
    return await api.post(url,data)
}
export async function logoutRequest() {
    const url = `/v1/logout`
    return await api.post(url)
}

export async function getTokensRequest() {
    const url = `/v1/profile/tokens`
    return await api.get(url)
}

export async function autologinRequest(token){
    const url = `/v1/auto-login?token=${token}`
    return await api.post(url)
}

export async function onBoardingCheckRequest({token, email, redirect}){
    const url = `/v1/on-boarding-check`
    return await api.post(url, {token, email, redirect})
} 

export async function onBoardingPasswordRequest({token, password, redirect}){
    const url = `/v1/on-boarding-password`
    return await api.post(url, {token, password, redirect})
} 
 