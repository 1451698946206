export function DepartmentIcon(props) {
	return (
		<svg
        {...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 16.75C4.59 16.75 4.25 16.41 4.25 16L4.25 9C4.25 8.59 4.59 8.25 5 8.25C5.41 8.25 5.75 8.59 5.75 9L5.75 16C5.75 16.41 5.41 16.75 5 16.75Z"
				fill="#686670"
			/>
			<path
				d="M5.25 9.25C3.04 9.25 1.25 7.46 1.25 5.25C1.25 3.04 3.04 1.25 5.25 1.25C7.46 1.25 9.25 3.04 9.25 5.25C9.25 7.46 7.46 9.25 5.25 9.25ZM5.25 2.75C3.87 2.75 2.75 3.87 2.75 5.25C2.75 6.63 3.87 7.75 5.25 7.75C6.63 7.75 7.75 6.63 7.75 5.25C7.75 3.87 6.63 2.75 5.25 2.75Z"
				fill="#686670"
			/>
			<path
				d="M5 22.75C2.93 22.75 1.25 21.07 1.25 19C1.25 16.93 2.93 15.25 5 15.25C7.07 15.25 8.75 16.93 8.75 19C8.75 21.07 7.07 22.75 5 22.75ZM5 16.75C3.76 16.75 2.75 17.76 2.75 19C2.75 20.24 3.76 21.25 5 21.25C6.24 21.25 7.25 20.24 7.25 19C7.25 17.76 6.24 16.75 5 16.75Z"
				fill="#686670"
			/>
			<path
				d="M19 22.75C16.93 22.75 15.25 21.07 15.25 19C15.25 16.93 16.93 15.25 19 15.25C21.07 15.25 22.75 16.93 22.75 19C22.75 21.07 21.07 22.75 19 22.75ZM19 16.75C17.76 16.75 16.75 17.76 16.75 19C16.75 20.24 17.76 21.25 19 21.25C20.24 21.25 21.25 20.24 21.25 19C21.25 17.76 20.24 16.75 19 16.75Z"
				fill="#686670"
			/>
			<path
				d="M18.1701 16.79C17.8601 16.79 17.5701 16.6 17.4601 16.29C16.7301 14.19 14.7501 12.78 12.5201 12.78C12.5101 12.78 12.5101 12.78 12.5001 12.78L9.07011 12.79C9.06011 12.79 9.06011 12.79 9.05011 12.79C6.86011 12.79 4.95011 11.31 4.40011 9.18999C4.30011 8.78999 4.54011 8.37999 4.94011 8.27999C5.34011 8.17999 5.75011 8.42 5.85011 8.82C6.23011 10.28 7.54011 11.3 9.05011 11.3H9.06011L12.4901 11.29C12.5001 11.29 12.5101 11.29 12.5201 11.29C15.3801 11.29 17.9301 13.1 18.8701 15.81C19.0101 16.2 18.8001 16.63 18.4101 16.76C18.3301 16.77 18.2501 16.79 18.1701 16.79Z"
				fill="#686670"
			/>
		</svg>
	)
}
