import React, { useMemo } from "react"
import styles from "./index.module.sass"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { answersSelector } from "../../../store/reducers/lesson"
import { format } from "date-fns"
import Attachments from "../../attachments/Attachments"
import AvatarView from "../../UIKit/avatarView/AvatarView"
import Status from '../../Status/Status'
import Typography from "../../UIKit/base/Typography"
import PropTypes from "prop-types"
import { AnswerType } from '../../../propTypes'

export default function Reviews() {
	const answers = useSelector(answersSelector)
	if (!answers?.length) {
		return null
	}
	return answers.map((e) => (
		<ReviewItem key={`${e.id}-${Math.random()}`} answer={e} />
	))
}

function ReviewItem({ answer }) {
	const { person, user, status } = answer
	const isUser = useMemo(
		() => Object.keys(user).some((e) => user[e]?.length),
		[user]
	)
	const isPerson = useMemo(
		() => Object.keys(person).some((e) => person[e]?.length),
		[person]
	)
	return (
		<div className={styles.card}>
			{isUser && (
				<div
					className={classNames(styles.container, {
						[styles.container__user]: isUser,
					})}
				>
				<div className={styles.status__mobile}>
						<Status className={classNames(styles.status, styles.status__mobile)}>
								{status}
							</Status>
							<Typography component="span" fontVariant="medium"
								className={styles.score}
							>{`${answer?.score}/${answer?.passing_total}`}</Typography>
							</div>

					<div className={styles.header}>
						<div className={styles.header__left}>
							<AvatarView
								name={person?.name}
								imgClassName={styles.avatar}
								textClassName={styles.avatar__text}
								src={user?.avatar?.origin}
							/>
							<Typography component="span" fontVariant="medium" className={styles.name}>{user?.name}</Typography>
							<Status className={styles.status}>
								{status}
							</Status>
							<Typography component="span" fontVariant="medium"
								className={styles.score}
							>{`${answer?.score}/${answer?.passing_total}`}</Typography>
						</div>
						<Typography component="span" fontVariant="medium" className={styles.date}>
							{format(new Date(user?.answered_at), "d MMM yyyy")}
						</Typography>
					</div>
				  <p className={styles.content}>{user?.answer}</p>
					<Attachments attachments={user.attachments} />
				</div>
			)}
			{isPerson && (
				<div
					className={classNames(styles.container)}
				>
				{!isUser && (
					<Status
						className={classNames(
							styles.status, styles.status__mobile
							)}
					>
						{status}
					</Status>
				)}
					<div className={styles.header}>
						<div className={styles.header__left}>
							<AvatarView
								imgClassName={styles.avatar}
								textClassName={styles.avatar__text}
								name={person?.name}
								src={person?.avatar?.origin}
							/>
							<Typography component="span" fontVariant="medium" className={styles.name}>{person?.name}</Typography>
							{!isUser && (
								<Status
									className={classNames(
										styles.status,
										)}
								>
									{status}
								</Status>
							)}
						</div>
						<Typography component="span" fontVariant="medium" className={styles.date}>
							{format(new Date(person?.answered_at), "d MMM yyyy")}
						</Typography>
					</div>
					<Typography component="p" fontVariant="medium" className={styles.content}>{person.answer}</Typography>
					<Attachments attachments={person.attachments} />
				</div>
			)}
		</div>
	)
}

ReviewItem.propTypes = {
	answer: PropTypes.shape(AnswerType)
}