import { useTranslation } from "react-i18next";
import { NoItemsImage } from "./NoItemsImage";
import style from "./Reviews.module.sass";

const NoItems = () => {
	const { t } = useTranslation();

	return (
		<div className={style.noItems}>
			<NoItemsImage />
			<h3>{t("no_reviews")}</h3>
			<p>{t("you_have_no_reviews")}</p>
		</div>
	);
};

export default NoItems;
