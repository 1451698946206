import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder";
import PreloaderBreadcrumbs from "../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderButton from "../PreloaderTemplates/PreloaderButton";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import styles from "./Preloaders.module.sass";

const PreloaderReviews = () => {
  return (
    <>
      <PreloaderMobileTitle />
      <PreloaderBreadcrumbs />
      <div className={styles.reviews}>
        <div className={styles.doc__header}>
          {" "}
          <div className={styles.doc__buttons}>
            <PreloaderRect width={400} height={48} />  
          </div>
           
        </div>
        <div className={styles.reviews__body}>
          <div className={styles.reviews__left}>
          
              <PreloaderFeedbackItem /> <PreloaderFeedbackItem />{" "}
              <PreloaderFeedbackItem /> <PreloaderFeedbackItem />
           
          </div>

          <div className={styles.reviews__right}>
          

            <PreloaderCard padding={24}>
              <div className={styles.reviews__ratingTitle}>
                <div className={styles.reviews__blocks}>
                  <PreloaderRect width={"100%"} height={40} />
                  <PreloaderRect width={"100%"} height={40} />
                </div>
               
              </div> 
              <PreloaderProductRating /> <PreloaderProductRating />{" "}
              <PreloaderProductRating /> <PreloaderProductRating />{" "}
              <PreloaderProductRating /> 
            </PreloaderCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreloaderReviews;

const PreloaderProductRating = () => {
  return (
    <div className={styles.reviews__rating}>
      <div className={styles.reviews__line}>
        <PreloaderCircle size={24} />
        <PreloaderRect height={24} width={"calc(100% - 96px)"} />

        <PreloaderCircle size={24} />
        <PreloaderCircle size={24} />
      </div>
    </div>
  );
};

const PreloaderFeedbackItem = () => {
  return (
    <div className={styles.reviews__item}>
      <div className={styles.feedback__left}>
        <div className={styles.feedback__avatar}>
          <PreloaderCircle size={40} />
        </div>
        <div className={styles.feedback__body}>
          <div className={styles.feedback__header}>
            <div className={styles.reviews__title}>
              <PreloaderLine width={120} />
              <PreloaderLine width={120} />
            </div>{" "}
            <div className={styles.reviews__title}>
              {" "}
              <PreloaderLine width={120} /> 
            </div>
          </div>
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={"100%"} />
          <PreloaderLine width={360} />
        </div>
      </div>
    </div>
  );
};
