import classNames from "classnames"
import { useState } from "react"
import styles from "./avatar.module.sass"
import Typography from "../../UIKit/base/Typography"

function prepareFiles(files) {
	if (files instanceof FileList && files.length) {
		const validElement = files[0].type.split("/")[0]
		if (validElement === "image") {
			return files[0]
		}
	}
	return null
}

export default function ProfileAvatar({
	avatar,
	name,
	handleAvatar,
	handleDelete,
	className,
}) {
	const [active, setActive] = useState(false)
	const [src, setSrc] = useState(avatar?.big || "")
	const arr = name ? name?.trim().split(" ") : []
	const res = arr.map((e) => e.slice(0, 1).toUpperCase()).join("")

	function handleClick(e) {
		e.preventDefault()
		handleDelete()
		setSrc("")
	}

	const handleActive = (e) => {
		e.preventDefault()
		setActive((s) => !s)
	}

	const handleDrop = (e) => {
		e.preventDefault()
		const { files } = e.dataTransfer
		handleAvatar(prepareFiles(files))
		setActive(false)
	}

	const handleChange = ({ target }) => {
		const { files } = target
		handleAvatar(prepareFiles(files))
		const img = URL.createObjectURL(prepareFiles(files))
		setSrc(img)
	}
	return (
		<Typography
			component="label"
			fontVariant="semiBold"
			className={classNames(
				styles.wrapper,
				{ [styles.active]: active },
				className
			)}
			onDragEnter={handleActive}
			onDragLeave={handleActive}
			onDragOver={(e) => e.preventDefault()}
			onDrop={handleDrop}
		>
			<AvatarRemoveIcon
				className={classNames(styles.icon, {
					[styles.icon__remove]: src,
				})}
				onClick={handleClick}
			/>
			{src ? (
				<img className={styles.avatar} src={src} alt="avatar" />
			) : res ? (
				res
			) : (
				<EmptyAvatarIcon />
			)}
			<AvatarMoveIcon className={classNames(styles.icon, styles.icon__move)} />
			<input type="file" onChange={handleChange} />
		</Typography>
	)
}

function AvatarMoveIcon(props) {
	return (
		<svg
			{...props}
			width="160"
			height="40"
			viewBox="0 0 160 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="160" height="40" fill="#5B5766" fillOpacity="0.6" />
			<path
				d="M86.9866 34.3334H73.0132C69.2799 34.3334 66.9066 32.1068 66.6932 28.3867L65.9999 17.3867C65.8932 15.7201 66.4666 14.1201 67.6132 12.9067C68.7466 11.6934 70.3466 11.0001 71.9999 11.0001C72.4266 11.0001 72.8399 10.7467 73.0399 10.3467L73.9999 8.44008C74.7866 6.88008 76.7599 5.66675 78.4799 5.66675H81.5332C83.2532 5.66675 85.2132 6.88008 85.9999 8.42675L86.9599 10.3734C87.1599 10.7467 87.5599 11.0001 87.9999 11.0001C89.6532 11.0001 91.2532 11.6934 92.3866 12.9067C93.5332 14.1334 94.1066 15.7201 93.9999 17.3867L93.3066 28.4001C93.0666 32.1734 90.7599 34.3334 86.9866 34.3334ZM78.4799 7.66675C77.4932 7.66675 76.2399 8.44008 75.7866 9.33341L74.8266 11.2534C74.2666 12.3334 73.1866 13.0001 71.9999 13.0001C70.8799 13.0001 69.8399 13.4534 69.0666 14.2667C68.3066 15.0801 67.9199 16.1467 67.9999 17.2534L68.6932 28.2668C68.8532 30.9601 70.3066 32.3334 73.0132 32.3334H86.9866C89.6799 32.3334 91.1332 30.9601 91.3066 28.2668L91.9999 17.2534C92.0666 16.1467 91.6932 15.0801 90.9332 14.2667C90.1599 13.4534 89.1199 13.0001 87.9999 13.0001C86.8132 13.0001 85.7332 12.3334 85.1732 11.2801L84.1999 9.33341C83.7599 8.45341 82.5066 7.68008 81.5199 7.68008H78.4799V7.66675Z"
				fill="#FBFAFF"
			/>
			<path
				d="M81.9999 15.6667H77.9999C77.4532 15.6667 76.9999 15.2134 76.9999 14.6667C76.9999 14.1201 77.4532 13.6667 77.9999 13.6667H81.9999C82.5466 13.6667 82.9999 14.1201 82.9999 14.6667C82.9999 15.2134 82.5466 15.6667 81.9999 15.6667Z"
				fill="#FBFAFF"
			/>
			<path
				d="M79.9999 29.0001C77.0532 29.0001 74.6666 26.6134 74.6666 23.6667C74.6666 20.7201 77.0532 18.3334 79.9999 18.3334C82.9466 18.3334 85.3332 20.7201 85.3332 23.6667C85.3332 26.6134 82.9466 29.0001 79.9999 29.0001ZM79.9999 20.3334C78.1599 20.3334 76.6666 21.8267 76.6666 23.6667C76.6666 25.5067 78.1599 27.0001 79.9999 27.0001C81.8399 27.0001 83.3332 25.5067 83.3332 23.6667C83.3332 21.8267 81.8399 20.3334 79.9999 20.3334Z"
				fill="#FBFAFF"
			/>
		</svg>
	)
}

function AvatarRemoveIcon(props) {
	return (
		<svg
			{...props}
			width="160"
			height="40"
			viewBox="0 0 160 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="160" height="40" fill="#5B5766" fillOpacity="0.6" />
			<path
				d="M71.5197 28.4792C71.7197 28.6792 71.9731 28.7726 72.2264 28.7726C72.4797 28.7726 72.7464 28.6792 72.9331 28.4792L79.9997 21.4126L87.0664 28.4792C87.2664 28.6792 87.5197 28.7726 87.7731 28.7726C88.0264 28.7726 88.2797 28.6792 88.4797 28.4792C88.8664 28.0926 88.8664 27.4526 88.4797 27.0659L81.4131 19.9992L88.4797 12.9326C88.8664 12.5459 88.8664 11.9059 88.4797 11.5192C88.0931 11.1326 87.4531 11.1326 87.0664 11.5192L79.9997 18.5859L72.9331 11.5192C72.5464 11.1326 71.9064 11.1326 71.5197 11.5192C71.1331 11.9059 71.1331 12.5459 71.5197 12.9326L78.5864 19.9992L71.5197 27.0659C71.1331 27.4526 71.1331 28.0926 71.5197 28.4792Z"
				fill="#FBFAFF"
			/>
		</svg>
	)
}

function EmptyAvatarIcon(props) {
	return (
		<svg
			{...props}
			width="44"
			height="44"
			viewBox="0 0 44 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M34.7197 43.5H9.17973C6.13973 43.5 3.35973 41.96 1.75973 39.36C0.159733 36.76 0.0197327 33.6 1.37973 30.86L4.81973 23.96C5.93973 21.72 7.73973 20.32 9.75973 20.1C11.7797 19.88 13.8397 20.88 15.3997 22.82L15.8397 23.38C16.7197 24.46 17.7397 25.04 18.7397 24.94C19.7397 24.86 20.6597 24.14 21.3397 22.92L25.1197 16.1C26.6797 13.28 28.7597 11.82 31.0197 11.92C33.2597 12.04 35.1797 13.72 36.4597 16.68L42.7197 31.3C43.8797 34 43.5997 37.08 41.9797 39.54C40.3797 42.04 37.6597 43.5 34.7197 43.5ZM10.3197 23.1C10.2397 23.1 10.1597 23.1 10.0797 23.12C9.07973 23.22 8.15973 24.02 7.49973 25.32L4.05973 32.22C3.15973 34 3.25973 36.1 4.29973 37.8C5.33973 39.5 7.17973 40.52 9.17973 40.52H34.6997C36.6597 40.52 38.3997 39.58 39.4797 37.94C40.5597 36.3 40.7397 34.34 39.9597 32.54L33.6997 17.92C32.9397 16.12 31.8797 15.02 30.8597 14.98C29.9197 14.92 28.6997 15.92 27.7397 17.62L23.9597 24.44C22.7997 26.52 20.9797 27.82 18.9997 28C17.0197 28.16 14.9997 27.2 13.4997 25.32L13.0597 24.76C12.2197 23.66 11.2597 23.1 10.3197 23.1Z"
				fill="#6B4DFF"
			/>
			<path
				d="M11.9397 15.5C7.81973 15.5 4.43973 12.14 4.43973 8C4.43973 3.86 7.79973 0.5 11.9397 0.5C16.0797 0.5 19.4397 3.86 19.4397 8C19.4397 12.14 16.0797 15.5 11.9397 15.5ZM11.9397 3.5C9.45973 3.5 7.43973 5.52 7.43973 8C7.43973 10.48 9.45973 12.5 11.9397 12.5C14.4197 12.5 16.4397 10.48 16.4397 8C16.4397 5.52 14.4197 3.5 11.9397 3.5Z"
				fill="#6B4DFF"
			/>
		</svg>
	)
}
