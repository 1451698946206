import React, { useState, useEffect } from "react"
import ClassNames from "classnames"
import styles from "./Alert.module.sass"
import { useSelector, useDispatch } from "react-redux"
import {
	alertSelector,
	dangerMessage,
	infoMessage,
	successMessage,
	resetItem,
} from "./alertReducer"
import CancelButton from "../buttons/CancelButton/CancelButton"
import Typography from "../base/Typography"

export const useMessage = () => {
	const dispatch = useDispatch()
	return {
		danger: (text) => dispatch(dangerMessage(text)),
		info: (text) => dispatch(infoMessage(text)),
		success: (text) => dispatch(successMessage(text)),
	}
}

const Alert = () => {
	const alerts = useSelector(alertSelector)
	const dispatch = useDispatch()

	const deleteItemHandler = (id) => {
		dispatch(resetItem(id))
	}

	if (!alerts.length) {
		return null
	}

	return (
		<div className={styles.wrapper}>
			{alerts.map((item) => (
				<AlertItem key={item.id} item={item} deleteItem={deleteItemHandler} />
			))}
		</div>
	)
}

export default Alert

const AlertItem = ({ item, deleteItem }) => {
	const [timer, setTimer] = useState(null)
	const messageStyles = ClassNames(styles.message, {
		[styles.info]: item.type === "info",
		[styles.success]: item.type === "success",
		[styles.danger]: item.type === "danger" || item.type === "errorNetwork",
	})
	useEffect(() => {
		let timerStart = setTimeout(() => {
			deleteItem(item.id)
		}, 5000)
		setTimer(timerStart)
	}, [deleteItem, item.id])

	const deletedItem = () => {
		clearTimeout(timer)
		deleteItem(item.id)
		setTimer(null)
	}

	return (
		<div className={messageStyles}>
			<CancelButton onClick={deletedItem} className={styles.remove} />
			<Typography className={styles.message__title}>{item.message}</Typography>
			{Array.isArray(item?.validationError) && (
				<ol start="1" className={styles.list}>
					{item?.validationError?.map((m, i) => (
						<Typography key={i} component="li" className={styles.message__info}>
							{m}
						</Typography>
					))}
				</ol>
			)}
		</div>
	)
}
