import { Field, Formik, Form } from "formik"
import * as Yup from "yup"
import styles from "./Onboarding.module.sass"
import Button from "../forms/button/Button"
import ProfileAvatar from "../Profile/profile-avatar/ProfileAvatar"
import classNames from "classnames"
import InputControl from "../forms/input-control"
import { useTranslation } from "react-i18next"
import {
	deletedAvatar,
	getUser,
	personSelector,
	sendAvatar,
	sendUser,
} from "../../store/reducers/user"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

export default function PersonalInformationForm({ setStep }) {
	const { t } = useTranslation()
	const user = useSelector(personSelector)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getUser())
	}, [dispatch])
	function handleSubmit(values, { resetForm }) {
		dispatch(
			sendUser({
				values,
				cb: () => {
					resetForm()
					setStep((s) => s + 1)
				},
			})
		)
	}

	function handleAvatar(file) {
		if (file) {
			dispatch(sendAvatar(file))
		}
	}

	function handleDeleteAvatar() {
		dispatch(deletedAvatar())
	}
	function valid(isValid, values) {
		return !(isValid && Object.keys(values).every((v) => values[v]?.length))
	}
	if (!user) {
		return null
	}
	return (
		<Formik
			initialValues={{ name: user?.name || "", phone: user?.phone || "" }}
			validationSchema={Yup.object().shape({
				name: Yup.string()
					.matches(
						/^[a-zA-Zа-яА-Яа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'-\s]+$/,
						t("field_only_letters")
					)
					.required(t("name_required"))
					.max(255),
				phone: Yup.string()
					.matches(/^([0-9\s\-\+\(\)]*)$/, t("phone_invalid"))
					.min(10)
					.max(255)
					.required(t("phone_required")),
			})}
			onSubmit={handleSubmit}
		>
			{({ isValid, values }) => (
				<Form>
					<div>
						<ProfileAvatar
							className={styles.avatar}
							avatar={user?.avatar}
							name={user?.name}
							handleAvatar={handleAvatar}
							handleDelete={handleDeleteAvatar}
						/>

						<Field
							component={InputControl}
							name="name"
							className={classNames(styles.input)}
							label={t("name")}
							placeholder={t("enter_name")}
							message
						/>
						<Field
							component={InputControl}
							name="phone"
							className={classNames(styles.input)}
							label={t("phone")}
							placeholder="+380 099 999 99 99"
							message
						/>
					</div>
					<div className={styles.form__button}>
						<Button type="submit" disabled={valid(isValid, values)}>
							next
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}
