import PreloaderCircle from "../../../Preloader/PreloaderTemplates/PreloaderCircle"
import PreloaderLine from "../../../Preloader/PreloaderTemplates/PreloaderLine"
import PreloaderRect from "../../../Preloader/PreloaderTemplates/PreloaderRect"
import styles from "./index.module.sass"

const Preloader = () => {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.header__wrapper}>
					<header className={styles.header}>
						<div className={styles.header__top}>
							<PreloaderCircle size={24} />
							<PreloaderLine className={styles.line_160} />
							<PreloaderCircle size={40} />
						</div>
					</header>
					<div className={styles.breadcrumbs}>
						<div className={styles.breadcrumbs__wrapper}>
							<PreloaderLine className={styles.breadcrumbs__line} />
						</div>
					</div>
				</div>

				<aside className={styles.aside}>
					<div className={styles.aside__item}>
						<PreloaderLine className={styles.line_160} />
						<PreloaderCircle size={24} />
					</div>
					<div className={styles.aside__item}>
						<PreloaderLine className={styles.line_160} />
						<PreloaderCircle size={24} />
					</div>
				</aside>
				<main className={styles.main}>
					<div className={styles.main__header}>
						<div className={styles.main__header_list}>
							<PreloaderLine className={styles.line_120} />
							<PreloaderLine className={styles.line_120} />
						</div>
						<PreloaderRect className={styles.rect_600} />
					</div>
					<div className={styles.content}>
						<PreloaderRect className={styles.rect_331} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_331} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_331} />
						<PreloaderRect width={331} height={32} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_744} />
						<PreloaderLine className={styles.line_331} />
					</div>
				</main>
			</div>
		</div>
	)
}
export default Preloader
