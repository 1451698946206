import React from "react";
import { useTranslation } from "react-i18next";
import { LogoGrey } from "../LogoGrey";
import styles from "./footer.module.sass";

const SredaFooter = ({ email, phone }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.sredaFooter}>
			<p>{t("powered_by")}</p>
			<LogoGrey />
		</div>
	);
};

export default SredaFooter;
