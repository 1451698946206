import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import { setColorPalette } from "../utils";

const initialColors = {
	primary_color: '#6B4DFF',
	bg_color: '#FBFAFF',
	secondaryBackground: '#F6F5FF',
	secondaryLightPurple: '#E2E0FF',
	secondaryGray: '#A4A1B2',
};

export function NoItemsImage(props) {
	const [colors, setColors] = useState(initialColors);

	const theme = useSelector(themeSelector);

	useEffect(() => {
		if (theme.title) {
			const newColors = setColorPalette(theme);
			setColors(newColors);
		}
	}, [theme]);

	return (
		 

		<svg
	width="331"
	height="268"
	viewBox="0 0 331 268"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M147.262 194.85C153.125 191 160.825 195.2 160.825 202.2V213.487C160.825 224.6 152.162 236.5 141.75 240L113.837 249.275C108.937 250.937 100.975 250.937 96.1624 249.275L68.2499 240C57.7499 236.5 49.1749 224.6 49.1749 213.487V202.112C49.1749 195.2 56.8749 191 62.6499 194.762L80.6749 206.487C87.5874 211.125 96.3374 213.4 105.087 213.4C113.837 213.4 122.587 211.125 129.5 206.487L147.262 194.85Z"
		fill={colors?.secondaryLightPurple}
	/>
	<path
		d="M174.825 114.521L122.413 80.1337C112.963 73.9212 97.3876 73.9212 87.9376 80.1337L35.2627 114.521C18.3752 125.459 18.3752 150.221 35.2627 161.246L49.2627 170.346L87.9376 195.546C97.3876 201.759 112.963 201.759 122.413 195.546L160.825 170.346L172.813 162.471V189.246C172.813 192.834 175.788 195.809 179.375 195.809C182.963 195.809 185.938 192.834 185.938 189.246V146.196C189.438 134.909 185.85 121.784 174.825 114.521Z"
		fill={colors?.secondaryLightPurple}
	/>
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M231.957 134.941C207.131 166.98 161.033 172.828 128.994 148.002C96.9553 123.177 91.1077 77.0785 115.933 45.0394C140.759 13.0003 186.857 7.15265 218.896 31.9784C250.935 56.8041 256.783 102.902 231.957 134.941Z"
		fill={colors?.secondaryBackground}
	/>
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M244.82 163.807C239.036 159.325 230.928 159.801 224.902 163.953C193.533 185.57 150.56 185.538 118.838 160.958C79.5935 130.549 72.4308 74.0832 102.84 34.8384C133.249 -4.40648 189.715 -11.5692 228.959 18.8399C260.681 43.4198 271.442 85.024 258.342 120.797C255.826 127.669 257.389 135.638 263.174 140.121L325.194 188.177C331.734 193.245 332.928 202.656 327.86 209.197C322.792 215.738 313.381 216.932 306.84 211.863L244.82 163.807ZM231.93 134.865C207.096 166.915 160.983 172.765 128.933 147.931C96.8828 123.096 91.0333 76.9828 115.867 44.9328C140.701 12.8829 186.815 7.03328 218.865 31.8674C250.915 56.7015 256.764 102.815 231.93 134.865Z"
		fill={colors?.bg_color}
	/>
	<mask
		id="mask0_399_115"
		style={{maskType: "alpha"}}
		maskUnits="userSpaceOnUse"
		x="101"
		y="17"
		width="148"
		height="148"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M233.126 135.604C208.263 167.692 162.095 173.548 130.008 148.685C97.9206 123.822 92.0642 77.6542 116.927 45.5667C141.791 13.4792 187.958 7.62277 220.046 32.486C252.133 57.3492 257.989 103.517 233.126 135.604Z"
			fill={colors?.bg_color}
		/>
	</mask>
	<g mask="url(#mask0_399_115)">
		<path
			d="M184.8 107.596L124.9 68.2958C114.1 61.1958 96.3001 61.1958 85.5001 68.2958L25.3002 107.596C6.0002 120.096 6.0002 148.396 25.3002 160.996L41.3002 171.396L85.5001 200.196C96.3001 207.296 114.1 207.296 124.9 200.196L168.8 171.396L182.5 162.396V192.996C182.5 197.096 185.9 200.496 190 200.496C194.1 200.496 197.5 197.096 197.5 192.996V143.796C201.5 130.896 197.4 115.896 184.8 107.596Z"
			stroke={colors?.secondaryLightPurple}
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="bevel"
			strokeDasharray="12 12"
		/>
	</g>
</svg>



	);
}
