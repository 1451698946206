import { Field, Formik, Form } from "formik"
import * as Yup from "yup"
import styles from "./Onboarding.module.sass"
import { useTranslation } from "react-i18next"
import { SelectControl } from "../forms/select"
import { useDispatch, useSelector } from "react-redux"
import { countiresSelector, timezonesSelector } from "../../store/reducers/domainTheme"
import InputControl from "../forms/input-control"
import classNames from "classnames"
import Button from "../forms/button/Button"
import { localesSelector, personSelector, sendUser } from "../../store/reducers/user"
import { useNavigate } from "react-router-dom"
import { authSelector } from "../../store/reducers/auth"

export default function AdditionalInformationForm({ setStep, redirectUrl }) {
	const { t } = useTranslation()
	const timezones = useSelector(timezonesSelector)
	const user = useSelector(personSelector)
	const auth = useSelector(authSelector)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const countries = useSelector(countiresSelector);

	const locales = useSelector(localesSelector)

	function handleBack() {
		setStep((s) => s - 1)
	}
	function handleSubmit(values, { resetForm }) {
		dispatch(
			sendUser({
				values,
				cb: () => {
					resetForm()
					navigate(redirectUrl ? redirectUrl : '/')
				},
			})
		)
	}
	function valid(isValid, values) {
		const { location, ...other } = values
		return !(isValid && Object.keys(other).every((v) => other[v]?.length))
	}
	if (!user || !auth) {
		return navigate("/", { replace: true })
	}
	return (
		<Formik
			initialValues={{
				name: user?.name,
				location: user?.location || "",
				timezone: user?.timezone || "",
				language: user?.language || "en",
			}}
			validationSchema={Yup.object().shape({
				location: Yup.string(),
				timezone: Yup.string().required(t("timezone_required")),
				language: Yup.string().required(t("language_required")),
			})}
			onSubmit={handleSubmit}
		>
			{({ isValid, values }) => (
				<Form>
					<div> 
						{countries && (
							<Field
								component={SelectControl}
								placeholder="location"
								labelClassName={classNames(styles.label, styles.select)}
								label={t("country")}
								name="location"
								options={countries}
								className={styles.input}
								search
							/>
						)}
						{timezones && (
							<Field
								component={SelectControl}
								placeholder="ngname_example@gmail.com"
								labelClassName={classNames(styles.label, styles.select)}
								label={t("time_zone")}
								name="timezone"
								options={timezones}
								className={styles.input}
								search
							/>
						)}
						<Field
							component={SelectControl}
							placeholder="en"
							labelClassName={styles.label}
							label={t("interface_language")}
							options={locales}
							name="language"
							className={classNames(styles.input)}
							wrapperClassName={styles.select}
						/>
					</div>
					<div className={styles.form__button}>
						<Button
							className={styles.back}
							type="buton"
							onClick={handleBack}
							variant="tetriary"
						>
							back
						</Button>
						<Button type="submit" disabled={valid(isValid, values)}>
							finish
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}
