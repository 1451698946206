import React, { useMemo } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { postSelector, scoreSelector, tryAgainQuiz } from "../../../store/reducers/lesson"
import ScoreProgress from "../../ScoreProgress"
import Typography from "../../UIKit/base/Typography"
import styles from "./QuizDone.module.sass" 

export default function QuizDone({ questions }) {
	const post = useSelector(postSelector)
	const score = useSelector(scoreSelector)
	const { t } = useTranslation()   

	const dispatch = useDispatch() 

	const isCompleted = useMemo(() => {
		return (score?.person_score || post?.score) >= post?.passing_score
	}, [post?.passing_score, post?.score, score?.person_score])

	const isMaxTries = useMemo(() => {
		return (post?.try) >= post?.max_tries
	}, [post?.try, post?.max_tries])

	if (!questions?.length) {
		return null
	}

	const handleTryAgain = () => {  
		dispatch(tryAgainQuiz({lessonId:  post?.id}))
	}
 
	return (
		<div className={styles.container}> 
			 
			{ post?.is_pass && 
				<div className={styles.wrapper}>
					<Typography component="p" fontVariant="medium" className={styles.text__title}>{t("quiz_completed")}</Typography>
					<Typography component="p" fontVariant="medium" className={styles.text__desktop}>{isCompleted ? t("quiz_completed_desc"): t("quiz_failed_end")}</Typography>
				</div>
			}

			{ !post?.is_pass && !!isMaxTries &&
				<div className={styles.wrapper}>
					<Typography component="p" fontVariant="medium" className={styles.text__title}>{t("quiz_completed")}</Typography>
					<Typography component="p" fontVariant="medium" className={styles.text__desktop}>{t("quiz_failed_end")}</Typography>
				</div>
			}
 
			{ !isMaxTries && !post?.is_pass &&
				<div className={styles.wrapper}>
					<Typography component="p" fontVariant="medium" className={styles.text__title}>{t("complete_quiz")}</Typography>
					<Typography component="p" fontVariant="medium" className={styles.text__desktop}>{t("quiz_failed")}</Typography>
					<div className={styles.quiz__failed} >
						<Typography className={styles.button} onClick={handleTryAgain}>{t("try_again")}</Typography>
					<Typography component="span" fontVariant="medium" className={styles.text}>{post?.try} / {post?.max_tries} {t("attempts")}</Typography>
					</div>
				</div> 
			}

			<ScoreProgress
				score={score?.person_score || post?.score}
				total={score?.total_score || post?.passing_total}
				passing={post?.passing_score}
			/>
		</div>
	)
}

