import { useState, useEffect, useMemo } from "react"
import classNames from "classnames"
import Typography from "../../UIKit/base/Typography"
import styles from "./select.module.sass"
import { useRef } from "react"
import useClickOutside from "../../hooks/useClickOutside"
import { useTranslation } from "react-i18next"
import { ArrowIcon } from "../../icons"

export default function Select({
	label,
	search,
	options,
	value: VALUE,
	onChange,
	placeholder,
	children,
	name,
	className,
	wrapperClassName,
	readOnly,
	create,
	transOption
}) {
	const [toggle, setToggle] = useState(false)
	const [value, setValue] = useState({ label: "", value: "" })
	const [searchValue, setSearchValue] = useState(undefined)
	const [searchOptions, setSearchOptions] = useState(null)
	const renderOptions = useMemo(() => {
		return searchOptions ?? options
	}, [options, searchOptions])
	const { t } = useTranslation()
	const htmlFor = `${Math.random()}-select`
	const ref = useRef(null)

	useClickOutside(ref, handleClose)

	function handleClick() {
		if (!readOnly) {
			setToggle(true)
		}
	}
	function handleClose() {
		if (toggle) {
			setToggle(false)
		}
	}
	function handleOptionClick(e) {
		e.stopPropagation()
		const { value } = e.target.dataset
		onChange(name, value)
		setValue(() => {
			return options.find((e) => e.value === value)
		})
		setSearchOptions(null)
		setSearchValue(undefined)
		handleClose()
	}
	useEffect(() => {
		if (VALUE) {
			setValue(() => {
				return options.find((e) => e.value === VALUE)
			})
		} else {
			setValue("")
			setSearchValue(undefined)
			setSearchOptions(options)
		}
	}, [VALUE, options])

	function handleSearch({ target: { value } }) {
		const res = options.filter((e) => {
			if (typeof e.label === "string") {
				const label = e.label.toLowerCase()
				return label.includes(value.toLowerCase())
			}
			return e.label.includes(value)
		})
		setSearchValue(value)
		setSearchOptions(res)
		if (create) {
			onChange(name, value)
		}
	}
	return (
		<div className={classNames(styles.wrapper, wrapperClassName)} ref={ref}>
			{label && (
				<Typography
					className={styles.label}
					fontVariant="medium"
					component="label"
					htmlFor={htmlFor}
				>
					{t(label)}
				</Typography>
			)}
			<div className={styles.position}>
				<div
					className={classNames(
						styles.container,
						{ [styles.container__open]: toggle },
						className
					)}
					onClick={handleClick}
				>
					<Typography
						component="input"
						fontVariant="medium"
						className={classNames(styles.input, {
							[styles.input_open]: toggle,
						})}
						id={htmlFor}
						type="text"
						readOnly={!search}
						value={searchValue ?? value?.label}
						placeholder={t(placeholder)}
						onChange={handleSearch}
					/>
					<ArrowIcon
						className={classNames(styles.arrow, {
							[styles.arrow_active]: toggle,
						})}
					/>

					{toggle && !!renderOptions && (
						<ul className={styles.popower}>
							{renderOptions.map((e, i) => (
								<Typography
									key={e.value + "option" + i}
									component="li"
									fontVariant="medium"
									className={classNames(
										styles.option,
										`notranslate`,
										styles.notranslate,
										{
											[styles.option_empty]: !e?.value,
										}
									)}
									data-value={e.value}
									value={e?.value}
									onClick={handleOptionClick}
								>
									{transOption ? t(e.label) : e.label}
								</Typography>
							))}
						</ul>
					)}
				</div>
			</div>
			{children}
		</div>
	)
}
