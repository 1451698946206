export default function CancelIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.63938 18.3594C5.78937 18.5094 5.97938 18.5794 6.16937 18.5794C6.35937 18.5794 6.55937 18.5094 6.69937 18.3594L11.9994 13.0594L17.2994 18.3594C17.4494 18.5094 17.6394 18.5794 17.8294 18.5794C18.0194 18.5794 18.2094 18.5094 18.3594 18.3594C18.6494 18.0694 18.6494 17.5894 18.3594 17.2994L13.0594 11.9994L18.3594 6.69937C18.6494 6.40937 18.6494 5.92938 18.3594 5.63938C18.0694 5.34937 17.5894 5.34937 17.2994 5.63938L11.9994 10.9394L6.69937 5.63938C6.40937 5.34937 5.92938 5.34937 5.63938 5.63938C5.34937 5.92938 5.34937 6.40937 5.63938 6.69937L10.9394 11.9994L5.63938 17.2994C5.34937 17.5894 5.34937 18.0694 5.63938 18.3594Z"
				fill="#FBFAFF"
			/>
		</svg>
	)
}
