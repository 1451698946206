import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  certificatesSelector,
  downloadCertificate,
  generateCertificate,
} from "../../store/reducers/certificates";
import { productSelector } from "../../store/reducers/products";
import Typography from "../UIKit/base/Typography";
import Modal from "../UIKit/modal";
import Button from "../forms/button/Button";
import { CloseIcon, TeacherIcon } from "../icons";

import styles from "./certificates.module.sass";

const CertificateModalButton = ({ isMobile }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { productId } = useParams();

  const [showLightbox, setShowLightbox] = useState(false);

  const product = useSelector(productSelector)?.product;
  const certificates = useSelector(certificatesSelector);
 

  const certificate = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return certificates?.find((item) => item?.product_id === +productId) || null;
  }, [certificates, productId]);

  function handleShowLighbox() {
    setShowLightbox(true);
  }

  function handleCloseLighbox() {
    setShowLightbox(false);
  }

  const handleLoadCertificate = (e, { close }) => {
    dispatch(
      downloadCertificate({
        certificateId: certificate?.id,
        cb: close,
      })
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    const cb = () => setShowModal(true);
    if (!certificate) {
      return dispatch(generateCertificate({ productId: productId, cb }));
    }
    cb();
  };
  if (!certificates || !certificate) {
    return null;
  }




  return (
    <>
  
      {!isMobile && (
        <Button
          className={styles.certificateModal__openButton}
          onClick={handleOpenModal}
         
        >
          view_certificate
        </Button>
      )}

      {isMobile && (
        <div
          onClick={handleOpenModal}
          className={styles.certificateModal__openButton_mobile}
        >
          <TeacherIcon />
        </div>
      )}

      <Modal
        isOpen={showModal}
        onClose={handleCloseModal}
        className={styles.certificateModal}
        submit={handleLoadCertificate}
      >
        {(close, submit) => (
          <>
            <div className={styles.certificateModal__form}>
              <div className={styles.certificateModal__header}>
                <Typography
                  component="p"
                  className={styles.certificateModal__title}
                >
                  {t("congratulations")}
                </Typography>

                <div
                  className={styles.certificateModal__close}
                  onClick={handleCloseModal}
                >
                  <CloseIcon />
                </div>
              </div>

              <div className={styles.certificateModal__text}>
                <p>
                  {t("completed_course_text", { course_name: product?.name })}
                </p>
              </div>
              <div
                className={styles.certificateModal__image}
                onClick={handleShowLighbox}
              >
                {certificate?.image && <img src={certificate?.image?.origin} alt="" />}

              </div>

              <div className={styles.certificateModal__buttons}>
                <Button
                  className={styles.certificateModal__cancelButton}
                  onClick={close}
                  variant="tetriary"
                >
                  back_to_course
                </Button>
                {certificate?.id && (
                  <Typography onClick={submit} component={Button}>
                    download
                  </Typography>
                )}
              </div>
            </div>
            {showLightbox && (
              <div
                className={styles.certificateItem__lightbox}
                onClick={handleCloseLighbox}
              >
                <div className={styles.certificateItem__lightbox_inner}>
                  <div
                    className={styles.certificateItem__lightbox_close}
                    onClick={handleCloseLighbox}
                  >
                    <CloseIcon />
                  </div>
                  <img src={certificate.image?.origin} alt="certificate" />
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default CertificateModalButton;
