import {api} from "../http";

export async function getCourseRequest(id) {
    const url = `/v1/course/${id}`
    return await api.get(url)
}

export async function sendCourseAccessRequest({type, id}) {
    const url = `/v1/${type}/${id}/request`
    return await api.post(url)
}