import JustifyContainer from "../../layouts/JustifyContainer";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import styles from "./Preloaders.module.sass";

const PreloaderHeader = () => {
  return (
    <div className={styles.header__wrapper}>
      <JustifyContainer>
        <div className={styles.header}>
          <PreloaderRect width={150} height={32} />
          <div className={styles.header__lines}>
            <PreloaderLine width={120} />
            <PreloaderLine width={120} />
            <PreloaderLine width={120} />
          </div>
          <div className={styles.header__right}> 
            <PreloaderCircle size={24} style={{ marginRight: "32px" }} />
            <PreloaderCircle size={48} />
          </div>
        </div>
      </JustifyContainer>
    </div>
  );
};

export default PreloaderHeader;
