import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { forgotPassword } from "../../store/reducers/user";
import Typography from "../UIKit/base/Typography";
import Modal from "../UIKit/modal";
import Button from "../forms/button/Button";
import InputControl from "../forms/input-control";
import { CloseIconLarge, PasswordRecoveryIcon } from "../icons";
import HeaderAuth from "../layouts/Header/HeaderAuth";
import { PasswordRecoveryImage } from "./Images/PasswordRecoveryImage";
import styles from "./Password.module.sass";

const PasswordRecovery = () => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(t("email_required")),
  });

  const handleSubmit = (values, { resetForm }) => {
    dispatch(forgotPassword({data: values, setShowModal}));
    //setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Typography className={styles.login}>
        <div className={styles.main}>
          <div className={styles.left_block}>
            <div className={styles.left_block_wrap}>
              <div className={styles.left_block_wrap_line}>
                <PasswordRecoveryImage />
              </div>
            </div>
          </div>
          <div className={styles.right_block}>
            <div className={styles.loginForm}>
              <div className={styles.loginForm_header}>
                <PasswordRecoveryIcon />
                  <Typography component="p" fontVariant="medium">{t("password_recovery")}</Typography>
              </div>
              <div className={styles.popUpText}>
                <Typography component="p" fontVariant="medium">
                  {t("enter_the_email")}
                </Typography>
              </div>
              <div className={styles.popUpText}>
                <Typography component="p" fontVariant="medium">
                  {t("password_via_email")}
                </Typography>
              </div>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                innerRef={ref}
              >
                {(props) => {
                  return (
                    <Form className={styles.form}>
                      <Field
                        component={InputControl}
                        name="email"
                        label={t("email")}
                        placeholder={t("enter_email")}
                        message
  
                      />

                      <div className={styles.buttons}>
                        <Button className={styles.submit} type="submit">
                          {t("send")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <Modal
                isOpen={showModal}
                className={styles.modal}
                onClose={handleCloseModal}
              >
                {(close) => (
                  <>
                    <div
                      className={styles.popUpWrap}
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      <div className={styles.loginForm}>
                        <div className={styles.loginForm_header_popup}>
                          <div><Typography component="p" fontVariant="medium">{t("password_recovery")}</Typography></div>
                          <div
                            className={styles.closeIcon}
                            onClick={() => {
                              setShowModal(false);
                            }}
                          >
                            <CloseIconLarge />
                          </div>
                        </div>
                        <div className={styles.popUpText}>
                           <Typography component="p" fontVariant="medium">{t("recover_password_send")}: {ref.current.values.email}
                           </Typography>
                        </div>
                        <div className={`${styles.buttons} ${styles.right}`}>
                          <Button className={styles.submit} variant="tetriaty">
                            {t("got_it")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </Typography>
    </>
  );
};

export default PasswordRecovery;
