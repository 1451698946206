import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import Typography from "../UIKit/base/Typography"
import JustifyContainer from "../layouts/JustifyContainer"
import styles from "./Onboarding.module.sass"
import classNames from "classnames"
import CircleProgress from "../UIKit/circle-progress"
import { tabs } from "./utils"
import { Children, useState, useEffect } from "react"
import PasswordForm from "./PasswordForm"
import PersonalInformationForm from "./PersonalInformationForm"
import { useSearchParams, useNavigate} from "react-router-dom"
import AdditionalInformationForm from "./AdditionalInformationForm"
import { onBoardingCheck, onBoardingPassword } from "../../store/reducers/auth"
import { useDispatch } from "react-redux"

export default function Onboarding({invite}) {
	const { t } = useTranslation()
	const projectName = window.location?.hostname?.split(".")[0]
	const [query] = useSearchParams()
	const token = query.get("token")
	const email = query.get("email")
	const redirect = query.get("redirect")
	const [step, setStep] = useState(0)
	const Component = tabs[step].icon

	const [redirectUrl, setRedirectUrl] = useState(null)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if(token && email) {
			dispatch(onBoardingCheck({token, email, redirect, setStep: setStep, navigate: navigate}))
		}
	}, [token, email, dispatch, navigate, redirect])

	return (
		<JustifyContainer>
			<div className={styles.container}>
				<div className={styles.container__header}>
					<Typography
						fontVariant={`light`}
						className={styles.container__title}
						component="h1"
					>
						{t("welcome")}
					</Typography>
					<Typography className={styles.container__subtext}>
						{t("onboarding_sub_text", { projectName })}
					</Typography>
				</div>
				<div className={styles.wrapper}>
					<div className={styles.tabs}>
						<ul>
							{tabs.map((e, i) => (
								<ProjectCreateTab
									key={e.name}
									active={i === step}
									order={e.order}
									tabCount={tabs.length}
									iconComponent={e.icon}
								>
									{e.name}
								</ProjectCreateTab>
							))}
						</ul>
					</div>
					<div className={styles.form}>
						<div className={styles.form__title_wrapper}>
							<Component className={styles.form__title_icon} />
							<Typography fontVariant="medium" className={styles.form__title}>
								{t(tabs[step].name)}
							</Typography>
						</div>
						<Stepper step={step}>
							<PasswordForm setStep={setStep} token={token} email={email} invite={invite} redirect={redirect} setRedirectUrl={setRedirectUrl}/>
							<PersonalInformationForm setStep={setStep} />
							<AdditionalInformationForm setStep={setStep} redirectUrl={redirectUrl}/>
						</Stepper>
					</div>
				</div>
			</div>
		</JustifyContainer>
	)
}

function ProjectCreateTab({
	children,
	active,
	iconComponent: Component,
	tabCount,
	order,
}) {
	const { t } = useTranslation()
	return (
		<Typography
			fontVariant="medium"
			className={classNames(styles.tab, { [styles.tab_active]: active })}
		>
			<div className={styles.tab__info}>
				{Component && (
					<div className={styles.icon__wrapper}>
						<Component className={styles.icon} />
					</div>
				)}
				<span className={styles.tab__text}>{t(children)}</span>
			</div>
			<CircleProgress
				className={styles.progress}
				value={order}
				count={tabCount}
			/>
		</Typography>
	)
}

ProjectCreateTab.propTypes = {
	children: PropTypes.string.isRequired,
}

function Stepper({ children, step }) {
	const childrenToArray = Children.toArray(children)
	const child = childrenToArray[step]
	return <div className={styles.stepper}>{child}</div>
}
