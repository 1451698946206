import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../../store/reducers/domainTheme";
import { setColorPalette } from "../../utils";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#E2E0FF",
  secondaryGray: "#A4A1B2",
};

export function PasswordRecoveryImage(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme.title) {
      const newColors = setColorPalette(theme);
      setColors(newColors);
    }
  }, [theme]);

  return (
    <svg
      width="256"
      height="306"
      viewBox="0 0 256 306"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M227.201 33.7506L143.838 2.39716C135.199 -0.799055 121.103 -0.799055 112.464 2.39716L29.1011 33.7506C13.0349 39.8386 0 58.7115 0 75.9102V199.193C0 211.521 8.03312 227.807 17.885 235.112L101.248 297.667C115.95 308.778 140.049 308.778 154.751 297.667L238.114 235.112C247.966 227.655 255.999 211.521 255.999 199.193V75.9102C256.15 58.7115 243.115 39.8386 227.201 33.7506ZM139.443 166.165V206.194C139.443 212.434 134.289 217.609 128.075 217.609C121.861 217.609 116.708 212.434 116.708 206.194V166.165C101.399 161.295 90.1831 146.988 90.1831 130.094C90.1831 109.09 107.159 92.0435 128.075 92.0435C148.992 92.0435 165.967 109.09 165.967 130.094C165.967 147.14 154.751 161.295 139.443 166.165Z"
        fill={colors?.secondaryLightPurple}
      />
    </svg>
  );
}
