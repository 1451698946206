import { useSelector } from "react-redux"
import Footer from "../../layouts/Footer/Footer"
import HeaderAuth from "../../layouts/Header/HeaderAuth"
import styles from "./index.module.sass"
import { themeSelector } from "../../../store/reducers/domainTheme"
import Logo from "../../Logo/Logo"
import i18next from "i18next"

import { Outlet } from "react-router-dom"
import JustifyContainer from "../../layouts/JustifyContainer"
import { useState } from "react"
import { AuthLayoutctx } from "../../layouts/AuthLayout"

export default function AnonymousLayout() {
	const theme = useSelector(themeSelector)
	const [currentLang, setCurrentLang] = useState(
		localStorage.getItem("i18nextLng") || i18next.resolvedLanguage
	)

	return (
		<AuthLayoutctx.Provider value={{ currentLang, setCurrentLang }}>
			<div className={styles.wrapper}>
				<HeaderAuth logo={theme?.logo?.small} noLng />
				<JustifyContainer className={styles.app}>
					<Outlet />
				</JustifyContainer>

				<Footer
					logo={Logo}
					email={theme?.support_email}
					phone={theme?.support_phone}
					showLogo={theme?.show_logo}
					className={styles.footer}
				/>
			</div>
		</AuthLayoutctx.Provider>
	)
}
