import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import style from "./VideoPlayer.module.sass"
import {
	ArrowLeftIcon,
	MaximizeIcon,
	MinimizeIcon,
	PauseIcon,
	PictureInPictureIcon,
	PlayIcon,
	PlaybackIcon,
	SettingsIcon,
	TickIcon,
	VolumeHightIcon,
	VolumeLowIcon,
	VolumeOffIcon,
} from "./VideoPlayerIcons"

const initalState = {
	isStopped: true,
	progress: 0,
	speed: 1,
	volume: 50,
	currentTime: formatTime(0),
	playbackRate: 1,
	duration: 0,
}

const speedList = [
	{ label: "0.25x", value: 0.25 },
	{ label: "0.5x", value: 0.5 },
	{ label: "0.75x", value: 0.75 },
	{ label: "Normal", value: 1 },
	{ label: "1.25x", value: 1.25 },
	{ label: "1.5x", value: 1.5 },
	{ label: "1.75x", value: 1.75 },
	{ label: "2x", value: 2 },
]

var timeout = null

const VideoPlayer = ({ handleProgress, url }) => {
	const [videoState, setVideoState] = useState(initalState)

	const [showControls, setShowControls] = useState(true)

	const [showVolumeBar, setShowVolumeBar] = useState(false)
	const [showSettings, setShowSettings] = useState(false)
	const [showPlaybackPanel, setShowPlaybackPanel] = useState(false)

	const [isFullscreen, setIsFullscreen] = useState(false)
	const [isInPicture, setIsInPicture] = useState(false)

	const [pressed, setPressed] = useState(false)
	const [position, setPosition] = useState({ x: 0, y: 0 })

	const { t } = useTranslation()

	const videoRef = useRef(null)
	const containerRef = useRef(null)

	const handleClickVideo = () => {
		closePanels()
		!isInPicture && handlePlayVideo()
	}

	const handlePlayVideo = () => {
		videoState.isStopped ? videoRef.current.play() : videoRef.current.pause()
		setVideoState({ ...videoState, isStopped: !videoState.isStopped })
	}

	const handleVideoProgress = (event) => {
		const manualChange = Number(event.target.value)
		videoRef.current.currentTime =
			(videoRef.current.duration / 100) * manualChange

		setVideoState({
			...videoState,
			progress: manualChange,
		})
	}

	const handleVolume = (event) => {
		const manualChange = Number(event.target.value)
		videoRef.current.volume = manualChange / 100
		setVideoState({
			...videoState,
			volume: manualChange,
		})
	}

	const toggleVolume = () => {
		if (videoRef.current.volume !== 0) {
			videoRef.current.volume = 0
			setVideoState({
				...videoState,
				volume: 0,
			})
		} else {
			videoRef.current.volume = 0.5
			setVideoState({
				...videoState,
				volume: 50,
			})
		}
	}
	
	const closePanels = () => {
		setShowSettings(false)
		setShowPlaybackPanel(false)
	}

	const handleShowSettings = () => {
		setShowSettings((prevState) => !prevState)

		if (showPlaybackPanel) {
			closePanels()
		}
	}

	const handleOnTimeUpdate = (e) => {
		handleProgress(e)
		const progress =
			videoRef.current.currentTime !== 0
				? (videoRef.current.currentTime / videoRef.current.duration) * 100
				: 0
		const currentTime = Math.floor(videoRef.current.currentTime)
		setVideoState({
			...videoState,
			progress: Math.round(progress),
			currentTime: formatTime(currentTime),
		})

		if (videoRef.current.ended) {
			setVideoState({ ...videoState, isStopped: true })
		}
	}

	const handleShowPlaybackPanel = () => {
		if (showPlaybackPanel) {
			setShowSettings(true)
			setShowPlaybackPanel(false)
		} else {
			setShowSettings(false)
			setShowPlaybackPanel(true)
		}
	}

	const changeVideoSpeed = (speed) => {
		videoRef.current.playbackRate = speed
		setVideoState({
			...videoState,
			playbackRate: speed,
		})
	}

	const handleLoadedMetadata = () => {
		setVideoState({
			...videoState,
			duration: formatTime(Math.floor(videoRef.current?.duration)),
		})
	}

	const handleFullscreen = () => {
		setIsInPicture(false)
		setIsFullscreen((prevState) => !prevState)
	}

	const handleInPicture = () => {
		setIsFullscreen(false)
		setIsInPicture((prevState) => !prevState)
		setShowSettings(false)
	}

	const handleMoveWindow = (event) => {
		if (pressed && isInPicture) {
			setPosition({
				x: position.x + event.movementX,
				y: position.y + event.movementY,
			})
		}
	}

	const handleShowControls = () => {
		setShowControls(true)

		clearTimeout(timeout)
		timeout = setTimeout(() => {
			setShowControls(false)
		}, 2000)
	}

	useEffect(() => {
		// 	showControls && setTimeout(() => setShowControls(false), 2000);
	}, [showControls])

	useEffect(() => {
		if (containerRef.current && isInPicture) {
			containerRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`
		}

		if (!isInPicture) {
			containerRef.current.style.transform = "none"
		}
	}, [position, isInPicture])

	useEffect(() => {
		if (videoRef?.current) {
			videoRef.current.oncontextmenu = function (e) {
				e.preventDefault()
			}
		}
	}, [videoRef])

	return (
		<div
			ref={containerRef}
			onMouseMove={handleMoveWindow}
			className={classNames(
				style.videoContainer,
				{ [style.fullScreen]: isFullscreen },
				{ [style.inPicture]: isInPicture }
			)}
			onMouseDown={() => setPressed(true)}
			onMouseUp={() => setPressed(false)}
			onMouseOut={() => setPressed(false)}
		>
			<video
				ref={videoRef}
				onTimeUpdate={handleOnTimeUpdate}
				onLoadedMetadata={handleLoadedMetadata}
				preload="auto"
				src={url}
				type="video/mp4"
				onClick={handleClickVideo}
				onMouseMove={handleShowControls}
				playsInline={true}
				loop={false}
				oncontextmenu='alert("Вы не можете сохранить это изображение.");return false;'
			/>

			{showControls && (
				<div
					className={style.videoСontrols}
					onMouseOver={() => clearTimeout(timeout)}
				>
					<div className={style.videoСontrols__buttons}>
						<div className={style.videoСontrols__left}>
							<div
								className={style.videoСontrols__volumeWrapper}
								onMouseEnter={() => setShowVolumeBar(true)}
								onMouseLeave={() => setShowVolumeBar(false)}
							>
								<div
									className={style.videoСontrols__volume}
									onClick={toggleVolume}
								>
									{videoState.volume === 0 && <VolumeOffIcon />}
									{videoState.volume <= 50 && videoState.volume > 0 && (
										<VolumeLowIcon />
									)}
									{videoState.volume > 50 && <VolumeHightIcon />}
								</div>

								{showVolumeBar && (
									<div className={style.videoСontrols__volumeBar}>
										<input
											type="range"
											min="0"
											max="100"
											value={videoState.volume}
											onChange={(e) => handleVolume(e)}
										/>
										<div
											className={style.videoСontrols__playLine}
											style={{ width: videoState.volume + "%" }}
										></div>
									</div>
								)}
							</div>

							<div className={style.videoControls__time}>
								{videoState.currentTime}/{videoState.duration}
							</div>
						</div>

						<div className={style.videoСontrols__center}>
							<div
								className={style.videoСontrols__play}
								onClick={handlePlayVideo}
							>
								{videoState.isStopped && <PlayIcon />}
								{!videoState.isStopped && <PauseIcon />}
							</div>
						</div>

						<div className={style.videoСontrols__right}>
							<div
								className={style.videoСontrols__settings}
								onClick={handleShowSettings}
							>
								<SettingsIcon />
							</div>

							<div
								className={style.videoСontrols__fullscreen}
								onClick={handleFullscreen}
							>
								{!isFullscreen && <MaximizeIcon />}
								{isFullscreen && <MinimizeIcon />}
							</div>

							{showSettings && (
								<>
									<div className={style.videoСontrols__settingsPanel}>
										<div
											className={style.videoControls__settingsItem}
											onClick={handleShowPlaybackPanel}
										>
											<PlaybackIcon />
											<span>{t("playback_speed")}</span>
										</div>

										<div
											className={style.videoControls__settingsItem}
											onClick={handleInPicture}
										>
											<PictureInPictureIcon />
											<span>{t("picture_in_picture")}</span>
										</div>
									</div>
									<div className={style.overlay} onClick={closePanels}></div>
								</>
							)}
							{showPlaybackPanel && (
								<>
									<div className={style.videoСontrols__playbackPanel}>
										<div
											className={style.videoСontrols__playbackTitle}
											onClick={handleShowPlaybackPanel}
										>
											<ArrowLeftIcon />
											<span>{t("playback_speed")}</span>
										</div>
										<ul className={style.videoСontrols__playbackList}>
											{speedList.map((item) => (
												<li
													className={
														item.value === videoState.playbackRate &&
														style.videoСontrols__currentPlayback
													}
													onClick={() => changeVideoSpeed(item.value)}
												>
													<TickIcon />
													<span>{item.label}</span>
												</li>
											))}
										</ul>
									</div>
									<div className={style.overlay} onClick={closePanels}></div>
								</>
							)}
						</div>
					</div>

					<div className={style.videoСontrols__bar}>
						<input
							type="range"
							min="0"
							max="100"
							value={videoState.progress}
							onChange={handleVideoProgress}
						/>
						<div
							className={classNames(
								style.videoСontrols__playLine,
								style.videoСontrols__progressBar
							)}
							style={{ width: videoState.progress + "%" }}
						></div>
					</div>
				</div>
			)}
		</div>
	)
}

export default VideoPlayer

function formatTime(time) {
	if (time < 10) {
		return `00:0${time}`
	}

	if (time < 60) {
		return `00:${time}`
	}

	if (time >= 60 && time < 3600) {
		const minutes = ("0" + Math.floor(time / 60)).slice(-2)
		const seconds = ("0" + (time - minutes * 60)).slice(-2)

		return `${minutes}:${seconds}`
	}

	if (time >= 3600) {
		const hours = ("0" + Math.floor(time / 3600)).slice(-2)
		const minutes = ("0" + Math.floor((time - hours * 3600) / 60)).slice(-2)
		const seconds = ("0" + (time - hours * 3600 - minutes * 60)).slice(-2)

		return `${hours}:${minutes}:${seconds}`
	}
}
