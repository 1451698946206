import React from 'react';
import styles from './percent.module.scss'

const Percent = (props) => {
    let tmpCircle = 2 * Math.PI * props.info.radius

    let strokeDashoffset = tmpCircle - props.info.passingScore / props.info.passingTotal * tmpCircle
 
    return (
        <div className={styles.time}>
            <div className={styles.circle}>
                <svg className={styles.progress_ring}>
                    <circle
                        className={styles.progress_ring_circle_bg}
                        cx="16" cy="16" r="16"
                        fill="transparent"
                    ></circle>
                    <circle
                        className={styles.progress_ring_circle}
                        style={{
                            strokeDashoffset: strokeDashoffset
                        }}
                        cx="16" cy="16" r="16"
                        stroke="#00CC66" fill="transparent"
                    ></circle>
                </svg>
            </div>
        </div>
    )
}

export default Percent