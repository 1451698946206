import {api} from "../http";

export async function getUserRequest() {
    const url = `/v1/profile`
    return await api.get(url)
}
export async function sendUserRequest(data) {
    const url = `/v1/profile`
    return await api.patch(url,data)
}
export async function sendUserAvatarRequest(data){
    const url = `/v1/profile/avatar`
    return await api.post(url,data)
}
export async function deleteUserAvatarRequest(){
    const url = `/v1/profile/avatar`
    return await api.delete(url)
}

export async function setEmailRequest(data) {
    const url = `/v1/set-email?token=${data.token}`
    return await api.get(url)
}

export async function getFaqRequest() {
    const url = `/v1/profile/support`
    return await api.get(url)
}

export async function forgotPasswordRequest(data) {
    const url = '/v1/forgot-password'
    return await api.post(url, data)
}

export async function resetPasswordRequest(data) {
    const url = `/v1/reset-password?email=${data.email}&token=${data.token}`
    return await api.post(url, data.body)
}

export async function setPasswordRequest(data) {
    const url = `/v1/set-password?email=${data.email}&token=${data.token}`
    return await api.post(url, data.body)
}

export async function getSkillsRequest() {
    const url = `/v1/skills`
    return await api.get(url)
}