import classNames from "classnames"
import Typography from "../../UIKit/base/Typography"
import styles from "./button.module.sass"
import { useTranslation } from "react-i18next"

export default function Button({
	children,
	className,
	type = "button",
	variant = "primary",
	fontVariant = "semiBold",
	disabled = false,
	...props
}) {
	const classes = classNames(styles.button, styles[variant], className, {
		[styles.button__disabled]: disabled,
	})
	const { t } = useTranslation()
	return (
		<Typography
			fontVariant={fontVariant}
			component="button"
			className={classes}
			type={type}
			{...props}
		>
			{t(children)}
		</Typography>
	)
}