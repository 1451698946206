import classNames from "classnames";
import Typography from "../UIKit/base/Typography";
import styles from "./ScoreProgress.module.sass";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function ScoreProgress({ total, score, passing }) {
  const { t } = useTranslation(); 
 
  return (
    <div className={styles.progress__wrapper}>
      <Typography className={styles.title}>{t("score")}</Typography>
      <div className={styles.wrapper}>
        <div
          className={styles.text__score}
          style={{
            left:
              score === total
                ? `calc(${(score * 100) / total}% - 24px)`
                : (!score
                ? `calc(${(score * 100) / total}% - 6px)`
                : `${(score * 100) / total}%`),
          }}
        >
          <Typography>{Math.round(score) || 0}</Typography>
        </div>
        <div className={styles.total}></div>
        <div
          className={styles.passed}
          style={{ width: `${(passing * 100) / total}%` }}
        ></div>
        <div
          className={styles.passed__wrapper}
          style={{ width: `${(passing * 100) / total}%` }}
        ></div>
        <div
          className={classNames(styles.score, {
            [styles.score__passing]: score >= passing,
          })}
          style={{
            left:
              score === total
                ? `calc(${(score * 100) / total}% - 24px)`
                : (!score
                ? `calc(${(score * 100) / total}% - 6px)`
                : `${(score * 100) / total}%`),
          }}
        ></div>
        <div className={styles.text__total}>
          <Typography>0</Typography>
          <Typography>{total}</Typography>
        </div>
      </div>
    </div>
  );
}

ScoreProgress.propTypes = {
  total: PropTypes.number,
  score: PropTypes.number,
};
