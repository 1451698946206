export const LeftArrow = (props) => {
	return (
		<svg
			{...props}
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M12 12H0C6.62742 12 12 6.62742 12 0V12Z" fill="#FBFAFF" />
		</svg>
	)
}
export const RightArrow = (props) => {
	return (
		<svg
			{...props}
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0 12H12C5.37258 12 0 6.62742 0 0V12Z" fill="#E2E0FF" />
		</svg>
	)
}
