import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Logo from "../../Logo/Logo"
import Typography from "../../UIKit/base/Typography"
import JustifyContainer from "../JustifyContainer"
import SredaFooter from "./SredaFooter"
import styles from "./footer.module.sass"
import classNames from "classnames" 
import { globalLoadSelector } from "../../../store/reducers/loader"
import { useSelector } from "react-redux";

const Footer = ({ email, phone, showLogo,className }) => {

	const { t } = useTranslation() 
	const year = new Date().getFullYear()
	const loading = useSelector(globalLoadSelector).loading

	if(loading) {
		return null
	}
	return (
		<footer className={classNames(styles.footer, className)}>
			<JustifyContainer className={styles.footer__container}>
				<Typography fontVariant="medium" className={styles.footer__left}>
					© {year} {t("all_rights_reserved")}
				</Typography>
				<Typography fontVariant="medium" className={styles.footer__center}>
					<span>{phone}</span>
					<span>{email}</span>
				</Typography>
				<Link to="/" className={styles.footer__right}>
					<Logo />
				</Link>
			</JustifyContainer>
			{!!showLogo && <SredaFooter />}
		</footer>
	)
}

export default Footer
