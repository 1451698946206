import {api} from "../http";

export async function getReviewsStatisticRequest({productId}) {
    const url = `/v1/course/${productId}/reviews/statistic`
    return await api.get(url)
}

export async function getReviewsRequest({product_id, page}) {
    const url = `/v1/course/${product_id}/reviews?page=${page}`
    return await api.get(url)
}

export async function sendReviewRequest({product_id, data}) {
    const url = `/v1/course/${product_id}/reviews`
    return await api.post(url, data)
}