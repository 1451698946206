import PreloaderBreadcrumbs from "../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderButton from "../PreloaderTemplates/PreloaderButton";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import styles from "./Preloaders.module.sass";

const PreloaderMobileTitle = () => {
  return (
    <div className={styles.mobileTitle}>
      <div className={styles.mobileTitle__arrow}>
        {" "}
        <PreloaderCircle size={24} />
      </div>
      <div className={styles.mobileTitle__title}>
        {" "}
        <PreloaderLine width={120} />
      </div>
      <div className={styles.mobileTitle__circles}>
        <PreloaderCircle size={24} />
        <PreloaderCircle size={48} />
      </div>
    </div>
  );
};

export default PreloaderMobileTitle;
