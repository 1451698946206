import classNames from "classnames"
import { CheckedIcon } from "../../icons"
import styles from './Checkbox.module.sass'

export default function Checkbox({ value, checked, onChange, className }) {
	const htmlFor = `checkbox-${Math.random()}`
	return (
		<div className={classNames(styles.checkbox__wrapper,className)}>
			<input
				id={htmlFor}
				type="checkbox"
				value={value}
				checked={checked}
				onChange={onChange}
			/>
			<label htmlFor={htmlFor} className={styles.checkbox}>
				<CheckedIcon />
			</label>
		</div>
	)
}