import { createAction, handleActions } from "redux-actions"
import {
	all,
	takeLeading,
	call,
	put,
	takeEvery,
} from "redux-saga/effects"
import {
	getCourseRequest,
	sendCourseAccessRequest,
} from "../../api/course/course"
import { getProduct } from './products'
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"
import {
	successMessage,
	dangerMessage,
} from "../../components/UIKit/alert/alertReducer"
import { getCertificates } from "./certificates"

const namespace = "course"

const initialState = {
	product: null,
	modules: null,
	speakers: null,
	reviews: null,
	error: null,
}

const GET_COURSE = `${namespace}/GET_COURSE`
const SET_COURSE = `${namespace}/SET_COURSE`
const RESET_COURSE = `${namespace}/RESET_COURSE`
const SET_MODULES = `${namespace}/SET_MODULES`

const SET_ERROR = `${namespace}/SET_ERROR`
const SEND_COURSE_ACCESS = `${namespace}/SEND_COURSE_ACCESS`

export const setCourse = createAction(SET_COURSE)
export const getCourse = createAction(GET_COURSE)
export const resetCourse = createAction(RESET_COURSE)
export const setModules = createAction(SET_MODULES)
export const setError = createAction(SET_ERROR)
export const sendCourseAccess = createAction(SEND_COURSE_ACCESS)

export default handleActions(
	{
		[SET_COURSE]: (state, { payload }) => ({ ...payload }),
		[SET_MODULES]: (state, { payload }) => ({ ...state, modules: payload }),
		[RESET_COURSE]: () => initialState,
		[SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	},
	initialState
)

export const courseSelector = (state) => state[namespace]
export const productSelector = (state) => state[namespace].product
export const modulesSelector = (state) => state[namespace].modules
export const speakersSelector = (state) => state[namespace].speakers
export const reviewsSelector = (state) => state[namespace].reviews
export const actionButtonSelector = (state) => state[namespace]?.action_button
export const errorSelector = (state) => state[namespace]?.error

function* getCourseSaga({ payload }) {
	yield put(addGlobalLoadItem(GET_COURSE))
	yield put(resetCourse())
	try {
		const { data } = yield call(getCourseRequest, payload)
		yield put(setCourse(data))

		if (data?.action_button?.button_type === "certificate") {
			yield put(getCertificates())
		}
	} catch (err) {
		console.log(err)
		if (err.response.status === 404) {
			yield put(setError(404))
		}
	} finally {
		yield put(removeGlobalLoadItem(GET_COURSE))
	}
}

function* sendCourseAccessSaga({ payload }) {
	yield put(addGlobalLoadItem(SEND_COURSE_ACCESS))
	try {
		const { data, status } = yield call(sendCourseAccessRequest, payload.data)
		if (status === 200) {
			yield put(getProduct({productId: payload.data.id}))
		}
		yield put(successMessage(data.message))
	} catch (err) {
		console.log(err) 
	} finally {
		yield put(removeGlobalLoadItem(SEND_COURSE_ACCESS))
	}
}

export function* courseSagas() {
	yield all([
		takeLeading(GET_COURSE, getCourseSaga),
		takeEvery(SEND_COURSE_ACCESS, sendCourseAccessSaga),
	])
}
