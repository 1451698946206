import { useState, useEffect, useRef } from "react";
import ReviewRating from "./ReviewRating";
import AvatarView from "../UIKit/avatarView/AvatarView";
import { timeSince } from "../utils";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import styles from "./Reviews.module.sass";

const ReviewsListItem = ({ reviewData, isMobile }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [overflowActive, setOverflowActive] = useState(false);
  const { t } = useTranslation();

  const textRef = useRef();

  const { person_name, rating, review, avatar, created_at } = reviewData;

  useEffect(() => {
    if (isOverflowActive(textRef.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [isOverflowActive]);

  const changeTextView = () => {
    setShowFullText((prevState) => !prevState);
  };

  function isOverflowActive(event) {
    return event.scrollHeight - event.offsetHeight > 1;
  }
  return (
    <>
      {review && (
        <div className={styles.reviewItem}>
          <div className={styles.reviewItem__inner}>
            <div className={styles.reviewItem__avatar}>
              <div
                className={`${styles.tabLine_header_name} ${styles.tabLine_name}`}
              >
                <div className={styles.tabLine_name_round}>
                  <AvatarView name={person_name} src={avatar?.big} />
                </div>
              </div>
            </div>
            <div className={styles.reviewItem__data}>
              <div className={styles.reviewItem__header}>
                <div className={styles.reviewItem__title}>
                  <div className={styles.reviewItem__name}>{person_name}</div>
                  <div className={styles.reviewItem__date}>
                    {timeSince(created_at, i18next.language)} {t("ago")}
                  </div>
                </div>

                <div className={styles.reviewItem__rating}>
                  <ReviewRating score={rating} isMobile={isMobile} />
                </div>
              </div>
              <div className={styles.reviewItem__textWrapper}>
                <p
                  className={classNames(styles.reviewItem__text, {
                    [styles.reviewItem__fullText]: !showFullText,
                  })}
                  ref={textRef}
                >
                  {review}
                </p>
                <div
                  className={styles.reviewItem__more}
                  onClick={changeTextView}
                >
                  {showFullText
                    ? t("show-less")
                    : overflowActive && t("show-more")}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.reviewItem__textWrapperMobile}>
            <p
              className={classNames(styles.reviewItem__text, {
                [styles.reviewItem__fullText]: !showFullText,
              })}
              ref={textRef}
            >
              {review}
            </p>
            <div className={styles.reviewItem__more} onClick={changeTextView}>
              {showFullText ? t("show-less") : overflowActive && t("show-more")}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewsListItem;
