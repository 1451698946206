export function LogoGrey(props) {
	return (
		<svg
			{...props}
			width="194"
			height="32"
			viewBox="0 0 194 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 0C7.17749 0 0 7.1775 0 16C0 24.8225 7.17749 32 16 32C24.8225 32 32 24.8225 32 16C32 7.1775 24.8225 0 16 0ZM3.55495 16C3.55495 12.5275 4.9864 9.38481 7.28698 7.12613C7.17209 7.69655 7.11126 8.28588 7.11126 8.88874C7.11126 13.79 11.0988 17.7775 16 17.7775C18.9413 17.7775 21.3338 20.17 21.3338 23.1113C21.3338 26.0525 18.9413 28.445 16 28.445C9.13745 28.4437 3.55495 22.8612 3.55495 16ZM24.7117 24.8739C24.8266 24.3035 24.8874 23.7141 24.8874 23.1113C24.8874 18.21 20.8999 14.2225 15.9986 14.2225C13.0574 14.2225 10.6649 11.83 10.6649 8.88874C10.6649 5.94745 13.0574 3.55495 15.9986 3.55495C22.8598 3.55495 28.4423 9.13745 28.4423 15.9986C28.4437 19.4711 27.0136 22.6138 24.7117 24.8739Z"
				fill="#A1A6AA"
			/>
			<path
				d="M47.2648 14.6995C47.2648 18.5718 57.3981 16.2918 57.3981 22.9509C57.3981 26.3166 54.5029 28.3795 50.6305 28.3795C47.0114 28.3795 44.4781 26.6423 43.4286 24.1452L46.1429 22.589C46.7219 24.3261 48.3867 25.4118 50.6305 25.4118C52.5486 25.4118 54.2134 24.7242 54.2134 22.9509C54.2134 19.0785 44.08 21.2861 44.08 14.7357C44.08 11.5509 46.8305 9.34328 50.4857 9.34328C53.4534 9.34328 55.8419 10.7547 57 13.1071L54.3581 14.5909C53.6705 12.9623 52.0781 12.2747 50.4857 12.2747C48.8572 12.2747 47.2648 13.0709 47.2648 14.6995Z"
				fill="#A1A6AA"
			/>
			<path
				d="M84.9512 12.8538C86.1093 10.3204 88.3169 9.48804 90.5969 9.48804V12.7814C87.8826 12.6728 84.9512 14.048 84.9512 18.3185V27.909H81.8027V9.81375H84.9512V12.8538Z"
				fill="#A1A6AA"
			/>
			<path
				d="M94.8957 20.309C95.5109 23.5661 98.0804 25.4118 101.446 25.4118C103.943 25.4118 105.753 24.2538 106.658 22.7699L109.336 24.2899C107.743 26.7509 104.993 28.3795 101.374 28.3795C95.5471 28.3795 91.6747 24.2899 91.6747 18.8614C91.6747 13.5052 95.5109 9.34328 101.157 9.34328C106.621 9.34328 110.168 13.8309 110.168 18.8976C110.168 19.368 110.132 19.8385 110.059 20.309H94.8957ZM101.157 12.3109C97.7547 12.3109 95.4024 14.4099 94.8957 17.5585H106.983C106.44 13.9757 103.871 12.3109 101.157 12.3109Z"
				fill="#A1A6AA"
			/>
			<path
				d="M128.735 2.57566H131.883V27.909H128.735V24.7966C127.287 27.0042 124.971 28.3795 121.895 28.3795C116.864 28.3795 112.739 24.2176 112.739 18.8614C112.739 13.5052 116.864 9.34328 121.895 9.34328C124.971 9.34328 127.287 10.7185 128.735 12.9261V2.57566ZM122.293 25.3395C125.948 25.3395 128.735 22.5528 128.735 18.8614C128.735 15.1699 125.948 12.3833 122.293 12.3833C118.674 12.3833 115.887 15.1699 115.887 18.8614C115.887 22.5528 118.674 25.3395 122.293 25.3395Z"
				fill="#A1A6AA"
			/>
			<path
				d="M151.637 9.81375H154.785V27.909H151.637V24.7966C150.189 27.0042 147.873 28.3795 144.797 28.3795C139.766 28.3795 135.641 24.2176 135.641 18.8614C135.641 13.5052 139.766 9.34328 144.797 9.34328C147.873 9.34328 150.189 10.7185 151.637 12.9261V9.81375ZM145.195 25.3395C148.85 25.3395 151.637 22.5528 151.637 18.8614C151.637 15.1699 148.85 12.3833 145.195 12.3833C141.576 12.3833 138.789 15.1699 138.789 18.8614C138.789 22.5528 141.576 25.3395 145.195 25.3395Z"
				fill="#A1A6AA"
			/>
			<path
				d="M161.546 28.3071C160.243 28.3071 159.194 27.2576 159.194 25.9547C159.194 24.6518 160.243 23.6023 161.546 23.6023C162.885 23.6023 163.898 24.6518 163.898 25.9547C163.898 27.2576 162.885 28.3071 161.546 28.3071Z"
				fill="#A1A6AA"
			/>
			<path
				d="M181.89 9.81375H185.038V27.909H181.89V24.7966C180.442 27.0042 178.126 28.3795 175.05 28.3795C170.019 28.3795 165.893 24.2176 165.893 18.8614C165.893 13.5052 170.019 9.34328 175.05 9.34328C178.126 9.34328 180.442 10.7185 181.89 12.9261V9.81375ZM175.448 25.3395C179.103 25.3395 181.89 22.5528 181.89 18.8614C181.89 15.1699 179.103 12.3833 175.448 12.3833C171.829 12.3833 169.042 15.1699 169.042 18.8614C169.042 22.5528 171.829 25.3395 175.448 25.3395Z"
				fill="#A1A6AA"
			/>
			<path
				d="M191.401 6.52042C190.207 6.52042 189.266 5.57946 189.266 4.42137C189.266 3.26327 190.207 2.28613 191.401 2.28613C192.559 2.28613 193.5 3.26327 193.5 4.42137C193.5 5.57946 192.559 6.52042 191.401 6.52042ZM189.809 27.909V9.81375H192.957V27.909H189.809Z"
				fill="#A1A6AA"
			/>
			<path
				d="M62.7276 20.309C63.3429 23.5661 65.9124 25.4118 69.2781 25.4118C71.7753 25.4118 73.5848 24.2538 74.4896 22.7699L77.1676 24.2899C75.5753 26.7509 72.8248 28.3795 69.2057 28.3795C63.3791 28.3795 59.5067 24.2899 59.5067 18.8614C59.5067 13.5052 63.3429 9.34328 68.9886 9.34328C74.4534 9.34328 78 13.8309 78 18.8976C78 19.368 77.9638 19.8385 77.8915 20.309H62.7276ZM68.9886 12.3109C65.5867 12.3109 63.2343 14.4099 62.7276 17.5585H74.8153C74.2724 13.9757 71.7029 12.3109 68.9886 12.3109Z"
				fill="#A1A6AA"
			/>
		</svg>
	)
}
