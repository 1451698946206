import { createAction, handleActions } from "redux-actions"

const namespace = "loader"

const ADD_GLOBAL_LOAD_ITEM = `${namespace}/ADD_GLOBAL_LOAD_ITEM`
const REMOVE_GLOBAL_LOAD_ITEM = `${namespace}/REMOVE_GLOBAL_LOAD_ITEM`
const SET_SHOW_LOADER =  `${namespace}/SET_SHOW_LOADER`

export const addGlobalLoadItem = createAction(ADD_GLOBAL_LOAD_ITEM)
export const removeGlobalLoadItem = createAction(REMOVE_GLOBAL_LOAD_ITEM)
export const setShowLoader = createAction(SET_SHOW_LOADER)

const initialState = {
	loading: 0,
	showLoader: true
}

export default handleActions(
	{
		[ADD_GLOBAL_LOAD_ITEM]: (state, { payload }) =>  ({
			...state,
			loading: state.loading + 1,
		}),  
		[REMOVE_GLOBAL_LOAD_ITEM]: (state, { payload }) =>  ({
			...state,
			loading: state.loading - 1,
		}), 
		[SET_SHOW_LOADER]: (state, { payload }) => ({
			...state,
			showLoader: payload,
		}),
	},
	initialState
)

export const globalLoadSelector = (state) => state[namespace]


