import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder"; 
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect"; 
import styles from "./Preloaders.module.sass";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import structureStyles from "../../KnowledgeBase/index.module.sass";
import JustifyContainer from "../../layouts/JustifyContainer";
import PreloaderBreadcrumbs from "../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import classNames from "classnames";

const PreloaderStructure = () => {
  return (
    <>    <PreloaderMobileTitle /> 
    <div className={styles.structure__wrapper}>
      <PreloaderBreadcrumbs />
       
         
      <div className={classNames(styles.list__tabs, styles.structure__header)}>
        <div className={styles.list__buttons}>
          <PreloaderRect width={360} height={48} />
        </div>
      </div>
    

      <div className={structureStyles.tree}>
        <ul className={structureStyles.first_node}>
          <li>
            <a
              className={structureStyles.card}
              href="/knowledge-base/company-structure/3686"
            >
              <div className={structureStyles.department}>
                <PreloaderStructureItem />
              </div>
              <button className={structureStyles.eye}>
                <PreloaderCircle size={24} />
              </button>
            </a>
            <ul>
              <li>
                <a
                  className={structureStyles.card}
                  href="/knowledge-base/company-structure/3687"
                >
                  <div className={structureStyles.department}>
                    <PreloaderStructureItem />
                  </div>
                </a>
              </li>
              <li>
                <a
                  className={structureStyles.card}
                  href="/knowledge-base/company-structure/3894"
                >
                  <div className="KnowledgeBase_department__NSp98">
                    <PreloaderStructureItem />
                  </div>
                </a>
              </li>
              {window.innerWidth > 1180 &&
              <li>
                <a
                  className={structureStyles.card}
                  href="/knowledge-base/company-structure/4198"
                >
                  <div className="KnowledgeBase_department__NSp98">
                    <PreloaderStructureItem />
                  </div>
                </a>
              </li>
              }
            </ul>
          </li>
        </ul>
      </div>
    </div>
    </>
  );
};

export default PreloaderStructure;

const PreloaderStructureItem = ({ className }) => {
  return (
    <div className={styles.structure}>
      <PreloaderRect width={"100%"} height={24} />
      <PreloaderBorder />

      <div className={styles.structure__data}>
        <PreloaderCircle size={40} />
        <div className={styles.structure__lines}>
          {" "}
          <PreloaderLine width={120} /> <PreloaderLine width={120} />{" "}
        </div>
      </div>
      <PreloaderBorder />
      <PreloaderLine width={80} />
    </div>
  );
};
