import { createAction, handleActions } from "redux-actions";
import { all, call, take, put } from "redux-saga/effects";
import {
  getManualSurveyRequest,
  getPerformanceSurveyRequest,
  getSurveyRequest,
  getSurveysRequest,
  sendAnswerRequest,
  sendFinishManualRequest,
  sendFinishPerformanceRequest,
  sendFinishRequest,
  sendManualAnswerRequest,
  sendPerformanceAnswerRequest,
} from "../../api/surveys/surveys";
import { successMessage } from "../../components/UIKit/alert/alertReducer";
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader";

const namespace = "surveys";

const GET_SURVEYS = `${namespace}/GET_SURVEYS`;
const SET_SURVEYS = `${namespace}/SET_SURVEYS`;
const GET_SURVEY = `${namespace}/GET_SURVEY`;
const GET_MANUAL_SURVEY = `${namespace}/GET_MANUAL_SURVEY`;
const GET_PERFORMANCE_SURVEY = `${namespace}/GET_PERFORMANCE_SURVEY`;
const SET_SURVEY = `${namespace}/SET_SURVEY`;
const SEND_ANSWER = `${namespace}/SEND_ANSWER`;
const SEND_MANUAL_ANSWER = `${namespace}/SEND_MANUAL_ANSWER`;
const SEND_PERFORMANCE_ANSWER = `${namespace}/SEND_PERFORMANCE_ANSWER`;
const SEND_FINISH = `${namespace}/SEND_FINISH`;
const SET_QUESTION = `${namespace}/SET_QUESTION`;
const SEND_FINISH_MANUAL = `${namespace}/SEND_FINISH_MANUAL`;
const SEND_FINISH_PERFORMANCE = `${namespace}/SEND_FINISH_PERFORMANCE`;
const SET_ERROR = `${namespace}/SET_ERROR`;

export const getSurveys = createAction(GET_SURVEYS);
export const setSurveys = createAction(SET_SURVEYS);
export const getSurvey = createAction(GET_SURVEY);
export const getManualSurvey = createAction(GET_MANUAL_SURVEY);
export const getPerformanceSurvey = createAction(GET_PERFORMANCE_SURVEY);
export const setSurvey = createAction(SET_SURVEY);
export const sendAnswer = createAction(SEND_ANSWER);
export const sendManualAnswer = createAction(SEND_MANUAL_ANSWER);
export const sendPerformanceAnswer = createAction(SEND_PERFORMANCE_ANSWER);
export const sendFinish = createAction(SEND_FINISH);
export const setQuestion = createAction(SET_QUESTION);
export const sendFinishManual = createAction(SEND_FINISH_MANUAL);
export const sendFinishPerformance = createAction(SEND_FINISH_PERFORMANCE);
export const setError = createAction(SET_ERROR);

export const initialState = {
  surveys: null,
  survey: {
    question: null,
    questions: [],
    next_question_id: null,
    previous_question_id: null,
    questionnaire: null,
  },
  error: null,
};

export default handleActions(
  {
    [SET_SURVEYS]: (state, { payload }) => ({ ...state, surveys: payload }),
    [SET_SURVEY]: (state, { payload }) => ({ ...state, survey: payload }),
    [SET_QUESTION]: (state, { payload }) => ({
      ...state,
      survey: { ...state["survey"], question: payload },
    }),
    [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
  },
  initialState
);

export const surveysSelector = (state) => state[namespace]?.surveys;
export const surveySelector = (state) => state[namespace]?.survey;
export const errorSelector = (state) => state[namespace]?.error;

function* getSurveysSaga() {
  while (true) {
    const { payload } = yield take(GET_SURVEYS);
    try {
      const { data } = yield call(getSurveysRequest, payload);
      yield put(setSurveys(data?.surveys));
    } catch (err) {
      console.log(err);
    }
  }
}
function* getSurveySaga() {
  while (true) {
    const {
      payload: { navigate, ...other },
    } = yield take(GET_SURVEY);
    yield put(addGlobalLoadItem());
    try {
      const { data } = yield call(getSurveyRequest, other);
      if (
        navigate &&
        data?.questionnaire?.status !== "in-progress" &&
        data?.questionnaire?.status !== "pending"
      ) {
        navigate("/");
        yield put(successMessage("this_survey_is_over"));
      } else {
        yield put(setSurvey(data));
      }

      yield put(setError(null));
    } catch (err) {
      console.log(err);
      yield put(setError(err?.response?.status));
    } finally {
      yield put(removeGlobalLoadItem());
    }
  }
}

function* sendAnswerSaga() {
  while (true) {
    const {
      payload: { cb, ...other },
    } = yield take(SEND_ANSWER);
    try {
      const { data } = yield call(sendAnswerRequest, { ...other });
      yield put(successMessage(data?.message));
      if (typeof cb === "function") {
        cb();
      }
    } catch (err) {
      console.log(err);
    }
  }
}

function* sendFinishSaga() {
  while (true) {
    const {
      payload: { cb, ...other },
    } = yield take(SEND_FINISH);
    try {
      const { data } = yield call(sendFinishRequest, other);
      yield put(successMessage(data?.message));
      if (typeof cb === "function") {
        cb();
      }
    } catch (err) {
      console.log(err);
    }
  }
}

function* getManualSurveySaga() {
  while (true) {
    const { payload } = yield take(GET_MANUAL_SURVEY);
    yield put(addGlobalLoadItem());
    try {
      const { data } = yield call(getManualSurveyRequest, payload);
      yield put(setSurvey(data));
    } catch (err) {
      console.log(err);
    } finally {
      yield put(removeGlobalLoadItem());
    }
  }
}

function* getPerformanceSurveySaga() {
  while (true) {
    const { payload } = yield take(GET_PERFORMANCE_SURVEY);
    yield put(addGlobalLoadItem());
    try {
      const { data } = yield call(getPerformanceSurveyRequest, payload);
      yield put(setSurvey(data));
    } catch (err) {
      console.log(err);
    } finally {
      yield put(removeGlobalLoadItem());
    }
  }
}

function* sendPerformanceAnswerSaga() {
  while (true) {
    const {
      payload: { cb, ...other },
    } = yield take(SEND_PERFORMANCE_ANSWER);
    yield put(addGlobalLoadItem());
    try {
      const { data } = yield call(sendPerformanceAnswerRequest, { ...other });
      yield put(setQuestion(data?.question));
      yield put(successMessage(data?.message));
      if (typeof cb === "function") {
        cb();
      }
    } catch (err) {
      console.log(err);
    } finally {
      yield put(removeGlobalLoadItem());
    }
  }
}

function* sendFinishPerformanceSaga() {
  while (true) {
    const {
      payload: { cb, ...other },
    } = yield take(SEND_FINISH_PERFORMANCE);
    yield put(addGlobalLoadItem())
    try {
      const { data } = yield call(sendFinishPerformanceRequest, other);

      yield put(successMessage(data?.message));
      if (typeof cb === "function") {
        cb();
      }
    } catch (err) {
      console.log(err);
    }finally{
      yield put(removeGlobalLoadItem())
    }
  }
}

function* sendManualAnswerSaga() {
  while (true) {
    const {
      payload: { cb, ...other },
    } = yield take(SEND_MANUAL_ANSWER);
    yield put(addGlobalLoadItem());
    try {
      const { data } = yield call(sendManualAnswerRequest, { ...other });
      yield put(setQuestion(data?.question));
      yield put(successMessage(data?.message));
      if (typeof cb === "function") {
        cb();
      }
    } catch (err) {
      console.log(err);
    } finally {
      yield put(removeGlobalLoadItem());
    }
  }
}

function* sendFinishManualSaga() {
  while (true) {
    const {
      payload: { cb, ...other },
    } = yield take(SEND_FINISH_MANUAL);
    yield put(addGlobalLoadItem())
    try {
      const { data } = yield call(sendFinishManualRequest, other);

      yield put(successMessage(data?.message));
      if (typeof cb === "function") {
        cb();
      }
    } catch (err) {
      console.log(err);
    }finally{
      yield put(removeGlobalLoadItem())
    }
  }
}

export function* surveysSagas() {
  yield all([
    getSurveysSaga(),
    getSurveySaga(),
    sendAnswerSaga(),
    sendFinishSaga(),
    getManualSurveySaga(),
    sendManualAnswerSaga(),
    sendFinishManualSaga(),
    getPerformanceSurveySaga(),
    sendPerformanceAnswerSaga(),
    sendFinishPerformanceSaga()
  ]);
}
