import React, { useState } from "react";
import styles from "./product.module.scss"; 
import { useTranslation } from "react-i18next";  
import classNames from "classnames";
import AvatarView from "../UIKit/avatarView/AvatarView";
import Typography from "../UIKit/base/Typography";

export default function Speakers({ title, speakers }) {
	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);

	const minSwipeDistance = 20;

	const [step, setStep] = useState(0);
	const { t } = useTranslation();

	function handleClick({ target: { value } }) {
		setStep(+value);
	}
	if (!speakers?.length) {
		return <NoItem title={title} />;
	}

	const count = speakers?.length <= 3 ? speakers?.length : 3;
	const current = speakers[step];

	const onTouchStart = (e) => {
		setTouchEnd(null);
		setTouchStart(e.targetTouches[0].clientX);
	};

	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		if (isLeftSwipe && step !== 2) {
			setStep(+step + 1);
		}

		if (isRightSwipe && step !== 0) {
			setStep(+step - 1);
		}
	};

	return (
		<div
			className={styles.courseInfo__speakers}
			onTouchStart={onTouchStart}
			onTouchMove={onTouchMove}
			onTouchEnd={onTouchEnd}
		>
			<Typography
				fontVariant="medium"
				className={styles.courseInfo__blockTitle}
			>
				{t(title)}
			</Typography>
			<div className={styles.speaker__main}>
				<div className={`${styles.tabLine_header_name} ${styles.tabLine_name}`}>
					<div className={styles.tabLine_name_round}>
						<AvatarView name={current.name} src={current?.avatar?.small} />
					</div>
				</div>
				<div>
					<Typography fontVariant="medium" className={styles.speaker__title}>
						{speakers?.length > 0 ? speakers[step].name : "-"}
					</Typography>
					<Typography fontVariant="" className={styles.speaker__subtitle}>
						{speakers?.length > 0 ? speakers[step].occupation : "-"}
					</Typography>
				</div>
			</div>
			<Typography fontVariant="" className={styles.speaker__bio}>
				{speakers?.length > 0 ? speakers[step].bio : "-"}
			</Typography>
			{speakers?.length > 1 && (
				<div className={styles.dot__group}>
					{Array.from(new Array(count), (_, i) => (
						<button
							className={classNames(styles.dot, {
								[styles.dot_current]: step === i,
							})}
							key={i}
							onClick={handleClick}
							value={i}
						/>
					))}
				</div>
			)}
		</div>
	);
}

function NoItem({ title }) {
	const { t } = useTranslation();

	return (
		<div className={styles.courseInfo__speakers}>
			<Typography
				fontVariant="medium"
				className={styles.courseInfo__blockTitle}
			>
				{t(title)}
			</Typography>
			<div className={styles.speaker__main}>
				<Typography fontVariant="medium" className={styles.NoItem}>
					{t(`no_speakers`)}
				</Typography>
			</div>
		</div>
	);
}
