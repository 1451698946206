export const tableHeaders = [
	{
		title: "survey_name",
		name: "title",
		isSort: true,
	},
	{
		title: "who_is_evaluated",
		name: "who_is_evaluated",
		isSort: false,
	},
	
	{
		title: "number_of_questions",
		name: "number_of_questions",
	},
	{
		title: "start_date",
		name: "start_date_at",
		isSort: true,
	},
	
	{
		title: "due_date",
		name: "finish_date_at",
		isSort: true,
	},
]