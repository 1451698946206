import Typography from "../../UIKit/base/Typography"
import styles from "./RangeInput.module.sass"

function currentSize(min, max, value) {
	const mx = +max
	let v = +value
	if (!v) {
		v = +min
	}
	return Math.abs((v * 100) / mx)
}

export default function RangeInput({
	value,
	max,
	min,
	step,
	onChange,
	...props
}) {
	return (
		<Typography
			{...props}
			className={styles.range}
			component="input"
			fontVariant="medium"
			type="range"
			min={min}
			max={max}
			step={step}
			value={value}
			onChange={onChange}
			style={{ backgroundSize: `${currentSize(min, max, value)}% 100%` }}
		/>
	)
}
