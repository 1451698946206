import { api } from "../http";

export async function getAllNotificationsRequest({type, page}) {
    const url = `/v1/notifications/${type}?page=${page}`
    return await api.get(url)
}

export async function getLastNotificationsRequest() {
    const url = `/v1/notifications/last`
    return await api.get(url)
}

export async function sendNotificationsViewedRequest(data) {
    const url = `/v1/notifications/set-viewed`
    return await api.patch(url, data)
}
 