import { handleActions, createAction } from "redux-actions"
import { all, call, take, put } from "redux-saga/effects"
import { getProductsRequest, getProductRequest } from "../../api/products/products"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"
import { dangerMessage } from "../../components/UIKit/alert/alertReducer"
import { setCourse } from './course'
import { getCertificates } from "./certificates"

const namespace = "products"

const initialProduct = {
	product: null,
	modules: null,
	speakers: null,
	reviews: null,
	error: null,
}

const initialState = {
	products: [],
	product: initialProduct
}

const GET_PRODUCTS = `${namespace}/GET_PRODUCTS`
const SET_PRODUCTS = `${namespace}/SET_PRODUCTS`

const GET_PRODUCT = `${namespace}/GET_PRODUCT`
const SET_PRODUCT = `${namespace}/SET_PRODUCT`

const RESET_PRODUCT = `${namespace}/RESET_PRODUCT`

export const getProducts = createAction(GET_PRODUCTS)
export const setProducts = createAction(SET_PRODUCTS)

export const getProduct = createAction(GET_PRODUCT)
export const setProduct = createAction(SET_PRODUCT)

export const resetProduct = createAction(RESET_PRODUCT)

export default handleActions(
	{
		[SET_PRODUCTS]: (state, { payload }) => ({...state, products: payload}),
		[SET_PRODUCT]: (state, { payload }) => ({...state, product: payload}),
		[RESET_PRODUCT]: (state, { payload }) => ({...state, product: initialProduct}),
	},
	initialState
)

export const productsSelector = (state) => state[namespace].products
export const productSelector = (state) => state[namespace].product

function* getProductsSaga() {
	while (true) {
		const { payload } = yield take(GET_PRODUCTS)
		yield put(addGlobalLoadItem(GET_PRODUCTS))
		try {
			const { data } = yield call(getProductsRequest, payload)
			yield put(setProducts(data?.project_products))
		} catch (err) {
			console.log(err) 
		} finally {
			yield put(removeGlobalLoadItem(GET_PRODUCTS))
		}
	}
}

function* getProductSaga() {
	while (true) {
		const { payload } = yield take(GET_PRODUCT)
		if(!payload.hidePreloader){
			yield put(addGlobalLoadItem(GET_PRODUCT))
		}
		try {
			const { data } = yield call(getProductRequest, payload)
			yield put(setProduct(data))
			if(data?.product?.type === "course") yield put(setCourse(data))

			if (data?.action_button?.button_type === "certificate") {
				yield put(getCertificates())
			}

		} catch (err) {
			console.log(err) 
		} finally {
			if(!payload.hidePreloader){
				yield put(removeGlobalLoadItem(GET_PRODUCT))
			}
		}
	}
}

export function* productsSagas() {
	yield all([getProductsSaga(), getProductSaga()])
}
