import { useField } from "formik"
import DatePicker from "../../UIKit/date-picker/DatePicker"
import { isBefore, isSameDay, isAfter } from "date-fns"
import { useMessage } from "../../UIKit/alert"
import { useTranslation } from "react-i18next"

export default function DatePickerControl({ name, beforeAllowed,  afterAllowed = true, ...props }) {
	const { t } = useTranslation()
	const [{ value }, _, { setValue }] = useField(name)
	const message = useMessage()
	function handleChange({ value }) {
		if(!afterAllowed &&  isAfter(new Date(value), new Date())){
			return message.danger(t("this_date_is_incorrect"))
		}
		if(beforeAllowed) {
			return setValue(value)
		}
		if (
			!isBefore(new Date(value), new Date()) ||
			isSameDay(new Date(value), new Date())
		) {
			return setValue(value)
		}
		return message.danger(t("this_date_is_incorrect"))
	}
	return (
		<DatePicker beforeAllowed={beforeAllowed} value={value} {...props} onChange={handleChange} name={name} monthChange={true} yearChange={true} />
	)
}
