import classNames from "classnames";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../ActionButton";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import MobileBreadcrumbs from "../Mobile/MobileBreadcrumbs";
import MobileTitle from "../Mobile/MobileTitle";
import RequestAccessButton from "../RequestAccessButton";
import Typography from "../UIKit/base/Typography";
import CancelButton from "../UIKit/buttons/CancelButton/CancelButton";
import Modal from "../UIKit/modal";
import styles from "./MobileHeader.module.sass";
import { CardSmall } from "./Product";
import { formatEndings } from "../utils";
import i18next from "i18next";

const MobileHeader = ({
  courseTitle,
  product,
  isTablet,
}) => {
  const { t } = useTranslation();

  const [showModules, setShowModules] = useState(false);

  const [isBought, setIsBought] = useState(true);
  const [isRequested, setIsRequested] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [startSoon, setStartSoon] = useState(false);

  useEffect(() => {
    setIsBought(product?.bought);
    setIsRequested(!!product?.request_status);
    setIsAvailable(product?.available);
    setStartSoon(!!product?.start_days_left);
  }, [product]);

  const handleOpenModules = () => {
    setShowModules(true);
  };

  const handleCloseModules = () => {
    setShowModules(false);
  };

  return (
    <>
      <div className={styles.mobileHeader}>
        {!isTablet && <MobileTitle title={courseTitle} />}

        {!isTablet && (
          <div className={styles.mobileHeader__tabletBreadcrumbs}>
            <Breadcrumbs />
          </div>
        )}

        <div
          className={classNames(styles.mobileHeader__menu, {
            [styles.mobileHeader__tabletMenu]: isTablet,
          })}
        >
          <MobileBreadcrumbs title="courses" />
          <div className={styles.mobileHeader__menuRight}>
            <div className={styles.mobileHeader__buttonTeacher}>
              {isBought && isAvailable && <ActionButton isMobile={true} />}

              {!isBought && !isAvailable && !isRequested && !startSoon && (
                <RequestAccessButton
                  setIsBought={setIsBought}
                  isMobile={true}
                />
              )}
              {!isBought &&
                !isAvailable &&
                isRequested &&
                !startSoon &&
                product?.request_status === "requested" && (
                  <Typography
                    className={classNames(
                      styles.courseInfo__blockTitle,
                      styles.courseInfo__requestSend
                    )}
                  >
                    {t("request_sent")}
                  </Typography>
                )}
              {!isBought &&
                !isAvailable &&
                isRequested &&
                !startSoon &&
                product?.request_status === "declined" && (
                  <RequestAccessButton setIsBought={setIsBought} />
                )}

              {isBought && !isAvailable && startSoon && (
                <Typography
                  className={classNames(
                    styles.courseInfo__blockTitle,
                    styles.courseInfo__startSoon
                  )}
                  fontVariant="semiBold"
                >
                  {formatEndings(
                    product?.start_days_left,
                    "day",
                    i18next.language
                  )}{" "}
                  {t("till_start")}
                </Typography>
              )}
            </div>
            <div
              className={styles.mobileHeader__buttonInfo}
              onClick={handleOpenModules}
            >
              {t("details")}
            </div>
          </div>
        </div>

        <Modal
          isOpen={showModules}
          className={styles.modal}
          onClose={handleCloseModules}
        >
          {(close) => (
            <div className={styles.modal__inner}>
              <div className={styles.modal__header}>
                <Typography component="span" fontVariant="">
                  {t("course_details")}
                </Typography>
                <CancelButton
                  className={styles.mobileHeader__cancel}
                  onClick={handleCloseModules}
                />
              </div>

              <CardSmall />
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default MobileHeader;
