import classNames from "classnames"
import { cloneElement, useState } from "react"
import { useTranslation } from "react-i18next"
import Modal from ".."
import Typography from "../../base/Typography"
import CancelButton from "../../buttons/CancelButton/CancelButton"
import styles from "./ConfirmModal.module.sass"

export default function ConfirmModal({
	outsideState = false,
	children = null,
	disabled = false,
	label,
	description,
	submitLabel,
	onSubmit,
	outsideModal,
	setOutsideModal,
	confirmType,
	callback,
}) {
	const { t } = useTranslation()
	const [modal, setModal] = useState(false)
	function handleCloseModal(e) {
		if (outsideState) {
			return setOutsideModal(false)
		}
		return setModal(false)
	}

	function handleOutside() {
		if (!disabled) {
			setOutsideModal(true)
		}
	}
	function handleOpen() {
		if (!disabled) {
			setModal(true)
		}
	}

	return (
		<>
			{children &&
				(outsideState
					? cloneElement(children, { onClick: handleOutside })
					: cloneElement(children, { onClick: handleOpen }))}
			<Modal
				className={styles.modal}
				onClose={handleCloseModal}
				isOpen={outsideModal || modal}
				submit={onSubmit}
				callback={callback}
			>
				{(close, submit) => (
					<>
						<div className={styles.modal__header}>
							<Typography component="span">{t(label)}</Typography>
							<CancelButton className={styles.cancel} onClick={close} />
						</div>
						<Typography component="p" className={styles.modal__content}>
							{description}
						</Typography>
						<div className={styles.modal__buttons}>
							<Typography
								component="button"
								fontVariant="semiBold"
								type="button"
								onClick={close}
								className={styles.button}
							>
								{t("cancel")}
							</Typography>
							<Typography
								component="button"
								fontVariant="semiBold"
								type="button"
								className={classNames(styles.button, {
									[styles.button_confirm]: confirmType === "confirm",
								})}
								onClick={(e) => {
									e.stopPropagation()
									submit()
								}}
							>
								{t(submitLabel)}
							</Typography>
						</div>
					</>
				)}
			</Modal>
		</>
	)
}
