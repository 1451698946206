import { createAction, handleActions } from "redux-actions"
import {
	all,
	call,
	delay,
	put,
	race,
	select,
	take,
	takeLatest,
	takeLeading,
} from "redux-saga/effects"
import {
	getHistoryRequest,
	searchAIRequest,
	searchRequest,
} from "../../api/assistant/assistant"
import { v4 as uuidv4 } from "uuid"

const namespace = "assistant"

const SEARCH_DOCUMENTS = `${namespace}/GET_DOCUMENTS`
const SET_DOCUMENTS = `${namespace}/SET_DOCUMENTS`

const GET_HISTORY = `${namespace}/GET_HISTORY`
const GET_LAZY_HISTORY = `${namespace}/GET_LAZY_HISTORY`
const SET_HISTORY = `${namespace}/SET_HISTORY`

const SEARCH = `${namespace}/SEARCH`

const SET_LOAD = `${namespace}/SET_LOAD`
const RESET_LOAD = `${namespace}/RESET_LOAD`
const SET_REQUEST = `${namespace}/SET_REQUEST`
const SET_MESSAGES = `${namespace}/SET_MESSAGES`
const SET_LAST_ID = `${namespace}/SET_LAST_ID`
const SET_ORIGIN_DATA = `${namespace}/SET_ORIGIN_DATA`
const RESET_STATE = `${namespace}/RESET_STATE`

const initialState = {
	load: 0,
	lastId: 0,
	history: null,
	documents: null,
	messages: null,
	originData: [],
}

export const searchDocuments = createAction(SEARCH_DOCUMENTS)
export const setDocuments = createAction(SET_DOCUMENTS)

export const getHistory = createAction(GET_HISTORY)
export const getLazyHistory = createAction(GET_LAZY_HISTORY)
export const setHistory = createAction(SET_HISTORY)

export const searchAi = createAction(SEARCH)

export const setLoad = createAction(SET_LOAD)
export const resetLoad = createAction(RESET_LOAD)

export const setRequest = createAction(SET_REQUEST)

export const setMessages = createAction(SET_MESSAGES)
export const setOriginData = createAction(SET_ORIGIN_DATA)
export const resetState = createAction(RESET_STATE)

export default handleActions(
	{
		[SET_HISTORY]: (state, { payload }) => ({ ...state, history: payload }),
		[SET_DOCUMENTS]: (state, { payload }) => ({ ...state, documents: payload }),
		[SET_LOAD]: (state) => ({ ...state, load: state.load + 1 }),
		[RESET_LOAD]: (state) => ({ ...state, load: state.load - 1 }),
		[SET_MESSAGES]: (state, { payload }) => ({ ...state, messages: payload }),
		[SET_LAST_ID]: (state, { payload }) => ({ ...state, lastId: payload }),
		[SET_ORIGIN_DATA]: (state, { payload }) => ({
			...state,
			originData: payload,
		}),
		[RESET_STATE]: () => initialState,
	},
	initialState
)

export const assistantDocumentsSelector = (state) => state[namespace]?.documents
export const assistantHistorySelector = (state) => state[namespace]?.history
export const assistantLoadSelector = (state) => state[namespace]?.load
export const assistantMessagesSelector = (state) => state[namespace]?.messages
export const assistantLastMessageIdSelector = (state) =>
	state[namespace]?.lastId
export const originDataSelector = (state) => state[namespace].originData

function* searchDocumentsSaga({ payload }) {
	yield delay(1000)
	if (!payload?.search?.length) {
		yield put(setDocuments(null))
	}
	if (payload?.search?.length >= 3) {
		yield call(searchSaga, payload)
	}
}

function* searchSaga(payload) {
	const { cb, ...other } = payload
	try {
		const { response } = yield race({
			response: call(searchRequest, other),
			cancel: take(SET_REQUEST),
		})
		const data = response?.data
		if (data) {
			yield put(setDocuments(data?.documents))
		}

		if (typeof cb === "function") {
			cb()
		}
	} catch (err) {
		console.log(err)
	}
}

function* prepareData(data) {
	if (!data?.length) {
		return null
	}
	const messages = {}
	let lastId = data[data?.length - 1]?.id
	const originData = yield select(originDataSelector)
	const newOriginData = [...data, ...originData]

	const history = newOriginData.reduce((acc, d, i) => {
		const { date, ...other } = d
		if (!acc[date]) {
			acc[date] = []
		}
		acc[date] = [...acc[date], other?.id]

		messages[other?.id] = other
		return acc
	}, {})
	return { history, messages, lastId, originData: newOriginData }
}

function* getHistorySaga({ payload }) {
	try {
		const { data } = yield call(getHistoryRequest, payload)
		const { history, messages, lastId, originData } = yield call(
			prepareData,
			data?.messages?.data
		)
		yield put(setHistory({ ...data?.messages, data: history }))
		yield put(setMessages(messages))
		yield put(setOriginData(originData))
		yield put({ type: SET_LAST_ID, payload: lastId })
	} catch (err) {
		console.log(err)
	}
}
function* getLazyHistorySaga({ payload: { setLoad, ...other } }) {
	setLoad(true)
	try {
		const { data } = yield call(getHistoryRequest, other)
		const { history, messages, lastId, originData } = yield call(
			prepareData,
			data?.messages?.data
		)

		yield put(setHistory({ ...data?.messages, data: history }))
		yield put(setMessages(messages))
		yield put(setOriginData(originData))
		yield put({ type: SET_LAST_ID, payload: lastId })
	} catch (err) {
		console.log(err)
	} finally {
		setLoad(false)
	}
}

function* setRequestSaga() {
	while (true) {
		const {
			payload: { cb, message, options },
		} = yield take(SET_REQUEST)
		const history = yield select(assistantHistorySelector)
		const messages = yield select(assistantMessagesSelector)
		const data = Object.keys(history?.data)
		if (!data?.length) return
		const now = data[data?.length - 1]

		const id = uuidv4()

		const obj = {
			id,
			request: {
				message,
			},
		}
		const newHistory = {
			...history,
			data: { ...history?.data, [now]: [...history?.data[now], id] },
		}
		const newMessages = { ...messages, [id]: obj }
		yield put(setHistory(newHistory))
		yield put(setMessages(newMessages))
		yield put(setDocuments(null))
		yield put(searchAi({ message, messageId: id, options }))
		cb()
	}
}

function* searchAISaga() {
	while (true) {
		const {
			payload: { message, messageId, options },
		} = yield take(SEARCH)
		yield put(setLoad())
		const messages = yield select(assistantMessagesSelector)
		const originData = yield select(originDataSelector)
		try {
			const { data } = yield call(searchAIRequest, { message, options })
			const date = data?.last_message?.response?.created_at
				? data?.last_message?.response?.created_at.split("T")[0]
				: null
			const messageObj = {
				...messages[messageId],
				response: data?.last_message?.response,
			}
			const newMessages = {
				...messages,
				[messageId]: messageObj,
			}
			yield put(setMessages(newMessages))
			yield put(setOriginData([...originData, { ...messageObj, date }]))
		} catch (err) {
			if (err?.response?.data?.last_message) {
				const date = err?.response?.data?.last_message?.response?.created_at
					? err?.response?.data?.last_message?.response?.created_at.split(
							"T"
					  )[0]
					: null
				const messageObj = {
					...messages[messageId],
					response: err?.response?.data?.last_message?.response,
				}
				const newMessages = {
					...messages,
					[messageId]: messageObj,
				}
				yield put(setMessages(newMessages))
				yield put(setOriginData([...originData, { ...messageObj, date }]))
			}
			console.log(err)
		} finally {
			// yield put(getHistory())
			yield put({ type: SET_LAST_ID, payload: messageId })
			yield put(resetLoad())
		}
	}
}

export function* assistantSagas() {
	yield all([
		takeLatest(SEARCH_DOCUMENTS, searchDocumentsSaga),
		takeLeading(GET_HISTORY, getHistorySaga),
		takeLeading(GET_LAZY_HISTORY, getLazyHistorySaga),
		searchAISaga(),
		setRequestSaga(),
	])
}
