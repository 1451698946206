import { handleActions, createAction } from "redux-actions";
import { all, call, take, put, select, takeEvery } from "redux-saga/effects";
import {
  getReviewsStatisticRequest,
  getReviewsRequest,
  sendReviewRequest,
} from "../../api/reviews/reviews";
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader";
import {
  successMessage,
  dangerMessage,
} from "../../components/UIKit/alert/alertReducer";

const namespace = "reviews";

const initialState = {
  reviews: [],
  statistic: [],
};

const GET_REVIEWS = `${namespace}/GET_REVIEWS`;
const SET_REVIEWS = `${namespace}/SET_REVIEWS`;

const GET_STATISTIC = `${namespace}/GET_STATISTIC`;
const SET_STATISTIC = `${namespace}/SET_STATISTIC`;

const SEND_REVIEW = `${namespace}/SEND_REVIEW`;

export const getReviews = createAction(GET_REVIEWS);
export const setReviews = createAction(SET_REVIEWS);

export const getStatistic = createAction(GET_STATISTIC);
export const setStatistic = createAction(SET_STATISTIC);

export const sendReview = createAction(SEND_REVIEW);

export default handleActions(
  {
    [SET_REVIEWS]: (state, { payload }) => ({ ...state, reviews: payload }),
    [SET_STATISTIC]: (state, { payload }) => ({ ...state, statistic: payload }),
  },
  initialState
);

export const reviewsSelector = (state) => state[namespace].reviews;
export const statisticSelector = (state) => state[namespace].statistic;

function* getReviewsSaga() {
  while (true) {
    const { payload } = yield take(GET_REVIEWS);
    yield put(addGlobalLoadItem(GET_REVIEWS));
    try {
      const { data } = yield call(getReviewsRequest, payload);

      const prevReviewsData = yield select(reviewsSelector);

      if (payload.page !== 1) {
        data.reviews.items = prevReviewsData.reviews.items.concat(
          data.reviews.items
        );
      }

      yield put(setReviews(data));
    } catch (err) {
      console.log(err); 
    } finally {
      yield put(removeGlobalLoadItem(GET_REVIEWS));
    }
  }
}

function* getReviewsStatisticSaga() {
  while (true) {
    const { payload } = yield take(GET_STATISTIC);
    yield put(addGlobalLoadItem(GET_STATISTIC));
    try {
      const { data } = yield call(getReviewsStatisticRequest, payload);

      yield put(setStatistic(data));
    } catch (err) {
      console.log(err); 
    } finally {
      yield put(removeGlobalLoadItem(GET_STATISTIC));
    }
  }
}

function* sendReviewSaga({ payload }) {
  try {
    const { data } = yield call(sendReviewRequest, payload);

    yield put(getReviews({ product_id: payload.product_id, page: 1 }));
    yield put(successMessage(data.message));

    if (payload.handleNavigate) {
      payload.handleNavigate();
    }
  } catch (err) {
    console.log(err); 
  }
}

export function* reviewsSagas() {
  yield all([
    getReviewsSaga(),
    getReviewsStatisticSaga(),
    takeEvery(SEND_REVIEW, sendReviewSaga),
  ]);
}
