import { useState } from "react";
import Calendar from "../../UIKit/calendar/Calendar";
import styles from "./Calendar.module.sass";

const DashboardCalendar = ({ calendar }) => {
  const [showModal, setShowModal] = useState(true);
  const [events, setEvents] = useState(null);
  const [date, setDate] = useState(null);

  const name = "calendar";
  const value = new Date();
  const startDate = new Date();

  const hadleDateSelect = (data) => {
    const dateString = formatDate(data?.value);

    const currEvents = calendar[dateString]; 

    setEvents(currEvents);
    setDate(data?.value);
    setShowModal(true);
  };
  return (
    <div className={styles.calendar}>
      <Calendar
        value={value}
        onChange={hadleDateSelect}
        name={name}
        chooseDate={startDate}
        calendarData={calendar}
        monthChange={true}
        yearChange={true}
        beforeAllowed={true}
      />
    </div>
  );
};

export default DashboardCalendar;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const newDate = `${year}-${month}-${day}`;

  return newDate;
};
