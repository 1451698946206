import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { reviewsSelector } from "../../store/reducers/reviews";
import AvatarView from "../UIKit/avatarView/AvatarView";
import Typography from "../UIKit/base/Typography";
import ReviewRating from "./ReviewRating";
import ReviewSend from "./ReviewSend";
import Reviews from "./Reviews";
import styles from "./Reviews.module.sass";

import CancelButton from "../UIKit/buttons/CancelButton/CancelButton";
import Modal from "../UIKit/modal";

const ReviewsSidebar = ({ title, showReviewSend, state }) => {
  const [step, setStep] = useState(0);
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const { t } = useTranslation();

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 20;

  const { productId } = useParams();

  const reviewsData = useSelector(reviewsSelector);
  const reviews = reviewsData?.reviews?.items;

  function handleClick({ target: { value } }) {
    setStep(+value);
  }

  const count = reviews?.length <= 3 ? reviews?.length : 3;
  const current = reviews?.length ? reviews[step] : 0;

  const handleCloseReviewsModal = () => {
    setShowReviewsModal(false);
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe && step !== 2) {
      setStep(+step + 1);
    }

    if (isRightSwipe && step !== 0) {
      setStep(+step - 1);
    }
  };

  return (
    <>
      {!reviews?.length && <NoItem title={title} />}

      {!!reviews?.length && (
        <div className={styles.reviewsSidebar}>
          <div className={styles.reviewsSidebar__header}>
            <Typography
              fontVariant="medium"
              className={styles.reviewsSidebar__blockTitle}
            >
              {t("reviews")}
            </Typography>
            <Typography
              component={Link}
              className={styles.reviewsSidebar__link}
              to={`/products/${productId}/reviews`}
              fontVariant="semiBold"
              state={state}
            >
              {t("view_all")}
            </Typography>

            <Typography
              component={Link}
              className={styles.reviewsSidebar__openModal}
              to={`/products/${productId}/reviews`}
              fontVariant="semiBold"
            >
              {t("view_all")}
            </Typography>
          </div>

          <div
            className={styles.reviewsSidebar__main}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <div
              className={`${styles.tabLine_header_name} ${styles.tabLine_name}`}
            >
              <div className={styles.tabLine_name_round}>
                <AvatarView
                  name={current.person_name}
                  src={current?.avatar?.big}
                />
              </div>
            </div>

            <div className={styles.reviewsSidebar__data}>
              <div className={styles.reviewsSidebar__title}>
                <Typography
                  fontVariant="medium"
                  className={styles.review__title}
                >
                  {reviews?.length > 0 ? reviews[step].person_name : "-"}
                </Typography>
                <ReviewRating score={reviews[step].rating} />
              </div>

              <Typography fontVariant="" className={styles.review__text}>
                {reviews?.length > 0 ? reviews[step].review : "-"}
              </Typography>
            </div>
          </div>
          {reviews?.length > 1 && (
            <div className={styles.dot__group}>
              {Array.from(new Array(count), (_, i) => (
                <button
                  className={classNames(styles.dot, {
                    [styles.dot_current]: step === i,
                  })}
                  key={i}
                  onClick={handleClick}
                  value={i}
                />
              ))}
            </div>
          )}
        </div>
      )}
      {!!showReviewSend && <ReviewSend />}

      <Modal
        isOpen={showReviewsModal}
        className={styles.modal}
        onClose={handleCloseReviewsModal}
      >
        {(close) => (
          <div className={styles.modal__inner}>
            <div className={styles.modal__header}>
              <Typography component="span" fontVariant="">
                {t("reviews")}
              </Typography>
              <CancelButton
                className={styles.modal__cancel}
                onClick={handleCloseReviewsModal}
              />
            </div>

            <Reviews isMobile={true} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ReviewsSidebar;

function NoItem() {
  const { t } = useTranslation();

  return (
    <div className={styles.courseInfo__reviews}>
      <Typography
        fontVariant="medium"
        className={styles.reviewsSidebar__blockTitle}
      >
        {t("reviews")}
      </Typography>
      <div className={styles.review__main}>
        <Typography fontVariant="medium" className={styles.NoItem}>
          {t("no_reviews")}
        </Typography>
      </div>
    </div>
  );
}
