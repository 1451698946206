import { all, call, put, spawn } from "redux-saga/effects"
import { domainThemeSagas } from "../reducers/domainTheme"
import { authSagas, logout } from "../reducers/auth"
import { userSagas } from "../reducers/user"
import { productsSagas } from "../reducers/products"
import { courseSagas } from "../reducers/course"
import { lessonSagas } from "../reducers/lesson"
import { breadcrumbsSagas } from "../reducers/breadcrumbs"
import { notificationsSagas } from "../reducers/notifications"
import { catalogSagas} from "../reducers/catalog"
import { certificatesSagas } from '../reducers/certificates'
import { reviewsSagas } from '../reducers/reviews'
import { commentsSagas } from '../reducers/comments'
import { knowledgeBaseSagas } from '../reducers/knowledgeBase'
import { surveysSagas } from "../reducers/surveys"
import { departmentSagas } from "../reducers/department"
import {dashboardSagas } from '../reducers/dashboard'
import { alertSagas } from "../../components/UIKit/alert/alertReducer"
import { assistantSagas } from "../reducers/assistant"
import { anonymousSagas } from "../reducers/anonymous"

export default function* sagas() {
	const sagas = [
		alertSagas,
		domainThemeSagas,
		authSagas,
		userSagas,
		productsSagas,
		courseSagas,
		lessonSagas,
		breadcrumbsSagas,
		catalogSagas,
		certificatesSagas,
		notificationsSagas,
		reviewsSagas,
		commentsSagas,
		knowledgeBaseSagas,
		surveysSagas,
		departmentSagas,
		dashboardSagas,
		assistantSagas,
		anonymousSagas
	]

	const retry = sagas.map((saga) => {
		return spawn(function* () {
			while (true) {
				try {
					yield call(saga)
					break
				} catch (error) {
					if(error?.response?.status === 401){
						yield put(logout()) //TODO:
					}
					console.log(error,'EEROR INDEX')
				}
			}
		})
	})

	yield all(retry)
}
