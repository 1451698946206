import { createAction, handleActions } from "redux-actions"
import { all } from "redux-saga/effects"

const namespace = "breadcrumbs"

const SET_BREADCRUMBS = `${namespace}/SET_BREADCRUMBS`
const RESET_BREADCRUMBS = `${namespace}/RESET_BREADCRUMBS`

export const setBreadcrumbs = createAction(SET_BREADCRUMBS)
export const resetBreadcrumbs = createAction(RESET_BREADCRUMBS)

const initialState = []

export default handleActions(
	{
		[SET_BREADCRUMBS]: (state, { payload }) => payload,
		[RESET_BREADCRUMBS]: () => initialState,
	},
	initialState
)

export const breadcrumbsSelector = (state) => state[namespace]

function* createBreadcrumbsSaga() {
	//TODO:
}

export function* breadcrumbsSagas() {
	return yield all([createBreadcrumbsSaga()])
}
