import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom"; 
import Button from "../forms/button/Button";
import styles from "./Finish.module.sass";
import { lessonSelector } from "../../store/reducers/lesson";
import { productSelector } from "../../store/reducers/products";
import { certificatesSelector, getCertificates } from "../../store/reducers/certificates";
import { courseSelector } from "../../store/reducers/course";
 
const FinishButton = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const navigate = useNavigate();

  const course = useSelector(courseSelector);

  const [redirect, setRedirect] = useState(false);

  const dispatch = useDispatch()

  const courseIsCompleted =
    course?.action_button?.button_type === "certificate"; 

    const certificates = useSelector(certificatesSelector);   
    
  const certificate = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return certificates?.find((item) => item?.product_id === +productId) || null;
  }, [certificates, productId]);

  useEffect(() => {
    //dispatch(getCertificates())
  }, [dispatch])

  const lesson = useSelector(lessonSelector)
  const pathId = lesson?.post?.product?.path_id
    
	let location = useLocation()
  const state = location?.state 
  
  useEffect(() => {
    if (redirect) {
      if (courseIsCompleted) {
        if (course?.product?.certificate && certificate) {
          navigate(`/products/${productId}/certificate`, {state: {...state, basicPathId: pathId}});
        } else {
          if (course?.product?.allow_reviews) {
            navigate(`/products/${productId}/review`, {state: {...state,  basicPathId: pathId}});
          } else {
            navigate(`/products/${productId}${state?.pathId ? `?filter[path_id]=${state?.pathId}` : pathId ? `?filter[path_id]=${pathId}` : ""}`, {state});
          }
        }
      }
    }
  }, [redirect, course?.action_button?.button_type, certificate]);

 

  const handleClick = () => {
    setRedirect(true);
  };

 
  return (
    <>
      {courseIsCompleted && (
        <Button
          className={styles.certificateModal__openButton}
          onClick={handleClick}
        >
          finish
        </Button>
      )}
    </>
  );
};

export default FinishButton;
