import { useTranslation } from "react-i18next";
import { NoItemsImage } from "./NoItemsImage";
import style from "./products.module.scss";

const NoItems = () => {
	const { t } = useTranslation();

	return (
		<div className={style.noItems}>
			<NoItemsImage />
			<h3>{t("no_courses")}</h3>
			<p>{t("you_have_no_courses")}</p>
		</div>
	);
};

export default NoItems;
