export default function Heart(props) {
	return (
		<svg
			{...props}
			width="200"
			height="200"
			viewBox="0 0 200 200"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M156.666 82.5833C147.249 82.5833 138.833 87.1666 133.583 94.1666C128.333 87.1666 119.916 82.5833 110.499 82.5833C94.5827 82.5833 81.666 95.5833 81.666 111.583C81.666 117.75 82.666 123.5 84.3327 128.75C92.4993 154.667 117.833 170.25 130.333 174.5C132.083 175.083 134.999 175.083 136.749 174.5C149.249 170.25 174.583 154.75 182.749 128.75C184.499 123.417 185.416 117.75 185.416 111.583C185.499 95.5833 172.583 82.5833 156.666 82.5833Z"
				fill="#6B4DFF"
			/>
			<path
				d="M172.916 69.4967C172.916 71.4134 170.999 72.6634 169.166 72.1634C157.916 69.2467 145.583 71.6634 136.249 78.3301C134.416 79.6634 131.916 79.6634 130.166 78.3301C123.583 73.4967 115.583 70.8301 107.166 70.8301C85.666 70.8301 68.166 88.4134 68.166 110.08C68.166 133.58 79.416 151.163 90.7494 162.913C91.3327 163.497 90.8327 164.497 90.0827 164.163C67.3327 156.413 16.666 124.247 16.666 69.4967C16.666 45.3301 36.0827 25.8301 60.0827 25.8301C74.3327 25.8301 86.916 32.6634 94.8327 43.2467C102.833 32.6634 115.416 25.8301 129.583 25.8301C153.499 25.8301 172.916 45.3301 172.916 69.4967Z"
				fill="#6B4DFF"
			/>
		</svg>
	)
}
