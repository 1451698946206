import styles from "./Mobile.module.sass";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import MobileBreadcrumbs from "./MobileBreadcrumbs";
import MobileTitle from "./MobileTitle";

const MobileHeader = ({ title, breadcrumbsTitle, hideBreadcrumbs }) => {
  return (
    <div className={styles.mobileHeader}>
      <MobileTitle title={title} />
 
        <div className={styles.mobileHeader__tabletBreadcrumbs}>
          <Breadcrumbs />
        </div>
 

        <div className={styles.mobileHeader__menu}>
          <MobileBreadcrumbs title={breadcrumbsTitle} />
        </div>
    </div>
    
  );
};

export default MobileHeader;
