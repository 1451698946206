import { api } from "../http"

export async function getDepartmentDocumentsRequest({departmentId}){
	const url = `/v1/department/${departmentId}/articles`
	return await api.get(url)
}
export async function getDepartmentRequest({departmentId}){
	const url = `/v1/department/${departmentId}`
	return await api.get(url)
}
export async function getPeopleOfDepartmentRequest({departmentId,queryString}){
	let url = `/v1/department/${departmentId}/people`
	if (queryString) {
		url = `${url}?${queryString}`
	}
	return await api.get(url)
}