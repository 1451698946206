import { NightIcon, DayIcon } from "../../icons";
import { setNegate, negateSelector } from "../../../store/reducers/domainTheme";
import { useSelector, useDispatch } from "react-redux";

import style from "./negate.module.sass";
import Typography from "../../UIKit/base/Typography";
import { useTranslation } from "react-i18next";

const NegateChangeTheme = () => {
	const negate = useSelector(negateSelector);
	const dispatch = useDispatch();
	const { t } = useTranslation()

	const negateChangeTheme = () => {
		dispatch(setNegate(!negate));
		localStorage.setItem("negate", !negate);
	};

	return (
		<button className={style.iconButton} onClick={negateChangeTheme}>
			{!negate ? <NightIcon /> : <DayIcon />}
			{window.innerWidth < 769 &&
				<Typography fontVariant="" className={style.iconButton__text}>
					{t(negate ? "light_mode" : "dark_mode")}
				</Typography>
			}	
		</button>
	);
};

export default NegateChangeTheme;
