import JustifyContainer from "../../layouts/JustifyContainer"
import PreloaderCircle from "./PreloaderCircle"
import PreloaderLine from "./PreloaderLine"
import styles from './PreloaderTemplates.module.sass'

const PreloaderBreadcrumbs = () => {
    return (
        <>
    { window.innerWidth <= 768 && <div className={styles.breadcrumbs}>  <PreloaderCircle size={16} /><PreloaderCircle size={16} /> <PreloaderLine  width={64} /> </div>}

    { window.innerWidth > 768 && <div className={styles.breadcrumbs}> <div className={styles.breadcrumbs__inner}> <PreloaderLine  width={240} /></div></div>}
    </>
    )
}
export default PreloaderBreadcrumbs