import { createAction, handleActions } from "redux-actions"
import { all, put, select, take } from "redux-saga/effects"
const namespace = "alert"

const SET_ITEM = `${namespace}/SET_ITEM`
const RESET_ITEM = `${namespace}/RESET_ITEM`
const SET_ERROR_NETWORK_ITEM = `${namespace}/SET_ERROR_NETWORK_ITEM`

export const dangerMessage = createAction(
	SET_ITEM,
	(message, validationError) => {
		return {
			id: Date.now().toString(),
			message,
			validationError,
			type: "danger",
		}
	}
)
export const infoMessage = createAction(SET_ITEM, (message) => ({
	id: Date.now().toString(),
	message,
	type: "info",
}))
export const successMessage = createAction(SET_ITEM, (message) => ({
	id: Date.now().toString(),
	message,
	type: "success",
}))
export const errorNetworkMessage = createAction(
	SET_ERROR_NETWORK_ITEM,
	(message) => {
		return {
			id: Date.now().toString(),
			message,
			type: "errorNetwork",
		}
	}
)

export const resetItem = createAction(RESET_ITEM)

const initialState = []

export default handleActions(
	{
		[SET_ITEM]: (state, { payload }) => [...state, payload],
		[RESET_ITEM]: (state, { payload }) => state.filter((e) => e.id !== payload),
	},
	initialState
)

function* setErrorNetworkItem() {
	while (true) {
		const { payload } = yield take(SET_ERROR_NETWORK_ITEM)
    const selector = yield select(alertSelector)
		if (!selector.some((e) => e?.type === "errorNetwork")) {
			yield put({
				type: SET_ITEM,
				payload,
			})
		}
	}
}

export const alertSelector = (state) => state[namespace]

export function* alertSagas() {
	yield all([setErrorNetworkItem()])
}
