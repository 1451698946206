import {api} from "../http";

export async function getAllCoursesRequest({type}) {
    const url = `/v1/products/catalog?type=${type}`
    return await api.get(url)
}

export async function getAllMembershipRequest() {
    const url = `/v1/project/memberships`
    return await api.get(url)
}