import { useEffect, useState } from "react";
import { useSelector } from "react-redux"; 
import { themeSelector } from "../../../store/reducers/domainTheme";
import { setColorPalette } from "../../utils";

const initialColors = {
  secondaryLightPurple: "#E2E0FF",
};

export const BotsImage = () => {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme.title) {
      const newColors = setColorPalette(theme);
      setColors(newColors);
    }
  }, [theme]);
  return (
    <svg
      width="220"
      height="157"
      viewBox="0 0 220 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17117_4240)">
        <path
          d="M110 0C59.5739 0 18.6951 35.1466 18.6951 78.5006C18.6951 100.839 29.5469 120.997 46.9662 135.292C46.7436 142.108 44.3631 148.854 40.2002 154.264C48.2708 153.943 56.1765 150.729 62.2553 145.426C76.1472 152.767 92.499 157.001 110 157.001C160.426 157.001 201.305 121.855 201.305 78.5006C201.305 35.1466 160.426 0 110 0ZM166.748 116.539C163.383 120.244 158.589 122.327 153.582 122.327H66.4182C61.4117 122.327 56.617 120.244 53.252 116.539C43.724 106.047 38.046 92.8433 38.046 78.4994C38.046 64.1567 43.7228 50.9528 53.2508 40.4607C56.6158 36.7544 61.4105 34.6716 66.4182 34.6716H153.581C158.65 34.6716 163.471 36.8302 166.866 40.5893C176.324 51.0598 181.954 64.2144 181.954 78.4982C181.955 92.8445 176.276 106.048 166.748 116.539Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M14.348 78.5006C14.348 89.2682 16.684 99.7098 21.2981 109.575C8.9708 105.524 0 97.5068 0 78.5006C0 60.4709 8.9708 51.4759 21.2993 47.4258C16.6864 57.2962 14.348 67.7427 14.348 78.5006Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M10.6495 24.1218V47.6556C7.45064 49.7191 4.744 52.2072 2.55606 55.1041C2.5368 55.1305 2.51634 55.1546 2.49829 55.1811V24.1218C2.49829 21.8731 4.32278 20.05 6.5733 20.05C8.82503 20.05 10.6495 21.8731 10.6495 24.1218Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M205.653 78.5006C205.653 89.2682 203.317 99.7098 198.703 109.575C211.03 105.525 220.001 97.508 220.001 78.5006C220.001 60.4709 211.03 51.4759 198.702 47.4258C203.315 57.2962 205.653 67.7427 205.653 78.5006Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M209.35 24.1218V47.6556C212.549 49.7191 215.256 52.2072 217.444 55.1041C217.463 55.1305 217.484 55.1546 217.502 55.1811V24.1218C217.502 21.8731 215.677 20.05 213.427 20.05C211.176 20.05 209.35 21.8731 209.35 24.1218Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M90.5974 76.0654C87.9449 80.1456 84.1178 76.0654 76.1219 76.0654C68.1283 76.0654 64.3 80.1456 61.6475 76.0654C59.0805 72.115 66.163 62.3708 76.1219 62.3708C86.0807 62.3708 93.1645 72.1162 90.5974 76.0654Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M158.353 76.0654C155.7 80.1456 151.873 76.0654 143.877 76.0654C135.883 76.0654 132.055 80.1456 129.403 76.0654C126.836 72.115 133.918 62.3708 143.877 62.3708C153.836 62.3708 160.918 72.1162 158.353 76.0654Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M110 94.6288C104.419 94.6288 100.738 90.2071 100.584 90.0195C99.7499 88.9997 99.9003 87.4965 100.921 86.6632C101.941 85.8298 103.446 85.9801 104.28 86.9999C104.295 87.0179 106.714 89.8583 110 89.8583C113.285 89.8583 115.704 87.0179 115.729 86.9891C116.563 85.9693 118.062 85.8238 119.083 86.6572C120.103 87.4905 120.25 88.9985 119.415 90.0183C119.261 90.2083 115.579 94.6288 110 94.6288Z"
          fill={colors?.secondaryLightPurple}
        />
      </g>
      <defs>
        <clipPath id="clip0_17117_4240">
          <rect width="220" height="157" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
