import React, { PureComponent } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import styles from "./ChartRadar.module.sass";
import Typography from "../UIKit/base/Typography";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import Color from "color";
import { Link } from "react-router-dom";
 

const ChartRadar = ({skills}) => {
  const { t } = useTranslation();
  const theme = useSelector(themeSelector);

  const { primary_color } = theme;
 
 
  const bg_color = theme.negate
    ? Color(theme.bg_color).negate().hex()
    : theme.bg_color;

  const pureWhite = theme.negate ? "#000000" : "#ffffff";
  const text_color = theme.negate
    ? Color(theme.text_color).negate().hex()
    : theme.text_color;
  const secondaryDarkGray = Color(pureWhite)
    .mix(Color(bg_color))
    .mix(Color(text_color), 0.72)
    .hex();
  const secondaryLightPurple = Color(pureWhite)
    .mix(Color(bg_color))
    .mix(Color(primary_color), 0.16)
    .hex();
 

  return (
    <div className={styles.radar}> 
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <RadarChart
          cx="50%"
          cy="50%"
          outerRadius="80%"
          data={skills}
          innerRadius="10%"
        >
          <PolarGrid
            stroke={secondaryLightPurple}
            gridType="circle"
            innerRadius="10%"
            outerRadius="90%"
            polarRadius={[18, 58, 98, 140]}
          />
          <PolarAngleAxis
            tickLine={false}
            dataKey="subject"
            tick={<CustomizedTick color={secondaryDarkGray} skills={skills} />}
          />
          <PolarRadiusAxis  domain={[1, 4]} axisLine={false}  tick={false }/>
          <Tooltip content={<CustomTooltip />} />
          <Radar
            name="Skills"
            dataKey="mark"
            stroke={primary_color}
            fill={primary_color}
            fillOpacity={0.2}
            dot={{
              stroke: primary_color,
              strokeWidth: 1,
              r: 4,
              fill: primary_color,
              fillOpacity: 1,
            }}
          />
        </RadarChart>
      </ResponsiveContainer>{" "}
    </div>
  );
};

export default ChartRadar;

const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();

  const level = payload?.[0]?.payload?.level;
  const title = payload?.[0]?.payload?.title;
  const mark = payload?.[0]?.payload?.mark;

  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <div>
          <Typography className={styles.tooltip__title}>{title}</Typography>
        </div>
        <div className={styles.tooltip__level}>
          <Level level={mark} />
          <Typography className={styles.tooltip__text}>
            {level}
          </Typography>
        </div>
      </div>
    );
  }
  return null;
};

export const Level = ({ level }) => { 

  return (
    <div className={styles.level}>
      {Array(4)
        .fill()
        .map((i, index) => (
          <div className={styles.level__empty} key={index}></div>
        ))}

      <div className={styles.level__filled}>
        {Array(level)
          .fill()
          .map((i, index) => (
            <div className={styles.level__fill} key={index}></div>
          ))}
      </div>
    </div>
  );
};

const CustomizedTick = (props) => {
  const { payload, x, y, textAnchor, skills } = props;

   let title = skills?.find(i => i.subject === payload.value)?.title

 if(title.length > 30) {
  title = title.slice(0, 28) + "..."
 }

  const offsetX = textAnchor === "start" ? 6 : textAnchor === "end" ? -6 : 0;
  const offsetY =
    payload.coordinate === 90 ? -6 : payload.coordinate === -90 ? 6 : 0;
  return (
    <text
      className={styles.tick}
      x={x + offsetX * 2}
      y={y + offsetY}
      textAnchor={textAnchor}
      fill={props.color}
    >
        <tspan x={x + offsetX * 2}>{window?.innerWidth <= 768 ? "": title} </tspan>
    </text>
  );
};
