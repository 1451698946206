import {api} from "../http";

export async function getCertificatesRequest() {
    const url = `/v1/certificates`
    return await api.get(url)
}

export async function generateCertificateRequest({productId}) {
    const url = `/v1/courses/${productId}/certificate`
    return await api.get(url)
}

export async function downloadCertificateRequest({certificateId}) {
    const url = `/v1/certificates/${certificateId}/download`
    return await api.get(url)
}

 