import { useState, useEffect } from "react"
import { blobToBase64 } from "../utils"
import SredaLogo from "./logo.svg"
import mainLogo from "./main-logo.svg"
import { logoSelector } from "../../store/reducers/domainTheme"
import { useSelector } from "react-redux"

const Logo = ({ isHeader }) => {
	const [logo, setLogo] = useState(null)
	const [showDefault, setShowDefault] = useState(true)

	const logoData = useSelector(logoSelector)

	useEffect(() => {
		if (logoData?.status === 404) {
			setShowDefault(true)
		}
		if (logoData?.status === 200) {
			setShowDefault(false)
		}

		const func = async function () {
			const base64logo = await blobToBase64(logoData?.data)
			setLogo(base64logo)
		}

		func()
	}, [logoData])

	const ownerLogo = isHeader ? mainLogo : SredaLogo

	return <img src={showDefault ? ownerLogo : logo} alt="logo" />
}

export default Logo
