import classNames from "classnames"
import { useField } from "formik"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import Typography from "../../../UIKit/base/Typography"
import CancelButton from "../../../UIKit/buttons/CancelButton/CancelButton"
import { AttachmentIcon } from "../../../attachments/Attachments"
import drop from "./Document-upload.png"
import styles from "./file.module.sass"

function prepareFiles(values, files, maxLength) {
	if (files instanceof FileList && files.length) {
		const arr = []
		for (let i = 0; i < files.length; i++) {
			files[i].id = uuidv4()
			arr.push(files[i])
		}
		return [...values, ...arr].slice(0, maxLength)
	}
	return null
}

const FileControl = ({ name, maxLength }) => {
	const [active, setActive] = useState(false)
	const [{ value }, meta, { setValue }] = useField(name)
	const { t } = useTranslation()
	const ref = useRef(null)

	const handleActive = (e) => {
		e.preventDefault()
		setActive((s) => !s)
	}

	const handleDrop = (e) => {
		e.preventDefault()
		const { files } = e.dataTransfer
		const prepare = prepareFiles(value, files, maxLength)
		setActive(false)
		setValue(prepare)
		ref.current.value = ""
	} 
	const handleChange = ({ target }) => {
		const { files } = target
		const prepare = prepareFiles(value, files, maxLength)
		setValue(prepare)
		ref.current.value = ""
	}
	const deleteImages = (e) => {
		e.preventDefault()
		const id = e.currentTarget.id
		setValue(value.filter((e) => e.id !== id))
	}

	return (
		<div className={styles.container}>
			{value?.length > 0 &&
				value.map((e, i) => {
					const type = e.name.split(".").pop()
					return (
						<div className={styles.attachments} key={e.id}>
							<div className={styles.attachments__container}>
								<AttachmentIcon type={type} />
								<Typography
									component="span"
									fontVariant="medium"
									className={styles.attachments__name}
								>
									{e.name}
								</Typography>
							</div>
							<CancelButton
								id={e.id}
								onClick={deleteImages}
								className={styles.attachments__cancel}
							/>
						</div>
					)
				})}
			<div
				className={classNames(styles.wrapper, {
					[styles.wrapper__active]: active,
				})}
				onDragEnter={handleActive}
				onDragLeave={handleActive}
				onDragOver={(e) => e.preventDefault()}
				onDrop={handleDrop}
			>
				<label className={styles.label}>
					<input
						className={styles.input}
						type="file"
						accept=".jpg,.png,.jpeg,.jpe, .txt,.doc,.ppt,.pptx,.docx,.zip,.pdf, .xls, .xlsx, .mp4, .m4v, .mov, .flv, .mpeg, .mpg, .webm, .mkv"
						multiple
						onChange={handleChange}
						name={name}
					/>
					<img src={drop} alt="" />
					<Typography
						fontVariant="medium"
						component="div"
						className={styles.label__description}
					>
						{t("сhoose_file")}
						<br />
						<span>{t("drag_here")}</span>
					</Typography>
				</label>
			</div>
		</div>
	)
} 

export default FileControl
