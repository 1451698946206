import { useTranslation } from "react-i18next"
import Typography from "../../UIKit/base/Typography"
import { BotsImage } from "./BotsImage"
import styles from './Bots.module.sass'
import classNames from "classnames"
import { Link } from "react-router-dom"
import { TelegramIcon, ViberIcon, WhatsappIcon } from "../../icons"

const Bots = ({ bots }) => {
	const { t } = useTranslation() 

    if(!bots || !Object.keys(bots)?.length) {
        return null
    }

	return (
		<div className={styles.bots}> 
        	<div className={styles.bots__image}> 
        <BotsImage />
        <Typography component={`p`}>{t("connect_bot")}</Typography></div>
			<div className={styles.bots__list}> 
				 {Object.entries(bots).map(([key, value]) => (
                    <Bot name={key} link={value} />
                 ))}
			</div>
		</div>
	)
}

export default Bots

const Bot = ({name, link}) => {
    const { t } = useTranslation() 
    return (
        <Link to={link} target="_blank" className={classNames(styles.bot, styles?.[name])}>
            {name === "telegram" && <TelegramIcon />}
            {name === "viber" && <ViberIcon />}
            {name === "whatsapp" && <WhatsappIcon />}
            <Typography>{t(name + "_bot")}</Typography>
        </Link>
    )
}