import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useParams, useSearchParams, useLocation } from "react-router-dom"
import { resetBreadcrumbs, setBreadcrumbs } from "../../store/reducers/breadcrumbs" 
import { lessonSelector } from "../../store/reducers/lesson"
import { getReviews, getStatistic } from "../../store/reducers/reviews"
import { useTranslation } from 'react-i18next' 
import { getProduct, productSelector, resetProduct } from "../../store/reducers/products"
import { globalLoadSelector } from "../../store/reducers/loader"


export default function ProductLayout() {
	const course = useSelector(productSelector)
	const lesson = useSelector(lessonSelector)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const params = useParams()

	const [query] = useSearchParams()
	const queryString = query.toString()

	const loading = useSelector(globalLoadSelector).loading


	let location = useLocation();  
	const crumbs = useMemo(() => {

		const arr = course?.product?.bought ? [{ path: "/products", title: t("my_courses") }] : [{ path: "/catalog", title: t("catalog") }]

		
		if (location.state && location.state?.pathId && course?.product?.type === "course") {
			arr.push({
				path: `/products/${location.state?.pathId}`,
				title: location.state?.pathName,
			})
		}
		
		if (params.productId && course?.product?.name) {
			arr.push({
				path: course?.product?.bought ? `/products/${params.productId}` : `/catalog/${params.productId}`,
				title: course?.product?.name,
			})
		}

		if (params.postId && lesson?.post?.title) {
			arr.push({
				path: course?.product?.bought ? `/products/${params?.productId}/${lesson?.post?.type}/${params?.postId}` : `/catalog/${params?.productId}/${lesson?.post?.type}/${params?.postId}`,
				title: lesson?.post?.title,
			})
		}
		if(location.pathname.split('/').pop() === 'reviews') {
			arr.push({
				path: `/products/${params.productId}/reviews`,
				title: t('reviews'),
			})
		}
		return arr
	}, [params, course, lesson])
 
		useEffect(() => {
			if (params.productId) {
				dispatch(getProduct({productId: params.productId, queryString}))
			}
			return ()=>dispatch(resetProduct())
		}, [params.productId, dispatch, queryString])

	useEffect(() => {
		if (params.productId) {
			dispatch(getReviews({product_id: params.productId, page: 1}))
			dispatch(getStatistic({productId: params.productId}))
		}
	}, [params.productId, dispatch])

	useEffect(() => {
		!loading && dispatch(setBreadcrumbs(crumbs))
		return () => {
			dispatch(resetBreadcrumbs())
		}
	}, [crumbs, dispatch, loading])

	return (
		<Outlet />
	)
}
