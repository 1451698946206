import { useEffect, useRef } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { commentsSelector, getComments } from "../../store/reducers/comments";
import CommentAdd from "./CommentAdd";
import styles from "./Comments.module.sass";
import CommentsList from "./CommentsList";

const Comments = () => {
	const dispatch = useDispatch();
	const { postId } = useParams();

	const commentsData = useSelector(commentsSelector);
	const comments = commentsData?.comments?.items;

	const containerRef = useRef();

	useEffect(() => {
		dispatch(getComments({ postId, page: 1 }));
	}, [postId, dispatch]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [commentsData, dispatch]);

	const handleScroll = (event) => {
		if (
			commentsData?.comments?.has_more_pages &&
			window.innerHeight + window.scrollY > containerRef.current.scrollHeight
		) {
			const pageNumber = commentsData.comments.next_page_url.split("page=")[1];
			dispatch(getComments({ postId, page: pageNumber }));
		}
	};

	return (
		<div ref={containerRef} className={styles.comments}>
			<CommentAdd />
			{!!comments?.length && <CommentsList comments={comments} />}
		</div>
	);
};

export default Comments;
