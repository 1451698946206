import classNames from "classnames";
import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder";
import PreloaderButton from "../PreloaderTemplates/PreloaderButton";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import styles from "./Preloaders.module.sass";
import PreloaderMobileTitle from "./PreloaderMobileTitle";

const PreloaderUser = () => {
  return (
    <> 
    <PreloaderMobileTitle />
    <div className={styles.user}>
      <div className={styles.list__tabs}>
        <div className={classNames(styles.list__buttons, styles.user__buttons)}>
          <PreloaderRect width={360} height={48} />
        </div>
        <div className={styles.list__buttons}>
          <PreloaderButton />
        </div>
      </div>
      <div className={styles.user__items}>
        <PreloaderUserItem />
        <PreloaderUserItem />
        <PreloaderUserItem className={styles.user__desktop} />
        <PreloaderUserItem className={styles.user__tablet} />
      </div>
    </div>
    </>
  );
};

const PreloaderUserItem = ({ className }) => {
  return (
    <div className={classNames({ [className]: !!className })}>
      <PreloaderCard padding={"24px"}>
        <div className={styles.user__header}>
          <PreloaderSquare size={24} />
          <PreloaderLine width={120} />
        </div>
        <PreloaderBorder />
        <div className={styles.user__data}>
          {" "}
          <PreloaderLine width={100} />{" "}
          <PreloaderRect width={"100%"} height={32} />
        </div>
        <div className={styles.user__data}>
          {" "}
          <PreloaderLine width={100} />{" "}
          <PreloaderRect width={"100%"} height={32} />
        </div>
        <div className={styles.user__data}>
          {" "}
          <PreloaderLine width={100} />{" "}
          <PreloaderRect width={"100%"} height={32} />
        </div>
      </PreloaderCard>
    </div>
  );
};

export default PreloaderUser;
