import { useSelector } from "react-redux";
import {
	reviewsSelector,
} from "../../store/reducers/reviews";
import ReviewsListItem from "./ReviewsListItem";

import styles from "./Reviews.module.sass";

const ReviewsList = ({isMobile}) => {

	const reviewsData = useSelector(reviewsSelector);
	const reviews = reviewsData?.reviews?.items;

	return (
		<>
			{!!reviews?.length && (
				<div className={styles.reviewsList}>
					{reviews.map((item) => (
						<ReviewsListItem key={item.created_at} reviewData={item} isMobile={isMobile}/>
					))}
				</div>
			)}
		</>
	);
};

export default ReviewsList;
