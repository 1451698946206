import { handleActions, createAction } from "redux-actions"
import { all, call, take, put } from "redux-saga/effects"
import { getAllCoursesRequest, getAllMembershipRequest } from "../../api/catalog/catalog"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"
import { dangerMessage } from "../../components/UIKit/alert/alertReducer"

const namespace = "catalog"

const initialState = {
	courses: [],
	membership: []
}

const GET_COURSES = `${namespace}/GET_COURSES`
const SET_COURSES = `${namespace}/SET_COURSES`
const GET_MEMBERSHIP = `${namespace}/GET_MEMBERSHIP`
const SET_MEMBERSHIP = `${namespace}/SET_MEMBERSHIP`


export const getCourses = createAction(GET_COURSES)
export const setCourses = createAction(SET_COURSES)
export const getMembership = createAction(GET_MEMBERSHIP)
export const setMembership = createAction(SET_MEMBERSHIP)


export default handleActions(
	{
		[SET_COURSES]: (state, { payload }) => ({ ...state, courses: payload }),
		[SET_MEMBERSHIP]: (state, { payload }) => ({ ...state, membership: payload }),
	},
	initialState
)

export const coursesSelector = (state) => state[namespace].courses
export const membershipSelector = (state) => state[namespace].membership
 
function* getAllCoursesSaga() {
	while (true) {
		const { payload } = yield take(GET_COURSES) 
		yield put(addGlobalLoadItem(GET_COURSES))
		try {
			const { data } = yield call(getAllCoursesRequest, payload)
			yield put(setCourses(data?.products_catalog))
		} catch (err) {
			console.log(err) 
		} finally {
			yield put(removeGlobalLoadItem(GET_COURSES))
		}
	}
}
 
 function* getAllMembershipSaga() {
	while (true) {
		yield take(GET_MEMBERSHIP)
		yield put(addGlobalLoadItem(GET_MEMBERSHIP))
		try {
			const { data } = yield call(getAllMembershipRequest)
			yield put(setMembership(data))
		} catch (err) {
			console.log(err) 
		} finally {
			yield put(removeGlobalLoadItem(GET_MEMBERSHIP))
		}
	}
}


export function* catalogSagas() {
	yield all([getAllCoursesSaga(), getAllMembershipSaga()])
}
