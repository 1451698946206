import React, { useState } from "react"
import styles from "./products.module.sass"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import ProductList from "../ProductList"
import { useSearchParams } from "react-router-dom"
import { createTabs } from "../UIKit/tabs/tabs"
import { getProducts } from "../../store/reducers/products"
import { useTranslation } from "react-i18next"
import useScrollTop from '../hooks/useScrollTop'
import { useSelector } from "react-redux"
import { productsSelector } from "../../store/reducers/products"
import NoItems from '../ProductList/NoItems'
import MobileTitle from '../Mobile/MobileTitle'
import { MenuMobile } from '../layouts/Header/Header'
import Typography from "../UIKit/base/Typography";
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader"
import PreloaderProducts from "../Preloader/Preloaders/PreloaderProducts"


const Products = () => {
	let [searchParams] = useSearchParams()
	const dispatch = useDispatch()
	const type = searchParams.get("type") || ""
	const status = searchParams.get("status") || ""
	const { t } = useTranslation()

	const products = useSelector(productsSelector)

	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])
 
 
	useScrollTop(searchParams);

	useEffect(() => {
		dispatch(getProducts({type, status}))
	}, [searchParams, dispatch, status, type])
 

	function viewTabs() {
		return (
			<>
				<Typography
					className={styles.products__title}
					fontVariant="bold"
					component="h2"
				>
					{t("my_courses")}
				</Typography>
			<div className={styles.line}>
				<div className={styles.tabLine}>
					{createTabs({
						text: t("all"),
						url: `/products`,
						style: {
							color: !type ? "active" : "secondaryDarkGray",
						},
					})}
					{createTabs({
						text: t("courses"),
						url: `/products?type=course`,
						style: {
							color: type === "course" ? "active" : "secondaryDarkGray",
						},
					})}
					{createTabs({
						text: t("paths"),
						url: `/products?type=path`,
						style: {
							color: type === "path" ? "active" : "secondaryDarkGray",
						},
					})}

				
				</div>
			</div>
			</>
		)
	}

	
	if(loading){
		return (<> 
			 <PreloaderProducts />
		</>)
	  }



	return (
		<>
			<MenuMobile />
			<MobileTitle title={t("my_courses")} showBackArrow={false} />
			{viewTabs()}
			{!products?.length && <NoItems />}
			<div className={styles.products}>
				<ProductList products={products} type="products"/>
			</div>
		</>
	)
}

export default Products
