import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import styles from "./Preloaders.module.sass";
import { Substract } from "../PreloaderTemplates/Substract";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import classNames from "classnames";

const PreloaderDashboard = () => {
  return (
    <>
      <PreloaderMobileTitle />
      <div className={styles.dashboard}>
        <div className={styles.dashboard__left}>
          <div className={styles.dashboard__onboarding}>
            <div className={styles.dashboard__onboarding_header}>
              <PreloaderCircle size={80} />
              <div className={styles.dashboard__onboarding_lines}>
                <PreloaderLine width={"100%"} />{" "}
                <PreloaderLine width={"100%"} /> <PreloaderLine width={150} />
              </div>
            </div>
          </div>

          <div className={styles.dashboard__cards}>
            <PreloaderDashboardCard />
            <PreloaderDashboardCard />
            <PreloaderDashboardCard />
            <PreloaderDashboardCard />
          </div>

          <div className={styles.dashboard__calendar}>
            <PreloaderCard padding={"24px"}>
              <div className={styles.dashboard__calendar_header}>
                <PreloaderCircle size={24} />
                <PreloaderRect width={80} height={24} />
                <PreloaderCircle size={24} />
              </div>
              <div className={classNames(styles.dashboard__tablet, styles.dashboard__tablet_margin) }>
              <div className={styles.dashboard__calendar_line}>
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
              </div>
              
              </div>
              <div className={styles.dashboard__calendar_line}>
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
              </div>
              <div className={styles.dashboard__calendar_line}>
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
              </div>
              <div className={styles.dashboard__calendar_line}>
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
              </div>
              <div className={styles.dashboard__calendar_line}>
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
              </div>
              <div className={styles.dashboard__tablet}>
              <div className={styles.dashboard__calendar_line}>
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
                <PreloaderCircle size={24} />
              </div>
              
              </div>
            </PreloaderCard>

            <div className={styles.dashboard__tablet}>
              {" "}
              <PreloaderCard padding={"36px"}>
              <div className={styles.dashboard__tablet_rect}><PreloaderCard padding={"24px"}><PreloaderRect width={"100%"} height={148} /> <PreloaderRect width={"100%"} height={24} /></PreloaderCard>
              </div><div className={styles.dashboard__tablet_lines}> <PreloaderRect width={"100%"} height={40} />
                <PreloaderRect width={"100%"} height={40} /></div>
              </PreloaderCard>
            </div>
          </div>
        </div>

        <div className={styles.dashboard__right}>
          <div className={styles.dashboard__panel}>
            {" "}
            <div className={styles.dashboard__surveys}>
              <PreloaderCard>
                <div className={styles.dashboard__surveys_header}>
                  <PreloaderRect width={200} height={24} />
                  <PreloaderRect width={120} height={24} />
                </div>
                <PreloaderBorder />
                <PreloaderSurvey />
                <PreloaderBorder />
                <PreloaderSurvey />
                <PreloaderBorder />
                <PreloaderSurvey />
              </PreloaderCard>
            </div>
          </div>

          <div className={styles.dashboard__bottom}>
          <div className={styles.dashboard__titles}>
            <PreloaderLine width={80} /> <PreloaderLine width={80} />
          </div>
              <div className={styles.dashboard__list}>
              <PreloaderCard>
                <div className={styles.dashboard__content}>
                  <PreloaderRect width={"100%"} height={130} />
                  <div>
                    <PreloaderLine width={80} />
                    <PreloaderRect width={"100%"} height={24} />
                    <PreloaderRect width={160} height={24} />
                    <PreloaderLine width={100} />
                    <PreloaderLine width={"100%"} />
                  </div>
                </div>
                </PreloaderCard>
                <PreloaderCard>
                <div className={styles.dashboard__content}>
                  <PreloaderRect width={"100%"} height={130} />
                  <div>
                    <PreloaderLine width={80} />
                    <PreloaderRect width={"100%"} height={24} />
                    <PreloaderRect width={160} height={24} />
                    <PreloaderLine width={100} />
                    <PreloaderLine width={"100%"} />
                  </div>
                </div>
                </PreloaderCard>
              </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default PreloaderDashboard;

export const PreloaderDashboardWidget = () => {
  return (
    <div className={styles.dashboard__widget}>
      <PreloaderCard height={260} /> <PreloaderCard height={260} />{" "}
      <PreloaderCard height={260} />
    </div>
  );
};

const PreloaderDashboardCard = () => {
  return (
    <div className={styles.dashboard__card}>
      <PreloaderCard padding={"24px"}>
        <div className={styles.dashboard__header}>
          <div className={styles.dashboard__card_lines}>
            <PreloaderLine width={84} />
            <PreloaderLine width={56} />
          </div>
          <PreloaderCircle size={24} />
        </div>
        <PreloaderCircle size={32} />
      </PreloaderCard>
    </div>
  );
};

const PreloaderSurvey = () => {
  return (
    <div className={styles.dashboard__survey}>
      <div className={styles.dashboard__survey_left}>
        <PreloaderRect width={240} height={24} />
        <div className={styles.dashboard__survey_lines}>
          <PreloaderLine width={80} />
          <PreloaderLine width={80} />
        </div>
      </div>

      <div className={styles.dashboard__survey_right}>
        <PreloaderCircle size={24} />
      </div>
    </div>
  );
};
