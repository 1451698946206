import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { productSelector } from "../../store/reducers/products";
import CertificateModalButton from "../Certificates/CertificateModalButton";
import Typography from "../UIKit/base/Typography";
import style from "./action.module.sass";

const ActionButton = ({ isMobile }) => {
  const { t } = useTranslation();
  const { productId } = useParams();

  const course = useSelector(productSelector);
  const [searchParams] = useSearchParams();
  const pathId = searchParams.get("filter[path_id]");

  const actionButton = course?.action_button;
  const type = actionButton?.button_type;
  const data = actionButton?.additional_data;

  const link =
    course?.product?.type === "course"
      ? `/products/${productId}/${data?.type}/${data?.id}`
      : `/products/${data?.id}?filter[path_id]=${course?.product?.id}`

  let location = useLocation();
  const state =
    course?.product?.type === "course"
      ? location?.state
      : {
          type: "path",
          pathId: course?.product?.id,
          pathName: course?.product?.name,
          isInPath: false,
        };

  return (
    <>
      {actionButton && !isMobile && (
        <>
          {type === "start_learning" && (
            <>
              <Typography
                component={Link}
                className={style.actionButton}
                to={link}
                state={state}
              >
                {t("start_learning")}
              </Typography>
            </>
          )}
          {type === "keep_learning" && (
            <Typography
              component={Link}
              className={style.actionButton}
              to={link}
              state={state}
            >
              {t("keep_learning")}
            </Typography>
          )}
          {type === "certificate" && !!course?.product?.certificate && (
            <>
              <CertificateModalButton />
            </>
          )}
          {!course?.product?.certificate && <p> </p>}
        </>
      )}

      {actionButton && isMobile && (
        <>
          {type === "start_learning" && (
            <>
              <Typography
                component={Link}
                className={style.actionButton}
                to={link}
                state={state}
              >
                {t("start_learning")}
              </Typography>
            </>
          )}
          {type === "keep_learning" && (
            <Typography
              component={Link}
              className={style.actionButton}
              to={link}
              state={state}
            >
              {t("keep_learning")}
            </Typography>
          )}
          {type === "certificate" && !!course?.product?.certificate && (
            <>
              <CertificateModalButton />
            </>
          )}
          {!course?.product?.certificate && <p> </p>}
        </>
      )}
    </>
  );
};

export default ActionButton;
