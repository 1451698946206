import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { sendReview } from "../../store/reducers/reviews";
import { dangerMessage } from "../UIKit/alert/alertReducer";
import Typography from "../UIKit/base/Typography";
import Modal from "../UIKit/modal";
import { createTextArea } from "../UIKit/textArea/textArea";
import Button from "../forms/button/Button";
import { CloseIcon } from "../icons";
import ReviewRatingSet from "./ReviewRatingSet";

import styles from "./Reviews.module.sass";

const ReviewSend = () => {
	const [showModal, setShowModal] = useState(false);

	const [reviewText, setReviewText] = useState("");
	const [reviewRating, setReviewRating] = useState(null); ///0

	const { productId } = useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleChange = (e) => {
		setReviewText(e.target.value);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setReviewText("");
		setReviewRating(null);
	};

	const handleSendReview = () => {

		if(!reviewText || !reviewRating) {
			dispatch(dangerMessage(t("fill_all_fields")))
				return; 
		}

		const data = {
			rating: reviewRating,
			review: reviewText,
		};
		dispatch(sendReview({ product_id: productId, data }));

		setShowModal(false);
		setReviewText("");
		setReviewRating(0);
	};

	return (
		<>
			<Button
				className={styles.reviewSend__openButton}
				onClick={() => setShowModal(true)}
				variant="secondary"
			>
				leave_review
			</Button>

			<Modal
				isOpen={showModal}
				onClose={handleCloseModal}
				className={styles.reviewSend__modal}
			>
				{(close) => (
					<>
						<div className={styles.reviewSend__form}>
							<div className={styles.reviewSend__header}>
								<Typography component="p" className={styles.reviewSend__title}>
									{t("leave_a_review")}
								</Typography>

								<div
									className={styles.reviewSend__close}
									onClick={handleCloseModal}
								>
									<CloseIcon />
								</div>
							</div>

							<div className={styles.reviewSend__rating}>
								<Typography component="p" fontVariant="medium">{t("rate")}</Typography>
								<ReviewRatingSet setReviewRating={setReviewRating} />
							</div>

							<div className={styles.reviewSend__textarea}>
								{createTextArea(
									{
										name: "review",
										label: t("review"),
										helper: "",
										value: reviewText,
										placeholder: t("enter_review"),
										style: {
											mainType: "",
											height: window.innerWidth <= 768 ? 158 : 80,
											margin: {
												bottom: "bottom",
											},
										},
									},
									handleChange
								)}
							</div>

							<div className={styles.reviewSend__buttons}>
								<Button
									className={styles.reviewSend__cancelButton}
									onClick={handleCloseModal}
									variant="tetriary"
								>
									cancel
								</Button>

								<Typography component={Button} onClick={handleSendReview}>
									submit
								</Typography>
							</div>
						</div>
					</>
				)}
			</Modal>
		</>
	);
};

export default ReviewSend;
