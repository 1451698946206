import classNames from "classnames"
import PropTypes from "prop-types"
import Typography from "../../UIKit/base/Typography"
import styles from "./AutoExpandInput.module.sass"
import { useLayoutEffect, useRef } from "react"
export default function AutoExpandInput({
	className = "",
	fontVariant = "medium",
	placeholder = "",
	readOnly = false,
	onChange = () => {},
	value = "",
	initialHeight,
	...props
}) {
	const ref = useRef(null)

	useLayoutEffect(() => {
		if (ref?.current) {
			ref.current.style.height = `${initialHeight}px`
			ref.current.style.height = `${Math.max(
				ref.current.scrollHeight,
				initialHeight
			)}px`
		}
	}, [value, initialHeight, ref])
	return (
		<div className={classNames(styles.wrapper, className)}>
			<Typography
				ref={ref}
				component="textarea"
				className={classNames(styles.input)}
				onChange={onChange}
				value={value}
				fontVariant={fontVariant}
				placeholder={placeholder}
				readOnly={readOnly}
				{...props}
			/>
		</div>
	)
}

AutoExpandInput.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.string,
	fontVariant: PropTypes.string,
	readOnly: PropTypes.bool,
	initialHeight: PropTypes.number.isRequired,
}
