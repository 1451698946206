import { createAction, handleActions } from "redux-actions"
import { all, call, put, select, take } from "redux-saga/effects"
import {
	getDepartmentDocumentsRequest,
	getDepartmentRequest,
	getPeopleOfDepartmentRequest,
} from "../../api/deaprtment/department"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"

const namespace = "department"

const GET_DEPARTMENT = `${namespace}/GET_DEPARTMENT`
const SET_DEPARTMENT = `${namespace}/SET_DEPARTMENT`

const GET_DEPARTMENT_DOCUMENTS = `${namespace}/GET_DEPARTMENT_DOCUMENTS`
const SET_DEPARTMENT_DOCUMENTS = `${namespace}/SET_DEPARTMENT_DOCUMENTS`

const GET_PEOPLE_OF_DEPARTMENT = `${namespace}/GET_PEOPLE_OF_DEPARTMENT`
const SET_PEOPLE_OF_DEPARTMENT = `${namespace}/SET_PEOPLE_OF_DEPARTMENT`

const RESET_DEAPARTMENT = `${namespace}/RESET_DEPARTMENT`

const initialState = {
	people: null,
	documents: null,
	department: null,
}
export const getDepartment = createAction(GET_DEPARTMENT)
export const setDepartment = createAction(SET_DEPARTMENT)

export const getDepartmentDocuments = createAction(GET_DEPARTMENT_DOCUMENTS)
export const setDepartmentDocuments = createAction(SET_DEPARTMENT_DOCUMENTS)

export const getPeopleOfDepartment = createAction(GET_PEOPLE_OF_DEPARTMENT)
export const setPeopleOfDepartment = createAction(SET_PEOPLE_OF_DEPARTMENT)

export const resetDepartment = createAction(RESET_DEAPARTMENT)

export default handleActions(
	{
		[SET_DEPARTMENT_DOCUMENTS]: (state, { payload }) => ({
			...state,
			documents: payload,
		}),
		[SET_PEOPLE_OF_DEPARTMENT]: (state, { payload }) => ({
			...state,
			people: payload,
		}),
		[SET_DEPARTMENT]: (state, { payload }) => ({
			...state,
			department: payload,
		}),
		[RESET_DEAPARTMENT]: () => initialState,
	},
	initialState
)

export const departmentDocumentsSelector = (state) =>
	state[namespace]?.documents
export const peopleOfDepartmentSelector = (state) => state[namespace]?.people
export const departmentSelector = (state) => state[namespace]?.department

function* getDepartmentSaga() {
	while (true) {
		const { payload } = yield take(GET_DEPARTMENT)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(getDepartmentRequest, payload)
			yield put(setDepartment(data?.department?.department || data?.department))
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

function* getDepartmentDocumentsSaga() {
	while (true) {
		const { payload } = yield take(GET_DEPARTMENT_DOCUMENTS)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(getDepartmentDocumentsRequest, payload)
			let doc = data?.data
			if (data?.data?.length) {
				doc = data?.data?.filter((d) => d?.status !== "draft")
			}
			yield put(setDepartmentDocuments({ ...data, data: doc }))
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}
function* getPeopleOfDepartmentSaga() {
	while (true) {
		const {
			payload: { lazy, ...other },
		} = yield take(GET_PEOPLE_OF_DEPARTMENT)
		yield put(addGlobalLoadItem())
		try {
			const { data } = yield call(getPeopleOfDepartmentRequest, other)

			if (lazy) {
				const selectItems = yield select(peopleOfDepartmentSelector)

				const res = [...selectItems?.data, ...data?.data]
				yield put(setPeopleOfDepartment({ data: res, meta: data?.meta }))
			} else {
				yield put(setPeopleOfDepartment(data))
			}
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem())
		}
	}
}

export function* departmentSagas() {
	yield all([
		getDepartmentDocumentsSaga(),
		getDepartmentSaga(),
		getPeopleOfDepartmentSaga(),
	])
}
