import JustifyContainer from "../../layouts/JustifyContainer"; 
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import styles from "./Preloaders.module.sass";

const PreloaderHeaderAuth = () => {
  return (
    <div className={styles.header__wrapper_auth}>
      <JustifyContainer>
        <div className={styles.header}>
          <PreloaderRect width={160} height={48} />
          <div className={styles.header__right}>
            <PreloaderSquare size={48} />
          </div>
        </div>
      </JustifyContainer>
    </div>
  );
};

export default PreloaderHeaderAuth;
