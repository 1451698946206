import React, {
	Children,
	forwardRef,
	useCallback,
	useRef,
	useState,
} from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"

import "swiper/css"
import "./Slider.sass"
import classNames from "classnames"

const Slider = ({ children, initialSlide }) => {
	const childrenToArray = Children.toArray(children)
	const sliderRef = useRef(null)
	const navigationNextRef = useRef(null)
	const navigationPrevRef = useRef(null)
	const [swiper, setSwiper] = useState(0)

	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return
		sliderRef.current.swiper.slidePrev()
	}, [])

	const handleNext = useCallback(() => {
		if (!sliderRef.current) return
		sliderRef.current.swiper.slideNext()
	}, [])

	function handleActive(e) {
		setSwiper(e.realIndex)
	}
	return (
		<div className="Slider__wrapper">
			<Swiper
				initialSlide={initialSlide}
				ref={sliderRef}
				modules={[Navigation]}
				className="mySwiper"
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				onBeforeInit={(swiper) => {
					swiper.navigation.nextEl = navigationNextRef.current
					swiper.navigation.prevEl = navigationPrevRef.current
				}}
				// onActiveIndexChange={handleActive}
			>
				{childrenToArray.map((c, i) => (
					<SwiperSlide  key={c?.id}>{c}</SwiperSlide>
				))}
			</Swiper>
			{childrenToArray?.length > 1 ? (
				<>
					<div
						ref={navigationPrevRef}
						className={classNames("slide-button prew-arrow", {
							// "slide-disabled": swiper <= 0,
						})}
						onClick={handlePrev}
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.53255 15.5001C7.65755 15.6251 7.81588 15.6834 7.97422 15.6834C8.13255 15.6834 8.29922 15.6251 8.41588 15.5001C8.65755 15.2584 8.65755 14.8584 8.41588 14.6168L4.42412 10.625H17.0825C17.4242 10.625 17.7075 10.3417 17.7075 10C17.7075 9.65833 17.4242 9.375 17.0825 9.375H4.42432L8.41588 5.38343C8.65755 5.14176 8.65755 4.74176 8.41588 4.5001C8.17422 4.25843 7.77422 4.25843 7.53255 4.5001L2.47422 9.55843C2.23255 9.8001 2.23255 10.2001 2.47422 10.4418L7.53255 15.5001Z"
								fill="black"
							/>
						</svg>
					</div>
					<div
						ref={navigationNextRef}
						className={classNames("slide-button next-arrow", {
							// "slide-disabled": swiper >= childrenToArray?.length - 1,
						})}
						onClick={handleNext}
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M11.5828 15.5001C11.7078 15.6251 11.8661 15.6834 12.0244 15.6834C12.1828 15.6834 12.3411 15.6251 12.4661 15.5001L17.5244 10.4418C17.7661 10.2001 17.7661 9.8001 17.5244 9.55843L12.4661 4.5001C12.2244 4.25843 11.8244 4.25843 11.5828 4.5001C11.3411 4.74176 11.3411 5.14176 11.5828 5.38343L15.5743 9.375H2.91602C2.57435 9.375 2.29102 9.65833 2.29102 10C2.29102 10.3417 2.57435 10.625 2.91602 10.625H15.5745L11.5828 14.6168C11.3411 14.8584 11.3411 15.2584 11.5828 15.5001Z"
								fill="black"
							/>
						</svg>
					</div>
				</>
			) : null}
		</div>
	)
}

Slider.propTypes = {
	children: PropTypes.node,
	options: PropTypes.object.isRequired,
}

export default Slider
