import { useMemo } from "react"
import { useSelector } from "react-redux"
import { themeSelector } from "../../../store/reducers/domainTheme"
import * as FieldIcons from "./Field"
import * as OutlinedIcons from "./Outlined"
import * as TwotoneIcons from "./TwoTone"

export default function useIconPack() {
	const iconpack =
		useSelector(themeSelector)?.iconpack || "Outlined"

	return useMemo(() => {
		if (iconpack === "default" || iconpack === "Outlined") {
			return OutlinedIcons
		}
		if (iconpack === "Field") {
			return FieldIcons
		}
		if (iconpack === "Twotone") {
			return TwotoneIcons
		}
	}, [iconpack])
}