import Typography from "../base/Typography"

export default function Avatar({
	src,
	name,
	imgClassName,
	textClassName,
	onClick,
	fontVariant="light"
}) {
	if (src) {
		return (
			<img onClick={onClick} className={imgClassName} src={src} alt="avatar" />
		)
	}
	const arr = name?.trim().split(" ")
	const res = arr?.map((e) => e.slice(0, 1).toUpperCase())
	return (
		<Typography
			onClick={onClick}
			fontVariant={fontVariant}
			className={textClassName}
		>
			{res?.join("")}
		</Typography>
	)
}
