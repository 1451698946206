import { useSelector } from "react-redux"
import styles from "./index.module.sass"
import { themeSelector } from "../../../store/reducers/domainTheme"
import JustifyContainer from "../../layouts/JustifyContainer"
import Footer from "../../layouts/Footer/Footer"
import Heart from "./Heart"
import Typography from "../../UIKit/base/Typography"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import classNames from "classnames"
import LogoText from "./LogoText.svg"
import Logo from "../../layouts/Logo.svg"

export default function FinishPage() {
	const theme = useSelector(themeSelector)
	const { t } = useTranslation()
	const img = theme?.logo?.small ? theme?.logo?.small : Logo
	return (
		<div className={styles.wrapper}>
			<header className={classNames(styles.header, styles.header__auth)}>
				<JustifyContainer className={styles.header__container}>
					<Link className={styles.header__logo} to="/products">
						{/* <img src={img} alt="Logo" /> */}
						{!theme?.logo?.small && (
							<span
							className={styles.header__logoText}
						>
							sereda.ai
						</span>
						)}
					</Link>

					{/* <div className={styles.header__right}> */}
					{/* {!hideLang && <LangSwitcher />} */}
					{/*<LangSwitcher />*/}
					{/* </div> */}
				</JustifyContainer>
			</header>
			<JustifyContainer className={styles.app}>
				<div className={styles.content}>
					<Heart />
					<Typography
						component="h2"
						className={styles.title}
						fontVariant="bold"
					>
						{t("thank_you")}
					</Typography>
					<Typography
						component="p"
						className={styles.description}
						fontVariant="medium"
					>
						{t("thank_you_description")}
					</Typography>
				</div>
			</JustifyContainer>

			<Footer
				logo={Logo}
				email={theme?.support_email}
				phone={theme?.support_phone}
				showLogo={theme?.show_logo}
				className={styles.footer}
			/>
		</div>
	)
}
