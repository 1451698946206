import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Comments.module.sass";
import { useTranslation } from "react-i18next";
import { sendComment } from "../../store/reducers/comments";
import { personSelector } from "../../store/reducers/user";
import { useParams } from "react-router-dom";
import AvatarView from "../UIKit/avatarView/AvatarView";
import InputControl from "../forms/input-control";

const CommentAdd = ({
  parent_id,
  setShowAddReply,
  reply,
  isReplyToReply,
  replyId,
  answerPersonName,
}) => {
  const [comment, setComment] = useState("");

  const person = useSelector(personSelector);

  const dispatch = useDispatch();
  const { postId } = useParams();

  const { t } = useTranslation();

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleClick = () => {
    if (!comment) {
      return;
    }

    if (isReplyToReply) {
      const answerPerson = answerPersonName.split(" ").join("");

      dispatch(
        sendComment({
          comment: {
            postId,
            data: {
              content: `@${answerPerson} ${comment}`,
              parent_id: replyId,
            },
          },
          clearInput: () => setComment(""),
        })
      );

      setShowAddReply(false);
    } else {
      dispatch(
        sendComment({
          comment: {
            postId,
            data: { content: comment, parent_id: parent_id ? parent_id : null },
          },
          clearInput: () => setComment(""),
        })
      );
    }
  };

  return (
    <div className={styles.commentAdd}>
      <div className={styles.commentAdd__header}>
        <div className={styles.commentAdd__avatar}>
          <div
            className={`${styles.tabLine_header_name} ${styles.tabLine_name}`}
          >
            <div className={styles.tabLine_name_round}>
              <AvatarView name={person?.name} src={person?.avatar?.big} />
            </div>
          </div>
        </div>
        <div className={styles.commentAdd__text}>
          <InputControl
            type="text"
            value={comment}
            onChange={handleChange}
            placeholder={t("enter_comment")}
          />
        </div>
      </div>

      <div className={styles.commentAdd__footer}>
        {reply && (
          <span
            className={styles.commentAdd__send}
            onClick={() => setShowAddReply(false)}
          >
            {t("cancel")}
          </span>
        )}
        <span className={styles.commentAdd__send} onClick={handleClick}>
          {t(reply ? "save" : "comment")}
        </span>
      </div>
    </div>
  );
};

export default CommentAdd;
