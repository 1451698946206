import style from './tabs.module.sass'
import {Link} from 'react-router-dom';
import React from "react";
import Typography from '../base/Typography';


export function createTabs(data) {
    return (
        <div className={`${style.tab} ${addMargin(data)}`}>
            <div className={`${style.tab_wrap}`} onClick={data.function}>
                {createLeftIcon(data)}
                <Typography fontVariant="medium" component={Link} to={data.url}
                      className={`${style.tab_wrap_text} ${colorFont(data)} ${regulationsStyleButton(data)}`}
                >
                    {data.text}
                </Typography>
            </div>
        </div>
    )
}

function addMargin(data) {
    if (data.style.hasOwnProperty('margin')) {
        return `
            ${data.style.margin.hasOwnProperty('left') && data.style.margin.left === 'left' ? style.marginLeft : ''} 
            ${data.style.margin.hasOwnProperty('right') && data.style.margin.right === 'right' ? style.marginRight : ''} 
            ${data.style.margin.hasOwnProperty('top') && data.style.margin.top === 'top' ? style.marginTop : ''} 
            ${data.style.margin.hasOwnProperty('bottom') && data.style.margin.bottom === 'bottom' ? style.marginBottom : ''}
        `
    }
    return ''
}

function createLeftIcon(data) {
    if (data.style.icon === 'left' || data.style.icon === 'both') {
        return (
            <div className={`${style.iconWrap} ${style.left}`}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 11.75C6.83 11.75 4.25 9.17 4.25 6C4.25 2.83 6.83 0.25 10 0.25C13.17 0.25 15.75 2.83 15.75 6C15.75 9.17 13.17 11.75 10 11.75ZM10 1.75C7.66 1.75 5.75 3.66 5.75 6C5.75 8.34 7.66 10.25 10 10.25C12.34 10.25 14.25 8.34 14.25 6C14.25 3.66 12.34 1.75 10 1.75Z"
                        fill="#5B5766"/>
                    <path
                        d="M18.5901 21.75C18.1801 21.75 17.8401 21.41 17.8401 21C17.8401 17.55 14.3202 14.75 10.0002 14.75C5.68015 14.75 2.16016 17.55 2.16016 21C2.16016 21.41 1.82016 21.75 1.41016 21.75C1.00016 21.75 0.660156 21.41 0.660156 21C0.660156 16.73 4.85015 13.25 10.0002 13.25C15.1502 13.25 19.3401 16.73 19.3401 21C19.3401 21.41 19.0001 21.75 18.5901 21.75Z"
                        fill="#5B5766"/>
                </svg>
            </div>
        )
    }
    return null
}

function regulationsStyleButton(data) {
    if (data.style.color === 'active') return style.activeTab
    return style.activeTabOff
}

function colorFont(data) {
    let tmp = ''
    if (data.style.color === '' || data.style.color === 'secondaryDarkGray') tmp = style.secondaryDarkGray
    if (data.style.color === 'active') tmp = style.primaryBase
    return tmp
}
