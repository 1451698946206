import { api } from "../http"

export async function searchRequest({ search, options }) {
	const url = `/v1/knowledge-base/documents/search?search=${search}`
	return await api.get(url, null, options)
}

export async function getHistoryRequest({ perPage = 5, page = 1 }) {
	const url = `/v1/ai-assistant?per_page=${perPage}&page=${page}`
	return await api.get(url)
}

export async function searchAIRequest({ message, options }) {
	const url = `/v1/ai-assistant/search?ai=true&search=${message}`
	return await api.get(url, null, options)
}
