import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import PreloaderHeaderAuth from "./PreloaderHeaderAuth";
import styles from "./Preloaders.module.sass";

const PreloaderLogin = () => {
  return (
    <>
      <PreloaderHeaderAuth />
      <div className={styles.login}>
        <div className={styles.login__left}>
          <div>
            <PreloaderRect width={456} height={325} backgroundLight={true} />
            <div className={styles.login__lines}>
              <PreloaderRect width={220} height={32} />
              <PreloaderRect width={220} height={32} />{" "}
            </div>
            <div className={styles.login__buttons}>
              <PreloaderSquare size={12} />
              <PreloaderSquare size={12} />
              <PreloaderSquare size={12} />
            </div>
          </div>
        </div>
        <div className={styles.login__right}>
          <PreloaderCard padding={"24px"}>
            <div className={styles.login__title}>
              <PreloaderCircle size={24} />
              <PreloaderLine width={160} />
            </div>
            <PreloaderBorder />
            <PreloaderRect width={408} height={240} backgroundLight={true} />
            <PreloaderRect width={120} height={48} />
          </PreloaderCard>
        </div>
      </div>
    </>
  );
};

export default PreloaderLogin;
