import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { resetPassword } from "../../store/reducers/user";
import Typography from "../UIKit/base/Typography";
import Modal from "../UIKit/modal";
import Button from "../forms/button/Button";
import { PasswordControl } from "../forms/input-control";
import { CloseIconLarge, PasswordResetIcon } from "../icons";
import HeaderAuth from "../layouts/Header/HeaderAuth";
import { PasswordResetImage } from "./Images/PasswordResetImage";
import styles from "./Password.module.sass";

const PasswordReset = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate()

  const [searchParams] = useSearchParams();
  const email = encodeURIComponent(searchParams.get("email"));
  const token = searchParams.get("token");

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("password_required"))
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
        t("password_helper")
      ),
  });

  function handleSubmit(values, {resetForm}) { 
    dispatch(resetPassword({data: {email, token, body: values}, navigate, setShowModal}));
    //setShowModal(true);// if dispatch
    resetForm()
  }

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/project", {replace: true})
  };

  return (
    <>
      <Typography className={styles.login}>
        <div className={styles.main}>
          <div className={styles.left_block}>
            <div className={styles.left_block_wrap}>
              <div className={styles.left_block_wrap_line}>
                <PasswordResetImage />
              </div>
            </div>
          </div>
          <div className={styles.right_block}>
            <div className={styles.loginForm}>
              <div className={styles.loginForm_header}>
                <PasswordResetIcon />
                <Typography>{t("new_password")}</Typography>
              </div>

              <Formik
                initialValues={{
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(props) => {
                  return (
                    <Form>
                      <Field
                        component={PasswordControl}
                        name="password"
                        label={t("password")}
                        placeholder={t("enter_password")}
                        message
                      />

                      <div className={styles.buttons}>
                        <Button className={styles.submit} type="submit">
                          {t("send")}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              <Modal
                isOpen={showModal}
                className={styles.modal}
                onClose={handleCloseModal}
              >
                {(close) => (
                  <>
                    <div
                      className={styles.popUpWrap}
                      onClick={handleCloseModal}
                    >
                      <div className={styles.loginForm}>
                        <div className={styles.loginForm_header_popup}>
                          <div><Typography>{t("password_updating")}</Typography></div>
                          <div className={styles.closeIcon}
                            onClick={handleCloseModal}
                          >
                            <CloseIconLarge />
                          </div>
                        </div>
                        <div className={styles.popUpText}>
                          <Typography>{t("password_changed")}</Typography>
                        </div>
                        <div className={`${styles.buttons} ${styles.right}`}>
                          <Button className={styles.submit} variant="tetriaty">
                            {t("got_it")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </Typography>
    </>
  );
};

export default PasswordReset;
