import i18next from "i18next";
import { createAction, handleActions } from "redux-actions";
import { all, call, put, take } from "redux-saga/effects";
import {
	deleteUserAvatarRequest,
	getFaqRequest,
	getUserRequest,
	resetPasswordRequest,
	sendUserAvatarRequest,
	sendUserRequest,
	setEmailRequest,
	setPasswordRequest,
  forgotPasswordRequest, getSkillsRequest
} from "../../api/user/user";
import { 
  dangerMessage,
	successMessage,
} from "../../components/UIKit/alert/alertReducer";
import { login } from "./auth";
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"; 
import { dispatch } from "../..";

const namespace = "user";

const initialState = {
  error: null,
  person: null,
  faq: null,
  locales: null,
  skills: null,
  menu: null
};

const SET_EMAIL = `${namespace}/SET_EMAIL`;

const GET_USER = `${namespace}/GET_USER`;
const SET_USER = `${namespace}/SET_USER`;
const SEND_USER = `${namespace}/SEND_USER`;
const SEND_PROFILE = `${namespace}/SEND_PROFILE`;
const RESET_USER = `${namespace}/RESET_USER`;

const SEND_AVATAR = `${namespace}/SEND_AVATAR`;
const DELETE_AVATAR = `${namespace}/DELETE_AVATAR`;
const SET_AVATAR = `${namespace}/SET_AVATAR`;

const SET_ERROR = `${namespace}SET_ERROR`;

const GET_FAQ = `${namespace}/GET_FAQ`;
const SET_FAQ = `${namespace}/SET_FAQ`;

const FORGOT_PASSWORD = `${namespace}/FORGOT_PASSWORD`;
const RESET_PASSWORD = `${namespace}/RESET_PASSWORD`;
const SET_PASSWORD = `${namespace}/SET_PASSWORD`;

const SET_LOCALES = `${namespace}/SET_LOCALES`;

const GET_SKILLS = `${namespace}/GET_SKILLS`;
const SET_SKILLS = `${namespace}/SET_SKILLS`;

const GET_MENU = `${namespace}/GET_MENU`;
const SET_MENU = `${namespace}/SET_MENU`;

export const setEmail = createAction(SET_EMAIL);

export const getUser = createAction(GET_USER);
export const setUser = createAction(SET_USER);
export const sendUser = createAction(SEND_USER);
export const resetUser = createAction(RESET_USER);

export const sendProfile = createAction(SEND_PROFILE);

export const sendAvatar = createAction(SEND_AVATAR);
export const deletedAvatar = createAction(DELETE_AVATAR);
export const setAvatar = createAction(SET_AVATAR);

const setError = createAction(SET_ERROR);

export const getFaq = createAction(GET_FAQ);
export const setFaq = createAction(SET_FAQ);

export const forgotPassword = createAction(FORGOT_PASSWORD);
export const resetPassword = createAction(RESET_PASSWORD);
export const setPassword = createAction(SET_PASSWORD);
export const setLocales = createAction(SET_LOCALES);

export const getSkills = createAction(GET_SKILLS);
export const setSkills = createAction(SET_SKILLS);
export const getMenu = createAction(GET_MENU);
export const setMenu = createAction(SET_MENU);
export default handleActions(
  {
    [SET_USER]: (state, { payload }) => ({ ...state, person: payload }),
    [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
    [SET_AVATAR]: (state, { payload }) => ({
      ...state,
      person: { ...state["person"], avatar: payload },
    }),
    [RESET_USER]: () => initialState,
    [SET_FAQ]: (state, { payload }) => ({ ...state, faq: payload }),
    [SET_LOCALES]: (state, { payload }) => ({ ...state, locales: payload }), 
    [SET_SKILLS]: (state, { payload }) => ({ ...state, skills: payload }),
    [SET_MENU]: (state, { payload }) => ({ ...state, menu: payload }),
  },
  initialState
);

export const userSelector = (state) => state[namespace];
export const personSelector = (state) => state[namespace].person || null;
export const faqSelector = (state) => state[namespace].faq;
export const localesSelector = (state) => state[namespace].locales;
export const skillsSelector = (state) => state[namespace].skills;
export const menuSelector = (state) => state[namespace].menu;

function* setEmailSaga() {
  while (true) {
    const { payload } = yield take(SET_EMAIL);
    try {
      const { data } = yield call(setEmailRequest, payload.data);
      yield put(successMessage(data?.message));
      typeof payload.cb === "function" && payload.cb();
    } catch (err) {
      setError(err); 
      console.log(err);
      typeof payload.cb === "function" && payload.cb();
    } finally {
    }
  }
}

function* changeLanguageSaga() {
  while (true) {
    const {
      payload: { language },
    } = yield take(SET_USER);
    if (language) {
      //TODO:
      i18next.changeLanguage(language);
    }
  }
}

function* getUserSaga() {
  while (true) {
    yield take(GET_USER);
    yield put(addGlobalLoadItem(GET_USER));
    try {
      const { data } = yield call(getUserRequest);
      yield put(setUser(data.person));
      yield put(setAvatar(data?.person?.avatar)); 
      yield put(setLocales(data.locales));
      yield put(setMenu(data.menu));
    } catch (err) {
      console.log(err);
    } finally {
      yield put(removeGlobalLoadItem(GET_USER));
    }
  }
}

function* sendUserSaga() {
  while (true) {
    const {
      payload: { values, cb },
    } = yield take(SEND_USER);
    yield put(addGlobalLoadItem(SEND_USER));
    try {
      const {
        data: { person, message, locales },
      } = yield call(sendUserRequest, values);
      yield put(successMessage(message));
      yield put(setUser(person));
      yield put(setLocales(locales));
      if (typeof cb === "function") {
        cb();
      }
    } catch (err) {
      console.log(err); 
    } finally {
      yield put(removeGlobalLoadItem(SEND_USER));
    }
  }
}

function* sendAvatarSaga() {
  while (true) {
    const { payload } = yield take(SEND_AVATAR);
    yield put(addGlobalLoadItem(SEND_AVATAR));
    try {
      const formData = new FormData();
      formData.append("avatar", payload);
      const { data } = yield call(sendUserAvatarRequest, formData);
      yield put(setAvatar(data.avatar));
      yield put(successMessage(data.message));
    } catch (err) {
      console.log(err); 
    } finally {
      yield put(removeGlobalLoadItem(SEND_AVATAR));
    }
  }
}
function* deleteAvatarSaga() {
  while (true) {
    yield take(DELETE_AVATAR);
    yield put(addGlobalLoadItem(DELETE_AVATAR));
    try {
      const { data } = yield call(deleteUserAvatarRequest);
      yield put(successMessage(data.message));
      yield put(setAvatar(null));
    } catch (err) {
      console.log(err); 
    } finally {
      yield put(removeGlobalLoadItem(DELETE_AVATAR));
    }
  }
}

function* getFaqSaga() {
  while (true) {
    yield take(GET_FAQ);
    yield put(addGlobalLoadItem(GET_FAQ));
    try {
      const { data } = yield call(getFaqRequest);
      yield put(setFaq(data));
    } catch (err) {
      console.log(err); 
    } finally {
      yield put(removeGlobalLoadItem(GET_FAQ));
    }
  }
}

function* forgotPasswordSaga() {
  while (true) {
    const { payload } = yield take(FORGOT_PASSWORD);

    try {
      const { data } = yield call(forgotPasswordRequest, payload.data);
      yield call(payload.setShowModal, true);
    } catch (err) {
      setError(err); 
      console.log(err);
      if(err?.response?.status === 429) {
        dispatch(dangerMessage(err?.response?.data?.message))
      }
    } finally {
    }
  }
}

function* setPasswordSaga() {
  while (true) {
    const { payload } = yield take(SET_PASSWORD);

    try {
      const { data } = yield call(setPasswordRequest, payload.data); 
      yield put(
        login({
          values: {
            email: decodeURIComponent(payload?.data?.email),
            password: payload?.data?.body?.password,
            rememberMe: false,
          },
          cb: payload?.navigate,
        })
      );
      yield put(successMessage(data?.message));
    } catch (err) {
      setError(err); 
      console.log(err);
    } finally {
    }
  }
}

function* resetPasswordSaga() {
  while (true) {
    const { payload } = yield take(RESET_PASSWORD);

    try {
      const { data } = yield call(resetPasswordRequest, payload.data);

      yield put(
        login({
          values: {
            email: decodeURIComponent(payload?.data?.email),
            password: payload?.data?.body?.password,
            rememberMe: false,
          },
          cb: () => payload?.navigate("/"),
        })
      );
      yield put(successMessage(data?.message));
    } catch (err) {
      setError(err); 
      console.log(err);
    } finally {
    }
  }
}

function* getSkillsSaga() {
  while (true) {
    yield take(GET_SKILLS);
    yield put(addGlobalLoadItem(GET_SKILLS));
    try {
      const { data } = yield call(getSkillsRequest);
      yield put(setSkills(data));
    } catch (err) {
      console.log(err); 
    } finally {
      yield put(removeGlobalLoadItem(GET_SKILLS));
    }
  }
}


export function* userSagas() {
  yield all([
    setEmailSaga(),
    sendUserSaga(),
    getUserSaga(),
    sendAvatarSaga(),
    deleteAvatarSaga(),
    changeLanguageSaga(),
    getFaqSaga(),
    forgotPasswordSaga(),
    resetPasswordSaga(),
    setPasswordSaga(),
    getSkillsSaga()
  ]);
}
