import PreloaderBorder from "../../PreloaderTemplates/PreloaderBorder";
import PreloaderBreadcrumbs from "../../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderButton from "../../PreloaderTemplates/PreloaderButton";
import PreloaderCard from "../../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../../PreloaderTemplates/PreloaderLine";
import PreloaderRect from "../../PreloaderTemplates/PreloaderRect";
import PreloaderMobileTitle from "../PreloaderMobileTitle";
import styles from "./index.module.sass";

const PreloaderSurvey = () => {
  return (
    <>
      <PreloaderMobileTitle />
      <PreloaderBreadcrumbs />
      <div className={styles.wrapper}>
        <div className={styles.survey}>
          <div className={styles.survey__header}>
            <PreloaderRect width={400} height={48} />
            <div className={styles.survey__lines_head}>
              <PreloaderLine width={120} />
              <PreloaderLine width={120} />
            </div>
          </div>
          <div className={styles.survey__body}>
            <div className={styles.survey__left}>
              <PreloaderCard padding={"24px"}>
                <div className={styles.survey__lines}>
                  <PreloaderLine width={"16%"} />
                  <PreloaderLine width={"16%"} />
                  <PreloaderLine width={"16%"} />
                  <PreloaderLine width={"16%"} />
                  <PreloaderLine width={"16%"} />
                  <PreloaderLine width={"16%"} />
                </div>
                <div className={styles.survey__data}>
                  <PreloaderLine width={120} />
                  <PreloaderRect width={"100%"} height={24} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={160} />
                </div>
                <PreloaderBorder />
                <PreloaderRect
                  backgroundLight={true}
                  width={"100%"}
                  height={196}
                />
                <div className={styles.survey__lines}>
                  <PreloaderButton /> <PreloaderButton />
                </div>
              </PreloaderCard>
            </div>

            <div className={styles.survey__right}>
              <PreloaderCard padding={24}>
                <div className={styles.survey__title}>
                  <PreloaderCircle size={24} />
                  <PreloaderLine width={220} />
                </div>
                <PreloaderBorder />
                <div>
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={160} />
                </div>
                <div>
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={160} />
                </div>
                <div>
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={"100%"} />
                  <PreloaderLine width={160} />
                </div>
              </PreloaderCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreloaderSurvey;
