export function DesignBezier(props) {
	return (
		<svg
			{...props}
			width="24"
			height="18"
			viewBox="0 0 24 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5 7.75H13.5C14.5443 7.75 15.4254 7.03366 15.6776 6.06688C17.1991 7.26926 18.1604 9.18406 18.2351 11.25H18C16.76 11.25 15.75 12.26 15.75 13.5V15.5C15.75 16.4 16.28 17.21 17.07 17.54C17.37 17.68 17.68 17.75 18 17.75H20C21.24 17.75 22.25 16.74 22.25 15.5V13.5C22.25 12.26 21.24 11.25 20 11.25H19.7359C19.6576 8.64334 18.4063 6.22634 16.4256 4.75H18.3533C18.6794 5.90488 19.7401 6.75 21 6.75C22.52 6.75 23.75 5.52 23.75 4C23.75 2.48 22.52 1.25 21 1.25C19.7401 1.25 18.6794 2.09512 18.3533 3.25H15.75V2.5C15.75 1.26 14.74 0.25 13.5 0.25H10.5C9.26 0.25 8.25 1.26 8.25 2.5V3.25H5.64669C5.32063 2.09512 4.25994 1.25 3 1.25C1.48 1.25 0.25 2.48 0.25 4C0.25 5.52 1.48 6.75 3 6.75C4.25994 6.75 5.32063 5.90488 5.64669 4.75H7.55112C5.57552 6.22907 4.32885 8.64535 4.25361 11.25H4C2.76 11.25 1.75 12.26 1.75 13.5V15.5C1.75 16.74 2.76 17.75 4 17.75H6C6.32 17.75 6.63 17.68 6.9 17.55C7.72 17.2 8.25 16.4 8.25 15.5V13.5C8.25 12.26 7.24 11.25 6 11.25H5.75435C5.82645 9.17743 6.79103 7.25604 8.31861 6.0521C8.56559 7.02635 9.4504 7.75 10.5 7.75ZM9.75 4.78972V5.5C9.75 5.91 10.09 6.25 10.5 6.25H13.5C13.91 6.25 14.25 5.91 14.25 5.5V4.87579C14.2333 4.78667 14.2328 4.69418 14.25 4.60278V2.5C14.25 2.09 13.91 1.75 13.5 1.75H10.5C10.09 1.75 9.75 2.09 9.75 2.5V4.66902C9.75331 4.70941 9.75327 4.74977 9.75 4.78972ZM5.0279 12.75L5.03 12.75L6 12.75C6.41 12.75 6.75 13.09 6.75 13.5V15.5C6.75 15.79 6.58 16.0601 6.29 16.1801C6.19 16.2301 6.1 16.25 6 16.25H4C3.59 16.25 3.25 15.91 3.25 15.5V13.5C3.25 13.09 3.59 12.75 4 12.75H5.0279ZM3 2.75C3.69 2.75 4.25 3.31 4.25 4C4.25 4.69 3.69 5.25 3 5.25C2.31 5.25 1.75 4.69 1.75 4C1.75 3.31 2.31 2.75 3 2.75ZM19.75 4C19.75 4.69 20.31 5.25 21 5.25C21.69 5.25 22.25 4.69 22.25 4C22.25 3.31 21.69 2.75 21 2.75C20.31 2.75 19.75 3.31 19.75 4ZM18 12.75C17.59 12.75 17.25 13.09 17.25 13.5V15.5C17.25 15.79 17.42 16.06 17.68 16.17C17.81 16.23 17.9 16.25 18 16.25H20C20.41 16.25 20.75 15.91 20.75 15.5V13.5C20.75 13.09 20.41 12.75 20 12.75H18Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignBrush(props) {
	return (
		<svg
			{...props}
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.97021 21.75H13.9702C19.4102 21.75 21.7202 19.43 21.7202 14V12C21.7202 11.59 21.3802 11.25 20.9702 11.25C20.5602 11.25 20.2202 11.59 20.2202 12V14C20.2202 18.61 18.5802 20.25 13.9702 20.25H7.97021C3.36021 20.25 1.72021 18.61 1.72021 14V8C1.72021 3.39 3.36021 1.75 7.97021 1.75H9.97021C10.3802 1.75 10.7202 1.41 10.7202 1C10.7202 0.59 10.3802 0.25 9.97021 0.25H7.97021C2.54022 0.25 0.220215 2.57 0.220215 8V14C0.220215 19.43 2.54022 21.75 7.97021 21.75Z"
				fill="#5B5766"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.49999 13.0702C4.70197 11.2066 6.10921 9.78959 7.9402 9.53896C8.03657 9.36691 8.14876 9.20775 8.27009 9.06989L9.50009 7.52989C11.6401 4.85989 15.9501 1.65989 19.1301 0.389888C19.8601 0.109888 20.6301 0.269888 21.1601 0.799888C21.7101 1.34989 21.8701 2.12989 21.5801 2.83989C20.3101 6.00989 17.1101 10.3299 14.4501 12.4599L12.8801 13.7199C12.7452 13.8156 12.6217 13.898 12.5038 13.9689C12.4012 14.8828 11.9986 15.7316 11.34 16.3902C10.71 17.0202 9.88999 17.4102 8.95999 17.5302L6.97999 17.7402C6.87999 17.7502 6.78999 17.7502 6.69999 17.7502C6.05999 17.7502 5.44999 17.5002 4.98999 17.0402C4.45999 16.5102 4.19999 15.7802 4.28999 15.0302L4.49999 13.0702ZM9.72009 9.66989C9.90643 9.73098 10.0872 9.80514 10.2617 9.8918C10.4508 9.98478 10.6321 10.0928 10.8049 10.2155C10.9966 10.3503 11.1787 10.5021 11.3501 10.6699C11.8101 11.1299 12.1401 11.6599 12.3301 12.2299L13.5101 11.2799C13.6926 11.1333 13.8779 10.9757 14.065 10.8084C13.9773 9.2465 12.7003 7.98718 11.1193 7.94124C10.9639 8.11602 10.8172 8.28915 10.6801 8.45989L9.72009 9.66989ZM12.3899 6.64784C13.7778 7.08412 14.8816 8.17001 15.3399 9.55083C17.3233 7.42077 19.2828 4.53433 20.1901 2.26989C20.2701 2.07989 20.1801 1.93989 20.1001 1.84989C20.0501 1.79989 19.9101 1.68989 19.6801 1.77989C17.4165 2.6899 14.5214 4.65839 12.3899 6.64784ZM8.59999 10.9902H8.49999C7.34999 11.0202 6.13999 11.7902 5.98999 13.2302L5.77999 15.2002C5.74999 15.4902 5.84999 15.7802 6.04999 15.9802C6.24999 16.1802 6.52999 16.2802 6.80999 16.2502L8.77999 16.0402C9.35999 15.9702 9.87999 15.7202 10.27 15.3302C10.75 14.8402 11.02 14.2002 11.02 13.5002C11.02 13.3902 11.01 13.2702 10.99 13.1502C10.92 12.6202 10.68 12.1502 10.28 11.7402C9.86999 11.3302 9.36999 11.0702 8.81999 11.0002C8.76999 10.9902 8.68999 10.9902 8.59999 10.9902Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignColorfilter(props) {
	return (
		<svg
			{...props}
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.25 7C4.25 7.59337 4.32617 8.17721 4.47522 8.7384C1.95002 9.7496 0.25 12.2247 0.25 14.9999C0.25 18.7199 3.28 21.7499 7 21.7499C8.45515 21.7499 9.85716 21.2792 11.0009 20.4303C12.1497 21.2818 13.5484 21.7502 15 21.7502C18.72 21.7502 21.75 18.7202 21.74 15.0002C21.74 12.2285 20.0441 9.76427 17.524 8.74463C17.6736 8.17924 17.75 7.5944 17.75 7C17.75 3.28 14.72 0.25 11 0.25C7.28 0.25 4.25 3.28 4.25 7ZM13.5222 13.2684C15.0054 12.6665 16.2396 11.5468 16.9843 10.132C18.9319 10.933 20.25 12.8518 20.25 15.0002C20.25 17.8902 17.89 20.2502 15 20.2502C13.96 20.2502 12.97 19.9502 12.12 19.3902C13.17 18.1802 13.75 16.6202 13.75 15.0002C13.75 14.4131 13.6708 13.826 13.5222 13.2684ZM12.25 14.9868C12.2489 14.5316 12.189 14.0958 12.08 13.6699C11.14 13.8199 10.12 13.7699 9.22 13.5199C7.72931 13.1081 6.43532 12.1944 5.53914 10.963C5.34649 10.6995 5.17239 10.4213 5.01905 10.1303C3.06957 10.9305 1.75 12.8502 1.75 14.9999C1.75 17.8899 4.11 20.2499 7 20.2499C8.3 20.2499 9.54 19.7699 10.5 18.8999C10.8512 18.5895 11.1541 18.2368 11.4046 17.853C11.8981 17.0962 12.1907 16.2142 12.2419 15.2924C12.2473 15.1953 12.25 15.0977 12.25 14.9999C12.25 14.9955 12.25 14.9912 12.25 14.9868ZM11 1.75C8.11 1.75 5.75 4.11 5.75 7C5.75 7.67 5.87 8.32 6.12 8.93C6.73 10.46 8.03 11.63 9.62 12.06C10.5 12.3 11.52 12.3 12.38 12.06C13.97 11.63 15.28 10.45 15.88 8.93C16.12 8.32 16.25 7.67 16.25 7C16.25 4.11 13.89 1.75 11 1.75Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignEraser(props) {
	return (
		<svg
			{...props}
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.54013 21.71C6.54013 21.71 5.59017 21.32 4.89017 20.61L1.38016 17.1C-0.0798438 15.64 -0.0798438 13.26 1.38016 11.8L11.8101 1.37C13.2201 -0.04 15.7001 -0.04 17.1101 1.37L20.6201 4.87999C22.0801 6.33999 22.0801 8.72 20.6201 10.18L14.4664 16.3338C14.4463 16.3603 14.4242 16.3857 14.4001 16.4098C14.3751 16.4348 14.349 16.4575 14.3221 16.4781L10.5502 20.25H20C20.41 20.25 20.75 20.59 20.75 21C20.75 21.41 20.41 21.75 20 21.75H8C7.91101 21.75 7.82533 21.734 7.7457 21.7047C7.67754 21.7082 7.609 21.71 7.54013 21.71ZM12.8102 15.88L9.13016 19.56C8.35336 20.346 7.02072 20.4052 6.15236 19.7374L11.41 14.4798L12.8102 15.88ZM10.35 13.4198L5.07998 18.6898L3.31503 16.9249L8.58508 11.6549L10.35 13.4198ZM2.44015 12.87C1.62276 13.6781 1.56461 14.9693 2.26569 15.8542L7.52508 10.5949L6.12019 9.18996L2.44015 12.87ZM13.8702 14.82L19.5601 9.12999C20.4401 8.24999 20.4401 6.82999 19.5601 5.94999L16.0501 2.44C15.2101 1.6 13.7201 1.59 12.8701 2.44L7.18019 8.12996L13.8702 14.82Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignCopy(props) {
	return (
		<svg
			{...props}
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.75 15.75H16.1C20.01 15.75 21.75 14.01 21.75 10.1V5.9C21.75 1.99 20.01 0.25 16.1 0.25H11.9C7.99 0.25 6.25 1.99 6.25 5.9V6.25H5.9C1.99 6.25 0.25 7.99 0.25 11.9V16.1C0.25 20.01 1.99 21.75 5.9 21.75H10.1C14.01 21.75 15.75 20.01 15.75 16.1V15.75ZM15.75 14.25H16.1C19.2 14.25 20.25 13.2 20.25 10.1V5.9C20.25 2.8 19.2 1.75 16.1 1.75H11.9C8.8 1.75 7.75 2.8 7.75 5.9V6.25H10.1C14.01 6.25 15.75 7.99 15.75 11.9V14.25ZM5.9 7.75C2.8 7.75 1.75 8.8 1.75 11.9V16.1C1.75 19.2 2.8 20.25 5.9 20.25H10.1C13.2 20.25 14.25 19.2 14.25 16.1V11.9C14.25 8.8 13.2 7.75 10.1 7.75H5.9Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignDropper(props) {
	return (
		<svg
			{...props}
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.99988 18.8699C2.25988 18.8699 1.52988 18.5899 0.969883 18.0299C0.429883 17.4899 0.129883 16.7699 0.129883 15.9999C0.129883 15.2299 0.429883 14.5099 0.969883 13.9699L8.94061 5.99915L8.46994 5.52848C8.17994 5.23848 8.17994 4.75848 8.46994 4.46848C8.75994 4.17848 9.23994 4.17848 9.52994 4.46848L10.0006 4.93915L13.9699 0.969883C15.0899 -0.150117 16.9099 -0.150117 18.0299 0.969883C18.5699 1.50988 18.8699 2.22988 18.8699 2.99988C18.8699 3.76988 18.5699 4.48988 18.0299 5.02988L14.0606 8.99915L14.5299 9.46848C14.8199 9.75848 14.8199 10.2385 14.5299 10.5285C14.3799 10.6785 14.1899 10.7485 13.9999 10.7485C13.8099 10.7485 13.6199 10.6785 13.4699 10.5285L13.0006 10.0592L5.02988 18.0299C4.46988 18.5899 3.73988 18.8699 2.99988 18.8699ZM2.02988 15.0299L10.0006 7.05915L11.9406 8.99915L3.96988 16.9699C3.43988 17.4999 2.55988 17.4999 2.02988 16.9699C1.76988 16.7099 1.62988 16.3699 1.62988 15.9999C1.62988 15.6299 1.76988 15.2899 2.02988 15.0299Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignLayer(props) {
	return (
		<svg
			{...props}
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.79 10.88C9.25 10.88 8.7 10.78 8.27 10.59L2.37 7.97C0.87 7.3 0.65 6.4 0.65 5.91C0.65 5.42 0.87 4.52 2.37 3.85L8.27 1.23C9.14 0.84 10.45 0.84 11.32 1.23L17.23 3.85C18.72 4.51 18.95 5.42 18.95 5.91C18.95 6.4 18.73 7.3 17.23 7.97L11.32 10.59C10.88 10.79 10.34 10.88 9.79 10.88ZM9.79 2.44C9.45 2.44 9.12 2.49 8.88 2.6L2.98 5.22C2.37 5.5 2.15 5.78 2.15 5.91C2.15 6.04 2.37 6.33 2.97 6.6L8.87 9.22C9.35 9.43 10.22 9.43 10.7 9.22L16.61 6.6C17.22 6.33 17.44 6.04 17.44 5.91C17.44 5.78 17.22 5.49 16.61 5.22L10.71 2.6C10.47 2.5 10.13 2.44 9.79 2.44Z"
				fill="#5B5766"
			/>
			<path
				d="M10 16.09C9.62 16.09 9.24 16.01 8.88 15.85L2.09 12.83C1.06 12.38 0.25 11.13 0.25 10C0.25 9.59 0.59 9.25 1 9.25C1.41 9.25 1.75 9.59 1.75 10C1.75 10.55 2.2 11.24 2.7 11.47L9.49 14.49C9.81 14.63 10.18 14.63 10.51 14.49L17.3 11.47C17.8 11.25 18.25 10.55 18.25 10C18.25 9.59 18.59 9.25 19 9.25C19.41 9.25 19.75 9.59 19.75 10C19.75 11.13 18.94 12.38 17.91 12.84L11.12 15.86C10.76 16.01 10.38 16.09 10 16.09Z"
				fill="#5B5766"
			/>
			<path
				d="M10 21.09C9.62 21.09 9.24 21.01 8.88 20.85L2.09 17.83C0.97 17.33 0.25 16.22 0.25 14.99C0.25 14.58 0.59 14.24 1 14.24C1.41 14.24 1.75 14.59 1.75 15C1.75 15.63 2.12 16.21 2.7 16.47L9.49 19.49C9.81 19.63 10.18 19.63 10.51 19.49L17.3 16.47C17.88 16.21 18.25 15.64 18.25 15C18.25 14.59 18.59 14.25 19 14.25C19.41 14.25 19.75 14.59 19.75 15C19.75 16.23 19.03 17.34 17.91 17.84L11.12 20.86C10.76 21.01 10.38 21.09 10 21.09Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignBlur(props) {
	return (
		<svg
			{...props}
			width="18"
			height="22"
			viewBox="0 0 18 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 21.75C4.12 21.75 0.150025 17.78 0.150025 12.9C0.120025 7.17 5.57999 2.4 7.92999 0.61C8.55999 0.13 9.44001 0.13 10.07 0.61C12.42 2.43 17.88 7.26 17.85 12.91C17.85 17.78 13.88 21.75 9 21.75ZM15.9104 15.4391C14.9577 18.0236 12.5883 19.9283 9.75 20.2131V18.4313L15.9104 15.4391ZM16.3306 13.5693L9.75 16.7655V13.4312L15.9596 10.4149C16.2172 11.2254 16.365 12.0615 16.36 12.91C16.36 13.1322 16.3501 13.3521 16.3306 13.5693ZM8.25 13.0556C8.24212 12.9931 8.24226 12.9304 8.25 12.8689V8.05544C8.24212 7.99296 8.24226 7.93027 8.25 7.86883V2.27553C6.13322 3.99805 1.63288 8.21246 1.65997 12.9C1.65997 16.7028 4.55515 19.8301 8.25 20.211V18.0556C8.24212 17.9932 8.24226 17.9305 8.25 17.869V13.0556ZM9.75 8.43135L13.8192 6.45742C14.4174 7.25192 14.9678 8.1131 15.4023 9.01982L9.75 11.7654V8.43135ZM9.75 6.76604V2.27993C10.5623 2.94698 11.7237 3.98159 12.8469 5.26375L9.75 6.76604Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignMask(props) {
	return (
		<svg
			{...props}
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.40002 18.6L3.40633 18.6062C5.43563 20.6324 8.13307 21.7501 11.0002 21.7501C16.9302 21.7501 21.7502 16.9301 21.7502 11.0001C21.7502 8.13012 20.6302 5.43012 18.6002 3.40012L18.5937 3.39371C16.5644 1.36769 13.867 0.25 11 0.25C5.07 0.25 0.25 5.07 0.25 11C0.25 13.87 1.37002 16.57 3.40002 18.6ZM11 1.75C5.9 1.75 1.75 5.9 1.75 11C1.75 13.22 2.53001 15.32 3.95001 16.99L6.15314 14.7869C6.17337 14.7601 6.19564 14.7345 6.21994 14.7102C6.24424 14.6859 6.26988 14.6636 6.29662 14.6434L9.6932 11.2468C9.71343 11.2201 9.73569 11.1944 9.75998 11.1701C9.78428 11.1458 9.8099 11.1236 9.83664 11.1034L16.99 3.95C15.32 2.53 13.22 1.75 11 1.75ZM7.81506 15.2452L10.2951 12.7652L16.1859 18.656C15.1449 19.3635 13.9528 19.8643 12.6691 20.0993L7.81506 15.2452ZM19.4196 14.8297C18.922 15.9193 18.2189 16.8961 17.3603 17.7104L11.3551 11.7052L13.8251 9.23519L19.4196 14.8297ZM19.9707 13.2608C20.1532 12.537 20.2502 11.7797 20.2502 11.0001C20.2502 8.78012 19.4702 6.68012 18.0502 5.01012L14.8851 8.1752L19.9707 13.2608ZM6.75504 16.3053L5.01019 18.0501C6.60347 19.4049 8.58812 20.1771 10.695 20.2452L6.75504 16.3053Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignCopysuccess(props) {
	return (
		<svg
			{...props}
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.50002 16.48C6.65002 16.63 6.84002 16.7 7.03002 16.7C7.22002 16.7 7.41002 16.62 7.55002 16.48L11.45 12.59C11.74 12.3 11.74 11.82 11.45 11.53C11.16 11.24 10.68 11.24 10.39 11.53L7.03002 14.89L5.61002 13.47C5.32002 13.18 4.84002 13.18 4.55002 13.47C4.26002 13.76 4.26002 14.24 4.55002 14.53L6.50002 16.48Z"
				fill="#5B5766"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.1 0.25H11.9C7.99 0.25 6.25 1.99 6.25 5.9V6.25H5.9C1.99 6.25 0.25 7.99 0.25 11.9V16.1C0.25 20.01 1.99 21.75 5.9 21.75H10.1C14.01 21.75 15.75 20.01 15.75 16.1V15.75H16.1C20.01 15.75 21.75 14.01 21.75 10.1V5.9C21.75 1.99 20.01 0.25 16.1 0.25ZM10.1 6.25H7.75V5.9C7.75 2.8 8.8 1.75 11.9 1.75H16.1C19.2 1.75 20.25 2.8 20.25 5.9V10.1C20.25 13.2 19.2 14.25 16.1 14.25H15.75V11.9C15.75 7.99 14.01 6.25 10.1 6.25ZM14.25 11.9V16.1C14.25 19.2 13.2 20.25 10.1 20.25H5.9C2.8 20.25 1.75 19.2 1.75 16.1V11.9C1.75 8.8 2.8 7.75 5.9 7.75H10.1C13.2 7.75 14.25 8.8 14.25 11.9Z"
				fill="#5B5766"
			/>
		</svg>
	)
}