import React, {useMemo} from "react"
import styles from "./quiz.module.scss"
import { useParams } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"

import {
	attachmentsSelector,
	lessonMoveStatuses,
	lessonSelector,
	progressSelector,
	questionsSelector,
	sendQuizStart,
} from "../../store/reducers/lesson"
import Attachments from "../attachments/Attachments"
import Button from "../forms/button/Button"
import FormQuiz, { FormQuizItem } from "./FormQuiz/FormQuiz"
import QuizDone from "./QuizDone/QuizDone"
import Typography from "../UIKit/base/Typography"
import { useTranslation } from "react-i18next"
import useScrollTop from "../hooks/useScrollTop"
import { formatEndings } from "../utils"
import i18next from "i18next"
import EditorView from "../UIKit/editor/view/EditorView"

const Quiz = () => {
	const { post } = useSelector(lessonSelector)
	const attachments = useSelector(attachmentsSelector)
	const questions = useSelector(questionsSelector)
	const dispatch = useDispatch()
 

	const { postId, productId } = useParams()

	useScrollTop(post)

	function handleStart() {
		dispatch(sendQuizStart(postId))
	}

	if (!post || !questions.length) {
		return null
	} 
	
	
	return (
		<>
			<div className={styles.card_big}>
				{(post?.description || !!attachments.length) &&
					<div className={styles.card}>
						<div className={styles.container}>
							{/* <Typography fontVariant="medium" className={styles.description}>{post && post.description}</Typography> */}
							{post?.description && (
								<EditorView content={post?.description} fontVariant="medium" />
							)}
							{!!attachments.length && (
								<div className={styles.wrapFiles}>
									<Attachments attachments={attachments} />
								</div>
							)}
						</div>
					</div>
				}
			</div>
			<div className={styles.card}>
				<QuizRender
					status={post?.status}
					handleStart={handleStart}
					questions={questions}
					postId={postId}
					productId={productId}
					post={post}
				/>
			</div>
		</>
	)
}

export default Quiz

function QuizRender({
	status,
	handleStart,
	post,
	questions,
	postId,
	productId,
}) {
	const { t } = useTranslation() 
	const progress = useSelector(progressSelector); 
	const tryStatus = post?.try_status

	if (status !== lessonMoveStatuses.COMPLETED && status !== "in_progress" && status !== "failed") {
		return (
			<div className={styles.quiz__start}>
				<Button onClick={handleStart}>start_quiz</Button>
				<Typography component="span" fontVariant="medium">
					{formatEndings(questions?.length, "question", i18next.language)}
				</Typography>
			</div>
		)
	} 


	if (status === "in_progress" && tryStatus !== "pending" && tryStatus !== "in_progress") {
		return (
			<QuizDone questions={questions} />
		)
	} 
	if (status === "in_progress" && (tryStatus === "pending" || tryStatus === "in_progress")) {
		return (
			<FormQuiz postId={postId} productId={productId} post={post}>
				{questions.map((q) => (
					<FormQuizItem key={`${q.id}-${q.type}`} question={q} />
				))}
			</FormQuiz>
		)
	}

	return <QuizDone questions={questions} />
}
