import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import {
	certificatesSelector,
	downloadCertificate,
	generateCertificate,
	getCertificates,
} from "../../store/reducers/certificates";
import Typography from "../UIKit/base/Typography";
import Button from "../forms/button/Button";

import styles from "./Finish.module.sass"; 
import { courseSelector } from "../../store/reducers/course";
import { lessonSelector } from "../../store/reducers/lesson";

const FinishCertificate = () => {
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { productId } = useParams();
	const navigate = useNavigate();

	const course = useSelector(courseSelector);

	const certificates = useSelector(certificatesSelector);

	const certificate = useMemo(() => {
		return certificates?.find((item) => item?.product_id === +productId) || null;
	}, [certificates, productId]);

	let location = useLocation()
	const state = location?.state 

	const lesson = useSelector(lessonSelector)
	const pathId = lesson?.post?.product?.path_id

	useEffect(() => {
		dispatch(getCertificates());
	}, []);

	const handleLoadCertificate = (e) => {
		dispatch(
			downloadCertificate({
				certificateId: certificate?.id,
			})
		);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleOpenModal = () => {
		const cb = () => setShowModal(true);
		if (!certificate) {
			return dispatch(generateCertificate({ productId: productId, cb }));
		}
		cb();
	};
	if (!certificates) {
		return null;
	}

	const handleContinue = () => {
		if (course?.product?.allow_reviews) {
            navigate(`/products/${productId}/review`, {state: {...state,  basicPathId: pathId}});
          } else {
            navigate(`/products/${productId}${state?.pathId ? `?filter[path_id]=${state?.pathId}` : pathId ? `?filter[path_id]=${pathId}` : ""}`, {state});
          }
	};
	return (
		<>
			<>
				<div className={`${styles.finishCertificate} ${styles.finish}`}>
					<div className={styles.finishCertificate__left}>
						<div className={styles.finishCertificate__image}>
							{certificate?.image && <img src={certificate?.image?.origin} alt="" />}
						</div>
					</div>
					<div className={styles.finishCertificate__right}>
						<Typography
							component="h3"
							className={styles.finishCertificate__title}
						>
							{t("congratulations")}
						</Typography>

						<div className={styles.finishCertificate__text}>
							<Typography component="p">
								{t("completed_course_text", {
									course_name: certificate?.product_title,
								})}
							</Typography>
							<Typography component="p">{t("you_can_view_certificate")}</Typography>
						</div>

						<div className={styles.finishCertificate__buttons}>
							<Button className={styles.button__white} onClick={handleContinue}>
								continue
							</Button>
							{certificate?.id && (
								<Typography
									onClick={handleLoadCertificate}
									component={Button}
									className={styles.button__outlined}
								>
									download
								</Typography>
							)}
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default FinishCertificate;
