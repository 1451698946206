export function DesignBezier(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 9C4.10457 9 5 8.10457 5 7C5 5.89543 4.10457 5 3 5C1.89543 5 1 5.89543 1 7C1 8.10457 1.89543 9 3 9Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 9C22.1046 9 23 8.10457 23 7C23 5.89543 22.1046 5 21 5C19.8954 5 19 5.89543 19 7C19 8.10457 19.8954 9 21 9Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19 7H15"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 7H5"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.5 16.5V18.5C7.5 19.11 7.13 19.64 6.61 19.86C6.42 19.95 6.22 20 6 20H4C3.17 20 2.5 19.33 2.5 18.5V16.5C2.5 15.67 3.17 15 4 15H6C6.83 15 7.5 15.67 7.5 16.5Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.5 16.5V18.5C21.5 19.33 20.83 20 20 20H18C17.78 20 17.58 19.95 17.39 19.86C16.87 19.64 16.5 19.11 16.5 18.5V16.5C16.5 15.67 17.17 15 18 15H20C20.83 15 21.5 15.67 21.5 16.5Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 5.5V8.5C15 9.32 14.32 10 13.5 10H10.5C9.68 10 9 9.32 9 8.5V5.5C9 4.68 9.68 4 10.5 4H13.5C14.32 4 15 4.68 15 5.5Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M15 7.73047C17.37 8.93047 19 11.5105 19 14.5005C19 14.6705 18.99 14.8305 18.97 15.0005"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M5.03 15.0005C5.01 14.8305 5 14.6705 5 14.5005C5 11.5105 6.63 8.93047 9 7.73047"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
export function DesignBrush(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.4"
				d="M10.9702 2H8.97021C3.97021 2 1.97021 4 1.97021 9V15C1.97021 20 3.97021 22 8.97021 22H14.9702C19.9702 22 21.9702 20 21.9702 15V13"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.8799 3.56022C20.6499 6.63022 17.5599 10.8102 14.9799 12.8802L13.3999 14.1402C13.1999 14.2902 12.9999 14.4102 12.7699 14.5002C12.7699 14.3502 12.7599 14.2002 12.7399 14.0402C12.6499 13.3702 12.3499 12.7402 11.8099 12.2102C11.2599 11.6602 10.5999 11.3502 9.91994 11.2602C9.75994 11.2502 9.59994 11.2402 9.43994 11.2502C9.52994 11.0002 9.65994 10.7702 9.82994 10.5802L11.0899 9.00022C13.1599 6.42022 17.3499 3.31022 20.4099 2.08022C20.8799 1.90022 21.3399 2.04022 21.6299 2.33022C21.9299 2.63022 22.0699 3.09022 21.8799 3.56022Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.7801 14.4905C12.7801 15.3705 12.4401 16.2105 11.8101 16.8505C11.3201 17.3405 10.6601 17.6805 9.87009 17.7805L7.90009 17.9905C6.83009 18.1105 5.91009 17.2005 6.03009 16.1105L6.24009 14.1405C6.43009 12.3905 7.89009 11.2705 9.45009 11.2405C9.61009 11.2305 9.77009 11.2405 9.93009 11.2505C10.6101 11.3405 11.2701 11.6505 11.8201 12.2005C12.3601 12.7405 12.6601 13.3605 12.7501 14.0305C12.7701 14.1905 12.7801 14.3505 12.7801 14.4905Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M15.8198 11.9804C15.8198 9.89043 14.1298 8.19043 12.0298 8.19043"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
export function DesignColorfilter(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.24 3.88 10.9 6.42 10.21C7.11 11.95 8.59 13.29 10.42 13.79C10.92 13.93 11.45 14 12 14C12.55 14 13.08 13.93 13.58 13.79C13.85 14.47 14 15.22 14 16Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M22 16C22 19.31 19.31 22 16 22C14.46 22 13.06 21.42 12 20.46C13.23 19.37 14 17.77 14 16C14 15.22 13.85 14.47 13.58 13.79C15.41 13.29 16.89 11.95 17.58 10.21C20.12 10.9 22 13.24 22 16Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
export function DesignEraser(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 22H21"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.90973 17.5797L6.41974 21.0897C7.58974 22.2597 9.49973 22.2597 10.6597 21.0897L21.0898 10.6597C22.2598 9.48973 22.2598 7.57972 21.0898 6.41972L17.5798 2.90973C16.4098 1.73973 14.4998 1.73973 13.3398 2.90973L2.90973 13.3397C1.73973 14.4997 1.73973 16.4097 2.90973 17.5797Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<g opacity="0.4">
				<path
					d="M7.12012 9.12988L14.8701 16.8799"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.52002 17.66L9.16998 12"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6.33984 20.4901L11.9998 14.8301"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}
export function DesignCopy(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
export function DesignDropper(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.4"
				d="M2.96988 21.0298C3.52988 21.5898 4.25988 21.8698 4.99988 21.8698C5.73988 21.8698 6.46988 21.5898 7.02988 21.0298L15.0006 13.059L13.9406 11.999L5.96988 19.9698C5.43988 20.4998 4.55988 20.4998 4.02988 19.9698C3.76988 19.7098 3.62988 19.3698 3.62988 18.9998C3.62988 18.6298 3.76988 18.2898 4.02988 18.0298L12.0006 10.059L10.9406 8.99902L2.96988 16.9698C2.42988 17.5098 2.12988 18.2298 2.12988 18.9998C2.12988 19.7698 2.42988 20.4898 2.96988 21.0298Z"
				fill="#5B5766"
			/>
			<path
				d="M10.4699 8.52848C10.1799 8.23848 10.1799 7.75848 10.4699 7.46848C10.7599 7.17848 11.2399 7.17848 11.5299 7.46848L12.0006 7.93915L15.9699 3.96988C17.0899 2.84988 18.9099 2.84988 20.0299 3.96988C20.5699 4.50988 20.8699 5.22988 20.8699 5.99988C20.8699 6.76988 20.5699 7.48988 20.0299 8.02988L16.0606 11.9992L16.5299 12.4685C16.8199 12.7585 16.8199 13.2385 16.5299 13.5285C16.3799 13.6785 16.1899 13.7485 15.9999 13.7485C15.8099 13.7485 15.6199 13.6785 15.4699 13.5285L10.4699 8.52848Z"
				fill="#5B5766"
			/>
		</svg>
	)
}
export function DesignLayer(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.01 2.92031L18.91 5.54031C20.61 6.29031 20.61 7.53031 18.91 8.28031L13.01 10.9003C12.34 11.2003 11.24 11.2003 10.57 10.9003L4.67002 8.28031C2.97002 7.53031 2.97002 6.29031 4.67002 5.54031L10.57 2.92031C11.24 2.62031 12.34 2.62031 13.01 2.92031Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M3 11C3 11.84 3.63 12.81 4.4 13.15L11.19 16.17C11.71 16.4 12.3 16.4 12.81 16.17L19.6 13.15C20.37 12.81 21 11.84 21 11"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M3 16C3 16.93 3.55 17.77 4.4 18.15L11.19 21.17C11.71 21.4 12.3 21.4 12.81 21.17L19.6 18.15C20.45 17.77 21 16.93 21 16"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
export function DesignBlur(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.6098 2.21C12.2498 1.93 11.7498 1.93 11.3898 2.21C9.48983 3.66 3.87979 8.39 3.90979 13.9C3.90979 18.36 7.53982 22 12.0098 22C16.4798 22 20.1098 18.37 20.1098 13.91C20.1198 8.48 14.4998 3.67 12.6098 2.21Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
			<g opacity="0.4">
				<path
					d="M12 2V22"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12 18.9597L19.7 15.2197"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12 13.9599L19.37 10.3799"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12 8.95976L17.03 6.50977"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}
export function DesignMask(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.92999 19.07C3.11999 17.26 2 14.76 2 12C2 6.48 6.48 2 12 2C14.76 2 17.26 3.12 19.07 4.93"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.0702 4.92969C20.8802 6.73969 22.0002 9.23969 22.0002 11.9997C22.0002 17.5197 17.5202 21.9997 12.0002 21.9997C9.24018 21.9997 6.74018 20.8797 4.93018 19.0697"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<g opacity="0.4">
				<path
					d="M19.0702 4.92969L4.93018 19.0697"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7.75977 16.2393L13.4097 21.8993"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.29 12.71L17.8701 19.28"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.8301 9.16992L21.1201 15.4699"
					stroke="#5B5766"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}
export function DesignCopysuccess(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.4"
				d="M22 11.1V6.9C22 3.4 20.6 2 17.1 2H12.9C9.4 2 8 3.4 8 6.9V8H11.1C14.6 8 16 9.4 16 12.9V16H17.1C20.6 16 22 14.6 22 11.1Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 17.1V12.9C16 9.4 14.6 8 11.1 8H6.9C3.4 8 2 9.4 2 12.9V17.1C2 20.6 3.4 22 6.9 22H11.1C14.6 22 16 20.6 16 17.1Z"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.08008 14.9998L8.03008 16.9498L11.9201 13.0498"
				stroke="#5B5766"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}