import { useState } from "react";
import { CloseIcon, AddIcon, ArrowUpIcon, ArrowDownIcon } from "../icons";
import Typography from "../UIKit/base/Typography";

import style from "./FAQ.module.sass";
import EditorView from "../UIKit/editor/view/EditorView";

const FAQItem = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={style.faq__item} onClick={handleClick}>
      <div className={style.faq__question}>
        <Typography component="h3">{item.question}</Typography>
        <div className={open ? style.faq__open : style.faq__close}>
          {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </div>
      {open && (
        <div className={style.faq__answer}>
          <EditorView content={item.answer} fontVariant="medium" />
        </div>
      )}
    </div>
  );
};

export default FAQItem;
