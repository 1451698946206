export function lessons(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 22.75H15C19.6163 22.75 21.9849 21.0732 22.5911 17.2342C22.6226 17.1518 22.64 17.0628 22.64 16.9702C22.64 16.9498 22.6391 16.9295 22.6375 16.9095C22.713 16.3215 22.75 15.6855 22.75 15V9C22.75 8.30417 22.7119 7.65941 22.634 7.06406C22.638 7.03328 22.64 7.00196 22.64 6.97021C22.64 6.863 22.6168 6.76057 22.5751 6.66775C21.9442 2.89823 19.5768 1.25 15 1.25L9 1.25C3.57 1.25 1.25 3.57 1.25 9L1.25 15C1.25 20.43 3.57 22.75 9 22.75ZM21.2056 16.2202C21.2356 15.8389 21.25 15.4325 21.25 15V12.75H17.6401L17.6401 16.2202H21.2056ZM17.6401 17.7202V20.9943C19.485 20.5596 20.5232 19.5417 20.9749 17.7202H17.6401ZM17.6401 3.00572C19.4648 3.43562 20.5004 4.4361 20.9599 6.22021L17.6401 6.22021V3.00572ZM21.2008 7.72021L17.6401 7.72021V11.25H21.25V9C21.25 8.54489 21.234 8.11872 21.2008 7.72021ZM7.64014 2.80618C8.06149 2.76821 8.51426 2.75 9 2.75L15 2.75C15.4024 2.75 15.7821 2.76249 16.1401 2.78837V11.25L7.64014 11.25L7.64014 2.80618ZM16.1401 21.2116V12.75L7.64014 12.75L7.64014 21.1938C8.06149 21.2318 8.51426 21.25 9 21.25H15C15.4024 21.25 15.7821 21.2375 16.1401 21.2116ZM6.14014 12.75V16.2202H2.79441C2.76444 15.8389 2.75 15.4325 2.75 15L2.75 12.75H6.14014ZM6.14014 11.25H2.75L2.75 9C2.75 8.54489 2.76598 8.11872 2.79923 7.72021L6.14014 7.72021V11.25ZM3.02508 17.7202C3.45852 19.4681 4.43201 20.476 6.14014 20.9387L6.14014 17.7202H3.02508ZM3.04013 6.22021L6.14014 6.22021L6.14014 3.06129C4.45151 3.51868 3.48084 4.509 3.04013 6.22021Z"
				fill="#FFBB33"
			/>
		</svg>
	);
}

////
export function products(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.25 14C18.25 14.41 17.91 14.75 17.5 14.75H13.25C12.84 14.75 12.5 14.41 12.5 14C12.5 13.59 12.84 13.25 13.25 13.25H17.5C17.91 13.25 18.25 13.59 18.25 14Z"
				fill="#00CC66"
			/>
			<path
				d="M8.25 18C8.25 18.41 8.59 18.75 9 18.75L17.5 18.75C17.91 18.75 18.25 18.41 18.25 18C18.25 17.59 17.91 17.25 17.5 17.25H9C8.59 17.25 8.25 17.59 8.25 18Z"
				fill="#00CC66"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.25 17C2.25 20.65 4.35 22.75 8 22.75H16C19.65 22.75 21.75 20.65 21.75 17L21.75 7C21.75 3.35 19.65 1.25 16 1.25L8 1.25C4.35 1.25 2.25 3.35 2.25 7L2.25 17ZM7.75 2.75365C5.05896 2.83354 3.75 4.22462 3.75 7L3.75 17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17L20.25 7C20.25 4.22462 18.941 2.83354 16.25 2.75365V9.85999C16.25 10.36 15.96 10.81 15.5 11C15.05 11.2 14.52 11.11 14.15 10.77L12 8.79999L9.84998 10.78C9.60998 11 9.31 11.11 9 11.11C8.83 11.11 8.66 11.08 8.5 11.01C8.04 10.81 7.75 10.36 7.75 9.87L7.75 2.75365ZM12.85 7.53998L14.75 9.28998L14.75 2.75L9.25 2.75V9.28998L11.15 7.53998C11.39 7.31998 11.7 7.21002 12 7.21002C12.3 7.21002 12.61 7.31998 12.85 7.53998Z"
				fill="#00CC66"
			/>
		</svg>
	);
}

export function homework(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.7002 18.4998L22.395 11.0982C22.3977 11.078 22.3996 11.0578 22.4007 11.0374L22.4502 10.5098C22.5602 9.44977 22.5902 7.89977 21.5502 6.73977C20.6602 5.74977 19.1702 5.25977 17.0002 5.25977H16.75V5.2C16.75 3.44 16.75 1.25 12.8 1.25L11.2 1.25C7.25 1.25 7.25 3.44 7.25 5.2V5.25977H7.00023C4.84023 5.25977 3.35023 5.73977 2.45023 6.73977C1.41023 7.89977 1.44023 9.44977 1.55023 10.4998L2.30023 18.5098C2.52023 20.5998 3.38023 22.7498 8.00023 22.7498H16.0002C20.6202 22.7498 21.4802 20.5998 21.7002 18.4998ZM16 6.75L15.9814 6.74977L8.01864 6.74977L8 6.75L7.98136 6.74977H7.00023C5.31023 6.74977 4.15023 7.07977 3.56023 7.73977C3.07023 8.27977 2.91023 9.10977 3.04023 10.3498L3.07092 10.6776C4.93943 11.9443 7.05666 12.7965 9.25 13.1721V13C9.25 11.59 9.59 11.25 11 11.25H13C14.41 11.25 14.75 11.59 14.75 13V13.142C16.9749 12.7407 19.0976 11.8629 20.9393 10.5828L20.9602 10.3598C21.0902 9.10977 20.9302 8.27977 20.4402 7.73977C19.8502 7.07977 18.6902 6.74977 17.0002 6.74977H16.0186L16 6.75ZM9.27113 14.6949C7.16312 14.3727 5.11044 13.6434 3.24698 12.5578L3.79023 18.3598C3.96023 19.9398 4.39023 21.2498 8.00023 21.2498H16.0002C19.6002 21.2498 20.0402 19.9398 20.2102 18.3498L20.7608 12.484C18.9153 13.5776 16.8663 14.3235 14.7288 14.6767C14.6426 15.6491 14.2065 16.75 12 16.75C9.79261 16.75 9.35706 15.6547 9.27113 14.6949ZM15.25 5.25977L8.75 5.25977V5.2C8.75 3.42 8.75 2.75 11.2 2.75L12.8 2.75C15.25 2.75 15.25 3.42 15.25 5.2V5.25977ZM10.75 12.75H13.25V14.02C13.25 15.09 13.25 15.25 12 15.25C10.75 15.25 10.75 15.06 10.75 14.03V12.75Z"
				fill="#FFBB33"
			/>
		</svg>
	);
}

export function system(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.25 22C14.25 22.41 14.59 22.75 15 22.75C15.41 22.75 15.75 22.41 15.75 22V20.25L16.5 20.25C17.25 20.25 17.8 20.22 18.24 20.16C21.59 19.79 22.25 17.8 22.25 14.5V9.5C22.25 6.2 21.6 4.22 18.27 3.85C17.8 3.78 17.25 3.75 16.5 3.75H15.75V2C15.75 1.59 15.41 1.25 15 1.25C14.59 1.25 14.25 1.59 14.25 2L14.25 22ZM15.75 5.25L15.75 18.75L16.5 18.75C17.18 18.75 17.66 18.72 18.05 18.67C20.08 18.45 20.75 17.81 20.75 14.5V9.5C20.75 6.19 20.08 5.56 18.08 5.33C17.66 5.27 17.18 5.25 16.5 5.25H15.75Z"
				fill="#FFBB33"
			/>
			<path
				d="M11.02 20.25H7.5C6.75 20.25 6.2 20.22 5.73 20.15C2.4 19.78 1.75 17.8 1.75 14.5L1.75 9.5C1.75 6.2 2.41 4.21 5.76 3.84C6.2 3.78 6.75 3.75 7.5 3.75L10.96 3.75C11.37 3.75 11.71 4.09 11.71 4.5C11.71 4.91 11.37 5.25 10.96 5.25L7.5 5.25C6.82 5.25 6.34 5.28 5.95 5.33C3.92 5.55 3.25 6.19 3.25 9.5L3.25 14.5C3.25 17.81 3.92 18.44 5.92 18.67C6.34 18.73 6.82 18.75 7.5 18.75H11.02C11.43 18.75 11.77 19.09 11.77 19.5C11.77 19.91 11.43 20.25 11.02 20.25Z"
				fill="#FFBB33"
			/>
			<path
				d="M6.7002 13.0002C6.5702 13.0002 6.4402 12.9702 6.3202 12.9202C6.2002 12.8702 6.0902 12.8002 5.9902 12.7102C5.9002 12.6102 5.8202 12.5002 5.7702 12.3802C5.7202 12.2602 5.7002 12.1302 5.7002 12.0002C5.7002 11.7402 5.8102 11.4802 5.9902 11.2902C6.3602 10.9202 7.0302 10.9202 7.4102 11.2902C7.5902 11.4802 7.7002 11.7402 7.7002 12.0002C7.7002 12.1302 7.6702 12.2602 7.6202 12.3802C7.5702 12.5002 7.5002 12.6102 7.4102 12.7102C7.3102 12.8002 7.2002 12.8702 7.0802 12.9202C6.9602 12.9702 6.8302 13.0002 6.7002 13.0002Z"
				fill="#FFBB33"
			/>
			<path
				d="M10.3202 12.9202C10.4402 12.9702 10.5702 13.0002 10.7002 13.0002C10.8302 13.0002 10.9602 12.9702 11.0802 12.9202C11.2002 12.8702 11.3102 12.8002 11.4102 12.7102C11.5002 12.6102 11.5702 12.5002 11.6202 12.3802C11.6702 12.2602 11.7002 12.1302 11.7002 12.0002C11.7002 11.7402 11.5902 11.4802 11.4102 11.2902C11.0402 10.9202 10.3602 10.9202 9.9902 11.2902C9.81019 11.4802 9.7002 11.7402 9.7002 12.0002C9.7002 12.1302 9.7302 12.2602 9.7702 12.3802C9.8302 12.5002 9.9002 12.6102 9.9902 12.7102C10.0902 12.8002 10.2002 12.8702 10.3202 12.9202Z"
				fill="#FFBB33"
			/>
		</svg>
	);
}

export function security(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.25 13.1462C10.0951 12.8201 9.25 11.7595 9.25 10.4995C9.25 8.97951 10.48 7.74951 12 7.74951C13.52 7.74951 14.75 8.97951 14.75 10.4995C14.75 11.7595 13.9049 12.8201 12.75 13.1462V15.4995C12.75 15.9095 12.41 16.2495 12 16.2495C11.59 16.2495 11.25 15.9095 11.25 15.4995V13.1462ZM12 11.7495C12.69 11.7495 13.25 11.1895 13.25 10.4995C13.25 9.80951 12.69 9.24951 12 9.24951C11.31 9.24951 10.75 9.80951 10.75 10.4995C10.75 11.1895 11.31 11.7495 12 11.7495Z"
				fill="#FFBB33"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9999 22.7595C10.9099 22.7595 9.8299 22.4395 8.9799 21.8095L4.6799 18.5995C3.5399 17.7495 2.6499 15.9795 2.6499 14.5595L2.6499 7.11945C2.6499 5.57945 3.7799 3.93945 5.2299 3.39945L10.2199 1.52945C11.2099 1.15945 12.7699 1.15945 13.7599 1.52945L18.7599 3.39945C20.2099 3.93945 21.3399 5.57945 21.3399 7.11945V14.5495C21.3399 15.9695 20.4499 17.7395 19.3099 18.5895L15.0099 21.7995C14.1699 22.4395 13.0899 22.7595 11.9999 22.7595ZM10.7499 2.93945L5.7599 4.80945C4.8999 5.12945 4.1499 6.20945 4.1499 7.12945L4.1499 14.5595C4.1499 15.5095 4.8199 16.8395 5.5699 17.3995L9.8699 20.6095C11.0199 21.4695 12.9699 21.4695 14.1199 20.6095L18.4199 17.3995C19.1799 16.8295 19.8399 15.4995 19.8399 14.5595V7.11945C19.8399 6.20945 19.0899 5.12945 18.2299 4.79945L13.2399 2.92945C12.5799 2.68945 11.4199 2.68945 10.7499 2.93945Z"
				fill="#FFBB33"
			/>
		</svg>
	);
}

export function custom(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9395 7.12988C12.4895 7.12988 12.9395 7.57988 12.9395 8.12988C12.9395 8.67988 12.4995 9.12988 11.9395 9.12988C11.3895 9.12988 10.9395 8.67988 10.9395 8.12988C10.9395 7.57988 11.3795 7.12988 11.9395 7.12988Z"
				fill="#FFBB33"
			/>
			<path
				d="M12.6895 11.0301C12.6895 10.6201 12.3495 10.2801 11.9395 10.2801C11.5195 10.2801 11.1895 10.6201 11.1895 11.0401L11.1895 15.8701C11.1895 16.2801 11.5295 16.6201 11.9395 16.6201C12.3495 16.6201 12.6895 16.2801 12.6895 15.8701L12.6895 11.0301Z"
				fill="#FFBB33"
			/>
			<path
				d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
				fill="#FFBB33"
			/>
		</svg>
	);
}

export function comments(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 22.81C11.31 22.81 10.66 22.46 10.2 21.85L8.7 19.85C8.67 19.81 8.55 19.76 8.5 19.75H8C3.83 19.75 1.25 18.62 1.25 13L1.25 8C1.25 3.58 3.58 1.25 8 1.25L16 1.25C20.42 1.25 22.75 3.58 22.75 8V13C22.75 17.42 20.42 19.75 16 19.75L15.5 19.75C15.42 19.75 15.35 19.79 15.3 19.85L13.8 21.85C13.34 22.46 12.69 22.81 12 22.81ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8L2.75 13C2.75 17.52 4.3 18.25 8 18.25H8.5C9.01 18.25 9.59 18.54 9.9 18.95L11.4 20.95C11.75 21.41 12.25 21.41 12.6 20.95L14.1 18.95C14.43 18.51 14.95 18.25 15.5 18.25L16 18.25C19.58 18.25 21.25 16.58 21.25 13V8C21.25 4.42 19.58 2.75 16 2.75L8 2.75Z"
				fill="#FFBB33"
			/>
			<path
				d="M12 12C11.44 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.56 12 12 12Z"
				fill="#FFBB33"
			/>
			<path
				d="M16 12C15.44 12 15 11.55 15 11C15 10.45 15.45 10 16 10C16.55 10 17 10.45 17 11C17 11.55 16.56 12 16 12Z"
				fill="#FFBB33"
			/>
			<path
				d="M8 12C7.44 12 7 11.55 7 11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11C9 11.55 8.56 12 8 12Z"
				fill="#FFBB33"
			/>
		</svg>
	);
}

export function reviews(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 22.7498C11.3 22.7498 10.59 22.4798 10.06 21.9498L8.35001 20.2598C7.93001 19.8398 7.35001 19.6097 6.76001 19.6097H6C3.93 19.6097 2.25 17.9398 2.25 15.8898V4.97974C2.25 2.92974 3.93 1.25977 6 1.25977H18C20.07 1.25977 21.75 2.92974 21.75 4.97974V15.8898C21.75 17.9398 20.07 19.6097 18 19.6097H17.24C16.65 19.6097 16.07 19.8498 15.65 20.2598L13.94 21.9498C13.41 22.4798 12.7 22.7498 12 22.7498ZM6 2.74976C4.76 2.74976 3.75 3.74973 3.75 4.96973V15.8798C3.75 17.1098 4.76 18.0997 6 18.0997H6.76001C7.75001 18.0997 8.71 18.4998 9.41 19.1898L11.12 20.8798C11.61 21.3598 12.4 21.3598 12.89 20.8798L14.6 19.1898C15.3 18.4998 16.26 18.0997 17.25 18.0997H18C19.24 18.0997 20.25 17.0998 20.25 15.8798V4.96973C20.25 3.73973 19.24 2.74976 18 2.74976H6Z"
				fill="#FFBB33"
			/>
			<path
				d="M11.9998 15.7498C11.8198 15.7498 11.6398 15.7197 11.4798 15.6697C10.1598 15.2197 6.75977 13.2298 6.75977 9.50977C6.75977 7.70977 8.21977 6.24976 10.0098 6.24976C10.7398 6.24976 11.4398 6.49976 12.0098 6.93976C12.5798 6.49976 13.2798 6.24976 14.0098 6.24976C15.7998 6.24976 17.2598 7.70977 17.2598 9.50977C17.2598 13.2298 13.8498 15.2197 12.5398 15.6697C12.3598 15.7197 12.1798 15.7498 11.9998 15.7498ZM9.99976 7.74976C9.03976 7.74976 8.24976 8.53977 8.24976 9.50977C8.24976 12.3398 10.9997 13.9198 11.9597 14.2498C11.9697 14.2498 12.0098 14.2498 12.0398 14.2498C13.0098 13.9098 15.7397 12.3298 15.7397 9.51978C15.7397 8.54978 14.9597 7.75977 13.9897 7.75977C13.4397 7.75977 12.9298 8.01978 12.5898 8.45978C12.3098 8.83978 11.6698 8.83978 11.3898 8.45978C11.0698 8.00978 10.5598 7.74976 9.99976 7.74976Z"
				fill="#FFBB33"
			/>
		</svg>
	);
}


export function survey(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.5313 7.93579C17.6651 8.43378 17.3692 8.94568 16.8712 9.0791C16.3731 9.21258 15.8614 8.91705 15.7281 8.419C15.5948 7.92102 15.8902 7.40912 16.3882 7.27563C16.8863 7.14221 17.398 7.43774 17.5313 7.93579Z"
				fill="#686670"
			/>
			<path
				d="M4.61432 8.98004C5.12994 8.98004 5.54791 8.56207 5.54791 8.04645C5.54791 7.53088 5.12994 7.11291 4.61432 7.11291C4.09869 7.11291 3.68073 7.53088 3.68073 8.04645C3.68073 8.56207 4.09869 8.98004 4.61432 8.98004Z"
				fill="#686670"
			/>
			<path
				d="M10.2652 8.98004C10.7808 8.98004 11.1988 8.56207 11.1988 8.04645C11.1988 7.53088 10.7808 7.11291 10.2652 7.11291C9.74957 7.11291 9.3316 7.53088 9.3316 8.04645C9.3316 8.56207 9.74957 8.98004 10.2652 8.98004Z"
				fill="#686670"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.2603 21.1459L20.835 20.7241C22.6314 20.2427 23.2784 19.179 22.8174 17.4597L19.3961 4.69012C18.9351 2.97082 17.8433 2.37305 16.0464 2.85443L14.4722 3.2763C14.0567 3.38757 13.7032 3.52997 13.4102 3.70441C12.9737 3.06647 12.2012 2.75409 11.0801 2.75409L9.44977 2.75409C8.56403 2.75409 7.89606 2.94903 7.43951 3.34546C6.98297 2.94903 6.315 2.75409 5.42926 2.75409H3.79889C1.93903 2.75409 1.03912 3.61407 1.03912 5.3941L1.03912 18.6141C1.03912 20.3941 1.93903 21.2541 3.79889 21.2541H5.42926C6.315 21.2541 6.98297 21.0591 7.43951 20.6627C7.89606 21.0591 8.56403 21.2541 9.44977 21.2541H11.0801C12.94 21.2541 13.8399 20.3941 13.8399 18.6141V11.5807L15.9112 19.3102C16.3716 21.0296 17.4639 21.6274 19.2603 21.1459ZM13.9385 6.15246C13.7134 5.31213 13.817 5.00476 14.8604 4.72522L16.4346 4.30334C17.4879 4.02124 17.7217 4.23804 17.9468 5.07837L20.7515 15.5454L16.7432 16.6195L13.9385 6.15246ZM21.3687 17.8479L21.0069 16.4977L16.9981 17.572L17.3599 18.922C17.585 19.7624 17.8194 19.9792 18.8721 19.6971L20.4464 19.2752C21.4898 18.9957 21.5938 18.6883 21.3687 17.8479ZM3.79889 4.25409C2.7193 4.25409 2.53912 4.52411 2.53912 5.3941L2.53912 16.2303H6.68903L6.68903 5.3941C6.68903 4.52411 6.5191 4.25409 5.42926 4.25409H3.79889ZM6.68903 17.2164V18.6141C6.68903 19.4841 6.50934 19.7541 5.42926 19.7541H3.79889C2.70905 19.7541 2.53912 19.4841 2.53912 18.6141V17.2164H6.68903ZM9.44977 4.25409C8.37018 4.25409 8.19 4.52411 8.19 5.3941L8.19 16.2303H12.3399L12.3399 5.3941C12.3399 4.52411 12.17 4.25409 11.0801 4.25409L9.44977 4.25409ZM8.19 18.6141V17.2164H12.3399V18.6141C12.3399 19.4841 12.1602 19.7541 11.0801 19.7541H9.44977C8.35992 19.7541 8.19 19.4841 8.19 18.6141Z"
				fill="#686670"
			/>
		</svg>
	);
}

export function kb_doc_access(props) {
	return (
	  <svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	  >
		<path
		  fillRule="evenodd"
		  clipRule="evenodd"
		  d="M9 22.75H15C20.43 22.75 22.75 20.43 22.75 15V10C22.75 9.87958 22.7207 9.76519 22.6688 9.66369C22.6335 9.59257 22.5869 9.52688 22.53 9.46999L14.53 1.46999C14.5087 1.4497 14.4865 1.43071 14.4635 1.41307C14.3354 1.31124 14.174 1.25 14 1.25H9C3.57 1.25 1.25 3.57 1.25 9V15C1.25 20.43 3.57 22.75 9 22.75ZM13.25 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10.75H18C14.58 10.75 13.25 9.41999 13.25 5.99999V2.75ZM14.75 5.99999V3.80999L20.19 9.24999H18C15.42 9.24999 14.75 8.57999 14.75 5.99999Z"
		  fill="#15121F"
		/>
	  </svg>
	);
  }