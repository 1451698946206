import PreloaderBorder from "../PreloaderTemplates/PreloaderBorder";
import PreloaderBreadcrumbs from "../PreloaderTemplates/PreloaderBreadcrumbs";
import PreloaderCard from "../PreloaderTemplates/PreloaderCard";
import PreloaderCircle from "../PreloaderTemplates/PreloaderCircle";
import PreloaderLine from "../PreloaderTemplates/PreloaderLine";
import PreloaderList from "../PreloaderTemplates/PreloaderList";
import PreloaderRect from "../PreloaderTemplates/PreloaderRect";
import PreloaderSquare from "../PreloaderTemplates/PreloaderSquare";
import PreloaderMobileTitle from "./PreloaderMobileTitle";
import styles from "./Preloaders.module.sass";

const PreloaderDepartment = () => {
  return (
    <><PreloaderMobileTitle /><PreloaderBreadcrumbs /> <div className={styles.department__wrapper}> 
<PreloaderRect width={400} height={48} />
    <div className={styles.department}>
      <div className={styles.department__left}>
        <PreloaderCard padding={24}>
          <div className={styles.department__head}>  
            <PreloaderLine width={160} /><PreloaderCircle size={24} /></div>
          <div className={styles.department__line}>
            <PreloaderCircle size={24} />
            <PreloaderLine width={320} />
          </div>
          <div className={styles.department__line}>
            <PreloaderCircle size={24} />
            <PreloaderLine width={240} />
          </div>
          <div className={styles.department__line}>
            <PreloaderCircle size={24} />
            <PreloaderLine width={320} />
          </div>
        </PreloaderCard>
        <PreloaderCard padding={24}>
        <div className={styles.department__head}>  
            <PreloaderLine width={160} /><PreloaderCircle size={24} /></div>
          <PreloaderDepartmentItem /> <PreloaderDepartmentItem />{" "}
          <PreloaderDepartmentItem /> <PreloaderDepartmentItem />{" "}
          <PreloaderDepartmentItem />
        </PreloaderCard>
      </div>

      <div className={styles.department__right}>
      <PreloaderCard padding={24}>
              <div className={styles.department__title}>
                <PreloaderCircle size={24} />
                <PreloaderLine width={220} />
              </div>
              <PreloaderBorder />
              <div>
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={160} />
              </div>
              <div>
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={160} />
              </div>
              <div>
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={"100%"} />
                <PreloaderLine width={160} />
              </div>
            </PreloaderCard>
      </div>
    </div></div>
    </>
  );
};

export default PreloaderDepartment;

const PreloaderDepartmentItem = () => {
  return (
    <div className={styles.department__data}>
      <div className={styles.department__line}>
        <PreloaderCircle size={40} />
        <div className={styles.department__string}> 
          <PreloaderRect width={"100%"} height={20} />
          <PreloaderLine width={130} />
        </div>
      </div>
      <div>
      <div className={styles.department__string}> 
          <PreloaderRect width={"100%"} height={20} />
          <PreloaderLine width={130} />
        </div>
      </div>
      <PreloaderRect width={"100%"} height={20} />
    </div>
  );
};
