import styles from "./LoginForm.module.scss"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import React from "react"
import { Field, Form, Formik } from "formik"
import InputControl, { PasswordControl } from "../../forms/input-control"
import SwitcherLabel from "../../forms/switcher/SwitcherLabel"
import { login } from "../../../store/reducers/auth"
import { useDispatch } from "react-redux"
import Typography from "../../UIKit/base/Typography"
import Button from "../../forms/button/Button"
import { useTranslation } from "react-i18next"


export default function LoginForm() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(t("email_must_be_valid")).required(t("email_required")),
		password: Yup.string()
			.required(t("password_required"))
			.matches(
				/^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
				t("password_helper")
			),
	})

	function handleSubmit(values) {
		dispatch(login({ values, cb: () => navigate("/") }))
	}

	return (
		<div className={styles.wrapper}>
			<Typography fontVariant="medium" className={styles.header}>
				<svg
					width="22"
					height="22"
					viewBox="0 0 22 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M14.24 21.27H14.11C9.67002 21.27 7.53001 19.52 7.16002 15.6C7.12002 15.19 7.42002 14.82 7.84002 14.78C8.25002 14.74 8.62002 15.05 8.66002 15.46C8.95002 18.6 10.43 19.77 14.12 19.77H14.25C18.32 19.77 19.76 18.33 19.76 14.26V7.73998C19.76 3.66998 18.32 2.22998 14.25 2.22998H14.12C10.41 2.22998 8.93002 3.41998 8.66002 6.61998C8.61001 7.02998 8.27002 7.33998 7.84002 7.29998C7.42002 7.26998 7.12002 6.89998 7.15001 6.48998C7.49002 2.50998 9.64001 0.72998 14.11 0.72998H14.24C19.15 0.72998 21.25 2.82998 21.25 7.73998V14.26C21.25 19.17 19.15 21.27 14.24 21.27Z"
						fill="#5B5766"
					/>
					<path
						d="M13.1897 11.75L1 11.75C0.59 11.75 0.25 11.41 0.25 11C0.25 10.59 0.59 10.25 1 10.25L13.19 10.25L11.1198 8.17984C10.8298 7.88984 10.8298 7.40984 11.1198 7.11984C11.4098 6.82984 11.8898 6.82984 12.1798 7.11984L15.5298 10.4698C15.8198 10.7598 15.8198 11.2398 15.5298 11.5298L12.1798 14.8798C12.0298 15.0298 11.8398 15.0998 11.6498 15.0998C11.4598 15.0998 11.2698 15.0298 11.1198 14.8798C10.8298 14.5898 10.8298 14.1098 11.1198 13.8198L13.1897 11.75Z"
						fill="#5B5766"
					/>
				</svg>
				{t("login")}
			</Typography>
			<Formik
				initialValues={{
					email: "",
					password: "",
					rememberMe: false,
				}}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{(props) => {
					return (
						<Form>
							<Field
								component={InputControl}
								name="email"
								label={t("email")}
								placeholder={t("enter_email_login")}
								message
							/>

							<div className={styles.password_wrap}>
								<Field
									component={PasswordControl}
									name="password"
									label={t("password")}
									placeholder={t("enter_password")}
									message
								/>
								<Typography
									fontVariant="medium"
									component={Link}
									className={styles.recover}
									to="/forgot-password"
								>
									{t("forgot_password")}
								</Typography>
							</div>
							<SwitcherLabel name="rememberMe" label={t("remember-me")} />
							<div className={styles.buttons}>
								<Button className={styles.submit} type="submit" fontVariant="">
									login
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}
