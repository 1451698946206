import styles from "./attachments.module.scss"
import React from "react"
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { AttachmentType } from '../../propTypes'
import { AttachmentPngIcon, AttachmentJpegIcon, AttachmentJpgIcon, AttachmentXlsxIcon, AttachmentXlsIcon, AttachmentZipIcon, AttachmentDocxIcon, AttachmentDocIcon, AttachmentPdfIcon, DocumentIcon } from '../icons'
export default function Attachments({ attachments }) {
	if (!attachments?.length) {
		return null
	}

	return attachments?.map((e) => {
		const type = e.filename.split(".").pop()
		return (
			<div key={`${e.id}-${e.filename}`} className={styles.files_wrap}>
				<Link
					to={e.url}
					filename={e.original_filename ? e.original_filename : e.filename}
					className={styles.link}
					download
				>
					<div className={styles.files_data_wrap}>
						<div className={styles.files_text_and_icon}>
							<AttachmentIcon type={type} />
							<div>
								{e.original_filename ? e.original_filename : e.filename}
							</div>
						</div>
					</div>
				</Link>
			</div>
		)
	})
}

export function AttachmentIcon({ type }) {
	switch (type) {
		case "pdf":
			return <AttachmentPdfIcon />
		case "zip":
			return <AttachmentZipIcon />
		case "doc":
			return <AttachmentDocIcon />
		case "docx":
			return <AttachmentDocxIcon />
		case "jpg":
			return <AttachmentJpgIcon />
		case "jpeg":
			return <AttachmentJpegIcon />
		case "png":
			return <AttachmentPngIcon />
		case "xls":
			return <AttachmentXlsIcon />
		case "xlsx":
			return <AttachmentXlsxIcon />
		case "ppt":
			return <DocumentIcon />
		case "pptx":
			return <DocumentIcon />
		default:
			return <DocumentIcon />
	}
}

Attachments.propTypes = {
	attachments: PropTypes.shape(AttachmentType),
}
