export function LoadIcon(props) {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 1.02C12 0.456668 11.5425 -0.00450375 10.9812 0.043323C9.3143 0.185352 7.69154 0.674759 6.21895 1.48432C4.4477 2.45807 2.95111 3.86346 1.86806 5.57008C0.785013 7.27669 0.150595 9.22923 0.0236786 11.2465C-0.103238 13.2638 0.28146 15.2805 1.14208 17.1094C2.00269 18.9383 3.31134 20.5201 4.94658 21.7082C6.58182 22.8963 8.49067 23.652 10.496 23.9054C12.5013 24.1587 14.5382 23.9014 16.4175 23.1573C17.9799 22.5387 19.3914 21.6003 20.5634 20.4065C20.958 20.0045 20.8943 19.358 20.4602 18.9989C20.0262 18.6398 19.3866 18.705 18.9851 19.1C18.0371 20.0327 16.9093 20.7685 15.6665 21.2606C14.1067 21.8782 12.4161 22.0917 10.7517 21.8815C9.08726 21.6712 7.50291 21.0439 6.14566 20.0578C4.78841 19.0717 3.70223 17.7587 2.98792 16.2408C2.27361 14.7228 1.95431 13.049 2.05965 11.3746C2.16499 9.70026 2.69156 8.07966 3.59049 6.66317C4.48942 5.24668 5.73159 4.0802 7.20173 3.27199C8.37308 2.62803 9.65891 2.22813 10.9818 2.09218C11.5421 2.03459 12 1.58333 12 1.02Z"
				fill="#FBFAFF"
			/>
			
		</svg>
	)
}
